import React, { FC } from "react"

import { Button, Form, Input } from "antd"
import { Link, useNavigate } from "react-router-dom"

import styles from "./Login.module.scss"
import { showFormErrors } from "../../../helpers/utils/ui"
import { routeNames } from "../../../shared/common/constants/routes"
import { useActions } from "../../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../../shared/common/hooks/useTypedSelector"

const Login: FC = () => {
  document.title = "Вход"

  const [ form ] = Form.useForm()
  const navigate = useNavigate()

  const { isLoading } = useTypedSelector((state) => state.auth)
  const { authLogin } = useActions()

  /**
   * Обработчик входа на сервис.
   * @param values - объект с данными для логина
   */
  const onFinish = async (values: { email: string; password: string }) => {
    await authLogin(
      values.email.toLowerCase(),
      values.password,
      () => {
        navigate(routeNames.home, { replace: true })
      },
      (errors) => {
        showFormErrors(form, errors)
      }
    )
  }

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginModal}>
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item>
            <div className={styles.logoWrapper}>
              <img alt="SCAN" src="/logo.jpg" />
            </div>
          </Form.Item>

          <Form.Item>
            <div className={styles.topInfo}>АВТОРИЗАЦИЯ</div>
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Введите email.", type: "email" },
            ]}
          >
            <Input placeholder="Email" size="large" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[ { required: true, message: "Введите пароль." } ]}
          >
            <Input.Password placeholder="Пароль" size="large" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className={styles.submitButton}
              loading={isLoading}
            >
              Войти
            </Button>
          </Form.Item>

          <div className={styles.loginInfo}>
            Забыли пароль? <Link to={routeNames.resetPassword}>Сбросить</Link>.{" "}
            <br />
            Нет аккаунта? <Link to={routeNames.signup}>Зарегистрироваться</Link>
            .
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Login
