import React, { useEffect, useState } from "react"

import { Form, InputNumber, Modal, Select } from "antd"
import TextArea from "antd/es/input/TextArea"

import { PaymentAdd } from "../../../../../../../app/types/unApi"
import { showErrorMessage } from "../../../../../../../helpers/utils/ui"
import { unApi } from "../../../../../api/endpoints/reports/unApi"
const AddPayment = ({ addPaymentModalVisible, setAddPaymentModalVisible, id, schoolName, loadRows }:any) => {
    const [ form ] = Form.useForm()

    const [ formLoading, setFormLoading ] = useState(false)
    const [ paymentTypes, setPaymentTypes ] = useState([])
    const [ paymentAccounts, setPaymentAccounts ] = useState([])
    const [ paymentCurrencyList, setPaymentCurrencyList ] = useState([])
    const [ formCurrency, setFormCurrency ] = useState(1)
    const fetchData = async () => {
        setFormLoading(true)
        try{
            const [ currencies, types, check ] = await Promise.all([
                unApi.getFilterList(0, "currency_name", ""),
                unApi.getFilterList(0, "crm_payment_type", ""),
                unApi.getFilterList(0, "crm_payment_check", ""),
            ])

            form.resetFields()
            setPaymentCurrencyList(currencies.data)
            setPaymentTypes(types.data)
            setPaymentAccounts(check.data)
        }catch(err){
            showErrorMessage("Произошла ошибка , повторите попытку позже.")
        }finally{
            setFormLoading(false)

        }
    }


    useEffect(() => {
        if (addPaymentModalVisible) {
            fetchData()
        }
    }, [ addPaymentModalVisible ])

    const submitForm =  async (values:PaymentAdd) => {
        setFormLoading(true)
        try{
            const response = await unApi.addPayment({ ...values,order_id:Number(id),payment_price:Number(values.payment_price),currency_id:Number(formCurrency) })
            if(response.status === 200){
                form.resetFields()
                setAddPaymentModalVisible(false)
                loadRows()
                Modal.info({
                    title: "Успех!",
                    content: "Платеж добавлен.",
                })
            }
        }catch(err){
            showErrorMessage("Произошла ошибка, повторите попытку позже.")
        }finally {
            setFormLoading(false)
        }
    }

    return (
        <Modal
            title={<h5 style={{ margin: 0 }}>Платеж по заказу №{id}</h5>}
            open={addPaymentModalVisible}
            onCancel={() => {setAddPaymentModalVisible(false)}}
            onOk={() => {form.submit()}}
            okButtonProps={{ loading: formLoading }}
            okText="Сохранить"
            destroyOnClose
        >
            <Form
                form={form}
                onFinish={submitForm}
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 16 }}
                requiredMark={false}
            >
                <Form.Item>
                    <h5>
                        {schoolName}
                    </h5>
                </Form.Item>
                <Form.Item
                    name="payment_price"
                    label="Сумма"
                    rules={[ { required: true } ]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    name="currency_id"
                    label="Валюта"
                >
                    <Select
                        style={{ width: 90, marginLeft: 5 }}
                        defaultValue={1}
                        value={formCurrency}
                        onSelect={(value) => setFormCurrency(value)}
                        loading={formLoading}
                        disabled={formLoading}
                    >
                        {
                            paymentCurrencyList.length > 0 ?
                                paymentCurrencyList.map((item:any, index:number) => {
                                    return (
                                        <Select.Option key={index} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    )
                                })
                                :
                                <Select.Option value="">Нет</Select.Option>
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="payment_type"
                    label="Тип оплаты"
                    rules={[ { required: true } ]}
                >
                    <Select loading={formLoading} disabled={formLoading}>
                        {
                            paymentTypes.length > 0 ?
                                paymentTypes.map((item:any, index:number) => {
                                    return (
                                        <Select.Option key={index} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    )
                                })
                                :
                                <Select.Option value="">Нет</Select.Option>
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="score_account"
                    label="Счет пополнения"
                    rules={[ { required: true } ]}
                >
                    <Select loading={formLoading} disabled={formLoading}>
                        {
                            paymentAccounts.length > 0 ?
                                paymentAccounts.map((item:any, index:number) => {
                                    return (
                                        <Select.Option key={index} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    )
                                })
                                :
                                <Select.Option value="">Нет</Select.Option>
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="comment_client"
                    label="Комментарий для клиента"
                >
                    <TextArea />
                </Form.Item>
                <Form.Item
                    name="comment_manager"
                    label="Комментарий для менеджера"
                >
                    <TextArea />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddPayment
