import React, { FC, useEffect, useState } from "react";

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Descriptions, Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";

import EditModal from "./EditModal";
import MainLayout from "../../../../shared/common/components/layouts/MainLayout";
import PageHead from "../../../../shared/common/components/page-header/PageHeader";
import { routeNames } from "../../../../shared/common/constants/routes";
import { useActions } from "../../../../shared/common/hooks/useActions";
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector";
import { displayLanguageByKey } from "../../../../helpers/utils/ui";
import {updateDurationIntervals} from "../../../../helpers/utils/data";

const SchoolEdit: FC = () => {
  const navigate = useNavigate();
  const [editModalVisible, setEditModalVisible] = useState(false);

  const { isLoading, schoolDetail, selectedSchool, isUpdate } =
    useTypedSelector((state) => state.currentSchool);
  const {
    currentSchoolFetchDetails,
    currentSchoolSetUpdate,
    currentSchoolDelete,
    fetchUserAndSchoolInfo,
  } = useActions();

  useEffect(() => {
    if (selectedSchool) {
      currentSchoolFetchDetails(selectedSchool.id);
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (isUpdate && selectedSchool) {
      currentSchoolFetchDetails(selectedSchool.id);
      currentSchoolSetUpdate(false);
    }
  }, [isUpdate]);

  const removeCurrentSchool = () => {
    if (selectedSchool) {
      currentSchoolDelete(selectedSchool.id, () => {
        fetchUserAndSchoolInfo(navigate, () => {
          navigate(routeNames.schoolAdd);
        });
      });
    }
  };

  const showConfirm = () => {
    Modal.confirm({
      title: "Вы уверены что хотите удалить школу?",
      icon: <ExclamationCircleOutlined />,
      okText: "Да",
      okType: "danger",
      cancelText: "Нет",
      onOk() {
        removeCurrentSchool();
      },
    });
  };

  return (
    <MainLayout pageTitle="Школа">
      <div className="page-wrapper">
        <PageHead
          title="Подключенная школа"
          extra={
            <div>
              <Button
                icon={<EditOutlined />}
                type="primary"
                loading={isLoading}
                disabled={!schoolDetail}
                onClick={() => setEditModalVisible(true)}
                style={{ marginRight: 5 }}
              />
              <Button
                icon={<DeleteOutlined />}
                loading={isLoading}
                onClick={() => showConfirm()}
                danger
              />
            </div>
          }
        />

        <div className="page-content">
          {isLoading ? (
            <div
              className="global-loading-container"
              style={{ height: "60vh" }}
            >
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : (
            <Descriptions bordered column={1}>
              <Descriptions.Item
                label="Название школы"
                labelStyle={{ minWidth: 300 }}
                contentStyle={{ minWidth: "60%" }}
              >
                {schoolDetail?.name || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Валюта">
                {schoolDetail?.currency.name || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Язык интерфейса">
                {displayLanguageByKey(schoolDetail?.language.name || "-")}
              </Descriptions.Item>
              <Descriptions.Item label="Часовой пояс">
                {schoolDetail?.timezone || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Домен школы геткурса">
                {schoolDetail?.domain || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Основной домен школы">
                {schoolDetail?.main_domain || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Последнее обновление">
                {schoolDetail?.last_update || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Частота обновления данных">
                {updateDurationIntervals.find((el)=>el.id === schoolDetail?.update_duration)?.name || "-"}
              </Descriptions.Item>
            </Descriptions>
          )}
        </div>

        <EditModal
          isVisible={editModalVisible}
          setVisible={setEditModalVisible}
          initialData={schoolDetail}
        />
      </div>
    </MainLayout>
  );
};

export default SchoolEdit;
