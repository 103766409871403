import React, { useEffect, useMemo, useState } from "react"

import { CopyOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import {Button, Dropdown, Empty, Menu, message, Tooltip} from "antd"
import _ from "lodash"
import moment from "moment"
import BaseTable from "react-base-table"
import "react-base-table/styles.css"

import { useLocation } from "react-router-dom"

import OverlayTable from "./OverlayTable"
import { showErrorMessage } from "../../../../../../../helpers/utils/ui"
import { unApi } from "../../../../../api/endpoints/reports/unApi"
import { API_REPORT_TABLE } from "../../../../../api/urls"
import { useActions } from "../../../../../hooks/useActions"
import { useTypedSelector } from "../../../../../hooks/useTypedSelector"
import Loading from "../Loading"
import {
    calcTableCellsFill,
    disableScroll,
    enableScroll,
    formatCogTableCell,
} from "../utils"


const COL_GROUP_MINWIDTH = 168
const COL_WIDTH = 168
const COL_MIN_WIDTH = 168
const COL_MAX_WIDTH = 900
const HEADER_HEIGHT = 35

export default function UniversalTable({ filters, filtersReq, loadingChart, loadingTable, setLoadingTable }) {
    const location = useLocation()
    const { setTableData, setMetaTableFetching } = useActions()
    const period = useTypedSelector((state) => state.period)
    const { isTableFetching } = useTypedSelector(state => state.meta)
    const { groupings, metrics, tableData } = useTypedSelector((state) => state.table)
    const { currency } = useTypedSelector((state) => state.meta)
    const { details } = useTypedSelector((state) => state.details)
    useEffect(()=>{
        loadRows()
    },[ details ])
    const isCohort = () => {
        const hasDigitsRegEx = /\d/
        const hasDigits = hasDigitsRegEx.test(details)
        if(location.pathname.includes("cohort") && hasDigits){
            return "d"
        }else{
            return details
        }
    }
    const [ dimensionsTable, setDimensionsTable ] = useState({ height: 0, width: 0 })
    const [ data, setData ] = useState([])
    const [ columnsTable, setColumnsTable ] = useState([])
    const [ errorTable, setErrorTable ] = useState(false)
    const [ parsedValues, setParsedValues ] = useState([])
    const [ tableWidth, setTableWidth ] = useState(1200)
    const [ errorMessage, setErrorMessage ] = useState("Ошибка при получении данных")

    const tableDiv = document.getElementById("universal_table")

    useEffect(() => {
        if (tableDiv) {
            disableScroll()

            const calcTableWidth = () => {
                const width = tableDiv.offsetWidth
                setTableWidth(width <= 1000 ? 1000 : width)
            }

            new ResizeObserver(calcTableWidth).observe(tableDiv)
            calcTableWidth()

            setTimeout(() => enableScroll(), 500)
        }
    }, [ tableDiv ])

    const loadRows = () => {
        let __attribution = filtersReq.attribution
        let __type_app = filtersReq.type_applications
        let __cohort = filtersReq.cohort
        let __metric = filtersReq.metric
        let __period = period

        setLoadingTable(true)

        const format = "YYYY-MM-DD"
        const _period = { start: moment(__period.start).format(format), end: moment(__period.end).format(format) }
        const expanded_ids = []
        const path = location.pathname
        const data = {
            period: _period,
            filters: filters,
            attribution: {
                attribution: typeof __attribution === "string" ? [ __attribution ] : __attribution,
                type_applications: typeof __type_app === "string" ? [ __type_app ] : __type_app,
                cohort: typeof __cohort === "string" ? [ __cohort ] : __cohort,
                metric: typeof __metric === "string" ? [ __metric ] : __metric,
            },
            details: isCohort() ,
            expanded_ids,
        }
        window.localStorage.setItem("prevRequestState", JSON.stringify(data))
        console.log("=>> REQUEST =>>\n", data, "\n=>> REQUEST =>>")
        unApi.getTableReportData(API_REPORT_TABLE, data, path)
            .then((response) => {
                const data = response.data

                console.log("<<= RESPONSE <<=\n", data, "\n<<= RESPONSE <<=")
                if (data.rows) {
                    setTableData({ ...data, groupings })

                } else {
                    showErrorMessage("Ошибка при получении данных")
                    if (response.status === 504) {
                        showErrorMessage("Запрос выполняется слишком долго, из-за большого количества данных. \n Можно уменьшить период отчёта, удалить ненужные метрики или фильтры.")
                    }

                    console.log("Ошибка")
                }

                setLoadingTable(false)
                // setCompareLoading((prevState) => ([ ...prevState.filter((val) => val.time !== time), {time: time, loading: false}]))
            })
            .catch((error) => {
                console.log("error = ", error)
                showErrorMessage("Ошибка при получении данных")
                if (error.response) {
                    if (error.response.status === 504) {
                        showErrorMessage("Запрос выполняется слишком долго, из-за большого количества данных. \n Можно уменьшить период отчёта, удалить ненужные метрики или фильтры.")
                    }
                }

                setData([])
                setLoadingTable(false)
            })
    }

    useEffect(() => {
        disableScroll()
        setErrorTable(false)

        if (isTableFetching === false) return
        loadRows()
        setMetaTableFetching({value:false})

        setTimeout(() => enableScroll(), 500)
    }, [ isTableFetching ])

    useEffect(() => {
        const docEl = document.documentElement
        setDimensionsTable({
            height: docEl.clientHeight - 150,
            width: tableWidth,
        })
    }, [ tableWidth ])

    const widthGroupCol = useMemo(() => {
        let width = dimensionsTable.width - 20 - 20 * COL_WIDTH
        return width > COL_GROUP_MINWIDTH ? width : COL_GROUP_MINWIDTH
    }, [ dimensionsTable.width, metrics?.length ])
    const makeNewCols = (_parsedValues) => {
        let cols = [
            {
                title: "",
                key: "dates",
                dataKey: "dates",
                width: widthGroupCol,
                minWidth: widthGroupCol,
                maxWidth: widthGroupCol + 300,
                height:30,
                maxHeight:30,
                frozen: "left",
                resizable: false,
                cellRenderer: (renderItem) => {
                    return <span style={{ fontWeight: "bold", minHeight: 35, display: "flex", alignItems: "center", justifyContent: "center" }}>{renderItem.cellData}</span>
                }
            },
            ...data.map((item) => {
                return {
                    title: item.dates,
                    dataKey: item.dates,
                    key: item.dates,
                    width: COL_WIDTH,
                    minWidth: COL_MIN_WIDTH,
                    maxWidth: COL_MAX_WIDTH,
                    headerRenderer: ({ column }) => (
                     column.title ? <span style={{height: 35, display: "flex", alignItems: "center",
                         justifyContent: "center", width: "100%" }}>{column.title}</span> : null
                    ),
                    cellRenderer: (renderItem) => {
                        const currentItem = _parsedValues.find((item) => item.value === renderItem.cellData)

                        let opacity = 0
                        if (currentItem) {
                            opacity = currentItem.opacity
                        }

                        if (renderItem.cellData === 0 || item.dates === "Всего" || renderItem.rowData.dates === "Всего") {
                            opacity = 0
                        }

                        if ((renderItem.cellData === "" || !renderItem.cellData) && (item.dates === "Всего" || renderItem.rowData.dates === "Всего")) {
                            return <span style={{ height: 35, display: "flex", alignItems: "center", justifyContent: "center", width: "100%", backgroundColor: "rgba(56,162,255,0)" }}>
                                {formatCogTableCell(0, currency)}
                            </span>
                        }

                        return <span style={{ height: 35, display: "flex", alignItems: "center", justifyContent: "center", width: "100%", backgroundColor: `rgba(56,162,255,${opacity})` }}>
                            {formatCogTableCell(renderItem.cellData, currency)}
                        </span>
                    }
                }
            }),
        ]

        if (cols.length > 1) {
            cols[1].resizable = false
            cols[1].frozen = "right"
            cols[1].cellRenderer = (renderItem) => {
                return <span style={{ height: 35, display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>{formatCogTableCell(renderItem.cellData, currency)}</span>
            }
        }

        return cols
    }

    useEffect(() => {
        const cols = makeNewCols(parsedValues)
        setColumnsTable(cols)
    }, [ data, parsedValues ])

    useEffect(() => {
        if (data.length > 0) {
            let _data = []

            data.forEach((item) => {
                Object.entries(item).forEach(([ key, val ]) => {

                    if (key === "Всего") return
                    if (_.isNumber(val)) {
                        _data.push(val)
                    }
                })
            })

            _data = _data.filter((value, index, self) => self.indexOf(value) === index)
            _data.sort((a, b) => { return a - b })

            const _newData = calcTableCellsFill(_data)

            setParsedValues(_newData)
            const cols = makeNewCols(_newData)
            setColumnsTable(cols)
        }
    }, [ data ])

    useEffect(() => {
        if (tableData.data.length > 0) {
            let _data = _.cloneDeep(tableData.data)
            _data.unshift(_data.pop())

            setData(_data)
        }
    }, [ tableData.data ])

    const copyAllTableData = () => {
        const removeHtmlTags = (input) => {
            const regex = /<[^>]*>/g;
            return input.replace(regex, '')
        }
        const parseData = (_data) => {
            let str = ''

            _data.forEach((dataItem) => {
                let _tempStr = ''
                Object.entries(dataItem).forEach(([key, val]) => {
                        if(location.pathname === "/users/cohort" && (key === "pathStr" || key === "pathStr2" || key === "children" || key === "clientData")) return
                        const cleanedValue = removeHtmlTags(String(val))
                        _tempStr += `${cleanedValue}\t`
                })
                str += `${_tempStr}\n`
            })

            return str
        };

        try {
            let data = tableData.data
            let copyDataStr = ""
            let headerRowStr = " \t"

            Object.entries(data[0]).forEach(([ key, val ]) => {
                if (key !== "dates" && key !== "pathStr" && key !== "pathStr2" && key !== "children" && key !== "clientData") {
                    headerRowStr += `${key}\t`
                }
            })

            copyDataStr += `${headerRowStr}\n`

            copyDataStr += parseData(data)

            navigator.clipboard.writeText(copyDataStr)
                .then(() => {
                    message.success({
                        content: "Скопировано",
                        style: {
                            marginTop: "20%",

                        },
                    })
                })
                .catch(() => {
                    message.error({
                        content: "Ошибка при копировании данных",
                        style: {
                            marginTop: "20%",
                        },
                    })
                })
        } catch (err) {
            message.error({
                content: "Ошибка при копировании данных",
                style: {
                    marginTop: "20%",
                },
            })
        }
    }
    const copyAllTableDataLow = () => {
        if(tableData.data.some((el)=>el.children.length > 0)){
            const parseData = () => {
                const removeNbsp = (value) => {
                    return value.replace(/&nbsp;/g, " ").replace(/<[^>]*>/g,"")
                }

                const parseRow = (item, prevTitle) => {
                    let _row = []

                    Object.entries(item).forEach(([ key, value ]) => {
                        if (key === "group") {
                            if (`${value}`.includes("<a")) {
                                let _val = `${value}`.split(">")
                                _val = _val[1].split("<")
                                _val = _val[0]

                                _row[0] = prevTitle ? `${prevTitle} - ${removeNbsp(_val)}` : removeNbsp(_val)
                            } else {
                                _row[0] = prevTitle ? `${prevTitle} - ${removeNbsp(value)}` : removeNbsp(value)
                            }
                        }

                        if (
                            key !== "id" &&
                            key !== "pathStr" &&
                            key !== "children" &&
                            key !== "clientData"
                        ) {
                            metrics.forEach((metricItem, index) => {
                                if (key === metricItem.dataKey) {
                                    if (`${value}`.includes("<a")) {
                                        let _val = `${value}`.split(">")
                                        _val = _val[1].split("<")
                                        _val = _val[0]
                                        _row[index + 1] = removeNbsp(_val)
                                    } else {
                                        _row[index + 1] = `${value}`.replaceAll(".", ",")
                                    }
                                }
                            })
                        }

                        if (key === "children" && value) {
                            if (value[0].group !== "empty") {
                                value.forEach((item2) => {
                                    let __row = parseRow(item2, _row[0])

                                    let strRow1 = "\n"

                                    __row.forEach((tempItem) => {
                                        tempItem.forEach((item3) => {
                                            strRow1 += `${removeNbsp(item3)}\t`
                                        })
                                    })

                                    _row.push(strRow1)
                                })
                            }
                        }
                    })

                    return [ _row ]
                }

                let copyDataStr = ""

                data.forEach((item) => {
                    let [ _row ] = parseRow(item)
                    let strRow = ""
                    _row.forEach((item) => {
                        strRow += `${removeNbsp(item)}\t`
                    })
                    strRow += "\n"

                    copyDataStr += strRow
                })

                return copyDataStr
            }

            try {
                let copyDataStr = ""
                let headerRowStr = ""

                groupings.forEach((groupItem, index) => {
                    if (index === 0) {
                        headerRowStr += groupItem.label
                    } else {
                        headerRowStr += ` - ${groupItem.label}`
                    }
                })

                headerRowStr += "\t"

                metrics.forEach((metricItem) => {
                    headerRowStr += `${metricItem.label}\t`
                })

                copyDataStr += `${headerRowStr}\n`
                const filterData = (rawData) => {
                    const lines = rawData.split("\n")
                    return lines.filter(line => {
                        const prefix = line.split("\t")[0]
                        return !lines.some(otherLine =>
                            otherLine !== line && otherLine.startsWith(prefix) && otherLine.length > line.length
                        )
                    })
                }


                copyDataStr += filterData(parseData()).join("\n")

                navigator.clipboard
                    .writeText(copyDataStr)
                    .then(() => {
                        message.success({
                            content: "Скопировано",
                            style: {
                                marginTop: "20%",
                            },
                        })
                    })
                    .catch(() => {
                        message.error({
                            content: "Ошибка при копировании данных",
                            style: {
                                marginTop: "20%",
                            },
                        })
                    })
            } catch (err) {
                message.error({
                    content: "Ошибка при копировании данных",
                    style: {
                        marginTop: "20%",
                    },
                })
            }
        }else {
            copyAllTableData()
        }
    }
    const baseTableRows = document.querySelectorAll(".BaseTable__row");
    baseTableRows.forEach((row) => {
        row.style.height = "35px";
    });

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={copyAllTableData}>
                Все уровни
            </Menu.Item>
            <Menu.Item key="2" onClick={copyAllTableDataLow}>
                Последний уровень
            </Menu.Item>
        </Menu>
    );
    return (
        <div className="universal-table" id="universal_table">
            <Loading loading={loadingTable}>
                <div className="universal-table-settings" style={{ width: dimensionsTable.width }}>
                    <div style={{ display: "flex", alignItems: "center", width: widthGroupCol - 10 }}>
                        <span>Даты регистраций</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: dimensionsTable.width - widthGroupCol - 15 }}>
                        <div>
                            <span>Даты платежей</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Tooltip
                                title={
                                    <div className="page-title-tooltip-text">
                                        Копирование данных таблицы в буфер обмена, чтобы можно было
                                        вставить данные в Excel или Гугл-таблицы.
                                    </div>
                                }
                                color="#fff"
                                zIndex={9999}
                                placement="topLeft"
                            >
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Button icon={<CopyOutlined />} type="text" disabled={loadingChart} />
                                </Dropdown>
                            </Tooltip>
                            <Tooltip
                                placement="bottom"
                                title={<div className="page-title-tooltip-text" dangerouslySetInnerHTML={{ __html: "Описание скоро будет..." }}></div>}
                                color="#fff"
                                zIndex={9999}
                                trigger="click"
                                overlayClassName="page-title-tooltip"
                            >
                                <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }}/>
                            </Tooltip>
                        </div>
                    </div>
                </div>

                <BaseTable
                    fixed
                    columns={columnsTable}
                    data={data}
                    rowKey="pathStr2"
                    width={dimensionsTable.width}
                    maxHeight={dimensionsTable.height}
                    minHeight={300}
                    headerHeight={HEADER_HEIGHT}
                    rowHeight={35}
                    disabled={loadingTable}
                    overlayRenderer={
                        <OverlayTable
                            headerHeight={HEADER_HEIGHT}
                            empty={data.length === 0}
                            error={errorTable}
                            errorMessage={errorMessage}
                        />
                    }
                    rowRenderer={
                        ({ rowData, cells }) => {
                            if (rowData.group === "empty")
                                return (
                                    <div className="table-row-empty">
                                        <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description={false}
                                            imageStyle={{ height: 27, marginBottom: 0 }}
                                        />
                                        Нет данных, соответствующих условиям отчёта.
                                    </div>
                                )
                            return cells
                        }
                    }
                />
            </Loading>
        </div>
    )
}
