import React from "react"

import IconFilter from "../IconFilter"

const TextView = ({ icon, name, text }: any) => {
    return (
        <>
            <IconFilter icon={icon} />
            &nbsp;{name}:
            &nbsp;{text}
        </>
    )
}

export default TextView
