import {Button, Form, message, Modal, Space, Upload} from "antd";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {useTypedSelector} from "../../../../shared/common/hooks/useTypedSelector";
import {getUploadProps} from "../../../../helpers/utils/functions";

interface Props {
    visible: boolean
    setVisible: Dispatch<SetStateAction<{ visible: boolean; account_id: number; }>>
    accountId:number
}
export const ImportFile = ({visible,setVisible,accountId}:Props) => {
    const selectedSchool = useTypedSelector((state)=>state.currentSchool.selectedSchool)
    const [isLoading,setIsLoading] = useState(false)
    const isMounted = useRef(false)
    useEffect(()=>{
       if(!isLoading && isMounted.current){
           setVisible({visible:false,account_id:0})
       }
        isMounted.current = true
    },[isLoading])
    return (
        <Modal onCancel={()=>setVisible({visible:false,account_id:0})} footer={null} open={visible} title={"Импорт из файла"}>
            <div style={{display:"flex",justifyContent:"center"}}>
                <Upload {...getUploadProps(setIsLoading,"vk",selectedSchool,accountId)}>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        loading={isLoading}
                    >
                        Загрузить
                    </Button>
                </Upload>
            </div>
        </Modal>
    )
}
