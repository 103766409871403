import React from 'react';
import { Modal, DatePicker, Form } from 'antd';
import {adsSpendApi} from "../../../../../../shared/common/api/endpoints/adsSpendApi";
import {showErrorMessage} from "../../../../../../helpers/utils/ui";
import {useTypedSelector} from "../../../../../../shared/common/hooks/useTypedSelector";
type Props = {
    state: { visible: boolean, id: number }
    setState: React.Dispatch<React.SetStateAction<{ visible: boolean,id: number }>>
    setRefetch: React.Dispatch<React.SetStateAction<boolean>>
}
const ProcessRKModal = ({ state, setState,setRefetch }:Props) => {
    const [form] = Form.useForm();
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)

    const handleOk =  () => {
        if(selectedSchool?.id){
            form.validateFields().then(async values => {
                const formattedValues = {
                    date_stat_at: values.date_stat_at.format('YYYY-MM-DD'),
                    date_stat_to: values.date_stat_to.format('YYYY-MM-DD')
                }
                const data = {
                    ...formattedValues,
                    school_id:selectedSchool.id,
                    id:state.id
                }
                try{
                    if(selectedSchool){
                        await adsSpendApi.processRK(data)
                        form.resetFields()
                        Modal.info({
                            title:"Успех!",
                        })
                        setRefetch((prev)=>!prev)
                        setState({visible:false,id:0})
                    }
                }catch (err:any) {
                    const errorMessage = err.response.data.date_stat
                    if(errorMessage.includes("Уже задан расход по этому объявлению за")){

                        form.setFields([ {
                            name:"date_stat",
                            errors:[ "За эту дату по указанным UTM меткам уже добавлен расход. Вы можете отредактировать сумму старого объявления или добавить новое с другими UTM метками. UTM метки нужны чтобы SCAN мог найти регистрации с этими метками и связать их с объявлением. Это позволит Вам видеть окупаемость рекламы." ]

                        } ])
                    }else{
                        showErrorMessage(errorMessage)
                    }
                }
            }).catch(info => {
                console.log('Validate Failed:', info);
            });
        }
    };

    return (
        <Modal
            title="Выбор даты"
            open={state.visible}
            onCancel={()=>{setState({visible:false,id:0})}}
            onOk={handleOk}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="date_stat_at"
                    label="Дата от"
                    rules={[{ required: true, message: 'Пожалуйста, выберите дату от' }]}
                >
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <DatePicker format="YYYY-MM-DD" />
                </Form.Item>
                <Form.Item
                    name="date_stat_to"
                    label="Дата до"
                    rules={[{ required: true, message: 'Пожалуйста, выберите дату до' }]}
                >
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <DatePicker format="YYYY-MM-DD" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ProcessRKModal;
