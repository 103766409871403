import axios, { AxiosResponse } from "axios"

import { API_SEND_HIT } from "../urls"

export const logsApi = {
    sendHit: (hit: string, location: string, type: number): Promise<AxiosResponse> | undefined => {
        const data = {
            type: type,
            url: location,
            hit: hit
        }
        if(!localStorage.getItem("access_admin")){
            return axios.post(API_SEND_HIT, data)
        }
    },
}
