import React, { FC, useEffect } from "react"

import {Form, Input, InputNumber, Modal, Select} from "antd"
import { useNavigate } from "react-router-dom"

import { useActions } from "../../../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"
import { ISchoolDetails, ISchoolFormData } from "../../../../app/providers/redux/slices/currentSchool/types"
import { showFormErrors } from "../../../../helpers/utils/ui"
import {validateDomain} from "../../../../helpers/utils/validators";
import {updateDurationIntervals} from "../../../../helpers/utils/data";

interface IAddModalProps {
    isVisible: boolean
    setVisible: (val: boolean) => void
    initialData: ISchoolDetails | undefined
}

const EditModal: FC<IAddModalProps> = ({ isVisible, setVisible, initialData }) => {
    const navigate = useNavigate()
    const [ form ] = Form.useForm()

    const { currentSchoolUpdate, currentSchoolSetUpdate, fetchUserAndSchoolInfo } = useActions()
    const { selectedSchool, isLoading } = useTypedSelector((state) => state.currentSchool)

    useEffect(() => {
        if (isVisible) {
            form.resetFields()
        }
    }, [ isVisible ])

    const editSchool = (values: ISchoolFormData) => {
        Object.entries(values).forEach(([ key, value ]) => {
            if (typeof value === "string") {
                if (value.startsWith("http")) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    values[key] = value.replace(/(^\w+:|^)\/\//, "")
                }
            }
        })
        currentSchoolUpdate(
            selectedSchool?.id || "", values,
            () => {
                currentSchoolSetUpdate(true)
                fetchUserAndSchoolInfo(navigate)
                setVisible(false)
            },
            (errors) => {
                showFormErrors(form, errors)
            }
        )
    }

    return (
        <Modal
            title="Изменение информации по школе, созданной на платформе GetCourse"
            open={isVisible}
            onOk={() => form.submit()}
            onCancel={() => setVisible(false)}
            okText="Сохранить"
            cancelText="Отмена"
            okButtonProps={{ loading: isLoading }}
            destroyOnClose
        >
            <Form
                form={form}
                onFinish={editSchool}
                layout="vertical"
                requiredMark={false}
                initialValues={
                    initialData ? {
                        ...initialData,
                        currency: initialData?.currency.id || "-",
                        language: initialData?.language.id || "-"
                    } : undefined
                }
            >

                <Form.Item
                    label="Название школы"
                    name="name"
                    rules={[ { required: true, message: "Поле обязательное" } ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Валюта"
                    name="currency"
                    rules={[ { required: true, message: "Поле обязательное" } ]}
                >
                    <Select disabled>
                        <Select.Option value={1}>Рубль РФ</Select.Option>
                        <Select.Option value={2}>Доллар США</Select.Option>
                        <Select.Option value={3}>Евро</Select.Option>
                        <Select.Option value={4}>Тенге</Select.Option>
                        <Select.Option value={5}>Гривна </Select.Option>
                        <Select.Option value={6}>Беларусский рубль</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Язык интерфейса"
                    name="language"
                    rules={[ { required: true, message: "Поле обязательное" } ]}
                >
                    <Select>
                        <Select.Option value={1}>Русский</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Поддомен на Getcourse"
                    name="domain"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательное",
                        },
                        {
                            validator:validateDomain
                        }
                    ]}
                    help={form.getFieldError("domain") ? form.getFieldError("domain")[0] : ""}
                >
                    <Input placeholder="В формате name.getcourse.ru" />
                </Form.Item>

                <Form.Item
                    label="Привязанный домен к Getcourse"
                    name="main_domain"
                    rules={[
                        {
                            validator:validateDomain
                        }
                    ]}
                    help={form.getFieldError("domain") ? form.getFieldError("domain")[0] : ""}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Getcourse API Key"
                    name="api_key"
                    rules={[ { required: true, message: "Поле обязательное" } ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Частота обновления данных"
                    name="update_duration"
                    rules={[ { required: true, message: "Поле обязательное" } ]}
                >
                    <Select>
                        {updateDurationIntervals.map((el)=>(
                            <Select.Option value={el.id} key={el.id}>
                                {el.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

            </Form>
        </Modal>
    )
}

export default EditModal
