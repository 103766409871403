import React, { FC, useEffect } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Form, Input, Modal, Spin } from "antd"

import { useActions } from "../../../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"
import { showFormErrors } from "../../../../helpers/utils/ui"

interface IAddModalProps {
    isVisible: boolean
    setVisible: (val: boolean) => void
    webinarId: number
    webinarName: string
}

const EditModal: FC<IAddModalProps> = ({ isVisible, setVisible, webinarId, webinarName }) => {
    const [ form ] = Form.useForm()

    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const { isLoading } = useTypedSelector((state) => state.webinar)
    const { webinarUpdateTitle } = useActions()

    useEffect(() => {
        if (isVisible) {
            form.resetFields()
            form.setFields([
                {
                    name: "webinar_name",
                    value: webinarName
                }
            ])
        }
    }, [ isVisible ])

    const saveWebinar = (values: { webinar_name: string }) => {
        if (selectedSchool) {
            webinarUpdateTitle(parseInt(selectedSchool.id), webinarId, values.webinar_name,
                () => {
                    setVisible(false)
                },
                (errors) => {
                    showFormErrors(form, errors)
                })
        }
    }

    return (
        <Modal
            title="Изменение название вебинара"
            open={isVisible}
            onOk={() => form.submit()}
            onCancel={() => setVisible(false)}
            okText="Сохранить"
            cancelText="Отмена"
            okButtonProps={{ loading: isLoading }}
            destroyOnClose
        >
            <Spin
                spinning={isLoading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            >
                <Form
                    form={form}
                    onFinish={saveWebinar}
                    layout="vertical"
                    requiredMark={false}
                >

                    <Form.Item
                        label="Название"
                        name="webinar_name"
                        rules={[ { required: true, message: "Поле обязательное" } ]}
                    >
                        <Input />
                    </Form.Item>

                </Form>
            </Spin>
        </Modal>
    )
}

export default EditModal
