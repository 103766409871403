import React from "react";
import {Popover} from "antd";

type Props = {
    handleGroupClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}
const ToggleFilter = ({handleGroupClick}:Props) => {
    const content = (
        <div>
            Добавить в фильтр
        </div>
    )
return (
    <Popover content={content}>
    <span onClick={handleGroupClick} style={{position:"absolute",right:10,fontSize:"14px",cursor:"pointer"}}>+</span>
    </Popover>
        )
}
export default ToggleFilter
