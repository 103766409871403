import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const Session: FC = () => {

    const metrics = [
        { name: "id сессии", dataKey: "session_id" },
        { name: "scan_id", dataKey: "scan_id" },
        { name: "Email", dataKey: "email" },
        { name: "account_id", dataKey: "account_id" },
        { name: "Дата и время сессии", dataKey: "session_timestamp" },
        { name: "Кол-во хитов", dataKey: "hits_count" },
        { name: "Сумма длительности каждого хита", dataKey: "hits_duration" },
        { name: "Общее количество кликов по всем хитам", dataKey: "hits_clicks_count" },
        { name: "URL адрес входа - адрес первого хита", dataKey: "first_hit_url" },
        { name: "Название страницы входа", dataKey: "first_hit_title" },
        { name: "URL адрес выхода - адрес последнего хита", dataKey: "last_hit_url" },
        { name: "Страна", dataKey: "country_name" },
        { name: "Регион", dataKey: "region_name" },
        { name: "Город", dataKey: "city_name" },
        { name: "Часовой пояс", dataKey: "utc_name" },
        { name: "IP адрес", dataKey: "ip_address" },
        { name: "Разрешение экрана высота", dataKey: "max_res_x" },
        { name: "Разрешение экрана ширина", dataKey: "max_res_y" },
        { name: "Тип устройства", dataKey: "type_value" },
        { name: "Модель устройства", dataKey: "device_value" },
        { name: "Операционная система", dataKey: "os_name_value" },
        { name: "Версия операционной системы", dataKey: "os_version_value" },
        { name: "Браузер", dataKey: "browser_name_value" },
        { name: "Версия браузера", dataKey: "browser_version_value" },
        { name: "Язык браузера", dataKey: "browser_language_value" },
        { name: "UTM source сессии", dataKey: "utm_source_value" },
        { name: "UTM medium сессии", dataKey: "utm_medium_value" },
        { name: "UTM campaign сессии", dataKey: "utm_campaign_value" },
        { name: "UTM group сессии", dataKey: "utm_group_value" },
        { name: "UTM term сессии", dataKey: "utm_term_value" },
        { name: "UTM content сессии", dataKey: "utm_content_value" },
        { name: "gcpc (партнёрский код ГК)", dataKey: "gcpc_value" },
        { name: "gcmlg (код письма рассылки)", dataKey: "gcmlg_value" },
        { name: "Refer - рефер первого хита", dataKey: "first_hit_refer" },
        { name: "Балл сессии", dataKey: "session_score" },
    ]

    return (
        <MainLayout pageTitle="Сессии">
            <div className="page-wrapper">
                <PageHead
                    title="Сессии"
                />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "Сессии" ]}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default Session
