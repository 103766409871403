export const periodDescription = `
    Выбор периода регистрации. 
    <a href="https://help.scananalytics.ru/?p=485" rel="noopener noreferrer" target="_blank">Подробнее</a>.
`;
export const detailsDescription = `В отчёте: Мои отчёты, данные в графике можно детализировать по:
    <br>
    - дням;<br>
    - неделям;<br>
    - дням недели;<br>
    - месяцам;<br>
    - кварталам;<br>
    - годам.<br>
    <br>
    В отчёте: Качество вебинара детализация по:<br>
    - 1 минуте;<br>
    - 5 минут;<br>
    - 15 минут.<br>
    <br>
    Если выбран большой период, то детализация графика автоматически укрупнится, чтобы отчёт быстрее сформировался.
    <br><br>
    <a href="https://help.scananalytics.ru/?p=512" rel="noopener noreferrer" target="_blank">Подробнее</a>.
`;
export const compareDescription = `
    Можно сравнить друг с другом визуально на графике и по значениям в таблице 2 разных сегмента или 2 разных периода. 
    <a href="https://help.scananalytics.ru/?p=515" rel="noopener noreferrer" target="_blank">Подробнее</a>.
`;

export const segmentDescription = `
    Сегменты — это набор параметров фильтра. Вы можете сохранить сотни сегментов и в 2 клика открывать нужные наборы фильтра, не тратя время на выбор одних и тех же параметров фильтра. Сегменты сохраняются за пользователем сервиса. Для каждого сотрудника будет свой индивидуальный набор сегментов. Сохранённые сегменты коллеги посмотреть нельзя. <a href="https://help.scananalytics.ru/?p=564" rel="noopener noreferrer" target="_blank">Подробнее</a>.
    <br><br>
    По работе с фильтрами описано <a href="https://help.scananalytics.ru/?p=534" rel="noopener noreferrer" target="_blank">здесь</a>.
`;
export const funnelDescription = `Администраторы сервиса могут сохранить наборы фильтров под каждую воронку продаж. Выбор воронок продаж будет доступен всем сотрудникам и во всех отчётах. Можно выбрать одну воронку продаж или сразу несколько.`

export const chartDescription =
  'На график можно вывести данные любой метрики и посмотреть её изменение за отчётный период с разными детализациями. В разделе Мои отчёты 5 типов графиков. На график можно выводить по несколько метрик и несколько группировок, чтобы увидеть зависимости. <a href="https://help.scananalytics.ru/?p=580 " rel="noopener noreferrer" target="_blank">Подробнее<a/>.';

export const reportDescription = `Это самый основной отчёт в сервисе SCAN, который позволяет увидеть эффективность воронки в разных разрезах регистраций и с разными условиями и сегментами пользователей.
 <br> <a href="https://help.scananalytics.ru/?p=309" rel="noopener noreferrer" target="_blank">Подробнее об отчёте</a>. <br><br>
 Как создавать, сохранять и работать с сотнями отчётов описано <a href="https://help.scananalytics.ru/?p=462" rel="noopener noreferrer" target="_blank">здесь</a>.
`;
