import React, { useEffect, useState } from "react"

import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { Button, Modal, Table } from "antd"
import { FilterDropdownProps } from "antd/lib/table/interface"

import {Column, getUniqueEmailsForFilters, parseDate} from "../../../../../helpers/utils/functions"
import { showErrorMessage, showSuccessMessage } from "../../../../../helpers/utils/ui"
import { unApi } from "../../../../../shared/common/api/endpoints/reports/unApi"
import ConnectReferal from "../modals/ConnectReferal"
import EditReferal from "../modals/EditReferal"
import CopyButton from "../ui/CopyButton"
import CustomFilter from "../ui/CustomFilter"


const Referals = () => {
    const [ connectReferalVisible,setConnectReferalVisible ] = useState(false)
    const [ forceUpdate,setForceUpdate ] = useState(false)
    const [ tableData,setTableData ] = useState([])
    const [ editReferal,setEditReferal ] = useState({
        visible:false,
        referal_id:0,
        partner_id:0
    })
    const fetchTableData = async () => {
        await   unApi.getTableReferals().then((res)=>{
           setTableData(res.data.rows)
        })
    }
    const handleEditReferal = (referal_id:number,partner_id:number) => {
        setEditReferal({ visible: true,referal_id,partner_id })
    }
    const handleRemoveReferal = (id:number) => {
        Modal.confirm({
            title: "Вы уверены что хотите удалить Реферала?",
            icon: <ExclamationCircleOutlined />,
            okText: "Да",
            okType: "danger",
            cancelText: "Нет",
            onOk() {
                try{
                    unApi.deleteTableReferal(id).then(()=>{
                        showSuccessMessage("Успешно удалено")
                        setForceUpdate((prev)=>!prev)
                    })
                }catch(err){
                    showErrorMessage("Произошла ошибка , повторите попытку позже.")
                }

            },
        })
    }
    useEffect(()=>{
        fetchTableData()
    },[ forceUpdate,editReferal ])
    const columns:Column[] = [
        { title: "Дата регистрации партнёра", dataIndex: "partner_reg_date", key: "partner_reg_date",
            sorter: (a:{partner_reg_date:string}, b:{partner_reg_date:string}) => new Date(a.partner_reg_date).getTime() - new Date(b.partner_reg_date).getTime(),
            render: (text: string) => {
                const date = new Date(text)
                const formattedDate = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`
                return <span>{formattedDate}</span>
            }},
        { title: "id партнёра", dataIndex: "partner_id", key: "partner_id" },
        { title: "Имя партнёра", dataIndex: "partner_name", key: "partner_name",
            render: (text) => {
                if (/<a/i.test(text)) {
                    return <span dangerouslySetInnerHTML={{ __html: text }} />
                }
                return text
                    }
              },
        {
            title: "Email партнёра",
            dataIndex: "partner_email",
            key: "partner_email",
            filterDropdown: (props:FilterDropdownProps)=>(
                <CustomFilter {...props} options={getUniqueEmailsForFilters("partner_email", tableData)} title={"Поиск по Email"} />
            ),
            onFilter: (value, record) => {
                const partnerEmail = record.partner_email.toLowerCase()
                return partnerEmail.includes(value.toLowerCase())
            }
        },
        {
            title: "Дата регистрации реферала",
            dataIndex: "referal_reg_date",
            key: "referal_reg_date",
            sorter: (a: { referal_reg_date: string }, b: { referal_reg_date: string }) => {
                const dateA = parseDate(a.referal_reg_date)
                const dateB = parseDate(b.referal_reg_date)
                return dateA.getTime() - dateB.getTime()
            },
            render: (text: string) => {
                const date = parseDate(text)
                const formattedDate = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`
                return <span>{formattedDate}</span>
            }
        },
        { title: "id реферала", dataIndex: "referal_id", key: "referal_id" },
        {
            title: "Имя реферала",
            dataIndex: "referal_name",
            key: "referal_name",
            render: (text) => {
                if (/<a/i.test(text)) {
                    return <span dangerouslySetInnerHTML={{ __html: text }} />
                }
                return text
            },
        },
        { title: "Email реферала", dataIndex: "referal_email", key: "referal_email",
            filterDropdown: (props:FilterDropdownProps)=>(
                <CustomFilter {...props} options={getUniqueEmailsForFilters("referal_email", tableData)} title={"Поиск по Email"} />
            ),
            onFilter: (value, record) => {
                const referal_email = record.referal_email.toLowerCase()
                return referal_email.includes(value.toLowerCase())
            }
        },
        { title: "Уровень реферала", dataIndex: "level_id", key: "level_id" },
        { title: "Привязан реферал до", dataIndex: "tied_up", key: "tied_up" },
        { title: "Осталось дней", dataIndex: "days_left", key: "days_left" },
        { title: "Сумма платежей рефералов", dataIndex: "payment_sum", key: "payment_sum" },
        { title: "Сумма начислений партнёру", dataIndex: "payment_amount", key: "payment_amount" },
        { title: "Комментарий", dataIndex: "comment", key: "comment" },
        {
            title:"Действия",
            dataIndex:"actions",
            key:"actions",
            render: (value, record) => (
                <div style={{ display:"flex",columnGap:"4px" }}>
                    <Button title={"Редактирование реферала"} icon={<EditOutlined />} onClick={()=>handleEditReferal(record.referal_id,record.partner_id)} />
                    <Button title={"Удаление реферала"} icon={<DeleteOutlined />} danger onClick={()=>handleRemoveReferal(record.referal_id)} />
                </div>
            ),
        }
    ]

    return (
        <>
            <Button type="primary" onClick={()=>setConnectReferalVisible(true)}>Привязать реферала</Button>
            <CopyButton columns={columns} tableData={tableData} styles={{ marginLeft:"10px" }} />
            <div style={{ overflow:"auto",marginTop:"10px" }}>
                <Table columns={columns} size={"small"} dataSource={tableData} />
            </div>
            {connectReferalVisible && <ConnectReferal visible={connectReferalVisible} setVisible={setConnectReferalVisible} />}
            {editReferal.visible && <EditReferal visible={editReferal.visible} setVisible={setEditReferal} id={{ referal_id:editReferal.referal_id,partner_id:editReferal.partner_id }} />}
        </>
    )
}
export default Referals
