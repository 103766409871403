import React, { FC, useEffect, useState } from "react";

import { Space } from "antd";

import Attribution from "./attribution";
import Details from "./details";
import CompareSection from "./filter/compare";
import CompareButton from "./filter/CompareButton";
import styles from "./Params.module.scss";
import PeriodSelector from "./period";
import { IAttributionItem, IDetailItem } from "../../../../../app/types/filter";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import DashboardFilter from "./filter/DashboardFilter";

interface IParamsProps {
  withAttribution?: boolean;
  attributionItems?: IAttributionItem[];
  withDetails?: boolean;
  detailsItems?: IDetailItem[];
  withCompare?: boolean;
  withSegments?: boolean;
  filtersFilter?: string[];
  isFilterVisible?: boolean;
}

const DashboardParams: FC<IParamsProps> = ({
  withAttribution = true,
  attributionItems,
  withDetails = true,
  detailsItems,
  withCompare = true,
  withSegments = true,
  filtersFilter,
  isFilterVisible = true,
}) => {
  const { isCompare, isNotParamsFilterVisible } = useTypedSelector(
    (state) => state.meta
  );
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        padding: "15px 15px 0px 15px",
      }}
    >
      {!isCompare && <PeriodSelector isCompare={isCompare} />}
      <Space
        direction="horizontal"
        style={{ position: "relative" }}
        className={styles.params}
      >
        {withAttribution && attributionItems && (
          <div>
            <Attribution items={attributionItems} />
          </div>
        )}
        {withDetails && detailsItems && <Details items={detailsItems} />}
        {withCompare && <CompareButton isCompare={isCompare} />}
      </Space>
      <div className="filter-wrapper">
        {isCompare ? (
          <CompareSection filtersFilter={filtersFilter} />
        ) : (
          isFilterVisible &&
          !isNotParamsFilterVisible && (
            <DashboardFilter
              isCompare={isCompare}
              withSegment={withSegments}
              filtersFilter={filtersFilter}
            />
          )
        )}
      </div>
    </Space>
  );
};

export default DashboardParams;
