import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth } from "../middleware"
import {
    API_SCHOOL_ADD_DOMAIN,
    API_SCHOOL_DELETE_DOMAIN,
    API_SCHOOL_FETCH_DOMAINS,
    API_SCHOOL_FETCH_UTM,
    API_SCHOOL_GET_VALIDATE_STATUS,
    API_SCHOOL_SET_UTM_STATUS,
    API_SCHOOL_SET_VALIDATE_STATUS,
    API_SCHOOL_UPDATE_DOMAIN_STATUS
} from "../urls"

export const domainApi = {
    fetchData: async (schoolId: string): Promise<AxiosResponse> => {
        return axios.get(`${API_SCHOOL_FETCH_DOMAINS}/${schoolId}`, axiosConfigAuth())
    },
    fetchUtmData:async (schoolId: string): Promise<AxiosResponse> => {
        return axios.get(`${API_SCHOOL_FETCH_UTM}/${schoolId}`, axiosConfigAuth())
    },
    changeActive: async (value: boolean, schoolId: number, domain: string) => {
        const data = {
            id: schoolId,
            domain: domain,
            status: value
        }

        return axios.post(API_SCHOOL_UPDATE_DOMAIN_STATUS, data, axiosConfigAuth())
    },

    addDomain: async (schoolId: number, domain: string): Promise<AxiosResponse> => {
        const data = {
            id: schoolId,
            domain: domain
        }

        return axios.post(API_SCHOOL_ADD_DOMAIN, data, axiosConfigAuth())
    },

    deleteDomain: async (schoolId: number, domain: string): Promise<AxiosResponse> => {
        const data = {
            id: schoolId,
            domain: domain
        }

        return axios.post(API_SCHOOL_DELETE_DOMAIN, data, axiosConfigAuth())
    },

    getValidateStatus: async (schoolId: number | string): Promise<AxiosResponse> => {
        return axios.get(`${API_SCHOOL_GET_VALIDATE_STATUS}/${schoolId}`, axiosConfigAuth())
    },

    setValidateStatus: async (schoolId: number, value: boolean): Promise<AxiosResponse> => {
        const data = {
            id: schoolId,
            status: value
        }

        return axios.post(API_SCHOOL_SET_VALIDATE_STATUS, data, axiosConfigAuth())
    },
    setUtmStatus: async (schoolId: number, value: boolean): Promise<AxiosResponse> => {
        const data = {
            id: schoolId,
            status: value
        }

        return axios.post(API_SCHOOL_SET_UTM_STATUS, data, axiosConfigAuth())
    },
}
