import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import {
    IAdsList,
    IAdsSpend,
    IAdsSpendState,
    IAdsSpendUpdateData,
} from "./types"

const initialState: IAdsSpendState = {
    adsSpendData: [],
    adsSpendUpdateData: null,
    adsCompanyList: [],
    adsCompanyGrList: [],
    adsCompanyAdList: [],
    isLoading: false,
    isUpdate: false,
}

export const adsSpendSlice = createSlice({
    name: "adsSpend",
    initialState: initialState,
    reducers: {
        setAdsSpendData: (state, action: PayloadAction<IAdsSpend[]>) => {
            state.adsSpendData = action.payload
        },
        setAdsSpendUpdateData: (
            state,
            action: PayloadAction<IAdsSpendUpdateData>
        ) => {
            state.adsSpendUpdateData = action.payload
        },
        setAdsCompanyList: (state, action: PayloadAction<IAdsList[]>) => {
            state.adsCompanyList = action.payload
        },
        setAdsCompanyGrList: (state, action: PayloadAction<IAdsList[]>) => {
            state.adsCompanyGrList = action.payload
        },
        setAdsCompanyAdList: (state, action: PayloadAction<IAdsList[]>) => {
            state.adsCompanyAdList = action.payload
        },
        setAdsSpendLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setAdsSpendUpdate: (state, action: PayloadAction<boolean>) => {
            state.isUpdate = action.payload
        },
    },
})

export const adsSpendSliceActions = adsSpendSlice.actions
export const adsSpendSliceReducer = adsSpendSlice.reducer
