import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const Payments: FC = () => {

    const metrics = [
        { name: "Дата и время оплаты", dataKey: "pay_timestamp" },
        { name: "Имя Фамилия клиента", dataKey: "name" },
        { name: "id пользователя", dataKey: "id" },
        { name: "Номер заказа", dataKey: "order_number" },
        { name: "Номер платежа", dataKey: "payment_id" },
        { name: "Статус", dataKey: "pay_status" },
        { name: "Тип платежа", dataKey: "pay_type" },
        { name: "Сумма", dataKey: "amount" },
        { name: "Комиссия", dataKey: "commission_payments" },
    ]

    return (
        <MainLayout pageTitle="Платежи">
            <div className="page-wrapper">
                <PageHead
                    title="Платежи"
                    description={`
                        Отчёт, который позволяет посмотреть и выгрузить нужные платежи онлайн-школы. Подробнее.   
                    `}
                />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "Пользователи", "Заказы", "Платежи" ]}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default Payments
