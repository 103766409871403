import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth } from "../middleware"
import {
    API_DELETE_REQUISITE,
    API_GET_BALANCE_DATA,
    API_GET_BILL_DATA, API_GET_EXPORT_ACT, API_GET_EXPORT_SCORE, API_GET_REQUISITE,
    API_GET_REQUISITES, API_GET_REQUISITES_LIST,
    API_GET_TRANSACTIONS_DATA,
    API_SAVE_REQUISITES
} from "../urls"
import {GetReq, GetScoreFile, SaveReq} from "./types";
import {getAccessToken} from "../../../../helpers/utils/auth";
import { modifyPdfFileName } from "../../../../helpers/utils/functions";
import moment from "moment";
export const billApi = {
    getReqList:(school_id:number)=>{
        const body = {
            school_id
        }

        return axios.post(API_GET_REQUISITES_LIST,body,axiosConfigAuth())
    },
    getScoreFile:(body:GetScoreFile) =>{
        const { order_date, ...restBody } = body

        const correctFileName = `ИП Зубарев Д.А. Счёт ${body.order_id} от ${order_date?.slice(0,10)}.pdf`

        fetch(API_GET_EXPORT_SCORE, {
            method: "POST",
            body: JSON.stringify(restBody),
            headers: {
                "Content-Type": "application/json",
                "Token": getAccessToken()
            }
        })
            .then((response) => {
                const contentDisposition = response.headers.get("content-disposition");
                const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
                const fileName = filenameMatch && filenameMatch[1] ? filenameMatch[1] : "score"

                return response.blob()
                    .then((pdf) => ({ pdf, fileName }));
            })
            .then(async ({ pdf, fileName }) => {

                const modifiedPdfBytes = await modifyPdfFileName(await pdf.arrayBuffer(), fileName);

                const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

                const link = document.createElement('a');
                link.download = correctFileName;
                link.href = URL.createObjectURL(blob);
                link.click();
                link.remove();
            });
    },
    getActFile:(body:GetScoreFile) =>{
        const correctFileName = `ИП Зубарев Д.А. Акт ${body.order_id} от ${moment(body.order_date).format("DD-MM-YYYY")}.pdf`

        fetch(API_GET_EXPORT_ACT, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Token": getAccessToken()
            }
        })
            .then((response) => {
                const contentDisposition = response.headers.get("content-disposition");
                const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
                const fileName = filenameMatch && filenameMatch[1] ? filenameMatch[1] : `ИП Зубарев Д.А. Акт ${body.order_id} от ${moment(body.order_date).format("DD-MM-YYYY")}.pdf`
                return response.blob()
                    .then((pdf) => ({ pdf, fileName }));
            })
            .then(async ({ pdf, fileName }) => {
                const modifiedPdfBytes = await modifyPdfFileName(await pdf.arrayBuffer(), fileName);

                const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

                const link = document.createElement('a');
                link.download = correctFileName;
                link.href = URL.createObjectURL(blob);
                link.click();
                link.remove();
            });
    },
    deleteReq:(body:GetReq):Promise<AxiosResponse>=>{
        const data = {
            ...body
        }
        return axios.post(API_DELETE_REQUISITE,data,axiosConfigAuth())
    },
    getReq: (body:GetReq) :Promise<AxiosResponse> => {
        const data = {
            ...body
        }

        return axios.post(API_GET_REQUISITE,data,axiosConfigAuth())
    },
    getReqs: (school_id: number| string) :Promise<AxiosResponse> => {
        const data = {
            school_id
        }

        return axios.post(API_GET_REQUISITES,data,axiosConfigAuth())
    },
    saveReq: (values:SaveReq) :Promise<AxiosResponse> => {
        const data = {
            ...values
        }

        return axios.post(API_SAVE_REQUISITES,data,axiosConfigAuth())
    },
    getBalanceInfo: (schoolId: number): Promise<AxiosResponse> => {
        const data = {
            school_id: schoolId
        }

        return axios.post(API_GET_BALANCE_DATA, data, axiosConfigAuth())
    },

    getBillsData: (schoolId: number): Promise<AxiosResponse> => {
        const data = {
            school_id: schoolId
        }

        return axios.post(API_GET_BILL_DATA, data, axiosConfigAuth())
    },

    getTransactionsData: (schoolId: number): Promise<AxiosResponse> => {
        const data = {
            school_id: schoolId
        }

        return axios.post(API_GET_TRANSACTIONS_DATA, data, axiosConfigAuth())
    },
}
