import {checkAverageDataType, formatCellData} from "./reports";
import {Properties} from "csstype";
import React from "react";

export const getCustomMetricRow = (dataKey:any,renderItem:any,item:any,currency:string,data:any[]) => {
    const customStyle: Properties = {
        width: "100%",
        backgroundImage: "unset",
        textAlign: "right",
        display:"flex",
        justifyContent:"flex-end",
        alignItems:'center',
        height:"38px",
        paddingRight:"4px"

    }
    const resultValue = typeof formatCellData(renderItem.cellData, dataKey) === "object" ? formatCellData(renderItem.cellData, dataKey)  : `${item?.unit === "Currency" ? currency : ''} ${formatCellData(renderItem.cellData, dataKey)}${item?.unit === "Percentage" ? "%" : ''}`
    const result: { [key: string]: { max: number, maxPositive: number, maxNegative: number } } = {}
    if (item?.isFilling === true) {
        data
            .filter((item: { pathStr: string }) => item.pathStr !== "total")
            .forEach((item: { [key: string]: any }) => {
                Object.entries(item).forEach(([ key, value ]) => {
                    const numericValue = Number(value)
                    if (result[key]) {
                        result[key].max = Math.max(result[key].max, numericValue)
                        result[key].maxPositive = Math.max(result[key].maxPositive, numericValue)
                        result[key].maxNegative = Math.min(result[key].maxNegative, numericValue)
                    } else {
                        result[key] = {
                            maxNegative: numericValue >= 0 ? 0 : numericValue,
                            maxPositive: numericValue >= 0 ? numericValue : 0,
                            max: numericValue,
                        }
                    }
                })
            })

        let percent = 0
        if (result[dataKey]) {
            const maxPositive = Math.max(result[dataKey].maxPositive, Math.abs(result[dataKey].maxNegative))
            percent = (Math.abs(Number(renderItem.cellData)) / maxPositive) * 100
            const checkSign = (value:number) => {
                let color = ""
                item?.fillingConditions.forEach((condition:{from:number | string,to:number,value:string}) => {
                    if (condition.from !== "" && value >= condition.from && value <= condition.to) {
                        color = condition.value
                    }
                })
                return color
            }
            if (renderItem.rowData.group !== "Итого и среднее" || renderItem.rowData.group !== "Итого и среднее") {
                customStyle.backgroundImage = `linear-gradient(to left, ${checkSign(renderItem.cellData)} ${Math.abs(Number(renderItem.cellData)) !== 0 && percent <5 ? 5 : percent}%, transparent ${Math.abs(Number(renderItem.cellData)) !== 0 && percent <5 ? 5 : percent}%)`
            }
        }

        return (
            <span style={customStyle}>
                {resultValue}
    </span>
        )
    }

    try{
        if(dataKey.startsWith("_")){
            item?.fillingConditions?.forEach((condition:any) => {
                if(condition.from !== ""){
                    if(renderItem.cellData >= condition.from && renderItem.cellData <= condition.to) {
                        customStyle.backgroundColor = condition.value
                    }
                }
            })
            return (
                <span style={customStyle}>
              {resultValue}
      </span>
            )
        }else {
            return (
                <span style={{ textAlign: "right", width: "100%",paddingRight:"4px" }}>
               {formatCellData(renderItem.cellData, dataKey)}
              </span>
            )
        }
    }catch(err){
        console.log(err)
    }
}

export const  processItems = (items: any[],result:any,formatGroup:string[]) => {
    items.forEach((item: { [key: string]: any; children?: any[] }) => {
        // Игнорируем элементы с pathStr === "total"
        if (item.pathStr === "total") return

        // Обработка текущего элемента
        Object.entries(item).forEach(([key, value]) => {
            if (formatGroup.includes(key)) {
                const numericValue = Number(value)
                if (result[key]) {
                    result[key].max = Math.max(result[key].max, numericValue);
                    result[key].maxPositive = Math.max(result[key].maxPositive, numericValue);
                    result[key].maxNegative = Math.min(result[key].maxNegative, numericValue);
                } else {
                    result[key] = {
                        maxNegative: numericValue >= 0 ? 0 : numericValue,
                        maxPositive: numericValue >= 0 ? numericValue : 0,
                        max: numericValue,
                    };
                }
            }
        });

        if (item.children && item.children.length > 0) {
            processItems(item.children,result,formatGroup)
        }
    })
}

