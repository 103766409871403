import React, { FC } from "react";

import { Button, Result } from "antd";
import { Link, useLocation } from "react-router-dom";

const NotFound: FC = () => {
  const location = useLocation();

  document.title = "Страница не найдена";

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Result
        status="warning"
        title={"404 / Страница не найдена"}
        extra={
          location.state && location.state.withExtra !== null ? null : (
            <Link to="/">
              <Button type="primary">На главную</Button>
            </Link>
          )
        }
      />
    </div>
  );
};

export default NotFound;
