import { useEffect } from "react"

import { Table } from "antd"

import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"

const paymentsColumns = [
    {
        title: "Дата платежа",
        dataIndex: "payment_date",
        key: "payment_date",
    },
    {
        title: "ID реферала",
        dataIndex: "referal_id",
        key: "referal_id",
    },
    {
        title: "Уровень реферала",
        dataIndex: "level_id",
        key: "level_id",
    },
    {
        title: "Имя реферала",
        dataIndex: "referal_name",
        key: "referal_name",
    },
    {
        title: "Email реферала",
        dataIndex: "referal_email",
        key: "referal_email",
    },
    {
        title: "ID платежа",
        dataIndex: "payment_id",
        key: "payment_id",
    },
    {
        title: "Сумма оплаты",
        dataIndex: "payment_sum",
        key: "payment_sum",
    },
    {
        title: "Привлёк покупателей",
        dataIndex: "attracted_buyers",
        key: "attracted_buyers",
    },
    {
        title: "% комиссии",
        dataIndex: "commission_percent",
        key: "commission_percent",
    },
    {
        title: "Сумма начисления",
        dataIndex: "commission_amount",
        key: "commission_amount",
    },
]

const paymentsData = [
    {
        key: "1",
        date: "01.01.2023",
        referralId: "2352",
        referralLevel: 1,
        referralName: "Иван Иванов",
        referralEmail: "vwevs****@mail.ru",
        paymentId: "124",
        paymentAmount: "15 000 р.",
        referralCount: 12,
        commissionPercentage: "20%",
        commissionAmount: "3 000 р.",
    },

]
const AccrualsTab = () => {
    const { accuralsFetchData } = useActions()
    const { accuralsList } = useTypedSelector(state => state.partner)
    useEffect(()=>{
        accuralsFetchData()
    },[])
    return (
		<Table columns={paymentsColumns} dataSource={accuralsList} pagination={false} />
    )
}

export default AccrualsTab