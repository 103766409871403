import React, { useState, useEffect } from "react";
import {
  Modal,
  Tabs,
  Input,
  Button,
  Form,
  Select,
  Space,
  Divider,
  InputNumber,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import styles from "./AddModal.module.scss";
import { useTypedSelector } from "../../../../../../shared/common/hooks/useTypedSelector";
import { useActions } from "../../../../../../shared/common/hooks/useActions";
import { unApi } from "../../../../../../shared/common/api/endpoints/reports/unApi";
import { showErrorMessage } from "../../../../../../helpers/utils/ui";
import { DatePicker } from "antd/es";
import moment from "moment";
import { adsSpendApi } from "../../../../../../shared/common/api/endpoints/adsSpendApi";
import Loader from "../../../../../../shared/common/components/loader/Loader";
import { validateURL } from "../../../../../../helpers/utils/validators";
import HelpButton from "../../../../../../shared/common/components/help-button/HelpButton";
import { tooltipText } from "./helpers";
import FieldLabel from "../FieldLabel";
import FieldTooltip from "../FieldTooltip";
import { ensureHttps } from "../../../../../../helpers/utils/functions";

const { TabPane } = Tabs;

type Props = {
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
  setRefetch: () => void;
};

const extractUTMParams = (url: string) => {
  const params = new URLSearchParams(url.split("?")[1]);
  return {
    utm_source: params.get("utm_source") || "",
    utm_medium: params.get("utm_medium") || "",
    utm_campaign: params.get("utm_campaign") || "",
    utm_term: params.get("utm_term") || "",
    utm_group: params.get("utm_group") || "",
    utm_content: params.get("utm_content") || "",
  };
};

const constructURLWithUTMParams = (
  baseUrl: string,
  params: { [key: string]: string }
) => {
  const url = new URL(baseUrl.split("?")[0]);
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      url.searchParams.set(key, params[key]);
    }
  });
  return url.toString();
};

const AddModal = ({ isVisible, setVisible, setRefetch }: Props) => {
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState("1");
  const [utmFields, setUtmFields] = useState({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_group: "",
    utm_content: "",
  });
  const [url, setUrl] = useState("");

  const [rkNamesLoading, setRkNamesLoading] = useState(false);
  const [rkNames, setRkNames] = useState([]);
  const [rkName, setRkName] = useState("");
  const [company, setCompany] = useState<number | string | null>(null);
  const [group, setGroup] = useState<number | string | null>(null);
  const [searchCompany, setSearchCompany] = useState("");
  const [searchGroup, setSearchGroup] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [nameComp, setNameComp] = useState("");
  const [groupDropdownOpen, setGroupDropdownOpen] = useState(false);
  const [nameGroup, setNameGroup] = useState("");
  const [searchAds, setSearchAds] = useState("");
  const [adDropdownOpen, setAdDropdownOpen] = useState(false);
  const [nameAds, setNameAds] = useState("");
  const [searchInput, setSearchInput] = useState<string | null>("");
  const { selectedSchool } = useTypedSelector((state) => state.currentSchool);
  const { isLoading, adsCompanyList, adsCompanyGrList, adsCompanyAdList } =
    useTypedSelector((state) => state.adsSpend);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const [isModalLoading, setIsModalLoading] = useState(false);
  const {
    setMetaTableFetching,
    adsSpendGetCompanyList,
    adsSpendAddSpend,
    adsSpendGetGroupList,
    adsSpendGetAdsList,
    setAdsCompanyGrList,
    setAdsCompanyAdList,
    setAppReportOpen,
    setDrawerLink,
  } = useActions();

  const handleSelectChange = (value: string) => {
    form.setFields([
      { name: "company", value: "" },
      { name: "group", value: "" },
      { name: "ad", value: "" },
    ]);
    setGroup(null);
    setCompany(null);
    setRkName(value);
  };

  useEffect(() => {
    const spend = form.getFieldValue("ads_spend");
    const validatedSpend = spend === null || spend === undefined;
    const date = form.getFieldValue("date_stat");
    if (!rkName || !url || validatedSpend || !date) {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  }, [
    rkName,
    url,
    form,
    form.getFieldValue("ads_spend"),
    form.getFieldValue("date_stat"),
  ]);

  useEffect(() => {
    const action = isVisible ? "add" : "remove";
    document.body.classList[action]("hiddenOverflow");
  }, [isVisible]);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    const utmParams = extractUTMParams(newUrl);
    setUtmFields(utmParams);
  };

  const filterGroupOptions = adsCompanyGrList.filter((item) =>
    item.name.toLowerCase().includes(searchGroup.toLowerCase())
  );

  const handleUtmChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const newUtmFields = { ...utmFields, [field]: e.target.value };
    setUtmFields(newUtmFields);

    const newUrl = url ? constructURLWithUTMParams(url, newUtmFields) : "";
    form.setFieldValue("url", newUrl);
    setUrl(newUrl);
  };

  const addCompItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    setCompany(nameComp);
    form.setFields([{ name: "company", value: `${nameComp}` }]);

    setAdsCompanyGrList([]);
    setGroup(null);
    form.setFields([{ name: "group", value: null }]);

    setAdsCompanyAdList([]);
    form.setFields([{ name: "ad", value: null }]);

    setNameComp("");
    setDropdownOpen(false);
  };

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const ad = {
        id: adsCompanyAdList.find((ad) => ad.id === values.ad)?.id,
        name:
          adsCompanyAdList.find((ad) => ad.id === values.ad)?.name || values.ad,
      };
      const adset = {
        id: adsCompanyGrList.find((group) => group.id === values.group)?.id,
        name:
          adsCompanyGrList.find((group) => group.id === values.group)?.name ||
          values.group,
      };
      const campaign = {
        id: adsCompanyList.find((company) => company.id === values.company)?.id,
        name:
          adsCompanyList.find((company) => company.id === values.company)
            ?.name || values.company,
      };
      const data = {
        school_id: Number(selectedSchool?.id),
        account_id: values.account_id,
        date_stat: moment(values.date_stat).format("YYYY-MM-DD"),
        ads_spend: values.ads_spend,
        ad,
        adset,
        campaign,
        impressions: values.impressions,
        count_link_clicks: values.count_link_clicks,
        url: ensureHttps(values.url),
        ads_comment: values.ads_comment,
        utm_source: utmFields.utm_source,
        utm_medium: utmFields.utm_medium,
        utm_campaign: utmFields.utm_campaign,
        utm_term: utmFields.utm_term,
        utm_group: utmFields.utm_group,
        utm_content: utmFields.utm_content,
        title: values.title,
        subtitle: values.subtitle,
        text: values.text,
        button_text: values.button_text,
        picture_url: values.picture_url,
      };
      if (selectedSchool) {
        await adsSpendApi.createAdsSpend(Number(selectedSchool?.id), data);
        form.resetFields();
        setMetaTableFetching({ value: true });
        setRefetch();
        Modal.info({
          title: "Успех!",
          content: "Рекламный расход успешно создан.",
        });
        setVisible(false);
      }
    } catch (err: any) {
      const errorMessage = err?.response?.data?.date_stat;
      if (errorMessage) {
        if (errorMessage.includes("Уже задан расход по этому объявлению за")) {
          form.setFields([
            {
              name: "date_stat",
              errors: [
                "За эту дату по указанным UTM меткам уже добавлен расход. Вы можете отредактировать сумму старого объявления или добавить новое с другими UTM метками. UTM метки нужны чтобы SCAN мог найти регистрации с этими метками и связать их с объявлением. Это позволит Вам видеть окупаемость рекламы.",
              ],
            },
          ]);
        } else {
          showErrorMessage(errorMessage);
        }
        handleTabChange("1");
        return;
      }
      if (err.errorFields) {
        console.log(err);
        showErrorMessage("Проверьте поля формы.");
      }
    }
  };

  const saveAndCloseButton = (
    <Form.Item>
      <Button
        disabled={isSubmitButtonDisabled}
        type="primary"
        onClick={onSubmit}
        danger
      >
        Добавить и закрыть
      </Button>
    </Form.Item>
  );

  const filterCompanyOptions = adsCompanyList.filter((item) =>
    item.name.toLowerCase().includes(searchCompany.toLowerCase())
  );

  const filterAdsOptions = adsCompanyAdList.filter((item) =>
    item.name.toLowerCase().includes(searchAds.toLowerCase())
  );

  const addGrItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    setGroup(nameGroup);
    form.setFields([{ name: "group", value: `${nameGroup}` }]);

    setAdsCompanyAdList([]);
    form.setFields([{ name: "ad", value: null }]);

    setNameGroup("");
    setGroupDropdownOpen(false);
  };

  const addAdItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    form.setFields([{ name: "ad", value: `${nameAds}` }]);

    setNameAds("");
    setAdDropdownOpen(false);
  };

  useEffect(() => {
    if (isVisible && selectedSchool && rkName) {
      const data = {
        school_id: Number(selectedSchool.id),
        account_id: Number(rkName),
      };
      adsSpendGetCompanyList(data);
    }
  }, [rkName]);

  const getRKNames = async () => {
    try {
      setRkNamesLoading(true);
      const { data } = await unApi.getRKList({
        school_id: Number(selectedSchool?.id),
      });
      setRkNames(data);
    } catch (err) {
      showErrorMessage("Произошла ошибка, повторите позже.");
    } finally {
      setRkNamesLoading(false);
    }
  };

  useEffect(() => {
    getRKNames();
  }, [isVisible]);

  useEffect(() => {
    if (selectedSchool && company && typeof company === "number") {
      adsSpendGetGroupList(parseInt(selectedSchool.id), company);
    }
  }, [company, isVisible]);

  useEffect(() => {
    if (
      selectedSchool &&
      company &&
      group &&
      typeof company === "number" &&
      typeof group === "number"
    ) {
      adsSpendGetAdsList(parseInt(selectedSchool.id), company, group);
    }
  }, [group, isVisible]);
  const handleSearchAd = async () => {
    if (selectedSchool?.id && searchInput) {
      setIsModalLoading(true);
      adsSpendApi
        .fetchUpdateData(selectedSchool?.id, searchInput)
        .then((response) => {
          if (response.status === 200) {
            const data = { ...response.data, ads_id: searchInput };
            form.setFieldsValue({
              ...data,
              date_stat: moment(data?.date_stat),
              company: data?.campaign_id,
              group: data?.adset_id,
              url: data?.url || "",
              ad: Number(data?.ads_id),
            });
            setGroup(data?.adset_id);
            setCompany(data?.campaign_id);
            setRkName(data.account_id.toString());
            setUrl(data?.url || "");
            setUtmFields(extractUTMParams(data?.url || ""));
          }
        })
        .catch((err) => {
          console.log(err);
          showErrorMessage("Расход с таким id объявления не найден.");
        })
        .finally(() => {
          setIsModalLoading(false);
        });
    }
  };
  const handleOpenDrawer = () => {
    setDrawerLink("https://help.scananalytics.ru/ruchnoy-vvod-rashodov/");
    setAppReportOpen(true);
  };

  return (
    <Modal
      title={
        <div className={styles.modalHeader}>
          <span>Добавление рекламного расхода</span>
          <HelpButton
            styles={{ marginRight: "auto", marginLeft: "10px" }}
            onClick={handleOpenDrawer}
            text={"Как добавить?"}
          />
          <Space style={{ marginRight: "auto" }}>
            <InputNumber
              placeholder="Поиск по ID объявления"
              disabled={isModalLoading}
              addonAfter={<SearchOutlined onClick={handleSearchAd} />}
              className={styles.searchInput}
              value={searchInput}
              onChange={(value) => setSearchInput(value)}
              onPressEnter={handleSearchAd}
            />
            <FieldTooltip text={tooltipText.searchInput} />
          </Space>
        </div>
      }
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={800}
      className={`${styles.modal} custom-modal`}
    >
      <Loader loading={isModalLoading}>
        <Tabs
          activeKey={activeTab}
          onChange={handleTabChange}
          tabPosition="left"
          className="customTabs"
          moreIcon={null}
        >
          <TabPane tab="Основные" key="1">
            <Form form={form} layout="vertical">
              <Form.Item
                name="account_id"
                label={
                  <FieldLabel
                    text={"Выберите рекламный кабинет"}
                    tooltipText={tooltipText.ad}
                  />
                }
                rules={[{ required: true, message: "Поле обязательное" }]}
              >
                <Select
                  loading={rkNamesLoading}
                  disabled={rkNamesLoading}
                  optionFilterProp="children"
                  showSearch={true}
                  filterOption={(input: any, option: any) =>
                    (option?.label?.toLowerCase() ?? "").includes(
                      input?.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  onChange={handleSelectChange}
                  value={rkName}
                >
                  {rkNames.length > 0 ? (
                    rkNames.map((item: any, index: any) => (
                      <Select.Option
                        key={index}
                        value={item.id}
                        label={item.name}
                      >
                        {item.name}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="">Нет РК</Select.Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                name="date_stat"
                label={
                  <FieldLabel
                    text={"Дата рекламы"}
                    tooltipText={tooltipText.adDate}
                  />
                }
                rules={[{ required: true, message: "Поле обязательное" }]}
              >
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <DatePicker format={"DD-MM-YYYY"} />
              </Form.Item>
              <Form.Item
                label={
                  <FieldLabel
                    text={"Сумма"}
                    tooltipText={tooltipText.adsSpend}
                  />
                }
                name="ads_spend"
                rules={[{ required: true, message: "Поле обязательное" }]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                label={
                  <FieldLabel
                    text={"URL ссылка на страницу с UTM метками"}
                    tooltipText={tooltipText.url}
                  />
                }
                name="url"
                rules={[{ required: true, message: "Поле обязательное" }]}
              >
                <Input value={url} onChange={handleUrlChange} />
              </Form.Item>
              {saveAndCloseButton}
            </Form>
          </TabPane>
          <TabPane tab="UTM метки" key="2">
            <Form layout="vertical" className={styles.utmGrid}>
              <Form.Item label="utm_source">
                <Input
                  value={utmFields.utm_source}
                  onChange={(e) => handleUtmChange(e, "utm_source")}
                />
              </Form.Item>
              <Form.Item label="utm_medium">
                <Input
                  value={utmFields.utm_medium}
                  onChange={(e) => handleUtmChange(e, "utm_medium")}
                />
              </Form.Item>
              <Form.Item label="utm_campaign">
                <Input
                  value={utmFields.utm_campaign}
                  onChange={(e) => handleUtmChange(e, "utm_campaign")}
                />
              </Form.Item>
              <Form.Item label="utm_term">
                <Input
                  value={utmFields.utm_term}
                  onChange={(e) => handleUtmChange(e, "utm_term")}
                />
              </Form.Item>
              <Form.Item label="utm_group">
                <Input
                  value={utmFields.utm_group}
                  onChange={(e) => handleUtmChange(e, "utm_group")}
                />
              </Form.Item>
              <Form.Item label="utm_content">
                <Input
                  value={utmFields.utm_content}
                  onChange={(e) => handleUtmChange(e, "utm_content")}
                />
              </Form.Item>
              {saveAndCloseButton}
            </Form>
          </TabPane>
          <TabPane tab="Креатив" key="3">
            <Form form={form} layout="vertical">
              <Form.Item
                label={
                  <FieldLabel
                    text={"Заголовок"}
                    tooltipText={tooltipText.heading}
                  />
                }
                name="title"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <FieldLabel
                    text={"Подзаголовок"}
                    tooltipText={tooltipText.subHeading}
                  />
                }
                name="subtitle"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <FieldLabel text={"Текст"} tooltipText={tooltipText.text} />
                }
                name="text"
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              <Form.Item
                label={
                  <FieldLabel
                    text={"Ссылка на картинку или видео"}
                    tooltipText={tooltipText.mediaUrl}
                  />
                }
                name="picture_url"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <FieldLabel
                    text={"Текст кнопки"}
                    tooltipText={tooltipText.buttonText}
                  />
                }
                name="button_text"
              >
                <Input />
              </Form.Item>
              {saveAndCloseButton}
            </Form>
          </TabPane>
          <TabPane tab="Показатели" key="4">
            <Form form={form} layout="vertical">
              <Form.Item
                label={
                  <FieldLabel
                    text={"Показы"}
                    tooltipText={tooltipText.impressions}
                  />
                }
                name="impressions"
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                label={
                  <FieldLabel text={"Клики"} tooltipText={tooltipText.clicks} />
                }
                name="count_link_clicks"
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
              {saveAndCloseButton}
            </Form>
          </TabPane>
          <TabPane tab="Структура РК" key="5">
            <Form form={form} layout="vertical">
              <Form.Item
                label={
                  <FieldLabel
                    text={"Название кампании"}
                    tooltipText={tooltipText.ads_company}
                  />
                }
                name="company"
              >
                <Select
                  showSearch
                  onSearch={(value) => setSearchCompany(value)}
                  filterOption={false}
                  open={dropdownOpen}
                  onDropdownVisibleChange={(open) => setDropdownOpen(open)}
                  onChange={(value) => {
                    setCompany(value);
                    form.setFields([
                      { name: "group", value: null },
                      { name: "ad", value: null },
                    ]);
                  }}
                  dropdownRender={(menu) => (
                    <>
                      <Space style={{ padding: "5px 8px", marginTop: 5 }}>
                        <Input
                          placeholder="Новая кампания"
                          value={nameComp}
                          onChange={(e) => setNameComp(e.target.value)}
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addCompItem}
                        >
                          Добавить
                        </Button>
                      </Space>
                      <Divider style={{ margin: "8px 0" }} />
                      {menu}
                    </>
                  )}
                >
                  {filterCompanyOptions.map((item, index) => (
                    <Select.Option value={item.id} key={index}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <FieldLabel
                    text={"Название группы объявлений"}
                    tooltipText={tooltipText.ads_group}
                  />
                }
                name="group"
              >
                <Select
                  showSearch
                  onSearch={(value) => setSearchGroup(value)}
                  filterOption={false}
                  open={groupDropdownOpen}
                  onDropdownVisibleChange={(open) => setGroupDropdownOpen(open)}
                  onChange={(value) => {
                    setGroup(value);
                    form.setFields([{ name: "ad", value: null }]);
                  }}
                  dropdownRender={(menu) => (
                    <>
                      <Space style={{ padding: "5px 8px", marginTop: 5 }}>
                        <Input
                          placeholder="Новая группа"
                          value={nameGroup}
                          onChange={(e) => setNameGroup(e.target.value)}
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addGrItem}
                        >
                          Добавить
                        </Button>
                      </Space>
                      <Divider style={{ margin: "8px 0" }} />
                      {menu}
                    </>
                  )}
                >
                  {filterGroupOptions.map((item, index) => (
                    <Select.Option value={item.id} key={index}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <FieldLabel
                    text={"Название объявления"}
                    tooltipText={tooltipText.ad_name}
                  />
                }
                name="ad"
              >
                <Select
                  showSearch
                  onSearch={(value) => setSearchAds(value)}
                  filterOption={false}
                  open={adDropdownOpen}
                  onDropdownVisibleChange={(open) => setAdDropdownOpen(open)}
                  dropdownRender={(menu) => (
                    <>
                      <Space
                        style={{
                          padding: "5px 8px",
                          marginTop: 5,
                          width: "500px",
                        }}
                      >
                        <Input
                          placeholder="Новое объявление"
                          value={nameAds}
                          onChange={(e) => setNameAds(e.target.value)}
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addAdItem}
                        >
                          Добавить
                        </Button>
                      </Space>
                      <Divider style={{ margin: "8px 0" }} />
                      {menu}
                    </>
                  )}
                >
                  {filterAdsOptions.map((item, index) => (
                    <Select.Option value={item.id} key={index}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {saveAndCloseButton}
            </Form>
          </TabPane>
          <TabPane tab="Заметки" key="6">
            <Form form={form} layout="vertical">
              <Form.Item
                label={
                  <FieldLabel
                    text={"Комментарий"}
                    tooltipText={tooltipText.comment}
                  />
                }
                name="ads_comment"
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              {saveAndCloseButton}
            </Form>
          </TabPane>
        </Tabs>
      </Loader>
    </Modal>
  );
};

export default AddModal;
