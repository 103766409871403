import { Form, Input, Modal } from "antd"
import {useTypedSelector} from "../../../../../../hooks/useTypedSelector";
import {showErrorMessage} from "../../../../../../../../helpers/utils/ui";
import {billApi} from "../../../../../../api/endpoints/billApi";
import {useEffect} from "react";

interface Props {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<{ visible: boolean; reqId: null }>>;
    setRefetch:(val: (prev:boolean) => boolean) => void;
    reqId:number | null;
}

const EditRequisite = ({ isVisible, setIsVisible,setRefetch,reqId }: Props) => {
    const [ form ] = Form.useForm()
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const getRequisiteData = async () => {
        const values = {
            school_id: Number(selectedSchool?.id),
            requisites_id:Number(reqId)
        }
        const response = await billApi.getReq(values)
        const responseData = response.data;
        form.resetFields()
        form.setFields([{
            name: 'id',
                value: responseData.id,
        },
        {
            name: 'school_id',
            value: responseData.school_id,
        },
        {
            name: 'company_name',
            value: responseData.company_name,
        },
        {
            name: 'user_name',
            value: responseData.user_name,
        },
        {
            name: 'company_phone',
            value: responseData.company_phone,
        },
        {
            name: 'company_address',
            value: responseData.company_address,
        },
        {
            name: 'company_email',
            value: responseData.company_email,
        },
        {
            name: 'postal_code',
            value: responseData.postal_code,
        },
        {
            name: 'inn',
            value: responseData.inn,
        },
        {
            name: 'kpp',
            value: responseData.kpp,
        },
        {
            name: 'ogrn',
            value: responseData.ogrn,
        },
        {
            name: 'okpo',
            value: responseData.okpo,
        },
        {
            name: 'bank_name',
            value: responseData.bank_name,
        },
        {
            name: 'bik',
            value: responseData.bik,
        },
        {
            name: 'check_account',
            value: responseData.check_account,
        },
        {
            name: 'corr_account',
            value: responseData.corr_account,
        },
        ])
    }
    const onFinish =  async(values: any) => {
        try {
            const data = {
                ...values,
                kpp: Number(values.kpp),
                inn: Number(values.inn),
                ogrn: Number(values.ogrn),
                okpo: Number(values.okpo),
                postal_code: Number(values.postal_code),
                bik: Number(values.bik),
                check_account: values.check_account,
                company_phone: Number(values.company_phone),
                corr_account: values.corr_account,
                school_id: Number(selectedSchool?.id),
                requisites_id :reqId
            }
            await billApi.saveReq(data)
            Modal.info({
                title: "Успех",
                content: "Реквизиты успешно отредактированы."
            })
            setRefetch((prev) => !prev)
            form.resetFields()
            setIsVisible((prev)=>({...prev,visible:false}))
        } catch (err) {
            showErrorMessage("Произошла ошибка , повторите попытку позже.")
        }
    }

useEffect(()=>{
    getRequisiteData()
},[selectedSchool,reqId])
    const handleKeyPress = (event:React.KeyboardEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.key)) || event.key === ' ') {
            event.preventDefault();
        }
    };
    const handlePaste = (event:React.ClipboardEvent<HTMLInputElement>,name:string) => {
        const pastedText = event.clipboardData.getData("text/plain");
        const sanitizedText = pastedText.replace(/[^0-9]/g, "");
        event.preventDefault()
        form.setFieldValue(name,sanitizedText)
    }
    return (
        <Modal
            title="Редактирование реквизитов"
            open={isVisible}
            onOk={() => form.submit()}
            onCancel={() => {
                form.resetFields()
                setIsVisible(({reqId:null,visible:false}))
            }}
            okText="Редактировать"
            cancelText="Отмена"
            destroyOnClose
            width={"52vw"}
        >
            <Form
                form={form}
                name="addRequisitesForm"
                onFinish={onFinish}
                layout={"vertical"}
            >
                <div>
                    <h3>Компания</h3>
                    <div style={{ display:"flex",flexDirection:"row",columnGap:"40px" }}>
                        <div style={{ width:"28vw" }}>
                            <Form.Item name={"company_name"} rules={[{
                                required:true,
                                message:"Пожалуйста, введите название кампании"
                            }]}  label="Название кампании">
                                <Input required={true} />
                            </Form.Item>
                            <Form.Item name={"company_phone"} label="Телефон">
                                <Input />
                            </Form.Item>
                            <Form.Item name={"company_email"} label="Email">
                                <Input />
                            </Form.Item>
                        </div>
                        <div style={{ display:"flex",columnGap:"20px",width:"20vw" }}>
                            <Form.Item name={"company_address"} label="Юридический адрес">
                                <Input />
                            </Form.Item>
                            <Form.Item name={"postal_code"} label="Индекс">
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div style={{ display:"flex",flexDirection:"row",columnGap:"40px" }}>
                    <div  style={{ width:"28vw" }}>
                        <h3>Реквизиты для документов и платежных поручений</h3>
                        <Form.Item rules={[{
                            required:true,
                            message:"Пожалуйста, введите ИНН"
                        }]} name={"inn"} label="ИНН">
                            <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"inn")} />
                        </Form.Item>
                        <Form.Item name={"kpp"} label="КПП">
                            <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"kpp")} />
                        </Form.Item>
                        <Form.Item name={"ogrn"} label="ОГРН">
                            <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"ogrn")} />
                        </Form.Item>
                        <Form.Item name={"okpo"} label="ОКПО">
                            <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"okpo")} />
                        </Form.Item>
                        <Form.Item name={"user_name"} label="ФИО для подписи">
                            <Input />
                        </Form.Item>
                    </div>
                    <div style={{ width:"20vw" }}>
                        <h3>Расчетный счет</h3>
                        <Form.Item name={"bank_name"} label="Название банка">
                            <Input />
                        </Form.Item>
                        <Form.Item name={"bik"} label="БИК">
                            <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"bik")} />
                        </Form.Item>
                        <Form.Item name={"check_account"} label="Р/C">
                            <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"check_account")} />
                        </Form.Item>
                        <Form.Item name={"corr_account"} label="К/С">
                            <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"corr_account")} />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </Modal>
    )
}

export default EditRequisite
