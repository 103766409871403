import React, { FC, useEffect, useState } from "react"

import {Button, Modal, Space, Table} from "antd"
import { ColumnsType } from "antd/es/table"

import {showErrorMessage} from "../../../../../../helpers/utils/ui"
import { billApi } from "../../../../api/endpoints/billApi"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import {useNavigate} from "react-router-dom";
import DownloadScore from "./components/modals/DownloadScore";
import DownloadAct from "../modals/DownloadAct";
import downloadAct from "../modals/DownloadAct";

const BillTab: FC<{currency: string}> = ({ currency }) => {
    const navigate = useNavigate()

    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)

    const [ data, setData ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [isDownloadVisible,setIsDownloadVisible] = useState({
        visible:false,
        order_id:1,
        order_date:""
    })
    const [downloadActState,setDownloadActState] = useState({
        visible:false,
        item:{
            id:0,
            date:"",
        },
        loading:false
    })

    const fetchData = () => {
        setIsLoading(true)

        billApi.getBillsData(parseInt(selectedSchool?.id || "0"))
            .then((response) => {
                if (response.status === 200) {
                    setData(response.data)
                }
            })
            .catch(() => {
                showErrorMessage("Ошибка при получении данных.")
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])
    const handleDownload = async (record: {id:number,created_date:string}) => {
         if (selectedSchool?.id) {
             const { data } = await billApi.getReqList(+selectedSchool.id)
             if(data.length ===  0){
                 navigate(`/users/billing?tab=item-4`)
                 Modal.info({
                     title: "Внимание",
                     content: "Для скачивания счета нужно добавить реквизиты."
                 })
             }else{
                 setIsDownloadVisible({visible: true,order_id: record.id,order_date: record.created_date})
             }
         }
    }
    const handleActDownload = async (record: {id:number,created_date:string}) => {
        setDownloadActState((prev)=>({...prev,loading:true}))
        if (selectedSchool?.id) {
            const { data } = await billApi.getReqList(+selectedSchool.id)
            if(data.length ===  0){
                navigate(`/users/billing?tab=item-4`)
                Modal.info({
                    title: "Внимание",
                    content: "Для скачивания счета нужно добавить реквизиты."
                })
            }else{
                setDownloadActState({
                    visible: true,
                    item:{
                        id:record.id,
                        date:record.created_date,
                    },
                    loading:false
                })
            }
        }
    }

    const columns: ColumnsType<any> = [
        {
            title: "Дата создания счета",
            dataIndex: "created_date",
            key: "created_date",
            width: 200,
        },
        {
            title: "№ счета",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Тариф",
            dataIndex: "tariff_name",
            key: "tariff_name",
        },
        {
            title: "Сумма",
            dataIndex: "order_price",
            key: "order_price",
            render: (value) => {
                return value ? `${value} ${currency}` : ""
            }
        },
        {
            title: "Оплачено",
            dataIndex: "total_paid",
            key: "total_paid",
            render: (value) => {
                return value ? `${value} ${currency}` : ""
            }
        },
        {
            title: "Осталось оплатить",
            dataIndex: "left_to_pay",
            key: "left_to_pay",
            render: (value) => {
                return value ? `${value} ${currency}` : ""
            }
        },
        {
            title: "Статус",
            dataIndex: "order_status",
            key: "order_status",
        },
        {
            title: "Действие",
            dataIndex: "action",
            key: "action",
            render: (value, record) => {
                return (
                    <Space>
                        <Button
                            type="primary"
                            style={{ marginRight: 5 }}
                            disabled={!record.left_to_pay || record.left_to_pay === 0 || record.left_to_pay === "0" || record.order_status === "Оплачен"}
                        >
                            <a href={`${record.payment_link}`} target="_blank">
                                Оплатить
                            </a>
                        </Button>

                        <Button
                            type="default"
                            onClick={() => handleDownload(record)}
                        >
                                Скачать счет
                        </Button>
                        {
                            record.order_status === "Оплачен" &&  <Button
                                type="default"
                                loading={downloadActState.loading}
                                onClick={() => handleActDownload(record)}
                            >
                                Скачать акт
                            </Button>
                        }

                    </Space>
                )
            }
        },
    ]

    return (
        <div>
            <Table
                columns={columns}
                dataSource={data}
                loading={isLoading}
            />
            {isDownloadVisible.visible && <DownloadScore orderDate={isDownloadVisible.order_date} isVisible={isDownloadVisible.visible} orderId={isDownloadVisible.order_id} setIsVisible={setIsDownloadVisible} />}
            {downloadActState.visible && <DownloadAct state={downloadActState} setState={setDownloadActState} />}
        </div>
    )
}

export default BillTab
