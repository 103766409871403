import React from "react"

import IconFilter from "../IconFilter"

const ListView = ({ icon, name, checkeds }: { icon: any, name: string, checkeds: any[] }) => {
    const start = checkeds.slice(0, 3)
    const end = checkeds.slice(3)

    return (
        <>
            <IconFilter icon={icon} />
            &nbsp;
            {name.replaceAll("&nbsp;", " ")}:&nbsp;

            {start.map((item: any, index: number) => (
                <span key={item.id}>
                    {index > 0 && <span style={{ opacity: 0.5 }}> или </span>}
                    {item.name.replaceAll("&nbsp;", " ")}
                </span>
            ))}

            {end.length > 0 && <span style={{ opacity: 0.5 }}> и еще {end.length}</span>}
        </>
    )
}

export default ListView
