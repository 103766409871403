import React, { useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Form, Input, Modal } from "antd"

import { showErrorMessage } from "../../../../../helpers/utils/ui"
import { unApi } from "../../../../../shared/common/api/endpoints/reports/unApi"
import { UpdateReferal} from "../../../../../app/types/unApi";
import moment from "moment";


type Props = {
    visible:boolean
    setVisible:React.Dispatch<React.SetStateAction<{visible: boolean, referal_id: number, partner_id: number}>>
    id:{ referal_id: number; partner_id: number; }
}

const EditReferal = ({ visible,setVisible,id }:Props) => {
    const [ form ] = Form.useForm()
    const [ formLoading, setFormLoading ] = useState(false)
    const fetchData = () => {
        try{
            unApi.getTableReferalInfo(id).then((res)=>{
                if(res.status === 200){
                    form.setFieldsValue({
                        name:res.data.name,
                        email:res.data.email,
                        reg_date: moment(res.data.reg_date, "YYYY-MM-DD"),
                        comment: res.data.comment,
                    })
                }
            })
        }catch(err){
            showErrorMessage("Произошла ошибка при получении информации об выплате , повторите попытку позже.")
        }

    }
    useEffect(()=> {
        fetchData()
    },[])
    const handleEdit = async (values:UpdateReferal) => {
        const data = {
            ...values,
            ...id,
            reg_date:moment(values.reg_date).format("YYYY-MM-DD")
        }
        setFormLoading(true)
        setVisible({ visible:false,referal_id: 0,partner_id:0 })
        try{
            const response = await unApi.updateTableReferal(data)
            if(response.status === 200){
                form.resetFields()
                setVisible({ visible:false,referal_id: 0,partner_id:0 })
                Modal.info({
                    title: "Успех!",
                    content: "Реферал обновлен.",
                })
            }
        }catch(err:any){
            if(err.response.data){
                showErrorMessage(err.response.data)
            }else{
                showErrorMessage(`Произошла ошибка, повторите попытку позже.`)
            }

        }finally{
            setFormLoading(false)
        }
    }

    return (
        <Modal
            title="Редактирование Реферала"
            open={visible}
            onCancel={() => {setVisible({ visible:false,referal_id: 0,partner_id:0 })}}
            onOk={() => {form.submit()}}
            okButtonProps={{ loading: formLoading }}
            okText="Сохранить"
        >
            {
                formLoading ?
                    <div className="loading-container">
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                    </div>
                    :
                    <Form
                        form={form}
                        onFinish={handleEdit}
                        requiredMark={false}
                    >
                        <Form.Item
                            name="comment"
                            label="Комментарий"
                            rules={[
                                { required: true },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
            }
        </Modal>
    )
}

export default EditReferal
