import React, { FC, useEffect, useState } from "react";

import { Button, Form, Input } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { routeNames } from "../../../shared/common/constants/routes";
import { useActions } from "../../../shared/common/hooks/useActions";
import { useTypedSelector } from "../../../shared/common/hooks/useTypedSelector";
import styles from "./Restore.module.scss";

const Restore: FC = () => {
  document.title = "Восстановление пароля";

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { isAuth, isLoading } = useTypedSelector((state) => state.auth);
  const { authRestorePassword } = useActions();

  const [resetToken, setResetToken] = useState("");

  useEffect(() => {
    setTimeout(() => {
      const token = searchParams.get("token");

      if (token && !isAuth) {
        setResetToken(token);
      } else {
        navigate(routeNames.notFound);
      }
    }, 10);
  }, []);

  /**
   * Обработчик входа на сервис.
   * @param values - объект с данными для логина
   */
  const onFinish = async (values: { password: string }) => {
    await authRestorePassword(resetToken, values.password, () => {
      navigate(routeNames.login);
    });
  };

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginModal}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item>
            <div className={styles.logoWrapper}>
              <img alt="SCAN" src="/logo.jpg" />
            </div>
          </Form.Item>

          <Form.Item>
            <div className={styles.topInfo}>Восстановление пароля</div>
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Введите пароль." }]}
          >
            <Input.Password placeholder="Новый пароль" size="large" />
          </Form.Item>

          <Form.Item
            name="password_repeat"
            rules={[
              {
                required: true,
                message: "Введите пароль еще раз.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Пароли должны совпадать."));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Повторите пароль" size="large" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className={styles.submitButton}
              loading={isLoading}
            >
              Сохранить
            </Button>
          </Form.Item>

          <div className={styles.loginInfo}>
            Вспомнили пароль? <Link to={routeNames.login}>Войти</Link>. <br />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Restore;
