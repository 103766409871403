import { FC } from "react"

import ModalSettings from "./ModalSettings"
import { prepareGroupsData, generateListKeys } from "../../../../helpers/utils/reports"
import { groupingsData,groupingsDataHome } from "../../constants/reports"
import { useActions } from "../../hooks/useActions"
import { useTypedSelector } from "../../hooks/useTypedSelector"

let data = prepareGroupsData(groupingsData)

export const listKeysGroupings = generateListKeys(groupingsData)

export const ads_keys = [
    "ads_date", "ads_date_w", "ads_date_dw", "ads_date_m", "ads_date_q", "ads_date_y",
    "ads_source", "ads_cabinet", "ads_campaign", "ads_adset", "ads_adv", "ads_campaign_id", "ads_adset_id", "ads_adv_id",
    "ads_utm_source", "ads_utm_medium", "ads_utm_campaign", "ads_utm_content", "ads_utm_term", "ads_utm_group", "ads_tag"
]

const Groupings: FC<any> = ({ isDisable, hidePersonalDataGroups, adsGroupsSelected, adsGroupsAvailable, setAdsGroupsAvailable, setAdsGroupsSelected }) => {
    const prepareGroupsDataHome = (data: any[], _preKey?: string): any[] => { // if children undefined
        const preKey = _preKey || "0";
        return data.map((item, index) => {
            const key = `${preKey}-${index}`;
            const newItem = {
                ...item,
                key,
                label: item.name
            };

            if (item.children) {
                newItem.checkable = false;
                newItem.children = prepareGroupsDataHome(item.children, key);
            }
            return newItem;
        });
    }

    const { groupings } = useTypedSelector((state) => state.table)
    const { setGroupings, setMetaTableFetching, setMetaChartFetching, setMetaCompareChartFetching, setMetaCompareTableFetching } = useActions()
    const actionSet = (selected: any) => {

        setGroupings({data:selected})

        setTimeout(() => {
            setMetaTableFetching({value:true})
            setMetaChartFetching({value:true})
            setMetaCompareChartFetching({value:true})
            setMetaCompareTableFetching({value:true})
        }, 50)
    }
    if(!data[0].children){
        data = prepareGroupsDataHome(groupingsDataHome)
    }
    return (
        <>
            <ModalSettings
                hidePersonalDataGroups={hidePersonalDataGroups}
                title="Группировки"
                dataItems={data}
                listKeys={listKeysGroupings}
                defSelected={groupings}
                defChecked={groupings.map((item) => item.key)}
                isDisable={isDisable}
                actionSet={actionSet}
                adsGroupsSelected={adsGroupsSelected}
                adsGroupsAvailable={adsGroupsAvailable}
                setAdsGroupsAvailable={setAdsGroupsAvailable}
                setAdsGroupsSelected={setAdsGroupsSelected}
            />
        </>
    )
}

export default Groupings
