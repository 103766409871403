import React, {SetStateAction} from "react"

import {CheckboxOptionType, Radio} from "antd"

import {useNavigate} from "react-router-dom";
import {getCurrentAdPage} from "../../../../helpers/utils/functions";


interface Props {
    pageMode:string;
    setPageMode:React.Dispatch<SetStateAction<string>>
    options:(string | number | CheckboxOptionType)[],
    children?:React.ReactNode
}
const TabSelect = ({ pageMode,setPageMode,options,children }:Props) => {
    const navigate = useNavigate()
    const handleTabChange = (key: string) => {
        navigate(getCurrentAdPage(key))
    }


    return (
        <div style={{ display:"inline-flex",columnGap:"20px" }}>
            <Radio.Group
                options={options}
                onChange={(e) => {
                    handleTabChange(e.target.value)
                    setPageMode(e.target.value)
                }}
                value={pageMode}
                optionType="button"
                buttonStyle="solid"
            />
            {children}
        </div>
    )
}
export default TabSelect
