import React, {FC, useEffect} from "react"

import {Button} from "antd"

import MainLayout from "../../shared/common/components/layouts/MainLayout";
import {showSuccessNotification} from "../../helpers/utils/ui";


const SuccessAddSchool: FC = () => {
    useEffect(()=>{
        showSuccessNotification("Школа успешно добавлена.");
    },[])
    return (
        <MainLayout pageTitle="Успех">
            <div className="page-wrapper">
                <div className="page-content">
                    <div style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",rowGap:"20px"}}>
                        <h3 style={{fontSize:"24px",textAlign:"center",maxWidth:"50%",margin:"0 auto",marginTop:"5%"}}> Поздравляем, школа успешно добавлена. Сейчас SCAN по API будет получать данные из GetCourse и через пару часов будет готов к работе. А пока, выполните следующие этапы подключения.</h3>
                        <Button
                            type="primary"
                            size={"middle"}
                            onClick={() => (window.location.href = "https://help.scananalytics.ru/etapy-nastroyki-analitiki/")}
                        >
                            Этапы подключения
                        </Button>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}
export default SuccessAddSchool
