import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const CrmOrders: FC = () => {

    const metrics = [
        { name: "Дата создания заказа", dataKey: "created_date" },
        { name: "Номер заказа", dataKey: "order_id" },
        { name: "id школы", dataKey: "school_id" },
        { name: "Название школы", dataKey: "school_name" },
        { name: "id пользователя", dataKey: "user_id" },
        { name: "Имя", dataKey: "name" },
        { name: "Телефон", dataKey: "email" },
        { name: "Email", dataKey: "phone" },
        { name: "Telegram", dataKey: "telegram" },
        { name: "Чат", dataKey: "school_chat" },
        { name: "Название тарифа", dataKey: "tariff_name" },
        { name: "Цена", dataKey: "order_price" },
        { name: "Скидка", dataKey: "discount" },
        { name: "Оплачено", dataKey: "payment_sum" },
        { name: "Осталось оплатить", dataKey: "payment_left" },
        { name: "id тарифа", dataKey: "tariff_id" },
        { name: "Статус", dataKey: "order_status" },
        { name: "Причина отмены заказа", dataKey: "order_reason" },
        { name: "Комментарий", dataKey: "order_comment" },
        { name: "Последнее изменение", dataKey: "updated_date" },
        { name: "Дата оплаты", dataKey: "payments_date" },
        { name: "История платежей", dataKey: "payment_history" },
        { name: "Ссылка на оплату заказа", dataKey: "payment_link" },
    ]

    return (
        <MainLayout pageTitle="Заказы">
            <div className="page-wrapper">
                <PageHead title="Заказы" />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "Заказы (CRM)" ]}
                        withSegment={false}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default CrmOrders
