import React, {FC, useEffect, useRef, useState} from "react"

import {PlusOutlined, QuestionCircleOutlined} from "@ant-design/icons"
import {Button, Tooltip} from "antd"

import AddModal from "./AddModal"
import MainLayout from "../../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../../shared/common/components/page-header/PageHeader"
import {useActions} from "../../../../shared/common/hooks/useActions";
import {useTypedSelector} from "../../../../shared/common/hooks/useTypedSelector";
import {useNavigate} from "react-router-dom";
import {routeNames} from "../../../../shared/common/constants/routes";


const SchoolAdd: FC = () => {
    const [ addModalVisible, setAddModalVisible ] = useState(false)
    const { setAppReportOpen,setDrawerLink } = useActions();
    const { userAccess }  = useTypedSelector((state)=>state.currentSchool)
    const currentUser  = useTypedSelector((state)=>state.currentUser)
    const navigate = useNavigate()
    const connectStagesBtn = (
        <Button onClick={()=>{
            setDrawerLink(`https://help.scananalytics.ru/etapy-nastroyki-analitiki?timestamp=${new Date().getTime()}`)
            setAppReportOpen(true)
        }} style={{ border:"1px solid black" }} shape="round" type="default">Этапы подключения</Button>
    )
    const isMounted = useRef(false)
    useEffect(() => {
        if(isMounted.current){
            if(userAccess?.status){
                navigate(routeNames.home)
            }
            if(userAccess === null && !currentUser.id){
                navigate(routeNames.error)
            }
        }else{
            isMounted.current = true
        }
    }, [userAccess,currentUser]);
    return (
        <MainLayout pageTitle="Добавить школу">
            <div className="page-wrapper">
                <PageHead title="Подключение новой школы" extraContent={connectStagesBtn} />

                <div className="page-content">
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => setAddModalVisible(true)}
                    >
                        Добавить школу GetCourse
                    </Button>
                </div>

                <AddModal
                    isVisible={addModalVisible}
                    setVisible={setAddModalVisible}
                />
            </div>
        </MainLayout>
    )
}

export default SchoolAdd
