import {Select} from "antd";
import React, {useEffect} from "react";

export const StatusSelect = ( {renderItem,statusNames,selectedSchool,handleStatusSelect }:any) => {
    const [selectedValue, setSelectedValue] = React.useState(renderItem.cellData)
    const [ isSelected,setIsSelected ] = React.useState(false)
    const handleSelect = (value:unknown) => {
        setSelectedValue(value)
        handleStatusSelect({order_status_id:value,order_number:+renderItem.rowData.order_number,school_id:Number(selectedSchool?.id ) || 0})
        setIsSelected(true)
    }
    useEffect(()=>{
        !isSelected && setSelectedValue(renderItem.cellData)
    },[ renderItem ])
    return (
        <Select
            loading={statusNames.loading}
            disabled={statusNames.loading}
            optionFilterProp="children"
            showSearch={true}
            value={selectedValue}
            style={{width:160}}
            onSelect={handleSelect}
            filterOption={(input, option) => (option?.label ?? "").includes(input)}
            filterSort={(optionA:any, optionB:any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
            }
        >
            {
                statusNames.data.length > 0 ?
                    <>
                        {
                            statusNames.data.map((item:any, index:any) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={item.id}
                                        label={item.name}
                                    >
                                        {item.name}
                                    </Select.Option>
                                )
                            })
                        }
                    </>
                    :
                    <Select.Option value="">Нет</Select.Option>
            }
        </Select>
    )
}
