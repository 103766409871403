import React, {FC, useEffect, useRef, useState} from "react"

import { Button } from "antd"

import CompareFilterBlock from "./CompareFilterBlock"
import ComparePeriod from "./ComparePeriod"
import { useActions } from "../../../../../hooks/useActions"
import { useTypedSelector } from "../../../../../hooks/useTypedSelector"
import moment from "moment";
import {calculateFirstBlock} from "../../../../../../../helpers/utils/functions";

interface ICompareSectionProps {
    filtersFilter?: string[]
}


const CompareSection: FC<ICompareSectionProps> = ({ filtersFilter }) => {

    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const period = useTypedSelector((state)=>state.period)
    const currentReport = useTypedSelector((state)=>state.reports.currentReport)
    const isCurrentReport = Object.keys(currentReport).length > 0
    const { comparePeriod,compareFilters } = useTypedSelector((state) => state.compare)
    const { getSegments, setComparePeriod, setMetaCompareChartFetching, setMetaCompareTableFetching,setCompareFilter } = useActions()

    const period1 = comparePeriod["comparePeriod1"]
    const period2 = comparePeriod["comparePeriod2"]
    const [ currentDate1, setCurrentDate1 ] = useState({ start: period1.start, end: period1.end, interval: period1.interval })
    const [ currentDate2, setCurrentDate2 ] = useState({ start: period2.start, end: period2.end, interval: period2.interval })
    const isLoadeed = useRef(false)

    useEffect(() => {
        if (selectedSchool?.id) {
            getSegments(selectedSchool?.id)
            setMetaCompareChartFetching({value:true})
            setMetaCompareTableFetching({value:true})
        }
    }, [])
    useEffect(()=>{
        if(isCurrentReport){
            setCurrentDate1(calculateFirstBlock(period2))
            setCurrentDate2(period2)
            const isEmptyFilters = () => {
                let result = false
                Object.values(compareFilters).forEach((el)=>{
                    result = el.length === 0;
                })
                return result
            }
            if(currentReport?.values?.filters?.length > 0 && isEmptyFilters()){
                setCompareFilter({ index: 1, filters: [...currentReport.values.filters]})
                setCompareFilter({index:2,filters:[...currentReport.values.filters]})
            }
            isLoadeed.current = true
        }

    },[comparePeriod["comparePeriod1"], comparePeriod["comparePeriod2"]])


    const savePeriod = (start: any, end: any, periodId: 1 | 2) => {
        const payload = { start: start, end: end, interval: "" }

        setComparePeriod({ index: periodId, period: payload })
    }

    const sendRequest = () => {
        savePeriod(currentDate1.start, currentDate1.end, 1)
        savePeriod(currentDate2.start, currentDate2.end, 2)
        setMetaCompareChartFetching({value:true})
        setMetaCompareTableFetching({value:true})
    }
    useEffect(()=>{
        if(isLoadeed.current){
            sendRequest()
        }
    },[isLoadeed.current,currentReport])

    return (
        <div>
            <div className="segments-compare-container">
                <div className="segments-compare-block">
                    <div>
                        <ComparePeriod currentDate={currentDate1} setCurrentDate={setCurrentDate1} />
                    </div>

                    <CompareFilterBlock prefix={1} filtersFilter={filtersFilter} />
                </div>

                <div className="segments-compare-block">
                    <div>
                        <ComparePeriod currentDate={currentDate2} setCurrentDate={setCurrentDate2} />
                    </div>

                    <CompareFilterBlock prefix={2} filtersFilter={filtersFilter} />
                </div>
            </div>

            <div
                style={{
                    padding: "10px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Button
                    type="primary"
                    className="request_button"
                    onClick={() => {
                        sendRequest()
                    }}
                >
                    Показать
                </Button>
            </div>
        </div>
    )
}

export default CompareSection


