import React, { FC, useEffect, useState } from "react"

import { DownOutlined, CloseOutlined, EllipsisOutlined, QuestionCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Tree, Input, Modal, Row, Col, Button, Tooltip, Space, Dropdown, Menu, InputNumber } from "antd"
import { ReactSortable } from "react-sortablejs"

import {
    filterGroupsDisabledData,
    getMetricsParentKey,
    loopSearch
} from "../../../../../helpers/utils/reports"
import { showErrorMessage } from "../../../../../helpers/utils/ui"
import { useActions } from "../../../hooks/useActions"
import { useForceUpdate } from "../../../hooks/useForceUpdate"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import SubMenu from "antd/es/menu/SubMenu";
import {findObjectById} from "../../../../../helpers/utils/functions";


function ClearOutlined() {
    return null
}
const actions = [
    { id: "calculate_conversion", label: "Конверсия (CR)" },
    { id: "add_menu", label: "Плюс (+)",children:[
            {id:"inc_by_number",label:"Число N"},
            {id:"add",label:"Другая метрика"},
            {id:"inc_by_percent",label:"N процентов"}
        ] },
    { id: "subtract_menu", label: "Минус (-)",children:[
            {id:"dec_by_number",label:"Число N"},
            {id:"subtract",label:"Другая метрика"},
            {id:"dec_by_percent",label:"N процентов"}
        ] },
    { id: "multiply_menu", label: "Умножить (*)",children:[
            {id:"multiply_by_number",label:"На Число N"},
            {id:"multiply",label:"На Другую метрику"},
        ]},
    { id: "divide_menu", label: "Разделить (/)",children:[
            {id:"divide_by_number",label:"На Число N"},
            {id:"divide",label:"На Другую метрику"},
        ]},
]
export const actionsWithN = [ "inc_by_number", "dec_by_number", "inc_by_percent", "dec_by_percent", "multiply_by_number","divide_by_number"]
interface InputState {
    checkedKeys: Array<string | number>
    selected: Array<any>;
    expandedKeys: Array<string | number>;
}

interface InputStates {
    [key: string]: InputState;
}

const initialInputState = {
    checkedKeys: [],
    selected: [],
    expandedKeys:[]
}

const ConversationUI: FC<any> = ({  dataItems, listKeys, defSelected, defChecked, actionSet, isDisable, hidePersonalDataGroups = false,setConverseData,converseData,isEdit=false }) => {
    const [ data, setData ] = useState(dataItems)
    const [ inputStates, setInputStates ] = useState<InputStates>({
        input1: { ...initialInputState },
    })
    const [ selected, setSelected ] = useState<any>([])
    const [ searchValue, setSearchValue ] = useState("")
    const [ autoExpandParent, setAutoExpandParent ] = useState(true)
    const userRole = useTypedSelector((state) => state.currentUser.role)
    const [ isBasicMetricsVisible,setIsBasicMetricsVisible ] = useState(false)
    const [ selectedAction, setSelectedAction ] = useState<{id:string,label:string}>({ id:"",label:"" })
    const [ currentInput,setCurrentInput ] = useState("input1")
    const [ constant,setConstant ] = useState<string | undefined>(undefined)
    useEffect(()=>{
        try{
            if(isEdit && inputStates.input1.selected.length === 0 && selectedAction.id === ""){
                converseData.selectedAction?.id.length > 0 && converseData.selectedAction?.label.length === 0  ? setSelectedAction(findObjectById(actions,converseData.selectedAction.id!)) : null
                converseData?.inputStates ?  setInputStates(converseData.inputStates) : null
            }
        }catch(err){
            console.log(err)
        }

    },[ converseData ])
    useEffect(()=>{
        if(isEdit){
            if(inputStates.input1?.selected?.length > 0 && selectedAction?.id !== ""){
                setConverseData((prev:any)=>({ ...prev,selectedAction,inputStates:{ ...inputStates },constant_argument:constant !== undefined ? Number(constant) : prev.constant_argument }))
                if(!constant && converseData.constant_argument){
                    setConstant(converseData.constant_argument)
                }
            }
        }else{
            setConverseData((prev:any)=>({ ...prev,selectedAction,inputStates:{ ...inputStates },constant_argument:constant !== undefined ? Number(constant) : null }))
        }
    },[ inputStates,constant ])

    useEffect(() => {

        if (hidePersonalDataGroups) {
            setData(filterGroupsDisabledData(data, [ "user_id", "user_email", "user_id_ext" ]))
        } else {
            setData(filterGroupsDisabledData(data, [ "" ]))
        }
    }, [ hidePersonalDataGroups, data, dataItems ])

    if (userRole === "user") {
        hidePersonalDataGroups = true
    }

    const onExpand = (expandedKeys: any) => {
        setInputStates((prev:any) => ({
            ...prev,
            [currentInput]: {
                ...prev[currentInput],
                expandedKeys: expandedKeys,
            },
        }))
        setAutoExpandParent(false)
    }
    const [ hoveredInput, setHoveredInput ] = useState<string>("")

    const handleMouseEnter = (inputKey:string) => {
        if(inputKey === "input1") return
        setHoveredInput(inputKey)
    }

    const handleMouseLeave = () => {
        setHoveredInput("")
    }

    const handleDeleteInput = (inputKey:string) => {
        const newInputStates = { ...inputStates }
        delete newInputStates[inputKey]
        setInputStates(newInputStates)
    }

    const addInput = () => {
        const lastInputNumber = Object.keys(inputStates).length
        const newInputKey = `input${lastInputNumber + 1}`

        setInputStates((prevInputStates:any) => ({
            ...prevInputStates,
            [newInputKey]: { ...initialInputState },
        }))
    }

    const updateSelectedMetrics = (isChecked: boolean, node: any, prevSelected: any) => {
        let newSelected = [ ...prevSelected ]

        if (isChecked) {
            newSelected.push({ ...node })
        } else {
            newSelected = newSelected.filter((item) => item.key !== node.key)
        }
        return newSelected.slice(-1)
    }
    const onCheck = (_checkedKeys: any, e: any) => {
        setInputStates((prev:any) => ({
            ...prev,
            [currentInput]: {
                checkedKeys: _checkedKeys.slice(-1),
                selected: updateSelectedMetrics(e.checked, e.node, prev[currentInput].selected),
            },
        }))

        if (_checkedKeys.length > 0) {
            setIsBasicMetricsVisible(false)
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        if (value !== "") {
            const reg = new RegExp(value, "i")

            let updatedInputStates: any = { ...inputStates }

            for (const [ inputKey, inputState ] of Object.entries(updatedInputStates)) {
                const _expandedKeys: any = listKeys
                    .map((item: any) => {
                        if (item.name.search(reg) > -1) {
                            return item?.dataKey?.startsWith("_") ? dataItems.find((el:{name:string})=>el.name === "Созданные метрики")?.key  : getMetricsParentKey(item.key, dataItems)
                        }
                        return null
                    })
                    .filter((item: any, i: number, self: any) => item && self.indexOf(item) === i)

                updatedInputStates[inputKey] = {
                    ...inputState as any,
                    expandedKeys: _expandedKeys,
                }
            }

            setInputStates(updatedInputStates)
            setSearchValue(value)
            setAutoExpandParent(true)
        } else {
            if (currentInput) {
                setInputStates((prev:any) => ({
                    ...prev,
                    [currentInput]: {
                        ...prev[currentInput],
                        expandedKeys: [],
                    },
                }))
                setSearchValue("")
                setAutoExpandParent(false)
            }
        }
    }

    const calculateWidth = (length:number) => {
        const defaultWidth = 150
        const characterCount = length || 0
        const calculatedWidth = (characterCount * 8) + 35
        return Math.min(calculatedWidth === 35 ? defaultWidth :  calculatedWidth, 1000)
    }
    const handleMenuClick = (action: { id: string; label: string; children?: { id: string; label: string; }[] } | undefined) => {
        setInputStates((prev)=>({
                input1: { ...prev.input1 }
            })
        )
        if(action){
            if(action.id === "calculate_conversion"){
                setInputStates((prev:any)=>({ ...prev,input2:{ ...initialInputState } }))
                setSelectedAction(action)
                return
            }
            setInputStates((prev:any)=>({ ...prev,input2:{ ...initialInputState } }))
            setSelectedAction(action)
        }
    }
    const findAction = (id: string) => {
        const actionWithChildren = actions.find(action => action.children && action.children.find(el => el.id === id))
        if (actionWithChildren) {
            const childAction = actionWithChildren.children?.find(el => el.id === id)
            if (childAction) {
                return childAction
            }
        } else {
            return actions.find(action => action.id === id)
        }
    }

    const cumulutiveMenuType =  <Menu onClick={addInput}>
        {actions.map((action) => (
            action.children && action.id === `${selectedAction.id}_menu` &&  (
                <SubMenu key={action.id} title={action.label}>
                    {
                         action.children.filter((el)=>el.label === "Другая метрика").map(child => (
                                    <Menu.Item key={child.id}>{child.label}</Menu.Item>
                                )
                            )
                    }
                </SubMenu>
            )
        ))}
    </Menu>
    const menuTypes = {
        0:(
            <Menu onClick={({ key }) => handleMenuClick(findAction(key))}>
                {actions.map((action) => (
                    action.children ? (
                        <SubMenu key={action.id} title={action.label}>
                            {action.children.map(child => (
                                <Menu.Item key={child.id}>{child.label}</Menu.Item>
                            ))}
                        </SubMenu>
                    ) : (
                        <Menu.Item key={action.id}>{action.label}</Menu.Item>
                    )
                ))}
            </Menu>
        ),
        1:cumulutiveMenuType,
        2:cumulutiveMenuType
    }
    const getMenu = () => {
        switch(selectedAction?.id){
            case "":{
                return menuTypes[0]

            }
            case "calculate_conversion":{
                return menuTypes[0]
            }
            case "subtract":{
                return menuTypes[1]
            }
            case "add":{
                return menuTypes[2]
            }
        }
    }
    const getActions = () => {
        switch (selectedAction?.id){
            case "":{
                return (
                    <div style={{ display:"flex",alignItems:"center",columnGap:"12px" }}>
                        <Dropdown overlay={getMenu()} trigger={["click"]} placement="bottomCenter" arrow>
                            <Button icon={<PlusOutlined />} type="primary" shape="circle" />
                        </Dropdown>
                    </div>
                )
            }
            case "calculate_conversion":{
                return <></>
            }
        }
    }
    const handleMetricChoose = (input:string) => {
        setCurrentInput(input)
        setIsBasicMetricsVisible(true)
    }
    const getActionWithNLabel = (actionId: string): string | undefined => {
        const actionLabels: Record<string, string> = {
            "inc_by_number": "+",
            "inc_by_percent": "+",
            "dec_by_number": "-",
            "dec_by_percent": "-",
            "multiply_by_number": "*",
            "divide_by_number": "/"
        }

        return actionLabels[actionId]
    }

    const getSelectedUI = () => {
        if(actionsWithN.includes(selectedAction?.id as string)){
            return (
                <>
                    <Dropdown overlay={menuTypes[0]} trigger={['click']}  placement="bottomCenter" arrow>
                        <div className={"hover-blue"} style={{ whiteSpace:"nowrap" }}>{getActionWithNLabel(selectedAction.id) ?? selectedAction.label.replace(/N%|N$/, "")}</div>
                    </Dropdown>
                    <div style={{ display:"flex",columnGap:"6px",alignItems:"center" }}>
                        <Input value={constant} onChange={(e)=>setConstant(e.target.value)} placeholder={selectedAction?.id.includes("number") ? "Введите число" : "Введите значение"} style={{ width:"auto" }} />
                        {selectedAction?.id.includes("percent") ?  "%" : null}
                            <div
                                style={{
                                    marginLeft: "4px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {handleDeleteInput("input2"),setSelectedAction({ id:"",label:"" })}}
                            >
                                <CloseOutlined />
                            </div>
                    </div>
                </>
            )
        }else{
            switch(selectedAction?.id){
                case "":{
                    return <></>
                }
                case "calculate_conversion":{
                    return (
                        <>
                            <Dropdown overlay={menuTypes[0]} trigger={['click']}  placement="bottomCenter" arrow>
                                <div className={"hover-blue"} style={{ whiteSpace:"nowrap" }}>{"CR"}</div>
                            </Dropdown>
                            <div>
                                {}
                                <Input  value={inputStates["input2"]?.selected[0]?.name} style={{ width:calculateWidth(inputStates["input2"]?.selected[0]?.name?.length) }} onClick={()=>handleMetricChoose("input2")} placeholder={"Выбор метрики"} />
                            </div>
                        </>
                    )
                }
                case "divide":{
                    return (
                        <>
                            <Dropdown overlay={menuTypes[0]} trigger={['click']}  placement="bottomCenter" arrow>
                                <div className={"hover-blue"} style={{ whiteSpace:"nowrap" }}>{"/"}</div>
                            </Dropdown>
                            <div>
                                {}
                                <Input  value={inputStates["input2"]?.selected[0]?.name} style={{ width:calculateWidth(inputStates["input2"]?.selected[0]?.name?.length) }}  onClick={()=>handleMetricChoose("input2")} placeholder={"Выбор метрики"} />
                            </div>
                        </>
                    )
                }
                case "multiply":{
                    return (
                        <>
                            <Dropdown overlay={menuTypes[0]} trigger={['click']}  placement="bottomCenter" arrow>
                                <div className={"hover-blue"} style={{ whiteSpace:"nowrap" }}>{"*"}</div>
                            </Dropdown>
                            <div>
                                {}
                                <Input  value={inputStates["input2"]?.selected[0]?.name} style={{ width:calculateWidth(inputStates["input2"]?.selected[0]?.name?.length) }}  onClick={()=>handleMetricChoose("input2")} placeholder={"Выбор метрики"} />
                            </div>
                        </>
                    )
                }
                case "subtract":{
                    return (
                        <>
                            <div style={{ display:"flex",columnGap:"6px",alignItems:"center",rowGap:"10px",minWidth:"500px",flexWrap:"wrap" }}>
                                {Object.keys(inputStates).map((inputKey,index,array) => (
                                    <div  style={{ display:"flex",alignItems:"center" }} onMouseEnter={() => handleMouseEnter(inputKey)}
                                          onMouseLeave={handleMouseLeave}>
                                        {<Input
                                        key={inputKey}
                                        value={inputStates[inputKey]?.selected[0]?.name}
                                        style={{ width: calculateWidth(inputStates[inputKey]?.selected[0]?.name?.length),height:"32px" }}
                                        onClick={() => handleMetricChoose(inputKey)}
                                        placeholder={"Выбор метрики"}
                                    />}
                                        {index === array.length - 1 && index !== 0  && (
                                            <div
                                                style={{
                                                    marginLeft: "4px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => handleDeleteInput(inputKey)}
                                            >
                                                <CloseOutlined />
                                            </div>
                                        )}
                                        { index < array.length - 1 && index === 0 &&
                                            <Dropdown overlay={menuTypes[0]} trigger={['click']}  placement="bottomCenter" arrow>
                                                <div className={"hover-blue"} style={{ whiteSpace:"nowrap",marginLeft:"5px" }}>-</div>
                                            </Dropdown>
                                        }
                                        { index < array.length - 1 && index !== 0 &&
                                            <div style={{ whiteSpace:"nowrap",marginLeft:"5px" }}>-</div>
                                        }
                                    </div>
                                ))}
                                <div style={{ display:"flex",alignItems:"center",columnGap:"12px" }}>
                                    <Dropdown overlay={menuTypes[1]} trigger={["click"]} placement="bottomCenter" arrow>
                                        <Button icon={<PlusOutlined />} type="primary" shape="circle" />
                                    </Dropdown>
                                </div>
                            </div>
                        </>
                    )
                }
                case "add":{
                    return (
                        <>
                            <div style={{ display:"flex",columnGap:"6px",alignItems:"center",rowGap:"10px",minWidth:"500px",flexWrap:"wrap" }}>
                                {Object.keys(inputStates).map((inputKey,index,array) => (
                                    <div  style={{ display:"flex",alignItems:"center" }} onMouseEnter={() => handleMouseEnter(inputKey)}
                                          onMouseLeave={handleMouseLeave}>
                                        {
                                            <Input
                                            key={inputKey}
                                            value={inputStates[inputKey]?.selected[0]?.name}
                                            style={{ width: calculateWidth(inputStates[inputKey]?.selected[0]?.name?.length),height:"32px" }}
                                            onClick={() => handleMetricChoose(inputKey)}
                                            placeholder={"Выбор метрики"}
                                        />
                                        }
                                        {index === array.length - 1 && index !== 0  && (
                                            <div
                                                style={{
                                                    marginLeft: "4px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => handleDeleteInput(inputKey)}
                                            >
                                                <CloseOutlined />
                                            </div>
                                        )}
                                        { index < array.length - 1 && index === 0 &&
                                            <Dropdown overlay={menuTypes[0]} trigger={['click']}  placement="bottomCenter" arrow>
                                                <div className={"hover-blue"} style={{ whiteSpace:"nowrap",marginLeft:"5px" }}>+</div>
                                            </Dropdown>
                                           }
                                        { index < array.length - 1 && index !== 0 &&
                                                <div style={{ whiteSpace:"nowrap",marginLeft:"5px" }}>+</div>
                                        }
                                    </div>
                                ))}
                                <div style={{ display:"flex",alignItems:"center",columnGap:"12px" }}>
                                    <Dropdown overlay={menuTypes[2]} trigger={["click"]} placement="bottomCenter" arrow>
                                        <Button icon={<PlusOutlined />} type="primary" shape="circle" />
                                    </Dropdown>
                                </div>
                            </div>
                        </>
                    )
                }
            }
        }
    }
    return (
        <div className="modal-settings" style={{ marginTop:"24px" }}>
            <Row gutter={12}>
                <Col>
                    <span style={{ fontWeight:"700" }}>Выберите метрику</span>
                    <div style={{ display:"flex",flexDirection:selectedAction?.id === "subtract" || selectedAction?.id === "add"  ? "row" : "column",marginTop:"12px" }}>
                        <div style={{ display:"flex",alignItems:"center",columnGap:"14px",flexWrap:selectedAction?.id === "subtract" || selectedAction?.id === "add" ? "wrap" : "unset" }}>
                            {
                                selectedAction?.id !== "subtract" && selectedAction?.id !== "add" &&  <div>
                                    <Input  value={inputStates["input1"]?.selected[0]?.name} style={{ width:calculateWidth(inputStates["input1"]?.selected[0]?.name?.length) }}  onClick={()=>handleMetricChoose("input1")} placeholder={"Выбор метрики"} />
                                </div>
                            }
                            {getSelectedUI()}
                            {getActions()}
                        </div>
                    </div>
                    <Modal
                        title={"Метрики"}
                        open={isBasicMetricsVisible}
                        onCancel={()=>setIsBasicMetricsVisible(false)}
                        width={800}
                        className="modal-settings"
                        destroyOnClose
                        footer={null}
                    >
                        <div style={{ overflow: "auto" }}>
                            <Input
                                style={{ marginBottom: 8,marginTop:"8px" }}
                                placeholder={"Поиск " + "метрики"}
                                onChange={onChange}
                                allowClear
                            />
                            <Tree.DirectoryTree
                                onExpand={onExpand}
                                autoExpandParent={autoExpandParent}
                                onCheck={onCheck}
                                checkedKeys={inputStates[currentInput]?.checkedKeys || []}
                                expandedKeys={inputStates[currentInput]?.expandedKeys || []}
                                blockNode
                                checkable
                                switcherIcon={<DownOutlined />}
                                showIcon={false}
                                selectable={false}
                                treeData={loopSearch(data, searchValue)}
                                titleRender={(nodeData: any) => {
                                    return nodeData.disabled ? (
                                        <Tooltip
                                            placement="top"
                                            color="#fff"
                                            zIndex={9999}
                                            overlayClassName="page-title-tooltip"
                                        >
                                            {nodeData.title}
                                        </Tooltip>
                                    ) : (
                                        <>
                                            {
                                                nodeData.description ?
                                                    <>
                                                        {
                                                            nodeData.disableCheckbox ?
                                                                <Tooltip
                                                                    placement="top"
                                                                    title={<div className="page-title-tooltip-text">
                                                                        Контактные
                                                                        данные пользователей не доступны. Обратитесь к
                                                                        администратору, чтобы получить к ним доступ.
                                                                    </div>}
                                                                    color="#fff"
                                                                    zIndex={9999}
                                                                    overlayClassName="page-title-tooltip"
                                                                >
                                                                    <span className="group-item-icon-wrapper">
                                                                        {nodeData.title}
                                                                        <span
                                                                            style={{ marginRight: 10, marginLeft: 5 }}
                                                                        >
                                                                            <Tooltip
                                                                                placement="bottom"
                                                                                title={<div className="page-title-tooltip-text"
                                                                                            dangerouslySetInnerHTML={{ __html: nodeData.description }}></div>}
                                                                                color="#fff"
                                                                                zIndex={9999}
                                                                                overlayClassName="page-title-tooltip"
                                                                            >
                                                                              <QuestionCircleOutlined
                                                                                  style={{ color: "#ccc", fontSize: 16 }}/>
                                                                            </Tooltip>
                                                                        </span>
                                                                    </span>
                                                                </Tooltip>
                                                                :
                                                                <span className="group-item-icon-wrapper">
                                                                    {nodeData.title}
                                                                    <span
                                                                        style={{ marginRight: 10, marginLeft: 5 }}
                                                                    >
                                                                        <Tooltip
                                                                            placement="bottom"
                                                                            title={<div className="page-title-tooltip-text" dangerouslySetInnerHTML={{ __html: nodeData.description }}></div>}
                                                                            color="#fff"
                                                                            zIndex={9999}
                                                                            overlayClassName="page-title-tooltip"
                                                                        >
                                                                        <QuestionCircleOutlined
                                                                            style={{ color: "#ccc", fontSize: 16 }}/>
                                                                        </Tooltip>
                                                                    </span>
                                                                </span>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            nodeData.disableCheckbox ?
                                                                <Tooltip
                                                                    placement="top"
                                                                    title={<div
                                                                        className="page-title-tooltip-text">Контактные
                                                                        данные пользователей не доступны. Обратитесь к
                                                                        администратору, чтобы получить к ним доступ.</div>}
                                                                    color="#fff"
                                                                    zIndex={9999}
                                                                    overlayClassName="page-title-tooltip"
                                                                >
                                                                <span>
                                                                    {nodeData.title}
                                                                </span>
                                                                </Tooltip>
                                                                :
                                                                <span>
                                                                {nodeData.title}
                                                            </span>
                                                        }
                                                    </>
                                            }
                                        </>
                                    )
                                }}
                            />
                        </div>
                    </Modal>
                </Col>
            </Row>

        </div>
    )
}

export default ConversationUI
