import React from "react"

import { Tabs } from "antd"

import Accruals from "./tabs/Accruals"
import Partners from "./tabs/Partners"
import Payments from "./tabs/Payments"
import Referals from "./tabs/Referals"

const PartnerTabs = () => {

const tabs = () => [
    { label: "Партнёры", key: "item-1", children: <Partners />, forceRender: true },
    { label: "Рефералы", key: "item-2", children: <Referals />, forceRender: true },
    { label: "Начисления", key: "item-3", children: <Accruals />, forceRender: true },
    { label: "Выплаты", key: "item-4", children: <Payments />, forceRender: true },
]

return (
    <div>
        <Tabs
            type="card"
            items={tabs()}
        />
    </div>
)
}
export default PartnerTabs
