import React, { FC, useState } from "react"

import { Form, Input, Modal, Select, Space } from "antd"
import {unApi} from "../../../../shared/common/api/endpoints/reports/unApi";
import {showErrorMessage} from "../../../../helpers/utils/ui";
import {useTypedSelector} from "../../../../shared/common/hooks/useTypedSelector";

interface Values {
    name:string,
    currency_id:number
}

interface ICreateRKProps {
    isVisible: boolean;
    setVisible: (val: boolean) => void;
}

const CreateRK: FC<ICreateRKProps> = ({ isVisible, setVisible }) => {
    const [ form ] = Form.useForm()
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const [ isLoading,setIsLoading ] = useState(false)

    const addSpend = async (values: Values) => {
        if (selectedSchool) {
            try{
                setIsLoading(true)
                const data = {
                    ...values,
                    source_id:3,
                    school_id:Number(selectedSchool.id),
                    currency_id: values.currency_id || 1,
                }
                await unApi.manualAddRK(data)
                Modal.info({
                    title:"Успех!",
                    content:"Рекламный кабинет успешно создан."
                })
                form.resetFields()
                setVisible(false)
            }catch(err){
                showErrorMessage("Произошла ошибка , повторите попытку позже. Проверьте название , оно должно быть уникальным.")
            }finally{
                setIsLoading(false)
            }
        }
    }


    return (
        <Modal
            title="Создание рекламного кабинета"
            open={isVisible}
            onOk={() => form.submit()}
            onCancel={() => setVisible(false)}
            okText="Добавить"
            cancelText="Отмена"
            okButtonProps={{ loading: isLoading }}
            destroyOnClose>
            <Form form={form} onFinish={addSpend} layout="vertical" requiredMark={false}>
                <Form.Item label="Название рекламного кабинета" name="name" rules={[ { required: true, message: "Поле обязательное" } ]}>
                    <Input style={{ width: "100%" }} />
                </Form.Item>
                <Space style={{ display: "flex", justifyContent: "space-between" }}>
                    <Form.Item label="Валюта" name="currency_id">
                        <Select defaultValue={1}>
                            <Select.Option value={1}>Рубль РФ</Select.Option>
                            <Select.Option value={2}>Доллар США</Select.Option>
                            <Select.Option value={3}>Евро</Select.Option>
                            <Select.Option value={4}>Тенге</Select.Option>
                            <Select.Option value={5}>Гривна </Select.Option>
                            <Select.Option value={6}>Беларусский рубль</Select.Option>
                        </Select>
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    )
}

export default CreateRK


