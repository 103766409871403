import { FC } from "react"

import { Space, Alert } from "antd"

import { IhandleResponseProps } from "./ConnectAds"
import styles from "./ConnectAds.module.scss"
const HandleResponse:FC<IhandleResponseProps> = ({ message,type,visible }) => {
    return(
        <Space direction="vertical" className={styles.errorWrapper}>
            {visible && (
                <Alert message={<span dangerouslySetInnerHTML={{ __html: message }} />} type={type} closable />
            )}
        </Space>
    )
}
export default HandleResponse