import axios, { AxiosResponse } from "axios"

import { ISchoolFormData } from "../../../../app/providers/redux/slices/currentSchool/types"
import { axiosConfigAuth } from "../middleware"
import {
    API_SCHOOL_ADD,
    API_SCHOOL_CHANGE,
    API_SCHOOL_DELETE,
    API_SCHOOL_GET_DATA,
    API_SCHOOL_GET_DETAILS, API_SCHOOL_SELECT
} from "../urls"

export const schoolApi = {
    fetchAllData: async (): Promise<AxiosResponse> => {
        return axios.get(API_SCHOOL_GET_DATA, axiosConfigAuth())
    },

    fetchCurrentSchoolData: async (school_id: string): Promise<AxiosResponse> => {
        const data = {
            school_id
        }

        return axios.post(API_SCHOOL_GET_DETAILS, data, axiosConfigAuth())
    },

    addSchool: async (values: ISchoolFormData): Promise<AxiosResponse> => {
        const data = {
            ...values
        }

        return axios.post(API_SCHOOL_ADD, data, axiosConfigAuth())
    },

    updateSchool: async (schoolId: string, values: ISchoolFormData): Promise<AxiosResponse> => {
        const data = {
            ...values,
            school_id: schoolId
        }

        return axios.post(API_SCHOOL_CHANGE, data, axiosConfigAuth())
    },

    deleteSchool: async (schoolId: string): Promise<AxiosResponse> => {
        return axios.delete(`${API_SCHOOL_DELETE}/${schoolId}`)
    },

    changeSchool: async (schoolId: string): Promise<AxiosResponse> => {
        const data = {
            id: schoolId
        }

        return axios.post(`${API_SCHOOL_SELECT}`, data, axiosConfigAuth())
    },
}
