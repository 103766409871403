import React, { useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { DatePicker , Form, Input, InputNumber, Modal, Select } from "antd"
import axios from "axios"
import moment from "moment"

import { showErrorMessage } from "../../../../../../../helpers/utils/ui"
import { unApi } from "../../../../../api/endpoints/reports/unApi"
const EditOrder = ({ editOrderModalVisible, setEditOrderModalVisible, id, loadRows }:any) => {

    const [ form ] = Form.useForm()
    const dateFormat = "YYYY-MM-DD HH:mm:ss"

    const [ statusNames, setStatusNames ] = useState([])
    const [ statusNamesLoading, setStatusNamesLoading ] = useState(false)
    const [ formLoading, setFormLoading ] = useState(false)

    const fetchData = async () => {
        setFormLoading(true)
        setStatusNamesLoading(true)
        try{
            const response = await unApi.getFilterList(0,"crm_orders_status","")
            const { data:orderInfo,status } = await unApi.getOrderInfo(id)
            if(status === 200){
                form.resetFields()
                form.setFieldsValue({
                    ...orderInfo,
                    created_date: orderInfo.created_date ? moment(orderInfo.created_date, dateFormat) : undefined
                })
            }else{
                showErrorMessage("Ошибка при получении информации об заказе.")
            }
            if(response.status === 200){
                setStatusNames(response.data)
            }else{
                showErrorMessage("Ошибка при получении статусов заказа.")
            }
        }catch(err){
            showErrorMessage("Произошла ошибка , повторите попытку позже.")
        }finally {
            setStatusNamesLoading(false)
            setFormLoading(false)
        }
    }

    useEffect(() => {
        if (editOrderModalVisible) {
            fetchData()
        }
    }, [ editOrderModalVisible ])

    const editOrder = async (values:any) => {
        setFormLoading(true)
        try{
            const inputValues = {
                ...values,
                order_id: id,
                created_date: values.created_date.format(dateFormat)
            }
            const response = await unApi.updateOrderInfo(inputValues)
            if(response.status === 200){
                setEditOrderModalVisible(false)
                loadRows()
                Modal.info({
                    title: "Успех!",
                    content: "Заказ обновлен.",
                })
            }else{
                showErrorMessage("Произошла ошибка, повторите попытку позже.")
            }


        }catch(error){
            showErrorMessage("Произошла ошибка, повторите попытку позже.")
        }finally {
            setFormLoading(false)
        }
    }

    return (
        <Modal
            title="Редактирование заказа"
            open={editOrderModalVisible}
            onCancel={() => {setEditOrderModalVisible(false)}}
            onOk={() => form.submit()}
            okButtonProps={{ loading: formLoading }}
            okText="Сохранить"
            cancelText="Отмена"
        >
            {
                formLoading ?
                    <div className="loading-container">
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                    </div>
                    :
                    <Form
                        form={form}
                        onFinish={editOrder}
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 16 }}
                        requiredMark={false}
                    >
                        <Form.Item
                            name="created_date"
                            label="Дата создания заказа"
                            rules={[ { required: true } ]}
                        >
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */ }
                            <DatePicker
                                placeholder="Выберите дату"
                                format={dateFormat}
                                showTime={true}
                            />
                        </Form.Item>

                        <Form.Item
                            name="discount"
                            label="Скидка"
                            rules={[ { required: false } ]}
                        >
                            <InputNumber />
                        </Form.Item>

                        <Form.Item
                            name="order_status"
                            label="Статус"
                            rules={[ { required: true } ]}
                        >
                            <Select
                                loading={statusNamesLoading}
                                disabled={statusNamesLoading}
                                optionFilterProp="children"
                                showSearch={true}
                                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                                filterSort={(optionA:any, optionB:any) =>
                                    (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                                }
                            >
                                {
                                    statusNames.length > 0 ?
                                        <>
                                            {
                                                statusNames.map((item:any, index:any) => {
                                                    return (
                                                        <Select.Option
                                                            key={index}
                                                            value={item.id}
                                                            label={item.name}
                                                        >
                                                            {item.name}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        <Select.Option value="">Нет</Select.Option>
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="order_reason"
                            label="Причина отмены заказа"
                            rules={[ { required: false } ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="order_comment"
                            label="Комментарий"
                            rules={[ { required: false } ]}
                        >
                            <Input />
                        </Form.Item>

                    </Form>
            }
        </Modal>
    )
}

export default EditOrder
