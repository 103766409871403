import React from "react";

import { ConfigProvider } from "antd";
import ruRU from "antd/es/locale/ru_RU";
import moment from "moment";
import ReactDOM from "react-dom";
import { Provider, useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./app/App";
import { initAuthInterceptor } from "./shared/common/api/middleware";
import { store, TAppDispatch } from "./app/providers/redux/store";
import reportWebVitals from "./reportWebVitals";
import "moment/locale/ru";
moment.locale("ru");

//const dispatch = useDispatch<TAppDispatch>()

initAuthInterceptor(/*dispatch*/).finally(() => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider locale={ruRU}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );


});
