import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ICurrentSchool,
  ISchoolDetails,
  ISchoolList,
  ISelectedSchool,
  IUserAccess,
} from "./types";

const initialState: ICurrentSchool = {
  isLoading: false,
  isUpdate: false,
  selectedSchool: null,
  schoolList: null,
  userAccess: null,
  schoolDetail: undefined,
};

export const currentSchoolSlice = createSlice({
  name: "currentSchool",
  initialState: initialState,
  reducers: {
    currentSchoolSetData: (
      state,
      action: PayloadAction<{
        selectedSchool: ISelectedSchool | null;
        schoolList: ISchoolList | null;
        userAccess: IUserAccess | null;
      }>
    ) => {
      state.selectedSchool = action.payload.selectedSchool;
      state.schoolList = action.payload.schoolList;
      state.userAccess = action.payload.userAccess;
    },

    currentSchoolSetLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    currentSchoolSetUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload;
    },

    currentSchoolSetDetails: (state, action: PayloadAction<ISchoolDetails>) => {
      state.schoolDetail = action.payload;
    },

    currentSchoolClearData: (state) => {
      state.schoolDetail = initialState.schoolDetail;
      state.selectedSchool = initialState.selectedSchool;
      state.schoolDetail = initialState.schoolDetail;
      state.userAccess = initialState.userAccess;
      state.isLoading = initialState.isLoading;
      state.isUpdate = initialState.isUpdate;
    },
  },
});

export const currentSchoolSliceActions = currentSchoolSlice.actions;
export const currentSchoolSliceReducer = currentSchoolSlice.reducer;
