import React, { FC, useEffect, useState } from "react"

import { AreaChartOutlined, DownOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Button, Modal, Popover, Tooltip } from "antd"
import {useTypedSelector} from "../../../../../shared/common/hooks/useTypedSelector";
import {IDetailItem} from "../../../../../app/types/filter";
import {useActions} from "../../../../../shared/common/hooks/useActions";
import {observeFiltersForWebinarLength} from "../../../../../helpers/utils/reports";
import { detailsDescription } from "../../../../../shared/common/constants/tooltips";
import moment from "moment/moment";
import {isMoment} from "../../../../../helpers/utils/functions";
import {initialDetailsState} from "../../../../../app/providers/redux/slices/reports/params/details/detailsSlice";


interface IDetailsProps {
    items: IDetailItem[]
}

const Details: FC<IDetailsProps> = ({ items }) => {
    const { start,end } = useTypedSelector((state)=>state.period)
    const { details } = useTypedSelector((state) => state.details)
    const { filters } = useTypedSelector((state) => state.filter)
    const { setDetails, setMetaChartFetching } = useActions()

    const [ currentDetailName, setCurrentDetailName ] = useState("")
    const [ isPopoverVisible, setIsPopoverVisible ] = useState(false)
    useEffect(() => {
        setCurrentDetailName(items.find((el)=>el.value === initialDetailsState.details)!?.name)
        setDetails(initialDetailsState.details)
    }, [])

    useEffect(() => {
        const detailName = items.find((item) => item.value === details)

        if (detailName) {
            setCurrentDetailName(detailName.name)
        }
    }, [ details ])

    const onChange = (value: string, name: string) => {
        const isCohort = location.pathname.includes("cohort")
        if ([ "1m", "5m", "15m" ].includes(value)) {
            const count = observeFiltersForWebinarLength(filters)

            if (count === 2) {
                if (value === "1m") {
                    setIsPopoverVisible(false)

                    Modal.info({
                        title: "Внимание!",
                        content: "Уменьшите период отчёта до 2-х часов, чтобы выбрать данную детализацию.",
                    })

                    return
                }
            }
        }
        if(isCohort){
            const diffInDays = isMoment(end) ? end.diff(start, "days") : moment(end).diff(moment(start), "days")
            const ranges = {
                "от 0 до 34": [ "по дням", "по неделям", "по месяцам", "по кварталам", "по годам" ],
                "от 35 до 179": [ "по неделям", "по месяцам", "по кварталам", "по годам" ],
                "от 180 до 365": [ "по месяцам", "по кварталам", "по годам" ],
                "От 366 до 1098": [ "по кварталам", "по годам" ],
                "Больше 1099": [ "по годам" ],
            }

            const generateErrorMessage = () => {
                let message = ""
                if (name === "по дням") {
                    message = "От 0 до 34 дней"
                } else if (name === "по неделям") {
                    message = "От 35 до 179 дней"
                } else if (name === "по месяцам") {
                    message = "От 180 до 365 дней"
                } else if (name === "по кварталам") {
                    message = "От 366 до 1098 дней"
                } else if (name === "по годам") {
                    message = "Больше 1099 дней"
                }
                return `Уменьшите период отчёта, диапазон под данный период: ${message}.`
            }

            let selectedRange

            if (diffInDays > 1099) {
                selectedRange = Object.entries(ranges).find(([ range ]) => range === "Больше 1099")
            } else {
                selectedRange = Object.entries(ranges).find(([ range ]) => {
                    const matchResult = range?.match(/от (\d+) до (\d+)/i)
                    const [ minMatch, maxMatch ] = matchResult ? matchResult.slice(1) : []
                    const min = parseInt(minMatch, 10)
                    const max = maxMatch === "Infinity" ? Infinity : parseInt(maxMatch, 10)
                    return diffInDays >= min && diffInDays <= max
                })
            }
            if (!selectedRange) {
                setIsPopoverVisible(false)
                Modal.info({
                    title: "Внимание!",
                    content: "Выберите корректный диапазон периода отчета.",
                })
                return
            }
            const [ options,rangess ] = selectedRange
            if (!rangess.includes(name)) {
                const errorMessage = generateErrorMessage()
                setIsPopoverVisible(false)
                Modal.info({
                    title: "Внимание!",
                    content: errorMessage,
                })
                return
            }

        }


        setDetails(value)
        setCurrentDetailName(name)

        setMetaChartFetching({value:true})
        setIsPopoverVisible(false)


    }

    const list = () => {
        return (
            <div style={{ marginLeft: "-16px", marginRight: "-16px" }}>
                {
                    items.map((item, index) => (
                        <div
                            className="segment-list-item"
                            onClick={() => onChange(item.value, item.name)}
                            key={index}
                        >
                            {item.name}
                        </div>
                    ))
                }
            </div>
        )
    }

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Popover
                content={list}
                open={isPopoverVisible}
                onOpenChange={(value) => {
                    setIsPopoverVisible(value)
                }}
                trigger="click"
                placement="bottomLeft"
                showArrow={false}
                overlayStyle={{ paddingTop: 0 }}
            >
                <Button
                    icon={<AreaChartOutlined />}
                    className="segment-show-button"
                >
                    Детализация: &nbsp;
                    {currentDetailName}
                    &nbsp; <DownOutlined />
                </Button>
            </Popover>

            <span style={{ marginLeft: 6, marginRight: 10, marginTop: 3 }}>
                <Tooltip
                    placement="bottom"
                    title={<div className="page-title-tooltip-text" dangerouslySetInnerHTML={{ __html: detailsDescription }}></div>}
                    color="#fff"
                    zIndex={9999}
                    trigger="click"
                    overlayClassName="page-title-tooltip"
                >
                    <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }}/>
                </Tooltip>
            </span>
        </div>
    )
}

export default Details
