import React, { FC } from "react";

import { API_REPORT_TABLE } from "../../../shared/common/api/urls";
import MainLayout from "../../../shared/common/components/layouts/MainLayout";
import PageHead from "../../../shared/common/components/page-header/PageHeader";
import TableReport from "../../../shared/common/components/reports/table-report/TableReport";

const CrmUsers: FC = () => {
  const metrics = [
    { name: "id пользователя", dataKey: "id" },
    {name:"Партнерский код",dataKey: "partner_id"},
    { name: "Дата регистрации", dataKey: "created_at" },
    { name: "Роль", dataKey: "role" },
    { name: "Имя и фамилия", dataKey: "name" },
    { name: "Телефон", dataKey: "phone" },
    { name: "Email", dataKey: "email" },
    { name: "Telegram", dataKey: "telegram" },
    { name: "Чем занимается", dataKey: "sphere" },
    { name: "utm_source", dataKey: "utm_source_id" },
    { name: "utm_medium", dataKey: "utm_medium_id" },
    { name: "utm_campaign", dataKey: "utm_campaign_id" },
    { name: "utm_group", dataKey: "utm_group_id" },
    { name: "utm_term", dataKey: "utm_term_id" },
    { name: "utm_content", dataKey: "utm_content_id" },
    { name: "Дата обновления данных", dataKey: "updated_at" },
    { name: "Количество школ", dataKey: "school_count" },
    {
      name: "Список название школ через запятую к которым пользователь имеет доступ.",
      dataKey: "school_all",
    },
    { name: "Последние данные", dataKey: "last_activity" },
    { name: "Комментарий", dataKey: "comment" },
  ];

  return (
    <MainLayout pageTitle="Пользователи">
      <div className="page-wrapper">
        <PageHead title="Пользователи" />

        <div className="page-content">
          <TableReport
            apiUrl={API_REPORT_TABLE}
            metrics={metrics}
            filtersFilter={["Пользователи (CRM)"]}
            withSegment={false}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default CrmUsers;
