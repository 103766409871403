import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const CrmRequests: FC = () => {

    const metrics = [
        { name: "id школы", dataKey: "school_id" },
        { name: "Название школы", dataKey: "school_name" },
        { name: "id пользователя", dataKey: "user_id" },
        { name: "Email пользователя", dataKey: "email" },
        { name: "Время выполнения базового запроса", dataKey: "base_query_execution_time" },
        { name: "Время выполнения тотал запроса", dataKey: "total_query_execution_time" },
        { name: "Время построения запроса", dataKey: "sql_generation_time" },
        { name: "id ошибки", dataKey: "error_id" },
        { name: "Текст ошибки", dataKey: "query_error" },
        { name: "Название отчета", dataKey: "report_name" },
        { name: "Тип отчета", dataKey: "report_type" },
        { name: "Базовый запрос", dataKey: "base_query_file_link" },
        { name: "Тотал запрос", dataKey: "total_query_file_link" },
        { name: "Дата запроса", dataKey: "created_at" },
        { name: "Фильтры", dataKey: "filters" }
    ];

    return (
        <MainLayout pageTitle="Запросы">
            <div className="page-wrapper">
                <PageHead title="Запросы" />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "Запросы (CRM)" ]}
                        withSegment={false}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default CrmRequests
