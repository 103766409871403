import React, { FC, useEffect } from "react"

import { Form, Input, Modal } from "antd"

import { useActions } from "../../../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"
import { showFormErrors } from "../../../../helpers/utils/ui"

interface IAddModalProps {
    isVisible: boolean
    setVisible: (val: boolean) => void
}

const AddDomainModal: FC<IAddModalProps> = ({ isVisible, setVisible }) => {
    const [ form ] = Form.useForm()

    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const { isLoading } = useTypedSelector((state) => state.domains)
    const { domainAdd } = useActions()

    useEffect(() => {
        if (isVisible) {
            form.resetFields()
        }
    }, [ isVisible ])

    const addDomain = (values: { domain: string }) => {
        if (selectedSchool) {
            domainAdd(parseInt(selectedSchool.id), values.domain, () => {
                setVisible(false)
            }, (errors) => {
                showFormErrors(form, errors)
            })
        }
    }

    return (
        <Modal
            title="Добавление домена"
            open={isVisible}
            onOk={() => form.submit()}
            onCancel={() => setVisible(false)}
            okText="Добавить"
            cancelText="Отмена"
            okButtonProps={{ loading: isLoading }}
            destroyOnClose
        >
            <Form
                form={form}
                onFinish={addDomain}
                layout="vertical"
                requiredMark={false}
            >

                <Form.Item
                    label="Название домена"
                    name="domain"
                    rules={[ { required: true, message: "Поле обязательное" } ]}
                >
                    <Input />
                </Form.Item>

            </Form>
        </Modal>
    )
}

export default AddDomainModal
