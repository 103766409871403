import React, { FC, useEffect, useState } from "react"

import { LoadingOutlined, StopOutlined } from "@ant-design/icons"
import { Form, Input, Modal, Select, Spin, Switch } from "antd"

import { useActions } from "../../../../shared/common/hooks/useActions"
import { useForceUpdate } from "../../../../shared/common/hooks/useForceUpdate"
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"
import { checkValueForStaffForms } from "../../../../helpers/utils/data"
import { showFormErrors } from "../../../../helpers/utils/ui"

interface IAddModalProps {
    isVisible: boolean
    setVisible: (val: boolean) => void
    userId: number
}

const EditModal: FC<IAddModalProps> = ({ isVisible, setVisible, userId }) => {
    const forceUpdate = useForceUpdate()
    const [ form ] = Form.useForm()

    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const { isLoading, ads_list, order_list, updateData } = useTypedSelector((state) => state.staff)
    const { staffGetUserData, staffGetLists, staffUpdateUser } = useActions()
    const [ isAdmin, setIsAdmin ] = useState<boolean>()

    const [ rkSelectDisable, setRkSelectDisable ] = useState<number[]>([])
    const [ offersSelectDisable, setOffersSelectDisable ] = useState<number[]>([])
    const [ showUtm, setShowUtm ] = useState(false)
    useEffect(() => {
        if (isVisible && selectedSchool) {
            form.resetFields()

            staffGetLists(selectedSchool.id, () => {
                staffGetUserData(parseInt(selectedSchool.id), userId)
            })
        }
    }, [ isVisible ])
    useEffect(() => {
        if (updateData) {
            const newValues = Object.entries(updateData).map(([ key, val ]) => {
                if ((key === "ads" || key === "offers") && (val === null || val === undefined)) {
                    return { name: key, value: [] }
                }

                return { name: key, value: val }
            })
            if(newValues.some((element)=>element.name.includes("utm"))){
                setShowUtm(true)
            }else{
                setShowUtm(false)
            }
            form.setFields(newValues)
        }
    }, [ updateData ])

    const saveStaff = (values: any) => {
        if (selectedSchool) {
            const parsedData = {
                ...values,
                ads: checkValueForStaffForms(values.ads),
                offers: checkValueForStaffForms(values.offers),
                contact: values.contact || false,
                setting: values.setting || false
            }

            staffUpdateUser(parseInt(selectedSchool.id), userId, parsedData, () => {
                setVisible(false)
            }, (errors) => {
                showFormErrors(form, errors)
            })
        }
    }
    useEffect(() => {
        form.setFieldsValue({ contact: isAdmin })
    }, [ isAdmin ])
    return (
        <Modal
            title="Редактирование сотрудника"
            open={isVisible}
            onOk={() => form.submit()}
            onCancel={() => setVisible(false)}
            okText="Сохранить"
            cancelText="Отмена"
            okButtonProps={{ loading: isLoading }}
            destroyOnClose
        >
            <Spin
                spinning={isLoading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            >
                <Form
                    form={form}
                    onFinish={saveStaff}
                    layout="vertical"
                    initialValues={{
                        rk_access: null,
                        ad_access: null,
                        utm_filter: false,
                        user_contacts_access: false,
                        settings_access: false,
                        utm_source: [],
                        utm_medium: [],
                        utm_campaign: [],
                        utm_term: [],
                        utm_content: [],
                        utm_group: []
                    }}
                    requiredMark={false}
                >

                    <Form.Item
                        label="Имя и Фамилия"
                        name="name"
                        rules={[ { required: true, message: "Поле обязательное" } ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Чем занимается"
                        name="role"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[ { required: true, type: "email", message: "Введите корректный Email" } ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Доступ к рекламным кабинетам:" name="ads">
                        <Select
                            loading={isLoading}
                            mode="multiple"
                            placeholder="Доступны все РК"
                            maxTagTextLength={20}
                            filterOption={(inputValue, option) => {
                                if (option && option.children && typeof option.children === "string") {
                                    const children = option.children as string;
                                    return children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                }
                                return false;
                            }}
                            onSelect={(value) => {
                                setRkSelectDisable([...rkSelectDisable, value]);
                                forceUpdate();
                            }}
                            onDeselect={(value) => {
                                setRkSelectDisable(rkSelectDisable.filter((item) => item !== value));
                                forceUpdate();
                            }}
                            allowClear
                        >
                            <Select.Option
                                value={0}
                                disabled={
                                    rkSelectDisable.find((item) => item === 0) !== 0 &&
                                    rkSelectDisable.length > 0
                                }
                            >
                                <StopOutlined style={{ color: "#ff4d4f", marginRight: 5 }} /> Нет доступа к РК
                            </Select.Option>
                            {ads_list.map((item) => {
                                return (
                                    <Select.Option
                                        key={item.id}
                                        value={item.id}
                                        disabled={rkSelectDisable.find((item) => item === 0) === 0}
                                    >
                                        {item.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>




                    <Form.Item
                        label="Показывать заказы определённых предложений"
                        name="offers"
                    >
                        <Select
                            loading={isLoading}
                            mode="multiple"
                            placeholder="Доступны все предложения"
                            maxTagTextLength={20}
                            onSelect={(value) => {
                                setOffersSelectDisable([ ...offersSelectDisable, value ])
                                forceUpdate()
                            }}
                            onDeselect={(value) => {
                                setOffersSelectDisable(offersSelectDisable.filter((item) => item !== value))
                                forceUpdate()
                            }}
                            filterOption={(inputValue, option) => {
                                if (option && option.children && typeof option.children === "string") {
                                    const children = option.children as string;
                                    return children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                }
                                return false;
                            }}
                            allowClear
                        >
                            <Select.Option
                                value={0}
                                disabled={offersSelectDisable.find((item) => item === 0) !== 0 && offersSelectDisable.length > 0}
                            >
                                <StopOutlined style={{ color: "#ff4d4f", marginRight: 5 }} /> Нет доступа к предложениям
                            </Select.Option>
                            {
                                order_list.map((item) => {
                                    return (
                                        <Select.Option
                                            key={item.id}
                                            value={item.id}
                                            disabled={offersSelectDisable.find((item) => item === 0) === 0}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Права администратора"
                        name="setting"
                        valuePropName="checked"
                    >
                        <Switch  onChange={(checked)=>(setIsAdmin(checked))}/>
                    </Form.Item>

                    <Form.Item
                        label="Показать контакты пользователей"
                        name="contact"
                        valuePropName="checked"
                    >
                        <Switch/>
                    </Form.Item>

                    <Form.Item
                        label="Фильтровать регистрации по UTM меткам"
                    >
                        <Switch
                            checked={showUtm}
                            onChange={(checked) => setShowUtm(checked)}
                        />
                    </Form.Item>

                    {
                        showUtm &&
                        <>
                            <Form.Item
                                label="Метки utm_source"
                                name="utm_source"
                            >
                                <Select
                                    mode="tags"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Метки utm_medium"
                                name="utm_medium"
                            >
                                <Select
                                    mode="tags"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Метки utm_campaign"
                                name="utm_campaign"
                            >
                                <Select
                                    mode="tags"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Метки utm_term"
                                name="utm_term"
                            >
                                <Select
                                    mode="tags"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Метки utm_content"
                                name="utm_content"
                            >
                                <Select
                                    mode="tags"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Метки utm_group"
                                name="utm_group"
                            >
                                <Select
                                    mode="tags"
                                    allowClear
                                />
                            </Form.Item>
                        </>
                    }

                </Form>
            </Spin>
        </Modal>
    )
}

export default EditModal
