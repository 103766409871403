import { AxiosError } from "axios";

import { adsCabinetSliceActions } from "./adsCabinetSlice";
import { adsCabinetApi } from "../../../../../../shared/common/api/endpoints/adsCabinetApi";
import { handleResponseError } from "../../../../../../shared/common/api/middleware";
import { TAppDispatch } from "../../../store";
import { showErrorMessage } from "../../../../../../helpers/utils/ui";

export const adsCabinetActions = {
  ...adsCabinetSliceActions,

  adsCabinetGetData:
    (schoolId: number | string, mode: number, filters: any) =>
    async (dispatch: TAppDispatch) => {
      dispatch(adsCabinetActions.setAdsCabinetLoading(true));

      adsCabinetApi
        .fetchData(schoolId, mode, filters)
        .then((response) => {
          if (response.status === 200) {
            dispatch(adsCabinetActions.setAdsCabinetData(response.data));
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err);
        })
        .finally(() => {
          dispatch(adsCabinetActions.setAdsCabinetLoading(false));
        });
    },

  adsCabinetConnectRK: (users: unknown[]) => async (dispatch: TAppDispatch) => {
    dispatch(adsCabinetActions.setAdsCabinetLoading(true));
    try {
      await adsCabinetApi.addAdvertCab(users);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 400) {
        showErrorMessage(err.response.data);
      }
    } finally {
      dispatch(adsCabinetActions.setAdsCabinetUpdate(true));
      dispatch(adsCabinetActions.setAdsCabinetLoading(false));
    }
  },

  adsCabinetUpdateRKStatus:
    (schoolId: number, RKId: number, status: boolean) =>
    async (dispatch: TAppDispatch) => {
      dispatch(adsCabinetActions.setAdsCabinetLoading(true));

      adsCabinetApi
        .updateStatus(schoolId, RKId, status)
        .then((response) => {
          if (response.status === 200) {
            dispatch(adsCabinetActions.setAdsCabinetUpdate(true));
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err);
        })
        .finally(() => {
          dispatch(adsCabinetActions.setAdsCabinetLoading(false));
        });
    },
  adsCabinetUpdateUTMStatus:
    (schoolId: number, RKId: number, status: boolean) =>
    async (dispatch: TAppDispatch) => {
      dispatch(adsCabinetActions.setAdsCabinetLoading(true));

      adsCabinetApi
        .updateUTMStatus(schoolId, RKId, status)
        .then((response) => {
          if (response.status === 200) {
            dispatch(adsCabinetActions.setAdsCabinetUpdate(true));
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err);
        })
        .finally(() => {
          dispatch(adsCabinetActions.setAdsCabinetLoading(false));
        });
    },
  adsCabinetUpdateUTMLabel:
    (schoolId: number, RKId: number, utm_label: string) =>
    async (dispatch: TAppDispatch) => {
      dispatch(adsCabinetActions.setAdsCabinetLoading(true));

      adsCabinetApi
        .updateUTMLabel(schoolId, RKId, utm_label)
        .then((response) => {
          if (response.status === 200) {
            dispatch(adsCabinetActions.setAdsCabinetUpdate(true));
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err);
        })
        .finally(() => {
          dispatch(adsCabinetActions.setAdsCabinetLoading(false));
        });
    },
  adsCabinetUpdateAdCabinet:
    (schoolId: number, RKId: number, ad_cabinet: string) =>
    async (dispatch: TAppDispatch) => {
      dispatch(adsCabinetActions.setAdsCabinetLoading(true));

      adsCabinetApi
        .updateAdCabinet(schoolId, RKId, ad_cabinet)
        .then((response) => {
          if (response.status === 200) {
            dispatch(adsCabinetActions.setAdsCabinetUpdate(true));
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err);
        })
        .finally(() => {
          dispatch(adsCabinetActions.setAdsCabinetLoading(false));
        });
    },
  adsCabinetUpdateUTMMask:
    (
      schoolId: number,
      RKId: number,
      utm_mask: {
        value?: boolean;
        number?: number;
        divider?: string;
      }
    ) =>
    async (dispatch: TAppDispatch) => {
      dispatch(adsCabinetActions.setAdsCabinetLoading(true));

      await adsCabinetApi
        .updateUTMMask(schoolId, RKId, utm_mask)
        .then((response) => {
          if (response.status === 200) {
            utm_mask?.value === false &&
              dispatch(adsCabinetActions.setAdsCabinetUpdate(true));
          }
        })
        .catch((err) => {
          dispatch(adsCabinetActions.setAdsCabinetUpdate(true));
          handleResponseError(dispatch, err);
        })
        .finally(() => {
          dispatch(adsCabinetActions.setAdsCabinetLoading(false));
        });
    },
  adsCabinetUpdateRKnds:
    (schoolId: number, RKId: number, status: boolean) =>
    async (dispatch: TAppDispatch) => {
      dispatch(adsCabinetActions.setAdsCabinetLoading(true));

      adsCabinetApi
        .updateNds(schoolId, RKId, status)
        .then((response) => {
          if (response.status === 200) {
            dispatch(adsCabinetActions.setAdsCabinetUpdate(true));
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err);
        })
        .finally(() => {
          dispatch(adsCabinetActions.setAdsCabinetLoading(false));
        });
    },

  adsCabinetUpdateRKCurrency:
    (schoolId: number, RKId: number, currency: number) =>
    async (dispatch: TAppDispatch) => {
      adsCabinetApi
        .updateCurrency(schoolId, RKId, currency)
        .then((response) => {
          if (response.status === 200) {
            dispatch(adsCabinetActions.setAdsCabinetUpdate(true));
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err);
        })
        .finally(() => {
          dispatch(adsCabinetActions.setAdsCabinetLoading(false));
        });
    },

  adsCabinetUpdateRKConvert:
    (schoolId: number, RKId: number, convert: number) =>
    async (dispatch: TAppDispatch) => {
      adsCabinetApi
        .updateConvert(schoolId, RKId, convert)
        .then((response) => {
          if (response.status === 200) {
            dispatch(adsCabinetActions.setAdsCabinetUpdate(true));
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err);
        })
        .finally(() => {
          dispatch(adsCabinetActions.setAdsCabinetLoading(false));
        });
    },
};
