import {ColumnsType} from "antd/es/table";
import {Button, Modal, Popconfirm, Switch, Table} from "antd";
import React, {useEffect, useState} from "react";
import {DeleteOutlined, DownloadOutlined, EditOutlined, LoadingOutlined} from "@ant-design/icons";
import {showErrorMessage} from "../../../../../../../../helpers/utils/ui";
import {useTypedSelector} from "../../../../../../hooks/useTypedSelector";
import {billApi} from "../../../../../../api/endpoints/billApi";
import {Properties} from "csstype";



type Props ={
    refetch:boolean,
    setRefetch:(val: (prev:boolean) => boolean) => void;
    setEditOptions: React.Dispatch<React.SetStateAction<{ visible: boolean; reqId: null }>>
}
const RequisitesTable = ({refetch,setEditOptions,setRefetch}:Props) => {
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const [tableData,setTableData] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const getTableData =  async () => {
        try{
            setIsLoading(true)
            const school_id = Number(selectedSchool?.id)
            const {data} = await billApi.getReqs(school_id)
            setTableData(data)
        }catch(err){
            showErrorMessage("Произошла ошибка , повторите попытку позже.")
        }
        finally{
            setIsLoading(false)
        }
    }
    useEffect(() => {
        getTableData()
    },[selectedSchool,refetch])

    const handleDeleteReq = async (body:{school_id:number,requisites_id:number}) => {
        try{
            const data = {
                ...body
            }
            await billApi.deleteReq(data)
            setRefetch((prev)=>!prev)
            Modal.info({
                title:"Успех!",
                content:"Реквизиты успешно удалены."
            })
        }catch(err){
            showErrorMessage("Произошла ошибка , повторите позже.")
        }
    }
    const requisitesListColumns: ColumnsType<any> = [
        {
            title:"id",
            key:"id",
            dataIndex:"id",
            width:"90px"
        },
        {
            title: "Название кампании",
            dataIndex: "company_name",
            key: "company_name",
            width:"90px"
        },
        {
            title: "Телефон",
            dataIndex: "company_phone",
            key: "company_phone",
            width:"90px"
        },
        {
            title: "ФИО для подписи",
            dataIndex: "user_name",
            key: "user_name",
            width:"90px"
        },
        {
            title: "Юридический адрес",
            dataIndex: "company_address",
            key: "company_address",
            width:"90px"
        },
        {
            title: "Email",
            dataIndex: "company_email",
            key: "company_email",
            width:"90px"
        },
        {
            title: "Индекс",
            dataIndex: "postal_code",
            key: "postal_code",
            width:"90px"
        },
        {
            title: "ИНН",
            dataIndex: "inn",
            key: "inn",
            width:"90px"
        },
        {
            title: "КПП",
            dataIndex: "kpp",
            key: "kpp",
            width:"90px"
        },
        {
            title: "ОГРН",
            dataIndex: "ogrn",
            key: "ogrn",
            width:"90px"
        },
        {
            title: "ОКПО",
            dataIndex: "okpo",
            key: "okpo",
            width:"90px"
        },
        {
            title: "Название банка",
            dataIndex: "bank_name",
            key: "bank_name",
            width:"90px"
        },
        {
            title: "БИК",
            dataIndex: "bik",
            key: "bik",
            width:"90px"
        },
        {
            title: "Р/С",
            dataIndex: "check_account",
            key: "check_account",
            width:"90px"
        },
        {
            title: "К/С",
            dataIndex: "corr_account",
            key: "corr_account",
            width:"90px"
        },
        {
            title:"Действия",
            dataIndex:"cab_actions",
            key:"cab_actions",
            render: (value, record) => {
                const customStyle: Properties = {
                    width: "100%",
                    backgroundImage: "unset",
                    textAlign: "right",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:'center',
                    height:"37px",
                    columnGap:"8px"

                };
                return (
                    <div style={customStyle}>
                        <Button
                            type="default"
                            icon={<EditOutlined />}
                            size="small"
                            title={"Редактировать реквизиты"}
                            onClick={() => setEditOptions((prev)=>({reqId:record.id,visible:true}))}
                        />
                        <Popconfirm
                            title="Подтвердите удаление"
                            onConfirm={() => handleDeleteReq({requisites_id:record.id,school_id:Number(selectedSchool?.id )})}
                            okText="Удалить"
                            cancelText="Отмена"
                        >
                            <Button
                                icon={<DeleteOutlined />}
                                type="default"
                                size="small"
                                title={"Удалить реквизиты"}
                                danger
                            />
                        </Popconfirm>
                    </div>
                )
            },
        },
    ]

    return (
        <Table
            columns={requisitesListColumns}
            dataSource={tableData}
            loading={{
                spinning: isLoading,
                indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
            }}
            style={{overflowX:"auto"}}
            bordered
        />
    )

}
export default RequisitesTable
