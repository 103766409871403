import React, { FC, useEffect, useState } from "react"

import { Button } from "antd"

import { showErrorMessage } from "../../../../../../helpers/utils/ui"
import { billApi } from "../../../../api/endpoints/billApi"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import Loader from "../../../loader/Loader"
import {UsersTable} from "./components/tables/UsersTable";
import {useNavigate} from "react-router-dom";

const BalanceTab: FC<{currency: string}> = ({ currency }) => {
    const navigate = useNavigate()
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const [ pageData, setPageData ] = useState({
        currentTariff: "",
        currentBill: "",
        currentBonusBill: "",
        allBill: "",
        billLeft: ""
    })
    const [ pageLoading, setPageLoading ] = useState(false)
    const [ pageError, setPageError ] = useState(false)
    const fetchData = () => {
        setPageError(false)
        setPageLoading(true)

        billApi.getBalanceInfo(parseInt(selectedSchool?.id || "0"))
            .then((response) => {
                if (response.status === 200) {
                    setPageData({
                        currentTariff: response.data.tariff_name,
                        currentBill: response.data.main_balance,
                        currentBonusBill: response.data.bonus_balance,
                        allBill: response.data.overall_balance,
                        billLeft: response.data.details,
                    })
                } else {
                    setPageError(true)
                }
            })
            .catch(() => {
                showErrorMessage("Ошибка при получении данных.")
                setPageError(true)
            })
            .finally(() => {
                setPageLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return pageLoading ? (
        <Loader loading={pageLoading} />
    ) : (
        <div
            style={{
                marginTop: 20,
                marginLeft: 5,
                display: "flex",
                flexDirection: "column",
            }}
        >
            {
                pageError ?
                    <div>
                        Произошла ошибка при загрузке данных, повторите попытку позже.
                    </div>
                    :
                    <>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <b>Тариф:&nbsp;</b>
                            <span>{pageData.currentTariff}</span>
                        </div>

                        <div style={{
                                marginTop: 25,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }} >
                            <div>
                                <div>
                                    <b>Основной счет: </b>
                                    <span>
                                    {pageData.currentBill} {currency}
                                    </span>
                                </div>

                                <div>
                                    <b>Бонусный счет: </b>
                                    <span>
                                        {pageData.currentBonusBill} {currency}
                                    </span>
                                </div>
                            </div>

                            <div style={{ margin: "0 40px" }}>
                                <b>Итого баланс: </b>
                                <span style={{ fontSize: 18, fontWeight: "bold" }}>{pageData.allBill} {currency}</span>
                            </div>

                            <div>
                                {pageData.billLeft}
                            </div>
                        </div>
                        <Button onClick={()=>navigate('/users/billing?tab=item-2')} danger type={"primary"} style={{ width:200,marginTop:15 }}>Пополнить баланс</Button>
                        <h3 style={{ marginTop:"18px",fontWeight:700 }}>Важно!</h3>
                        <p style={{ fontWeight:500 }}>
                            Стоимость сервиса зависит от количество активных пользователей в течение 30 дней.<br />
                            Активный пользователь - этот тот, кто зарегистрировался или сделал заказ или оплатил или посетил вебинар. Не активные пользователи НЕ учитываются. Если <br /> пользователь и зарегистрировался и посетил вебинар и оплатил, то он считается за 1 человека.
                        </p>
                        <UsersTable />
                    </>
            }
        </div>
    )
}

export default BalanceTab
