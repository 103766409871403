import { logsApi } from "../../shared/common/api/endpoints/logsApi"

// Создаем переменные для хранения хита и пути страницы
let currentHit: string | null = null
let currentPathname: string | null = null

export const generateHit = (): string => {
    if (currentHit === null || window.location.pathname !== currentPathname) {
        let d = new Date().getTime()

        if (
            typeof performance !== "undefined" &&
            typeof performance.now === "function"
        ) {
            d += performance.now()
        }

        currentHit = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
            let r = (d + Math.random() * 16) % 16 | 0
            d = Math.floor(d / 16)

            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
        })

        currentPathname = window.location.pathname
    }

    return currentHit
}

export const sendPageHit = () => {
    const hit = generateHit()

    logsApi.sendHit(hit, window.location.href, 1)?.catch((err) => {
        console.log(err)
    })
}

export const bindClickObserver = () => {
    document.body.addEventListener("click", () => {
        const hit = generateHit()
        logsApi.sendHit(hit, window.location.href, 2)?.catch((err) => {
            console.log(err)
        })
    })
}
