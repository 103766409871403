import React, { useEffect, useMemo, useState } from "react";
import { splitNameAndId } from "../../../../helpers/utils/functions";
import { useActions } from "../../../../shared/common/hooks/useActions";
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector";

const CustomInput = ({ cellData, rowData }: any) => {
  const [value, setValue] = useState(splitNameAndId(cellData)[0]);
  const id = splitNameAndId(cellData)[1];
  const { adsCabinetUpdateAdCabinet } = useActions();
  const { selectedSchool } = useTypedSelector((state) => state.currentSchool);

  const handleBlur = () => {
    if (selectedSchool) {
      adsCabinetUpdateAdCabinet(
        parseInt(selectedSchool.id, 10),
        rowData.id,
        value
      );
    }
  };

  useEffect(() => {
    setValue(splitNameAndId(cellData)[0]);
  }, [cellData]);

  return (
    <span className="ant-input-affix-wrapper" style={{ height: "unset" }}>
      <input
        type="text"
        className={"ant-input"}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={handleBlur}
      />
      <span style={{ fontWeight: "700", marginLeft: "8px" }}>{id}</span>
    </span>
  );
};

export default CustomInput;
