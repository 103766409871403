import React, { FC, useEffect, useMemo, useState } from "react"

import moment from "moment"
import { useLocation } from "react-router-dom"

import Banners from "./Banners"
import { formatBannerData, moneyFormat, toolFormat, webinarPredictDotsCount } from "../../../../../helpers/utils/reports"
import { showErrorMessage } from "../../../../../helpers/utils/ui"
import { unApi } from "../../../api/endpoints/reports/unApi"
import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import Loader from "../../loader/Loader"
import ChartWrap from "../ChartWrap"

interface IProps {
    isLoading: boolean
    setIsLoading: (v: boolean) => void
}


const WebinarChart: FC<IProps> = ({ isLoading, setIsLoading }) => {
    const chartDiv = document.getElementById("universal_chart")
    const location = useLocation()
    const { filters } = useTypedSelector((state) => state.filter)
    const period = useTypedSelector((state) => state.period)
    const { details } = useTypedSelector((state) => state.details)
    const { isChartFetching } = useTypedSelector((state) => state.meta)

    const { setMetaChartFetching, setDetails } = useActions()

    const [ series, setSeries ] = useState<any[]>([])
    const [ tempSeries, setTempSeries ] = useState<any[]>([])
    const [ tempBanners, setTempBanners ] = useState([])
    const [ loadingData, setLoadingData ] = useState<any[]>([])
    const [ noDataText, setNoDataText ] = useState("В фильтре выберите название или id вебинара.")
    const [ dimensionsChart, setDimensionsChart ] = useState({ width: 1000, height: 370 })
    const [ chartWidth, setChartWidth ] = useState(1000)

    const setBanners = (data: any) => {
        Object.entries(data).forEach(([ key ]) => {
            const dataElement = document.getElementById(key)

            if (dataElement) {
                dataElement.textContent = formatBannerData(key, data[key], moneyFormat)
            }
        })
    }
    const fetchData = () => {
        try {
            const time = Date.now()

            setLoadingData((prevState: any) => ([ ...prevState, { time: time, loading: true } ]))
            setIsLoading(true)


            let _data = {
                period: {
                    start: moment(period.start).format("YYYY-MM-DD"),
                    end: moment(period.end).format("YYYY-MM-DD")
                },
                filters: filters,
                details: details
            }

            const dotsCount = webinarPredictDotsCount(_data.filters)

            if (dotsCount === 2) {
                let newDetails = _data.details
                if (_data.details === "1m") {
                    newDetails = "15m"
                }

                _data.details = newDetails
                setDetails(newDetails)
            }

            unApi.getWebinarChartData(_data, location.pathname)
                .then((response) => {
                    if (response.status === 504) {
                        showErrorMessage("Запрос выполняется слишком долго, из-за большого количества данных. \n Можно уменьшить период отчёта, удалить ненужные метрики или фильтры.")
                        return
                    }
                    if (typeof response.data === "string") {

                        setSeries([])
                        setLoadingData((prevState: any) => ([ ...prevState.filter((val: any) => val.time !== time), {
                            time: time,
                            loading: false
                        } ]))

                        return
                    }

                    const data = response.data.data.xy.y
                    const x = response.data.data.xy.x

                    const newSeries = [
                        {
                            name: "Зашедшие",
                            type: "line",
                            data: data["incomer_count"].map((item: any, index: number) => {
                                return {
                                    x: x[index],
                                    y: item
                                }
                            }),
                        },
                        {
                            name: "Вышедшие",
                            type: "line",
                            data: data["leave_count"].map((item: any, index: number) => {
                                return {
                                    x: x[index],
                                    y: item
                                }
                            }),
                        },
                        {
                            name: "Онлайн",
                            type: "area",
                            data: data["online_count"].map((item: any, index: number) => {
                                return {
                                    x: x[index],
                                    y: item
                                }
                            }),
                        },
                        {
                            name: "Комментарии",
                            type: "line",
                            data: data["comments_count"].map((item: any, index: number) => {
                                return {
                                    x: x[index],
                                    y: item
                                }
                            }),
                        },
                        {
                            name: "Комментаторы",
                            type: "area",
                            data: data["commentators_count"].map((item: any, index: number) => {
                                return {
                                    x: x[index],
                                    y: item
                                }
                            }),
                        },
                        {
                            name: "Кликнувшие",
                            type: "area",
                            data: data["clickers_count"].map((item: any, index: number) => {
                                return {
                                    x: x[index],
                                    y: item
                                }
                            }),
                        },
                        {
                            name: "Заказы",
                            type: "column",
                            data: data["orders_count"].map((item: any, index: number) => {
                                return {
                                    x: x[index],
                                    y: item
                                }
                            }),
                        },
                        {
                            name: "Клики",
                            type: "area",
                            data: data["clicks_count"].map((item: any, index: number) => {
                                return {
                                    x: x[index],
                                    y: item
                                }
                            }),
                        },
                    ]
                    setTempSeries(newSeries)
                    setTempBanners(response.data.data.banners)

                    setTempSeries(newSeries)
                    setTempBanners(response.data.data.banners)

                    setLoadingData((prevState) => ([ ...prevState.filter((val) => val.time !== time), {
                        time: time,
                        loading: false
                    } ]))
                })
                .catch((err) => {
                    console.log(err)

                    setNoDataText("Нет данных.")
                    setSeries([])
                    setLoadingData((prevState) => ([ ...prevState.filter((val) => val.time !== time), {
                        time: time,
                        loading: false
                    } ]))
                })
        } catch (err) {
            console.log(err)
        }

    }
    useEffect(() => {
        if (!isLoading) {
            let loadingOff = false

            loadingData.forEach((item) => {
                if (item.loading) {
                    loadingOff = true
                }
            })

            if (loadingOff) {
                const newLoadingData = loadingData.map((item) => ({
                    time: item.time,
                    loading: false
                }))

                setLoadingData(newLoadingData)
            }
        }

        if (isLoading) {
            let setLoadingOff = true

            loadingData.forEach((item) => {
                if (item.loading) {
                    setLoadingOff = false
                }
            })

            if (setLoadingOff && loadingData[loadingData.length - 1].loading === false) {
                setSeries(tempSeries)
                setBanners(tempBanners)
                setIsLoading(false)
            }
        }
    }, [ isLoading, loadingData ])

    useEffect(() => {
        if (isChartFetching) {

            const filtersKeys = filters.map((item: any) => item.id)

            if (filtersKeys.find((item: any) => item === "list_webinar" || item === "list_webinar_id")) {
                setNoDataText("Нет данных.")
                fetchData()
                setSeries([])
            } else {
                setNoDataText("В фильтре выберите название или id вебинара.")
                setSeries([])
                setIsLoading(false)
            }

            setMetaChartFetching({ value:false })
        }
    }, [ isChartFetching ])

    useEffect(() => {
        setDimensionsChart({
            width: chartWidth,
            height: 390
        })
    }, [ chartWidth ])

    useEffect(() => {
        if (chartDiv) {
            const calcChartWidth = () => {
                const width = chartDiv.offsetWidth
                setChartWidth(width <= 1000 ? 1000 : width)
            }

            new ResizeObserver(calcChartWidth).observe(chartDiv)
            calcChartWidth()
        }
    }, [ chartDiv ])

    const chartOpt = {
        chart: {
            background: "#fefefe",
            offsetY: 20,
            height: 500,
            type: "line",
            fontFamily: "Open Sans",
            animations: {
                enabled: false,
                easing: "linear",
                dynamicAnimation: {
                    speed: 1000
                }
            },
            stacked: false,
            toolbar: {
                show: true,
                offsetY: 20,
                tools: {
                    download: false,
                    selection: false,
                    zoom: true,
                    zoomin: false,
                    zoomout: true,
                    pan: false,
                    reset: true
                },
            },
        },
        fill: {
            opacity: 0.5,
        },
        stroke: {
            width: [ 3, 2, 4, 0, 2, 2, 2, 2 ],
            curve: "smooth",
        },
        plotOptions: {
            bar: {
                columnWidth: "0",
            }
        },
        dataLabels: {
            enabled: false,
            enabledOnSeries: 0
        },
        series: [],
        noData: {
            text: noDataText,
        },
        title: {
            text: "",
            align: "left",
            offsetX: 110,
        },
        colors: [ "#40aa5f", "#ea4940", "#faba30", "#000", "#D6D6D6", "#7FC9FF", "#EA4940", "#4289f0" ],
        xaxis: {
            type: "category",
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: true,
                style: {
                    colors: [],
                    fontSize: "10px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                    cssClass: "apexcharts-xaxis-label",
                },
            },
            axisBorder: {
                show: true,
                color: "#eaeaea",
                height: 1,
                width: "100%",
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: "solid",
                color: "#eaeaea",
                height: 6,
                offsetX: 0,
                offsetY: 0,
            },
        },
        yaxis: [
            {
                seriesName: "Участники",
                decimalsInFloat: 0,
                showAlways: true,
                opposite: false,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: "#faba30"
                },
                labels: {
                    style: {
                        colors: "#FEB019",
                    },
                },
                title: {
                    text: "Участники",
                    style: {
                        color: "#FEB019",
                    }
                }
            },
            {
                seriesName: "Онлайн",
                show: false,
            },
            {
                seriesName: "Онлайн",
                show: false,
            },
            {
                seriesName: "Онлайн",
                show: false,
            },
            {
                seriesName: "Онлайн",
                show: false,
            },
            {
                seriesName: "Онлайн",
                show: false,
            },
            {
                seriesName: "Клики / заказы",
                decimalsInFloat: 0,
                opposite: true,
                showAlways: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: "#39a759"
                },
                labels: {
                    style: {
                        colors: "#FEB019",
                    },
                },
                title: {
                    text: "Клики / заказы",
                    style: {
                        color: "#FEB019",
                    }
                }
            },
            {
                seriesName: "Заказы",
                show: false,
            },
        ],
        markers: {
            strokeColors: "#000",
            strokeWidth: 2,
            shape: "square",
            radius: 2,
            hover: {
                size: 3,
                sizeOffset: 0
            },
            size: [ 0,0,0,2,0,0,0,0 ]
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            fixed: {
                enabled: false,
                position: "topRight",
            },
            y: {
                formatter: (value: any, { seriesIndex }: any) => {
                    return toolFormat(value, seriesIndex)
                }
            }
        },
        legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
            markers: {
                offsetY: 0,
            },
            itemMargin: {
                vertical: 0,
            },
        }
    }

    const chartProps = {
        width: dimensionsChart.width,
        height: dimensionsChart.height,
    }


    const renderChart = useMemo(() => {
        return  (
            <>
                {
                    series.length === 0 && !isLoading ?
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "60vh",
                            fontSize: 22,
                        }}>
                            {noDataText}
                        </div>
                        :
                        <div>
                            <Loader hasTip={false} loading={isLoading}>
                                <Banners/>
                            </Loader>

                            <Loader loading={isLoading} fullHeight>
                                <div className="universal-chart" id="universal_chart">
                                    <ChartWrap
                                        type="area"
                                        series={series}
                                        options={{
                                            ...chartOpt,
                                        }}
                                        {...chartProps}
                                    />
                                </div>
                            </Loader>
                        </div>
                }
            </>
        )
    }, [
        isLoading,
        series,
    ])
    return renderChart
}

export default WebinarChart
