import {
    DesktopOutlined, PlusCircleOutlined,
    QuestionCircleOutlined, SendOutlined,
    SettingOutlined,
    UsergroupAddOutlined,
    VideoCameraOutlined
} from "@ant-design/icons"
import { Dropdown } from "antd"
import { Link } from "react-router-dom"

import styles from "./Header.module.scss"
import {getLinkPath, isAccessEnded} from "../../../../helpers/utils/functions"
import { routeNames } from "../../constants/routes"
import PermissionWrapper from "../../hoc/PermissionWrapper"
import usePermission from "../../hooks/usePermission"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import React from "react";


const SchoolDropdowns = () => {
    const checkPermission = usePermission()
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)


    const settingsMenuItems = [
        {
            key: "1",
            isAllowed: checkPermission([ "admin", "user:settings" ]),
            label: (
                <Link to={getLinkPath(routeNames.schoolEdit)}>Данные по школе</Link>
            ),
        },
        {
            key: "2",
            isAllowed: checkPermission([ "admin", "user:settings" ]),
            label: (
                <Link to={getLinkPath(routeNames.schoolTrackScript)}>
                    Скрипт отслеживания
                </Link>
            ),
        },
        {
            key: "3",
            isAllowed: checkPermission([ "admin", "user:settings" ]),
            label: (
                <Link to={getLinkPath(routeNames.schoolStaff)}>
                    {isAccessEnded() && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style={{marginRight: "5px", color: "red"}}>
                            <path fill="currentColor"
                                  d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"/>
                        </svg>
                    )}
                    Сотрудники
                </Link>
            ),
        },
        {
            key: "4",
            isAllowed: true,
            label: (
                <Link to={getLinkPath(routeNames.schoolLoadData)}>
                    Ручная загрузка данных по школе
                </Link>
            ),
        },
        {
            key: "5",
            isAllowed: true,
            label: (
                <Link to={getLinkPath(routeNames.schoolAdd)}>
                    Подключить новую школу
                </Link>
            ),
        },
        {
            key: "6",
            isAllowed: checkPermission([ "admin", "superadmin" ]),
            label: (
                <Link to={getLinkPath(routeNames.lastData)}>
                    Последние полученные данные
                </Link>
            ),
        },
        {
            key: "7",
            // isAllowed: checkPermission([ "superadmin" ]),
            isAllowed: true,
            label: (
                <Link to={getLinkPath(routeNames.yandexMetrics)}>
                    Яндекс Метрика
                </Link>
            ),
        },
    ]
    const webMenuItems = [
        {
            key: "1",
            label: (
                <Link to={getLinkPath(routeNames.webinarList)}>Список вебинаров</Link>
            ),
        },
        {
            key: "2",
            label: (
                <Link to={getLinkPath(routeNames.webinarConnectBizon)}>
                    Подключить Bizon 365
                </Link>
            ),
        },
        // {
        //     key: "3",
        //     label: (
        //         <Link to={routeNames.webinarCommentsSettings}>
        //             Задания по коментариям
        //         </Link>
        //     )
        // },
    ]
    const adsMenuItems = [
        {
            key: "1",
            label: (
                <Link to={getLinkPath(routeNames.adsConnect)}>
                    {isAccessEnded() && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style={{marginRight: "5px", color: "red"}}>
                            <path fill="currentColor"
                                  d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"/>
                        </svg>
                    )}
                    Подключение РК
                </Link>
            ),
        },
        {
            key: "2",
            label: (
                <Link to={getLinkPath(routeNames.adsSpend)}>
                    {isAccessEnded() && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style={{marginRight: "5px", color: "red"}}>
                            <path fill="currentColor"
                                  d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"/>
                        </svg>
                    )}
                    Добавить рекламный расход
                </Link>
            ),
        },
    ]

    const helpMenuItems = [
        {
            key: "0",
            label: (
                <a href="https://help.scananalytics.ru" target="_blank">
                    Инструкции
                </a>
            ),
        },
        {
            key: "1",
            label: (
                <a href="https://t.me/analytics_scan" target="_blank">
                    <SendOutlined /> Написать в поддержку
                </a>
            ),
        },
        {
            key: "2",
            label: (
                <a href="https://t.me/scananalytics" target="_blank">
                    <SendOutlined /> Телеграм канал
                </a>
            ),
        },
        {
            key: "3",
            label: (
                <a href="https://help.scananalytics.ru/?p=357" target="_blank">
                    Контакты
                </a>
            ),
        },
    ]

    const crmMenuItems = [
        {
            key: "1",
            label: <Link to={routeNames.crmSchool}>Школы</Link>,
        },
        {
            key: "2",
            label: <Link to={routeNames.crmUsers}>Пользователи</Link>,
        },
        {
            key: "3",
            label: <Link to={routeNames.crmOrders}>Заказы</Link>,
        },
        {
            key: "4",
            label: <Link to={routeNames.crmPayments}>Платежи</Link>,
        },
        {
            key: "5",
            label: <Link to={routeNames.crmTariffs}>Тарифы</Link>,
        },
        // {
        //     key: "6",
        //     label: (
        //         <Link to={routeNames.crmTasks}>
        //             Задачи
        //         </Link>
        //     )
        // },
        {
            key: "7",
            label: <Link to={routeNames.crmActivity}>Активность</Link>,
        },
        {
            key: "8",
            label: <Link to={routeNames.crmDetails}>Детализация</Link>,
        },
        {
            key: "9",
            label: (
                <Link to={routeNames.crmPartner}>
                    Партнёрская программа
                </Link>
            )
        },
        {
            key: "10",
            label: (
                <Link to={routeNames.crmRequests}>
                    Запросы
                </Link>
            )
        },
    ]
    if(selectedSchool){
        return(
            <>
                <Dropdown
                    menu ={{ items:settingsMenuItems.filter((el) => el.isAllowed) }}
                    placement="bottomLeft"
                    trigger={[ "click" ]}
                >
                    <div className={styles.headerItem}>
                        <SettingOutlined /> Настройки
                    </div>
                </Dropdown>

                <PermissionWrapper
                    allowedRoles={[ "admin", "user:settings" ]}
                    redirectToErrPage={false}
                >
                    <Dropdown
                        menu={{ items:webMenuItems }}
                        placement="bottomLeft"
                        trigger={[ "click" ]}
                    >
                        <div className={styles.headerItem}>
                            <VideoCameraOutlined /> Вебинары
                        </div>
                    </Dropdown>
                </PermissionWrapper>

                <Dropdown
                    menu={{ items:adsMenuItems }}
                    placement="bottomLeft"
                    trigger={[ "click" ]}
                >
                    <div className={styles.headerItem}>
                        <DesktopOutlined /> Реклама
                    </div>
                </Dropdown>

                <Dropdown
                    menu={{ items:helpMenuItems }}
                    placement="bottomLeft"
                    trigger={[ "click" ]}
                >
                    <div className={styles.headerItem}>
                        <QuestionCircleOutlined /> Помощь
                    </div>
                </Dropdown>
                <Link style={{ all:"unset" }} to={getLinkPath(routeNames.partner)}>
                    <div className={styles.headerItem}>
                        <UsergroupAddOutlined /> Партнёрская программа
                    </div>
                </Link>
                <PermissionWrapper allowedRoles={[]} redirectToErrPage={false}>
                    <Dropdown
                        menu={{ items:crmMenuItems }}
                        placement="bottomLeft"
                        trigger={[ "click" ]}
                    >
                        <div className={styles.headerItem}>СRM</div>
                    </Dropdown>
                </PermissionWrapper>
            </>
        )
    }else{
        return(
            <>
                <Dropdown
                    menu={{ items:helpMenuItems }}
                    placement="bottomLeft"
                    trigger={[ "click" ]}
                >
                    <div className={styles.headerItem}>
                        <QuestionCircleOutlined /> Помощь
                    </div>
                </Dropdown>
                <Link style={{ all:"unset" }} to={getLinkPath(routeNames.partner)}>
                    <div className={styles.headerItem}>
                        <UsergroupAddOutlined /> Партнёрская программа
                    </div>
                </Link>
                <Link style={{ all:"unset", color:"#FF0000" }} to={getLinkPath(routeNames.schoolAdd)}>
                    <div className={styles.headerItem}>
                        <PlusCircleOutlined /> Подключить новую школу
                    </div>
                </Link>
            </>
        )
    }
}
export default SchoolDropdowns
