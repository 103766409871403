import BankImage from './img/pay_1.png';
import ScoreImage from './img/pay_2.png';
export const CardIcon = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 512 512"
        >
            <g>
                <g>
                    <path d="M500.364,256c-6.982,0-11.636,4.655-11.636,11.636v139.636c0,5.818-2.327,11.636-6.982,16.291s-10.473,6.982-16.291,6.982
                        H46.545c-12.8,0-23.273-10.473-23.273-23.273V162.909h430.545c6.982,0,11.636-4.655,11.636-11.636
                        c0-6.982-4.655-11.636-11.636-11.636H11.636C4.655,139.636,0,144.291,0,151.273v256c0,25.6,20.945,46.545,46.545,46.545h418.909
                        c12.8,0,24.436-4.655,32.582-13.964c9.309-9.309,13.964-20.945,13.964-32.582V267.636C512,260.655,507.345,256,500.364,256z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M139.636,360.727H58.182c-6.982,0-11.636,4.655-11.636,11.636S51.2,384,58.182,384h81.455
                    c6.982,0,11.636-4.655,11.636-11.636S146.618,360.727,139.636,360.727z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M232.727,360.727h-46.545c-6.982,0-11.636,4.655-11.636,11.636S179.2,384,186.182,384h46.545
                    c6.982,0,11.636-4.655,11.636-11.636S239.709,360.727,232.727,360.727z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M453.818,360.727h-46.545c-6.982,0-11.636,4.655-11.636,11.636S400.291,384,407.273,384h46.545
                    c6.982,0,11.636-4.655,11.636-11.636S460.8,360.727,453.818,360.727z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M465.455,58.182H46.545C20.945,58.182,0,79.127,0,104.727c0,6.982,4.655,11.636,11.636,11.636s11.636-4.655,11.636-11.636
                    c0-12.8,10.473-23.273,23.273-23.273h418.909c12.8,0,23.273,10.473,23.273,23.273v104.727H58.182
                    c-6.982,0-11.636,4.655-11.636,11.636c0,6.982,4.655,11.636,11.636,11.636h442.182c6.982,0,11.636-4.655,11.636-11.636V104.727
                    C512,79.127,491.055,58.182,465.455,58.182z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>

    )
}

export const UMoneyIcon = () => {
    return (
        <svg
            height="100%"
            strokeMiterlimit="10"
            version="1.1"
            viewBox="0 0 450.58 320"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs/>
            <g>
                <path d="M284.49 10C201.05 10 134 77.5 134 160C134 243.18 201.71 310 284.47 310C367.23 310 435 242.5 435 160C435 77.5 367.25 10 284.49 10ZM284.49 215.91C253.71 215.91 228.41 190.68 228.41 160C228.41 129.32 253.71 104.09 284.49 104.09C315.27 104.09 340.58 129.32 340.58 160C339.89 190.68 315.27 215.91 284.49 215.91Z" fill="#8b3ffd" fillRule="nonzero" opacity="1" stroke="none"/>
                <path d="M134 53.58L134 271.76L80.68 271.76L12.28 53.58L134 53.58Z" fill="#8b3ffd" fillRule="nonzero" opacity="1" stroke="none"/>
            </g>
        </svg>
    )
}

export const QiwiIcon = () => {
    return (
        <svg
            height="100%"
            strokeMiterlimit="10"
            version="1.1"
            viewBox="0 0 266.7 265.8"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs/>
            <g>
                <path d="M213.012 178.042C213.678 183.037 212.179 185.201 210.514 185.201C208.849 185.201 206.519 183.203 204.021 179.208C201.524 175.212 200.525 170.717 201.857 168.386C202.689 166.887 204.354 166.221 206.685 167.054C210.847 168.552 212.512 175.045 213.012 178.042ZM189.37 189.03C194.531 193.359 196.03 198.52 193.366 202.016C191.868 204.014 189.37 205.013 186.54 205.013C183.71 205.013 180.713 204.014 178.715 202.183C174.053 198.187 172.721 191.528 175.718 187.865C176.884 186.367 178.882 185.534 181.046 185.534C183.876 185.701 186.873 186.866 189.37 189.03ZM180.879 230.653C202.856 230.653 226.664 238.144 252.969 261.619C255.633 263.95 259.129 261.12 256.798 258.123C230.826 225.325 206.852 219.165 183.044 213.837C153.742 207.344 138.758 190.862 128.269 172.881C126.105 169.218 125.272 169.884 125.106 174.546C124.939 180.207 125.272 187.698 126.604 195.024L126.604 195.024C125.439 195.024 124.273 195.024 123.108 195.024C81.3193 195.024 47.3557 161.06 47.3557 119.272C47.3557 77.4831 81.3193 43.5195 123.108 43.5195C164.897 43.5195 198.86 77.4831 198.86 119.272C198.86 122.269 198.694 125.265 198.361 128.096C192.867 127.097 181.878 126.93 174.22 127.596C171.39 127.763 171.723 129.261 173.887 129.594C198.86 134.089 216.175 149.573 220.004 177.876C220.171 178.542 221.003 178.708 221.336 178.209C231.658 161.06 237.485 140.915 237.485 119.438C237.485 56.1726 186.207 4.89418 122.941 4.89418C59.6758 4.89418 8.39737 56.1726 8.39737 119.438C8.39737 182.704 59.6758 233.982 122.941 233.982C139.757 233.982 156.406 230.653 180.879 230.653Z" fill="#ff8c00" fillRule="nonzero" opacity="1" stroke="none"/>
            </g>
        </svg>
    )
}

export const BankCardIcon = () => {
    return <img style={{maxHeight:"102px",width:"88px",marginRight:"28px"}} src={BankImage} alt="Банковская карта" />;
};
export const DownloadScoreIcon = () => {
    return <img style={{maxHeight:"116px",width:"102px",marginRight:"18px"}} src={ScoreImage} alt="Скачать счёт для безналичной оплаты" />;
};
