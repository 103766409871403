import { useTypedSelector } from "./useTypedSelector";

// Hook checks if access for current school ended
// Returns true or false based on this
const useIsAccessEnded = () => {
    const currentSchool = useTypedSelector((state) => state.currentSchool)

    try{
        return !currentSchool?.userAccess?.status
    }catch(err){
        console.log(err)
        return false
    }
};

export default useIsAccessEnded;
