import React, {FC, useEffect, useRef, useState} from "react"


import {Button, CheckboxOptionType, Drawer, Tooltip} from "antd"
import usePermission from "../../../shared/common/hooks/usePermission";
import {useActions} from "../../../shared/common/hooks/useActions";
import PageHead from "../../../shared/common/components/page-header/PageHeader";
import TabSelect from "../ads/add-spend/components/TabSelect";
import MainLayout from "../../../shared/common/components/layouts/MainLayout";
import YandexTable from "./components/YandexTable";
import axios, {AxiosResponse} from "axios";
import {API_ADS_ADD_RK_TOKEN} from "../../../shared/common/api/urls";
import {axiosConfigAuth} from "../../../shared/common/api/middleware";
import {showErrorMessage} from "../../../helpers/utils/ui";
import {routeNames} from "../../../shared/common/constants/routes";
import HandleResponse from "../ads/connect-ads/HandleResponse";
import {IhandleResponseProps, IItem} from "../ads/connect-ads/ConnectAds";
import {useNavigate} from "react-router-dom";
import AddModal from "./components/AddModal";
import CreateTaskModal from "./components/CreateTask";
import {CloseOutlined, LinkOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import EditTaskModal from "./components/EditTask";



const YandexMetricsPage: FC = () => {
    const [ handleResponseProps,setHandleResponseProps ] = useState<IhandleResponseProps>({
        visible:false,
        message:"",
        type:"success"
    })
    const getCurrentTab = () => {
        const searchParams = new URLSearchParams(location.search);
        return Number(searchParams.get('tab')) || 1
    };
    const { setAdsSpendUpdate } = useActions()
    const [ addModalVisible, setAddModalVisible ] = useState(false)
    const [ pageMode, setPageMode ] = useState(getCurrentTab())
    const [ createTaskModalVisible,setCreateTaskModalVisible ] = useState(false)

    const [ mode, setMode ] = useState(getCurrentTab())
    const [ refetch,setRefetch ] = useState(false)
    const [ items, setItems ] = useState<IItem[]>([ {} ])
    const navigate = useNavigate()
    const [ editId,setEditId ] = useState<number | null>(null)
    const plainOptions = [
        { label:"Задачи",value:1 },
        { label: "Подключение", value: 2 },
    ] as (string | number | CheckboxOptionType)[]
    const TabsButtons = {
        1:(
            <Button   onClick={() => {
                setCreateTaskModalVisible(true)
            }} style={{ marginBottom:"16px" }} type={"primary"}>Создать задачу</Button>
        ),
        2:(
            <Button   onClick={() => {
                window.location.href =
                    "https://oauth.yandex.ru/authorize?response_type=code&client_id=c9ef0a193c4d4dd29ebe899e781a17b9&redirect_uri=https://lk.scananalytics.ru/source/yandex?source=11&force_confirm=yes"
            }} style={{ marginBottom:"16px" }} type={"primary"}>Подключить метрику</Button>
        )
    }
    useEffect(() => {
        if(window.location.search.length > 0 && !window.location.search.includes('tab')){
            try{
                const urlParams = new URLSearchParams(window.location.search)
                const formData = new FormData()
                const entries = urlParams.entries()
                for (let entry = entries.next(); !entry.done; entry = entries.next()) {
                    const [ name, value ] = entry.value
                    formData.append(name, value)
                }
                const getAdCabList = (): Promise<AxiosResponse> => {
                    const data = { ...Object.fromEntries(formData) }
                    return axios.post(API_ADS_ADD_RK_TOKEN, data, axiosConfigAuth())
                }
                getAdCabList()
                    .then((response) => {
                        if (response.status === 200) {
                            if (response.data && Object.keys(response.data).length !== 0) {
                                setItems(response.data)
                                setAddModalVisible(true)
                            }
                        }
                    })
                    .catch((err) => {
                        const { response: { data:errorMessage } } = err
                        if(typeof errorMessage === 'object'){
                            setHandleResponseProps({  message: errorMessage.message, visible: true,type:"error" })

                        }else {
                            setHandleResponseProps({  message: errorMessage, visible: true,type:"error" })
                        }
                        console.log("Получение метки не удалось", err)
                    })
            }catch(error){
                console.log(error)
                showErrorMessage("Получение метки не удалось")

            }finally {
                navigate(routeNames.yandexMetrics)
            }
        }

    }, [])
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };
    const iframeRef = useRef(null);

    const openLink = () => {
        window.open(`https://help.scananalytics.ru/integratsiya-s-yandeks-metrikoy`, '_blank');
    }

    useEffect(() => {
        const drawerBody = document.querySelector('.ant-drawer-body');
        if (drawerBody) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            drawerBody.style.padding = '0';
        }
        function postMessageToIframe(data:any) {
            const iframe = iframeRef.current
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            if (iframe && iframe.contentWindow) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                iframe.contentWindow.postMessage(data, 'https://lk.scananalytics.ru');
            }
        }
        postMessageToIframe("deleteElement");

    })
    const handleEditTask =  (taskId:number) => {
        setEditId(taskId)
    }

    useEffect(()=>{
        setTimeout(()=>{
            setRefetch((prev)=>!prev)
        },150)
    },[createTaskModalVisible,editId])
    return (
        <MainLayout pageTitle="Яндекс метрика">
            <div className="page-wrapper">
                <PageHead title={
                    (<>
                        Интеграция с Яндекс Метрикой
                             <span
                                 style={{
                                     marginLeft: 10,
                                     marginRight: 10,
                                     marginTop: 3,
                                 }}
                             >
                        <QuestionCircleOutlined
                            onClick={()=>setVisible(true)}
                            style={{ color: "#ccc", fontSize: 14 }}
                        />
                  </span>
                    </>) as unknown as Element
                } />
                <div className="page-content">
                    <HandleResponse {...handleResponseProps} />
                    <div>
                        <TabSelect options={plainOptions}  pageMode={pageMode} setPageMode={setPageMode} />
                    </div>

                    {/*{pageMode === 1  ?  (*/}
                    {/*        <TableReport*/}
                    {/*            apiUrl={API_REPORT_TABLE}*/}
                    {/*            metrics={metrics}*/}
                    {/*            withFilter={true}*/}
                    {/*            filtersFilter={["Реклама (РК)"]}*/}
                    {/*            withSegment={false}*/}
                    {/*        />*/}
                    {/*    )*/}
                    {/*    : <TableCabs refetch={refetch} setRefetch={setRefetch} handleRenameRK={handleRenameRK} mode={mode} />}*/}
                    <div style={{ marginTop:"18px" }}>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                        {/*@ts-ignore*/}
                        {TabsButtons[pageMode]}
                        <YandexTable handleEditTask={handleEditTask}  refetch={refetch} addModalVisible={addModalVisible} mode={pageMode} />
                    </div>
                </div>
                { addModalVisible && <AddModal
                    isVisible={addModalVisible}
                    setVisible={setAddModalVisible}
                    items={items}
                    setItems={setItems}
                    setRefetch={setRefetch}
                />}
                {
                    createTaskModalVisible && <CreateTaskModal visible={createTaskModalVisible} setVisible={setCreateTaskModalVisible} />
                }
                {
                    editId !== null && <EditTaskModal editId={editId} visible={true} setVisible={setEditId} />
                }
                <Drawer
                    placement="right"
                    onClose={onClose}
                    visible={visible}
                    width={650}
                    rootStyle={{ padding:0,overflow:"hidden" }}
                >
                    <div style={{ position: 'absolute', top: 30, left: -40, zIndex: 1000 }}>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={onClose}
                            shape={"circle"}
                            style={{ display: 'block', marginBottom: 10 }}
                        />
                        <Button
                            icon={<LinkOutlined />}
                            onClick={openLink}
                            shape={"circle"}
                            style={{ display: 'block' }}
                        />
                    </div>
                    <div style={{ marginTop:"10px" }}></div>
                    <iframe
                        style={{ border:"none" }}
                        ref={iframeRef}
                        src={`https://help.scananalytics.ru/integratsiya-s-yandeks-metrikoy?timestamp=${new Date().getTime()}`}
                        width="100%"
                        height="100%"

                    />
                </Drawer>
            </div>
        </MainLayout>
    )
}

export default YandexMetricsPage
