import React, { FC, useEffect, useState } from "react"

const getLabelName = (filterType: string) => {
    try{
        const dateObjects = [
            {
                value:"order",
                content:"Дата заказа"
            },
            {
                value:"reg",
                content:"Дата регистрации"
            },
            {
                value:"ad",
                content:"Дата рекламы"
            },
            {
                value:"web",
                content:"Дата вебинара"
            },
            {
                value:"payment",
                content:"Дата платежа"
            },
            {
                value:"visit",
                content:"Дата посещения страницы"
            }
        ]
        return dateObjects.find((el)=>el.value === filterType)?.content || "Дата регистрации"
    }catch(err){
        console.log(err)
    }
    switch (filterType) {
        case "reg":
            return "Дата регистрации"
        case "geo":
            return "Дата регистрации"
        case "app":
            return "Дата регистрации"
        case "orders":
            return "Дата регистрации"
        case "payments":
            return "Дата регистрации"
        case "webinar":
            return "Дата регистрации"
        case "ads":
            return "Дата рекламы"
        default:
            return "Дата регистрации"
    }
}

const ChartLabel: FC<any> = ({ chartFilter, isDisabled = false, chartType, width }) => {

    const [ label, setLabel ] = useState("")
    useEffect(() => {
        if (chartFilter) {
            setLabel(getLabelName(chartFilter))
        } else {
            setLabel("")
        }
    }, [ chartFilter ])

    if (chartType !== "line" && chartType !== "area") {
        return null
    }

    return chartFilter && !isDisabled ? (
        <div className="universal-chart-label" style={{ width: "100%",marginTop:"-16px" }}>
            <p style={{ marginBottom:0 }}>
                {label}
            </p>
        </div>
    ) : null
}

export default ChartLabel
