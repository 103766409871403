import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth } from "../middleware"
import {
    API_ADS_SPEND_AD_LIST,
    API_ADS_SPEND_COMP_LIST,
    API_ADS_SPEND_CREATE, API_ADS_SPEND_DELETE,
    API_ADS_SPEND_GET_DATA, API_ADS_SPEND_GET_RATE_BY_ID, API_ADS_SPEND_GET_UPDATE_DATA,
    API_ADS_SPEND_GR_LIST, API_ADS_SPEND_PROCESS, API_ADS_SPEND_UPDATE
} from "../urls"
import moment from "moment/moment";

export const adsSpendApi = {
    fetchAdsSpendList: async (schoolId: string | number): Promise<AxiosResponse> => {
        return axios.get(`${API_ADS_SPEND_GET_DATA}/${schoolId}`, axiosConfigAuth())
    },

    fetchAdsSpendCompList: async (values:{account_id :number,school_id:number}): Promise<AxiosResponse> => {
        const data = {
            ...values
        }

        return axios.post(API_ADS_SPEND_COMP_LIST, data, axiosConfigAuth())
    },

    fetchAdsSpendGrList: async (schoolId: number, compId: number): Promise<AxiosResponse> => {
        const data = {
            school_id: schoolId,
            campaign_id: compId
        }

        return axios.post(API_ADS_SPEND_GR_LIST, data, axiosConfigAuth())
    },

    fetchAdsSpendAdList: async (schoolId: number, compId: number, grId: number): Promise<AxiosResponse> => {
        const data = {
            school_id: schoolId,
            campaign_id: compId,
            group_id: grId
        }

        return axios.post(API_ADS_SPEND_AD_LIST, data, axiosConfigAuth())
    },

    createAdsSpend: async (schoolId: number, values: any): Promise<AxiosResponse> => {
        const data = {
            school_id: schoolId,
            ...values
        }

        return axios.post(API_ADS_SPEND_CREATE, data, axiosConfigAuth())
    },

    fetchUpdateData: async (schoolId: string, spendId: string, date?: string): Promise<AxiosResponse> => {
        const convertDateFormat = (dateStr:string) => {
            const parts = dateStr.split('-');
            return `${parts[2]}-${parts[1]}-${parts[0]}`;
        }
        const data = {
            school_id: schoolId,
            id: spendId,
            ...(date && {date_stat: convertDateFormat(date)})
        }


        return axios.post(API_ADS_SPEND_GET_RATE_BY_ID, data, axiosConfigAuth())
    },

    updateAdsSpend: async (values: any): Promise<AxiosResponse> => {
        const data = {
            ...values
        }

        return axios.post(API_ADS_SPEND_UPDATE, data, axiosConfigAuth())
    },

    deleteAdsSpend: async (schoolId: number, spendId: number, date: string): Promise<AxiosResponse> => {
        const data = {
            school_id: schoolId,
            id: spendId,
            date_stat: date
        }

        return axios.post(API_ADS_SPEND_DELETE, data, axiosConfigAuth())
    },
    processRK: async(data:{school_id: string, id: number, date_stat_at: string,date_stat_to:string}): Promise<AxiosResponse> => {
        return axios.post(API_ADS_SPEND_PROCESS, data, axiosConfigAuth())
    },
}
