import React, { FC } from "react"

import { Tabs } from "antd"

import BalanceTab from "./tabs/BalanceTab"
import BillTab from "./tabs/BillTab"
import RequisitesTab from "./tabs/RequisitesTab"
import WithdrawsTab from "./tabs/WithdrawsTab"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import { useLocation,useNavigate } from 'react-router-dom';

const BillingPage: FC = () => {
    const {currency} = useTypedSelector((state) => state.meta)
    const navigate = useNavigate()
    const location = useLocation();
    const tabs = (currencyValue: string) => {
        const tabsArr = [
            {
                label: "Баланс и тариф",
                key: "item-1",
                children: <BalanceTab currency={"₽"}/>,
                forceRender: true,
            },
            {
                label: "Счета и акты",
                key: "item-2",
                children: <BillTab currency={"₽"}/>,
                forceRender: true,
            },
            {
                label: "Пополнения и списания",
                key: "item-3",
                children: <WithdrawsTab currency={"₽"}/>,
                forceRender: true,
            },
            {
                label: "Реквизиты",
                key: "item-4",
                children: <RequisitesTab/>,
                forceRender: true,
            }
        ]
    return tabsArr
}
    const handleTabChange = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.set('tab', key);
        navigate(url.pathname + url.search);
    };

    const getCurrentTab = () => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get('tab') || 'item-1';
    };

    return (
    <div>
      <Tabs type="card" items={tabs(currency)} activeKey={getCurrentTab()} onChange={handleTabChange} />
    </div>
    )
}

export default BillingPage
