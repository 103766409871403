import axios, { AxiosResponse } from "axios";

import { axiosConfigAuth } from "../middleware";
import {
  API_ADS_GET_DATA,
  API_ADS_UPDATE_CONVERT,
  API_ADS_UPDATE_CURRENCY,
  API_ADS_UPDATE_NDS,
  API_ADS_UPDATE_STATUS,
  API_ADS_ADD_RK,
  API_YANDEX_UPDATE_STATUS,
  API_YANDEX_REMOVE_COUNTER,
  API_ADS_UPDATE_UTM,
  API_ADS_UPDATE_UTM_STATUS,
  API_ADS_UPDATE_UTM_MASK, API_ADS_UPDATE_AD_CABINET,
} from "../urls";

export const adsCabinetApi = {
  fetchData: (
    schoolId: number | string,
    mode: number,
    filters?:any
  ): Promise<AxiosResponse> => {
    const data = {
      school_id: schoolId,
      status_id: mode,
      type:1,
      filters
    };

    return axios.post(API_ADS_GET_DATA, data, axiosConfigAuth());
  },

  addAdvertCab: (users: any[]): Promise<AxiosResponse> => {
    return axios.post(API_ADS_ADD_RK, users, axiosConfigAuth());
  },
  updateStatus: (
    schoolId: number,
    cabinetId: number,
    status: boolean
  ): Promise<AxiosResponse> => {
    const data = {
      school_id: schoolId,
      id: cabinetId,
      status_id: status ? 1 : 2,
    };

    return axios.post(API_ADS_UPDATE_STATUS, data, axiosConfigAuth({ Pathname:window.location.pathname }));
  },
  updateUTMStatus: (
      schoolId: number,
      cabinetId: number,
      status: boolean
  ): Promise<AxiosResponse> => {
    const data = {
      school_id: schoolId,
      id: cabinetId,
      utm_has_id:Number(status)
    }

    return axios.post(API_ADS_UPDATE_UTM_STATUS, data, axiosConfigAuth({ Pathname:window.location.pathname }));
  },
  updateUTMLabel: (
      schoolId: number,
      cabinetId: number,
      utm_label: string
  ): Promise<AxiosResponse> => {
    const data = {
      school_id: schoolId,
      id: cabinetId,
      utm_label
    }

    return axios.post(API_ADS_UPDATE_UTM, data, axiosConfigAuth({ Pathname:window.location.pathname }));
  },
  updateUTMMask: (
      schoolId: number,
      cabinetId: number,
      utm_mask: {
        value?: boolean,
        number?: number,
        divider?: string
      }
  ): Promise<AxiosResponse> => {
    const data = {
      school_id: schoolId,
      id: cabinetId,
      utm_mask:`${JSON.stringify(utm_mask)}`
    }

    return axios.post(API_ADS_UPDATE_UTM_MASK, data, axiosConfigAuth({ Pathname:window.location.pathname }));
  },
  updateAdCabinet: (
      schoolId: number,
      cabinetId: number,
      ad_cabinet: string
  ): Promise<AxiosResponse> => {
    const data = {
      school_id: schoolId,
      id: cabinetId,
      ad_cabinet
    }

    return axios.post(API_ADS_UPDATE_AD_CABINET, data, axiosConfigAuth({ Pathname:window.location.pathname }));
  },
  updateStatusYandex: (
      schoolId: number,
      cabinetId: number,
      status: boolean
  ): Promise<AxiosResponse> => {
    const data = {
      school_id: schoolId,
      id: cabinetId,
      status_id: status ? 1 : 2,
    };

    return axios.post(API_YANDEX_UPDATE_STATUS, data, axiosConfigAuth({ Pathname:window.location.pathname }));
  },
  removeYandexCounter:(counter_id:number) => {
    return axios.get(`${API_YANDEX_REMOVE_COUNTER}/${counter_id}`,axiosConfigAuth())
  },

  updateNds: (
    schoolId: number,
    cabinetId: number,
    status: boolean
  ): Promise<AxiosResponse> => {
    const data = {
      school_id: schoolId,
      id: cabinetId,
      vat: status,
    };

    return axios.post(API_ADS_UPDATE_NDS, data, axiosConfigAuth());
  },

  updateCurrency: (
    schoolId: number,
    cabinetId: number,
    value: number
  ): Promise<AxiosResponse> => {
    const data = {
      school_id: schoolId,
      id: cabinetId,
      currency_id: value,
    };

    return axios.post(API_ADS_UPDATE_CURRENCY, data, axiosConfigAuth());
  },

  updateConvert: (
    schoolId: number,
    cabinetId: number,
    value: number
  ): Promise<AxiosResponse> => {
    const data = {
      school_id: schoolId,
      id: cabinetId,
      percent: value,
    };

    return axios.post(API_ADS_UPDATE_CONVERT, data, axiosConfigAuth());
  },
};
