import React, { useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Form, InputNumber, Modal } from "antd"

import { ConnectReferals } from "../../../../../app/types/unApi"
import { showErrorMessage } from "../../../../../helpers/utils/ui"
import { unApi } from "../../../../../shared/common/api/endpoints/reports/unApi"


type Props = {
    visible:boolean
    setVisible:  React.Dispatch<React.SetStateAction<boolean>>
}
const ConnectReferal = ({ visible,setVisible }:Props) => {
    const [ form ] = Form.useForm()
    const [ formLoading, setFormLoading ] = useState(false)

    const connectReferals = async (values:ConnectReferals) => {
        const data = {
          ...values
        }
        setFormLoading(true)
        setVisible(false)
        try{
            const response = await unApi.connectReferals(data)
            if(response.status === 200){
                form.resetFields()
                setVisible(false)
                Modal.info({
                    title: "Успех!",
                    content: "Реферал привязан.",
                })
            }
        }catch(err){
            showErrorMessage("Произошла ошибка, повторите попытку позже.")

        }finally{
            setFormLoading(false)
        }
    }

    return (
        <Modal
            title="Привязка реферала"
            open={visible}
            onCancel={() => {setVisible(false)}}
            onOk={() => {form.submit()}}
            okButtonProps={{ loading: formLoading }}
            okText="Привязать"
        >
            {
                formLoading ?
                    <div className="loading-container">
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                    </div>
                    :
                    <Form
                        form={form}
                        onFinish={connectReferals}
                        requiredMark={false}
                    >
                        <Form.Item
                            name="partner_id"
                            label="id партнёра"
                            rules={[ { required: true } ]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            name="referal_id"
                            label="id реферала"
                            rules={[
                                { required: true },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Form>
            }
        </Modal>
    )
}

export default ConnectReferal
