import React, { useEffect, useState } from "react"

import { Form, Modal, Select } from "antd"
import axios from "axios"

import { showErrorMessage } from "../../../../../../../helpers/utils/ui"
import { unApi } from "../../../../../api/endpoints/reports/unApi"

const AddOrder = ({ addOrderModalVisible, setAddOrderModalVisible, loadRows }:any) => {

    const [ form ] = Form.useForm()

    const [ schoolNames, setSchoolNames ] = useState([])
    const [ tariffs, setTariffs ] = useState([])
    const [ schoolNamesLoading, setSchoolNamesLoading ] = useState(false)
    const [ tariffsLoading, setTariffsLoading ] = useState(false)
    const [ formLoading, setFormLoading ] = useState(false)
    const [ currentPrice, setCurrentPrice ] = useState(0)

    const fetchData = async () => {
        setSchoolNamesLoading(true)
        setTariffsLoading(true)
        try{
            setCurrentPrice(0)
            form.resetFields()
            let { data:schoolNames,status:codeSchool } = await unApi.getFilterList(0,"crm_school_name","")
            const { data:tariffs,status:codeFilter } = await unApi.getFilterList(0,"crm_tariff_list","")
            if(codeSchool === 200){
                let regex = /&nbsp;/g
                schoolNames = schoolNames.map((element:{id:number,name:string})=>({...element, name: element.name.replace(regex, " ")}))
                setSchoolNames(schoolNames)
            }else{
                showErrorMessage("Произошла ошибка, с подгрузкой названий школ.")
            }
            if(codeFilter === 200){
                setTariffs(tariffs)

            }else{
                showErrorMessage("Произошла ошибка, с подгрузкой тарифов.")
            }
        }catch(err){
            showErrorMessage("Произошла ошибка, с подгрузкой данных, повторите позже.")
        }finally {
            setSchoolNamesLoading(false)
            setTariffsLoading(false)
        }
    }

    useEffect(() => {
        if (addOrderModalVisible) {
            fetchData()
        }
    }, [ addOrderModalVisible ])

    const applyOrder = async (values:{school_name:number,tariff_name:number}) => {
        setFormLoading(true)
        try{
            const response = await unApi.addOrder(values.school_name,values.tariff_name)
            if(response.status === 200){
                form.resetFields()
                setAddOrderModalVisible(false)
                Modal.info({
                    title: "Успех!",
                    content: "Заказ добавлен.",
                })
                loadRows()
            }else{
                showErrorMessage("Ошибка при добавлении заказа.")
            }
        }catch(err){
            showErrorMessage("Ошибка при добавлении заказа.")
        }finally{
            setFormLoading(false)
        }
    }

    return (
        <Modal
            title="Создание заказа"
            open={addOrderModalVisible}
            onCancel={() => {setAddOrderModalVisible(false)}}
            onOk={() => form.submit()}
            okButtonProps={{ loading: formLoading }}
            okText="Сохранить"
            cancelText="Отмена"
            destroyOnClose
        >
            <Form
                form={form}
                onFinish={applyOrder}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                requiredMark={false}
            >
                <Form.Item
                    name="school_name"
                    label="Название школы"
                    rules={[ { required: true } ]}
                >
                    <Select
                        loading={schoolNamesLoading}
                        disabled={schoolNamesLoading}
                        optionFilterProp="children"
                        showSearch={true}
                        filterOption={(input:any, option:any) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                    >
                        {
                            schoolNames.length > 0 ?
                                <>
                                    {
                                        schoolNames.map((item:any, index:any) => {
                                            return (
                                                <Select.Option
                                                    key={index}
                                                    value={item.id}
                                                    label={item.name}
                                                >
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </>
                                :
                                <Select.Option value="">Нет</Select.Option>
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    name="tariff_name"
                    label="Тариф"
                    rules={[ { required: true } ]}
                >
                    <Select
                        loading={tariffsLoading}
                        disabled={tariffsLoading}
                        onSelect={(value, option) => {setCurrentPrice(option.sum)}}
                        optionFilterProp="children"
                        showSearch={true}
                        filterOption={(input:any, option:any) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                    >
                        {
                            tariffs.length > 0 ?
                                <>
                                    {
                                        tariffs.map((item:any, index:any) => {
                                            return (
                                                <Select.Option
                                                    key={index}
                                                    value={item.id}
                                                    label={item.tariff_name}
                                                    sum={item.tariff_sum}
                                                >
                                                    {item.tariff_name}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </>
                                :
                                <Select.Option value="">Нет</Select.Option>
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Сумма"
                    style={{ marginBottom: 0 }}
                >
                    {currentPrice} руб.
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddOrder
