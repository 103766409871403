import React from "react"

// eslint-disable-next-line import/default
import ReactApexChart from "react-apexcharts"

const ChartWrap = (props) => {
    return (
        <ReactApexChart
            {...props}
        />
    )
}

export default ChartWrap
