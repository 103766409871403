import axios, { AxiosResponse } from "axios"

import { getAccessToken } from "../../../../helpers/utils/auth"
import { axiosConfigAuth, axiosRefreshConfig } from "../middleware"
import {
    API_AUTH_LOGIN,
    API_AUTH_LOGOUT,
    API_AUTH_RECOVERY,
    API_AUTH_REFRESH,
    API_AUTH_RESET,
    API_AUTH_SIGNUP
} from "../urls"

export const authApi = {
    signup: async (name: string, email: string, password: string, phone: string, agreed: boolean, reg_url: string, fingerprint: string) => {
        const data = {
            name,
            email,
            password,
            phone,
            reg_url,
            agreed,
            fingerprint
        }

        return axios.post(API_AUTH_SIGNUP, data, axiosRefreshConfig())
    },

    login: async (login: string, password: string, fingerprint: string): Promise<AxiosResponse> => {
        const data = {
            login,
            password,
            fingerprint
        }

        return axios.post(API_AUTH_LOGIN, data, axiosRefreshConfig())
    },

    logout: async (token: string): Promise<AxiosResponse> => {
        return axios.get(
            API_AUTH_LOGOUT,
            {
                headers: {
                    Token: `${token}`,
                }
            }
        )
    },

    resetPassword: async (email: string): Promise<AxiosResponse> => {
        const data = {
            email: email
        }
        return axios.post(API_AUTH_RESET, data)
    },

    recoveryPassword: async (token: string, password: string, fingerprint: string): Promise<AxiosResponse> => {
        const data = {
            password,
            token,
            fingerprint
        }

        return axios.post(API_AUTH_RECOVERY, data)
    },

    refreshToken: async (fingerprint: string): Promise<AxiosResponse> => {
        const data = {
            fingerprint: fingerprint
        }

        return axios.post(API_AUTH_REFRESH, data, axiosRefreshConfig())
    }
}
