import {Space} from "antd";
import React from "react";
import FieldTooltip from "./FieldTooltip";

const FieldLabel = ({text,tooltipText}:{text:string,tooltipText?:string}) => (
    <Space>
        {text}
        {tooltipText && <FieldTooltip text={tooltipText} />}
    </Space>
)
export default FieldLabel
