import React, { FC, useEffect, useState } from "react"

import {CloseOutlined, DownOutlined, PlusOutlined} from "@ant-design/icons"
import { Button, Input, Popover, Tree, Typography } from "antd"
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"
import {data, updateFilters} from "../../../../shared/common/constants/filters";
import { generateListKeys, getParentKey, prepareData } from "../../../../helpers/utils/reports";
import FilterItemSelected from "../../../../shared/common/components/reports/params/filter/FilterItemSelected";
import RequestButton from "../../../../shared/common/components/reports/params/filter/RequestButton";
import { useForceUpdate } from "../../../../shared/common/hooks/useForceUpdate";
import { useActions } from "../../../../shared/common/hooks/useActions";
import RefreshButton from "../../../../shared/common/components/reports/params/filter/RefreshButton";



interface IUniversalFilterProps {
    isCompare: boolean
    withSegment: boolean
    disabled?: boolean
    disableAttr?: boolean
    filtersFilter?: string[]
    initialExpandedKeys?:string[]
    withLabel?:boolean
    notParams?:boolean
}

export const listKeys = generateListKeys(data)


const UniversalFilter: FC<IUniversalFilterProps> = ({
                                                        isCompare,
                                                        withSegment,
                                                        filtersFilter,
                                                        disabled = false,
                                                        disableAttr = false,
                                                        withLabel = true,
                                                        notParams = false,
                                                        initialExpandedKeys = []
                                                    }) => {
    const forceUpdate = useForceUpdate()

    const { filters, filterSelectorVisible,filterParent } = useTypedSelector(
        (state) => state.filter
    )

    const { setFilterSelectorVisible, setMetaCompare,setFilterParent,setFilters,setCurrentSegment,setAllDataFetching,setCurrentFunnel,setIsNotParamsFilterVisible } = useActions()
    const [ expandedKeys, setExpandedKeys ] = useState<string[]>(initialExpandedKeys)
    const [ searchValue, setSearchValue ] = useState("")
    const [ autoExpandParent, setAutoExpandParent ] = useState(true)
    const [ treeData, setTreeData ] = useState<any>([])
    const [ btnDisabled, setBtnDisabled ] = useState(true)

    useEffect(() => {
        let data_ = data
        if(location.pathname === '/users/ad-reports'){
            data_ = updateFilters()
        }
        const filteredData = filtersFilter
            ? data_.filter((item:any) => filtersFilter.includes(item.name))
            : data_
        const _data = prepareData(filteredData, searchValue, filters, disableAttr,'','','','',[''],'',filterParent)

        if (_data.length === 1) {
            setAutoExpandParent(true)
            setExpandedKeys([ "0-0" ])
        }

        setTreeData(_data)
        forceUpdate()
    }, [ data, searchValue, filters, filters.length, isCompare,filterParent,location.pathname,filtersFilter ])

    useEffect(() => {
        if (!searchValue) {
            let data_ = data
            if(location.pathname === '/users/ad-reports'){
                data_ = updateFilters()
            }
            setAutoExpandParent(false)
            const filteredData = filtersFilter
                ? data_.filter((item:any) => filtersFilter.includes(item.name))
                : data_
            const _data = prepareData(filteredData, searchValue, filters, disableAttr,'','','','',[''],'',filterParent)

            setTreeData(_data)
        }

        forceUpdate()
    }, [ searchValue ])

    useEffect(() => {
        forceUpdate()
    }, [ treeData ])

    useEffect(() => {
        if (!isCompare) {
            setMetaCompare({value:false})
        }
    }, [ isCompare ])



    const onExpand = (_expandedKeys: any) => {
        setExpandedKeys(_expandedKeys)
        setAutoExpandParent(false)
    }

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        if (value !== "") {
            const reg = new RegExp(value, "i")
            const _treeData = treeData

            let _expandedKeys: any = listKeys
                .map((item: any) => {
                    if (item.name.search(reg) > -1) {
                        return getParentKey(item.id, _treeData)
                    }

                    return null
                })
                .filter(
                    (item: any, i: number, self: any) => item && self.indexOf(item) === i
                )

            setExpandedKeys(_expandedKeys)
            setSearchValue(value)
            setAutoExpandParent(true)
        } else {
            setSearchValue("")
            setAutoExpandParent(false)
        }
    }
    const onClearFilters = () => {
        setCurrentSegment(null)
        setCurrentFunnel([])
        setFilters([])
        setFilterParent([])
        setBtnDisabled(true)
        setAllDataFetching({value:true})
    }

    return (
        <div className="params__filter">
            <RefreshButton />
            {filters.length > 0 && <Button
                style={{ marginRight:5 }}
                onClick={() => onClearFilters()}
                icon={<CloseOutlined />}
                title="Очистить"
            />}
            <div
                style={{
                    display: "inline",
                    marginLeft: withSegment ? 10 : 0,
                    height: 34,
                }}
            >
                {withLabel && <Typography.Text type="secondary">
                    Условия фильтра:&nbsp;
                </Typography.Text>}
                {filters.length === 0
                    ? null
                    : filters.map((item: any, index: number) => {
                        if (item.type === "datetimerange") {
                            if (!item.start && !item.end) {
                                return null
                            }
                        }

                        let prefix = "и"
                        let secondPrefix = null
                        const prevIndex = index === 0 ? 0 : index - 1

                        if (
                            item.id === filters[prevIndex].id
                        ) {
                            prefix = "или"
                        }

                        if (item.id.includes("not_")) {
                            secondPrefix = "не"
                        }

                        return (
                            <>
                                {index !== 0 && (
                                    <Typography.Text type="secondary">
                                        &nbsp;{prefix}&nbsp;
                                    </Typography.Text>
                                )}
                                {secondPrefix ? (
                                    <b style={{ fontWeight: 600, color: "#ff0000" }}>
                                        {secondPrefix}&nbsp;
                                    </b>
                                ) : (
                                    ""
                                )}
                                {
                                    <FilterItemSelected
                                        arrayIndex={index}
                                        id={item.id}
                                        itemData={item}
                                        full_name={item.full_name}
                                        {...item}
                                    />
                                }
                            </>
                        )
                    })}
                &nbsp;
                <Popover
                    content={
                        <Tree.DirectoryTree
                            blockNode
                            switcherIcon={<DownOutlined />}
                            showIcon={false}
                            selectable={false}
                            treeData={
                                disableAttr
                                    ? treeData.filter(
                                        (item: any) =>
                                            item.name !== "Реклама" && item.name !== "Регистрации"
                                    )
                                    : treeData
                            }
                            onExpand={onExpand}
                            expandedKeys={expandedKeys}
                            autoExpandParent={autoExpandParent}
                        />
                    }
                    title={
                        <Input
                            onChange={(e) => onSearch(e)}
                            placeholder="Найти условие"
                            allowClear
                        />
                    }
                    trigger="click"
                    placement="bottom"
                    overlayClassName="filter-popover"
                    open={filterSelectorVisible}
                    onOpenChange={(visible)=>{setFilterSelectorVisible(visible)}}
                >
                    <Button size={"small"} icon={<PlusOutlined />} type="primary" shape="circle" />
                </Popover>
                {!notParams && <RequestButton
                    btnDisabled={btnDisabled}
                    setBtnDisabled={setBtnDisabled}
                />}
            </div>
        </div>
    )
}

export default UniversalFilter
