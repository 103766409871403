import {Button, Form, message, Modal, Select, Space, Upload} from "antd";
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import {useEffect, useState} from "react";
import {UploadOutlined} from "@ant-design/icons";
import {useTypedSelector} from "../../../../../../shared/common/hooks/useTypedSelector";
import {unApi} from "../../../../../../shared/common/api/endpoints/reports/unApi";
import {showErrorMessage, showSuccessMessage} from "../../../../../../helpers/utils/ui";
import {useActions} from "../../../../../../shared/common/hooks/useActions";
import Loading from "../../../../../../shared/common/components/reports/cohort/components/Loading";

interface Props {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    setRefetch:(prev: (prev: boolean) => boolean)=>void;
}
export const ImportExcel = ({visible,setVisible,setRefetch}:Props) => {
    const { setMetaTableFetching } = useActions()
    const selectedSchool = useTypedSelector((state)=>state.currentSchool.selectedSchool)
    const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
    const [isCabsLoading,setIsCabsLoading] = useState(true)
    const [cabinetsData,setCabinetsData] = useState<{id:number,name:string,ad_account:string}[]>([])
    const [accountId,setAccountId] = useState<string>('');
    const getCabinetsData = async () => {
        try {
            setIsCabsLoading(true)
            const { data } = await unApi.getRK({school_id:Number(selectedSchool?.id), status_id:1,type:2,filters:[]});
            setCabinetsData(data)
        } catch (err) {
            console.log(err);
        }finally{
            setIsCabsLoading(false)
        }
    };
    useEffect(() => {
        getCabinetsData()
    }, []);
    useEffect(()=>{
        fileList.length > 0 ? handleUpload() : null
    },[fileList])
    const handleUpload =  async () => {
        if(selectedSchool){
            const body = new FormData();
            const file = fileList[0]
            body.append("school_id", selectedSchool?.id);
            body.append("file_v", file as unknown as Blob);
            body.append("name", "ads");
            if(!accountId){
                showErrorMessage("Выберите рекламный кабинет и попробуйте снова.")
                setFileList([])
                return
            }
            body.append("account_id", accountId);

            try{
                await unApi.uploadExcelSources(body)
                showSuccessMessage('Загрузка данных прошла успешно.')
                setFileList([])
                setMetaTableFetching({value:true})
                setRefetch((prev)=>!prev)
                setVisible(false)
            }catch(err:any){
                setFileList([])
                showErrorMessage(err.response.data.message)
            }
        }
    };

    const props: UploadProps = {
        beforeUpload: (file) => {
            const isXlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const isCsv = file.type === "text/csv";
            if (!isXlsx && !isCsv) {
                message.error("Вы можете загружать лишь XLSX или CSV файлы");
                return false;
            }
            setFileList([...fileList, file]);
            return false;
        },
        fileList
    };

    return (
        <Modal onCancel={()=>setVisible(false)} footer={null} open={visible} title={"Импорт из Excel"}>
            <Loading loading={isCabsLoading}>
            <Form layout={"vertical"}>
                <Space direction="vertical" style={{width: '100%', justifyContent: 'center'}}>
                    <Form.Item
                        name="cabinet"
                    >
                        <p style={{textAlign:"center"}}>Название кабинета</p>
                        <Select onChange={(value)=>{setAccountId(value)}}>
                            { cabinetsData?.length > 0 && cabinetsData.map(cab=>(
                            <Select.Option key={cab.id} value={cab.id} title={cab?.ad_account}>
                                {cab?.ad_account}
                            </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={{ display:"flex",justifyContent:'center' }}
                        name="name"
                    >
                        <p style={{textAlign:"center"}}>Загрузка файла</p>
                        <Upload showUploadList={false} {...props}>
                            <Button disabled={fileList.length > 0} icon={<UploadOutlined />}>Выбрать файл</Button>
                        </Upload>
                    </Form.Item>
                </Space>
            </Form>
            </Loading>
        </Modal>
    )
}
