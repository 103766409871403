
import React, {FC, useEffect, useState} from "react";
import {Button, Checkbox, Input, Modal, Select } from "antd";
import { useActions } from "../../../../shared/common/hooks/useActions";
import {unApi} from "../../../../shared/common/api/endpoints/reports/unApi";
import {useTypedSelector} from "../../../../shared/common/hooks/useTypedSelector";
import {showErrorMessage, showSuccessMessage} from "../../../../helpers/utils/ui";
import Filter from "./Filter";
import usePermission from "../../../../shared/common/hooks/usePermission";
type Props = {
    visible: boolean;
    setVisible:any;
}
const actionOptionsStatic = [
    // { id: 'conversions', name: 'Отправляем конверсию' },
    { id: 'profit', name: 'Отправляем доходы' },
];
const goalOptions = [
    { value: 'old', label: 'В созданную цель' },
    { value: 'new', label: 'Создать новую цель' },
];
const conditionsOptionsStatic = [
    { value:'reg',name:'Регистрации',id:0, },
    { value:'order',name:'Заказы',id:1, },
    { value:'payment',name:'Платежи',id:2, },
    { value:'webinar',name:'Посещения вебинара',id:3, },
    { value:'page',name:'Посещения страницы',id:4, },
]
interface Goal {
    name: string;
    value: string;
}

const CreateTaskModal = ({ visible,setVisible }: Props) => {
    const [modalState, setModalState] = useState({
        name: "",
        description: "",
        whatWeCount: "",
        sum: undefined,
        counter_id:null,
        conditionType: "equal",
        conditionValue: null,
        goalName:"",
        goalTitle:"",
        whatSend:0
    })
    const filtersMap: Record<string | number, string[]>  = {
        0: [ "Пользователи", "Регистрации", "Шаги воронки", "Заказы", "Платежи", "Вебинары", "Реклама"],
        1:["Пользователи","Регистрации"],
        2:["Пользователи","Заказы"],
        3:["Пользователи","Заказы", "Платежи"],
        4:["Пользователи","Вебинары"],
        5:["Пользователи","Посещения страницы"],
        6:["Пользователи","Посещения страницы"],
        "":[ "Пользователи", "Регистрации", "Шаги воронки", "Заказы", "Платежи", "Вебинары", "Реклама"]
    }
    const getFilters = () => {
        if(modalState.whatWeCount === "Отправляем доходы"){
            return [ "Пользователи", "Заказы"]
        }else{
            return filtersMap[modalState.whatSend]
        }
    }

    const { setFilters } = useActions()
    const { filters } = useTypedSelector((state) => state.filter)
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const [ counters,setCounters ] = useState([])
    const [ actionOptions,setActionOptions ] = useState(actionOptionsStatic)
    const [ conditionsOptions,setConditionsOptions ] = useState(conditionsOptionsStatic)
    const [ goals,setGoals ] = useState<Goal[]>([])
    const [ converseValueTransfer,setConverseValueTransfer ] = useState(false)
    const getGoals = async (counter_id:number) => {
        try {
            if(counter_id !== null){
                const { data } =  await unApi.getGoals(counter_id)
                setGoals(data)
            }
        }catch(err){
            console.log(err)
        }
    }

    const handleInputChange = (e:any) => {
        const { name, value } = e.target;
        if(name === "name" && value.length >= 200){
            showErrorMessage("Название задачи имеет лимит 200 символов.")
            setModalState(prevState => ({ ...prevState, [name]: value.substring(0,200) }));
            return
        }
        setModalState(prevState => ({ ...prevState, [name]: value }));
    };
    const handleSelectChange = (value:string) => {
        setModalState(prevState => ({ ...prevState, whatWeCount: value,goalName:"", whatSend:0 }));
        setFilters([])
    }
    const [maxWidth, setMaxWidth] = useState(200)
    useEffect(() => {
        if (goals.length > 0) {
            const maxItemWidth = Math.max(
                ...goals.map((el) => el.name.length * 8)
            );
            setMaxWidth(maxItemWidth);
        }
    }, [goals]);
    const ConversationsUi = (
        <div>

        <div style={{ marginTop:"20px",display:"flex",columnGap:"26px" }}>
            <div style={{display:"flex",flexDirection:"column",rowGap:"6px"}}>
                <div style={{ fontWeight:"600" }}>Выберите название цели</div>
                <Select value={modalState.goalName} onChange={(value) =>  { setModalState(prevState => ({ ...prevState, goalName: value,goalTitle:"",whatSend:0 })),setFilters([])}} placeholder="Выберите название цели" style={{ width: 200, marginRight: 16 }}>
                    {
                        goalOptions.map((el,index)=>{
                            return (
                                <Select.Option
                                    key={index}
                                    value={el.value}
                                    label={el.label}
                                >
                                    {el.label}
                                </Select.Option>
                            )
                        })
                    }
                </Select>
            </div>
            { modalState.goalName === "old" && <div style={{display:"flex",flexDirection:"column",rowGap:"6px"}}>
                <div style={{ fontWeight:"600" }}>Созданные цели</div>
                <Select value={modalState.goalTitle}  onChange={value =>  setModalState(prevState => ({ ...prevState, goalTitle: value }))} placeholder="Выбор цели"  style={{ minWidth: maxWidth, marginRight: 16}} dropdownMatchSelectWidth={true} >
                    {
                        goals.length > 0 && goals.map((el,index)=>{
                            return (
                                <Select.Option
                                    key={index}
                                    value={el.name}
                                    label={el.name}
                                >
                                    {el.name}
                                </Select.Option>
                            )
                        })
                    }
                </Select>
            </div>}
            { modalState.goalName === "new" && <div style={{display:"flex",flexDirection:"column",rowGap:"6px"}}>
                <div style={{ fontWeight:"600" }}>Название новой цели</div>
                <Input value={modalState.goalTitle} style={{ width:"145%" }} onChange={(e)=>{setModalState((prev)=>({...prev,goalTitle:e.target.value}))}} />
            </div> }
        </div>
            <div style={{display:"flex",flexDirection:"column",rowGap:"6px",marginTop:"20px"}}>
                <div style={{ fontWeight:"600" }}>Что отправляем?</div>
                <Select value={modalState.whatSend === 0 ? "" as unknown as number : modalState.whatSend} onChange={(value:number) =>  { setModalState(prevState => ({ ...prevState, whatSend: value })),setFilters([])}} placeholder="Выберите условие" style={{ width: 200, marginRight: 16 }}>
                    {
                        conditionsOptions.map((el,index)=>{
                            return (
                                <Select.Option
                                    key={index}
                                    value={el.id}
                                    label={el.name}
                                >
                                    {el.name}
                                </Select.Option>
                            )
                        })
                    }
                </Select>
            </div>
            { (modalState.whatSend === 2 || modalState.whatSend === 3) && (
                <Checkbox value={converseValueTransfer} onChange={(event)=>{setConverseValueTransfer(event.target.checked)}} style={{ marginTop: "16px" }}>
                    Передавать сумму { modalState.whatSend === 2 ? "заказа" : "платежа"} в доход цели
                </Checkbox>
            )}
        </div>
    )
    useEffect(()=>{
       const fetchCounters = async () => {
           try{
               const { data } = await unApi.getYandexMetrics(Number(selectedSchool?.id))
               if(data.length > 0){
                   setModalState((prev)=>({...prev,counter_id:data[0].counter_name}))
                   getGoals(data[0].counter_id)
               }
               setCounters(data)
           }catch(err){
               showErrorMessage("Ошибка при получении счётчиков.")
           }

       }
       const fetchActionOptions = async () => {
           try{
               const { data } = await unApi.getTaskTypes()
               if(Array.isArray(data) && data.length > 0){
                  setActionOptions(data)
               }
           }catch(err){
               showErrorMessage("Ошибка при получении действий.")
           }
       }
       const fetchConditionOptions = async () => {
           try{
               const { data } = await unApi.getTaskObjects()
               setConditionsOptions(data)
           }catch(err){
               showErrorMessage("Ошибка при обьектов задач.")
           }
       }

        fetchCounters()
        fetchActionOptions()
        fetchConditionOptions()
    },[])

    const handleSubmit = async () => {
        if(  modalState.name.length === 0 || modalState.whatWeCount.length === 0 || (modalState.whatWeCount !== "Отправляем доходы" && modalState.goalName.length === 0) || counters.length === 0 || (modalState.whatWeCount === "Отправляем конверсии" && (modalState.goalTitle.length === 0 || modalState.whatSend === 0))){
            showErrorMessage("Заполните все поля.")
        }else{
        try{
            const goalValues = {
                counter_id:modalState.counter_id as unknown as number,
                gools_name:modalState.goalName
            }
            const values = {
                school_id:Number(selectedSchool?.id),
                counter_id:typeof modalState.counter_id === "number" ? modalState.counter_id : (counters.find((el)=> (el as {counter_name:string}).counter_name === modalState.counter_id) as unknown as {counter_id:number})?.counter_id,
                task_name:modalState.name,
                task_type_id:actionOptions.find((el)=>el.name === modalState.whatWeCount)?.id as unknown as number,
                task_object_id:modalState.whatWeCount === "Отправляем доходы" ? null : modalState.whatSend,
                converse_value_transfer:(modalState.whatSend === 3 || modalState.whatSend === 2) ? converseValueTransfer : null,
                target_label:modalState.goalTitle,
                target_flags:modalState.goalName !== "new",
                filter_json:JSON.stringify(filters)
            }

            //await unApi.addYandexGoal(goalValues)
            await unApi.addYandexTask(values)
            showSuccessMessage("Задача успешно создана.")
            setVisible(false)
            setFilters([])
        }catch(err){
            showErrorMessage("Произошла ошибка, повторите попытку позже.")
            console.log(err)
        }
        }
    }

    return (
        <Modal width={"55%"} title={"Создание задачи"} onOk={()=>{setFilters([]),setVisible(false)}} onCancel={()=>{setFilters([]),setVisible(false)}} open={visible}
               footer={[
                   <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                       <Button type={"primary"} onClick={()=>{handleSubmit()}} key="save">Создать задачу</Button>
                   </div>
               ]}
        >
            <div>
                <div style={{  width:"85% "}}>
                    <div style={{ fontWeight:"600",marginBottom:"10px" }}>Название задачи</div>
                    <Input
                        name="name"
                        value={modalState.name}
                        maxLength={201}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div>
                    <div style={{ marginTop:"20px",fontWeight:"600",marginBottom:"10px" }}>В какой счётчик отправляем?</div>
                    <Select value={modalState.counter_id} onChange={(val)=> setModalState(prevState => ({ ...prevState, counter_id: val,goalTitle:"" }))} placeholder="Выбор счётчиков" style={{ minWidth: 200, marginRight: 16 }}>
                        {
                            counters.length > 0 && counters.map((item:any, index:any) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={item.counter_id}
                                        label={item.counter_name}
                                    >
                                        {item.counter_name}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                <div style={{ display:"flex",columnGap:"14px",marginTop:"5px" }}>
                    <div>
                        <div style={{ fontWeight:"600",marginBottom:"10px",marginTop:"20px" }}>Что делаем?</div>
                        <Select
                            showSearch
                            placeholder="Выберите действие"
                            value={modalState.whatWeCount}
                            onChange={handleSelectChange}
                            style={{ minWidth: 196 }}
                            filterOption={(input, option) => {
                                const optionChildren = option?.children as unknown as string
                                return optionChildren.toLowerCase().includes(input.toLowerCase())
                            }
                            }
                        >
                            {actionOptions.length > 0 && actionOptions.map(option => (
                                <Select.Option key={option.name} value={option.name}>
                                    {option.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    { /* modalState.whatWeCount !== "conversions" && <div style={{ marginTop:"10px" }}>
                        <Select
                            style={{ minWidth: 135,marginTop:"12px" }}
                            value={modalState.sum}
                            onChange={(value) => handleInputChange({ target: { name: "sum", value: value } })}
                        >
                            {options.map(option => (
                                <Option key={option.value} value={option.value}>{option.label}</Option>
                            ))}
                        </Select>
                    </div> */}
                </div>
                {  modalState.whatWeCount === "Отправляем конверсии" && ConversationsUi }
                <div style={{marginTop:"10px"}}>
                     <Filter
                        notParams={true}
                        withSegment={false}
                        filtersFilter={getFilters()}
                        isCompare={false}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default CreateTaskModal
