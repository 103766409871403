import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const HitStat: FC = () => {

    const metrics = [
        { name: "id хита", dataKey: "hit_id" },
        { name: "Номер экрана", dataKey: "screen_number" },
        { name: "Клики", dataKey: "clicks_count" },
        { name: "Длительность в секундах", dataKey: "duration" },
        { name: "Доля длительности от общего времени", dataKey: "duration_part" },
    ]

    return (
        <MainLayout pageTitle="Статистика по экранам хита">
            <div className="page-wrapper">
                <PageHead
                    title="Статистика по экранам хита"
                />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "Сессии", "Хиты" ]}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default HitStat
