import { useRef } from 'react'

type UseDidComponentUpdate = () => [boolean, () => void];
export const useDidComponentUpdate:UseDidComponentUpdate = () => {
    const didUpdate = useRef<boolean>(false)
    function handleUpdate(){
        didUpdate.current = true
    }
    return [ didUpdate.current,handleUpdate ]
}
