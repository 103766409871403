import React, { FC } from "react"

import { Button, Typography } from "antd"

import { profileApi } from "../../shared/common/api/endpoints/profileApi"
import { showErrorMessage } from "../../helpers/utils/ui"
import {endImpersonation} from "../../shared/common/components/header/Header";
import MainLayout from "../../shared/common/components/layouts/MainLayout";
const { Title } = Typography
const TelegramConnect: FC = () => {
    const handleClick = () => {

        profileApi
            .getConnectTelegramLink()
            .then((response) => {
                if (response.status === 200) {
                    window.location.href = response.data.telegram_url
                }
            })
            .catch(() => {
                showErrorMessage(
                    "Ошибка при получении ссылки. Повторите попытку позже."
                )
            })
    }

    return (
        <MainLayout pageTitle="Привязка Telegram">
    <div className="global-loading-container" style={{ display:"flex",flexDirection:"column" }}>
        <Title level={2}>Привяжите Telegram для завершения регистрации</Title>
        <div>
            <Button
                type="primary"
                onClick={handleClick}
            >
                Привязать Telegram
            </Button>
            {localStorage.getItem("access_admin") && <Button style={{marginLeft:"20px"}} type={"primary"} onClick={()=>endImpersonation()}>Выйти из пользователя</Button>}
        </div>
    </div>
        </MainLayout>
    )
}

export default TelegramConnect
