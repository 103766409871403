import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export default function Loading({ loading, children, hasTip = true }) {
    return (
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip={hasTip ? "Загрузка..." : ""}>
            {children}
        </Spin>
    )
}
