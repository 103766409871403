import _ from "lodash"

export const disableScroll = () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

    window.onscroll = function() {
        window.scrollTo(scrollLeft, scrollTop)
    }
}
export const formatStrings = (arr) => {
    return arr.map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' | ');
};
export const enableScroll = () => {
    window.onscroll = function() {}
}
export const getColorTitle = (series,title) => {
    const chartColors = [ "#2E93fA", "#35be6a", "#546E7A", "#E91E63", "#FF9800", "#1979C9", "#D62A0D", "#FAA219" ]
    const titles = title.split(' | ');
    let colors = []
    for(const t of titles){
        const index = series.findIndex(el => el && el.name && el.name.includes(t.replace(/\.{3,}$/, '')))
        if (index !== -1) {
            let adjustedIndex = index;

            while (adjustedIndex >= chartColors.length) {
                adjustedIndex -= chartColors.length;
            }

            colors.push(chartColors[adjustedIndex]);
        }
    }
    return colors
}
export const getTitleYaxis = (chartGroups,axis) => {
    const string = Object.values(chartGroups).flatMap((group) => {
        const cleanedSeriesName = axis.seriesName.replace(/\([^)]+\)/g, "").trim();
        if (group.includes(cleanedSeriesName)) {
            return formatStrings(group.filter((seriesName) => seriesName !== axis.seriesName))
        }
        return [];
    }).join('')
    if (string.length >= 52) {
        return string.substring(0, 49) + '...';
    }
    return string;
}
export  const findOffsetY = (length) => {
    if(length === 11){
        return 114
    }
    if(length === 13){
        return 102
    }
    if(length === 26) {
        return 58
    }
    if(length === 12){
        return 110
    }
    if(length === 30){
        return 44
    }
    if(length === 6){
        return 113
    }
    if(length <= 7){
        return 110
    }

    if(length < 10){
        return 130
    }
    if(length > 14 && length < 17){
        return 85
    }

    if(length < 20){
        return 90
    }

    if(length <=24){
        return 65
    }
    if(length > 40){
        return 24
    }
    if(length >= 38){
        return 20
    }
    if(length > 30){
        return 30
    }
    if(length >= 26){
        return 52
    }
}
export  const findOffsetX = (largestY) => {
    const length = `${largestY}`.length
    if(length >= 7) {
        return -74
    }
    if(length >= 6){
        return -64
    }
    if(length >= 5){
        return -58
    }
    return -50
}
export const getSeriesAndLabels = (tableData) => {
    let data = tableData.data
    let _series = []
    let _labels = []

    Object.entries(data[0]).forEach(([ key ]) => {
        const isCohort =  location.pathname === '/users/cohort' ? true : true
        if (key !== "dates" && key !== "Всего" && key !== "pathStr" && key !== "children" && key !== "clientData" && key !== "pathStr2" && isCohort) {
            _labels.push(key)
        }
    })

    data.forEach((item) => {
        let _subSeries = []
        if (item.dates === "Всего") return

        Object.entries(item).forEach(([ key, val ]) => {
            if (key === "dates" || key === "Всего" || key === "pathStr2") return

            let _val = val === "" ? 0 : val
            _subSeries.push(_val)
        })

        const _newSeries = {
            name: item.dates,
            data: _subSeries
        }

        _series.push(_newSeries)
    })

    return {
        newSeries: _series,
        newLabels: _labels
    }
}

const numberWithSpaces = (x) => {
    let parts = x.toString().split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    return parts.join(".")
}

export const formatCogTableCell = (numberData, currency) => {
    if (numberData === 0) return `${currency} ${numberData}`
    if (!numberData || numberData === "") return ""
    if (typeof numberData !== "number") return numberData

    return `${currency} ${numberWithSpaces(numberData)}`
}

export const calcTableCellsFill = (_data) => {
    const _arrayMax = Math.max.apply(null, _data.filter(Boolean))
    const _arrayMin = Math.min.apply(null, _data.filter(Boolean))
    const _ind = (_arrayMax - _arrayMin) / 100
    let _newData = []

    _data.forEach((item) => {
        let i = (item - _arrayMin) / _ind - 100
        i = Math.abs(i)
        i = _.round(i)

        _newData.push({ value: item, opacity: 1 - (i * 0.01) })
    })

    return _newData
}
