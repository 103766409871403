import React, { FC } from "react";

import { Button, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { routeNames } from "../../../shared/common/constants/routes";
import { useActions } from "../../../shared/common/hooks/useActions";
import { useTypedSelector } from "../../../shared/common/hooks/useTypedSelector";
import { showFormErrors } from "../../../helpers/utils/ui";
import styles from "./Reset.module.scss";

const Reset: FC = () => {
  document.title = "Сброс пароля";

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { isLoading } = useTypedSelector((state) => state.auth);
  const { authResetPass } = useActions();

  /**
   * Обработчик сброса пароля.
   * @param values - объект адресом электронной почты
   */
  const onFinish = async (values: { email: string }) => {
    await authResetPass(
      values.email,
      () => {
        navigate("/login", { replace: true });
      },
      (errors) => {
        showFormErrors(form, errors);
      }
    );
  };

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginModal}>
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item>
            <div className={styles.logoWrapper}>
              <img alt="SCAN" src="/logo.jpg" />
            </div>
          </Form.Item>

          <Form.Item>
            <div className={styles.topInfo}>
              Введите свой адрес электронной почты. На него мы вышлем письмо с
              инструкцией для восстановления пароля.
            </div>
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Введите email.", type: "email" },
            ]}
          >
            <Input placeholder="Email" size="large" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className={styles.submitButton}
              loading={isLoading}
            >
              Сбросить пароль
            </Button>
          </Form.Item>

          <div className={styles.loginInfo}>
            Вспомнили пароль? <Link to={routeNames.login}>Войти</Link>. <br />
            Нет аккаунта? <Link to={routeNames.signup}>Зарегистрироваться</Link>
            .
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Reset;
