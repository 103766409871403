import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"

import { Button, Form, Input,Modal, Popconfirm, Select, TimePicker, TreeSelect } from "antd"
import moment from "moment"

import { findNodeByValue, isObjectEmpty } from "../../../../../../../../helpers/utils/functions"
import {  showErrorNotification } from "../../../../../../../../helpers/utils/ui"
import { tgApi } from "../../../../../../api/endpoints/tgApi"
import { SetTask } from "../../../../../../api/endpoints/types"
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector"

type Props = {
    report:{ visible: boolean; title: string;id:number}
    isVisible: boolean
    setVisible: Dispatch<SetStateAction<{ visible: boolean; title: string;id:number }>>
    setRefetch:(val: (prev:boolean) => boolean) => void;
}
type User = {
    value: number
    label: string
  }
const treeData = [
  {
    title: "Ежедневно",
    value: "0-0",
    key: "0-0",
  },
  {
    title: "Еженедельно",
    value: "0-1",
    key: "0-1",
    checkable: false,
    children: [
      {
        title: "Понедельник",
        value: "0-1-0",
        key: "0-1-0",
      },
      {
        title: "Вторник",
        value: "0-1-1",
        key: "0-1-1",
      },
      {
        title: "Среда",
        value: "0-1-2",
        key: "0-1-2",
      },
      {
        title: "Четверг",
        value: "0-1-3",
        key: "0-1-3",
      },
      {
        title: "Пятница",
        value: "0-1-4",
        key: "0-1-4",
      },
      {
        title: "Суббота",
        value: "0-1-5",
        key: "0-1-5",
      },
      {
        title: "Воскресенье",
        value: "0-1-6",
        key: "0-1-6",
      },
    ],
  },
  {
    title:"Ежемесячно",
    value:"0-2",
    key:"0-2",
    checkable: false,
    children: Array.from({ length: 31 }, (_, index) => ({
      title: `${index + 1} число`,
      value: `0-2-${index + 1}`,
      key: `0-2-${index + 1}`,
    })),
  }
]

const EditReportTG = ({ isVisible,setVisible,report,setRefetch }:Props) => {
  const user = useTypedSelector((state) => state.currentUser)
  const [ form ] = Form.useForm()
  const [ whenValue,setWhenValue ] = useState([ "0-0-0" ])
  const [ options, setOptions ] = useState<[] | User[]>([])
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const taskId = useRef<null | number>(null)
  const fetchData = async () => {
    const fetchedData = await tgApi.getTaskById(report.id)
    if(fetchedData){
      taskId.current = fetchedData.task_data[0].id
      const dateSendValues = JSON.parse(fetchedData.task_data[0].date_send)
      const dateWeekSendValues = JSON.parse(fetchedData.task_data[0].date_week_send)
      const selectedKeys = []

      if (!isObjectEmpty(dateSendValues)) {
        selectedKeys.push(...dateSendValues.date_send.map((value: number) => `0-2-${value}`))
      }

      if (!isObjectEmpty(dateWeekSendValues)) {
        selectedKeys.push(
          ...dateWeekSendValues.date_week_send.map((value: number) => `0-1-${value - 1}`)
        )
      }
      if(fetchedData.task_data[0].frequency_sending_id === 1){
        selectedKeys.push("0-0")
      }
      const send_to = fetchedData.users_data.map((user:{id:number,name:string})=>user.id)
      const fetchedTime = moment(fetchedData.task_data[0].time_send, "HH:mm")
      form.setFields([
        {
          name:"send_to",
          value:!rolesForSelect.includes(user.role) ? user.name : send_to
        },
        {
          name: "what_time",
          value: fetchedTime,
        },
        {   name:"when",
          value: selectedKeys
        }
      ])
    }

    setWhenValue(fetchedData)
  }

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await tgApi.getAvailableUsers()
      if (Array.isArray(users)) {
        const newUsers = users.map((user) => ({
          value: user.id,
          label: user.name,
        }))
        setOptions(newUsers)
      }
    }
    fetchUsers()
  }, [])
  useEffect(()=>{
    fetchData()
  },[])
  const handleSubmit = (values: any) => {
    const report_id = report.id
    const frequency_sending_id = Number(values.when[0].slice(2, 3)) + 1
    const date_week_send = values.when
      .filter((el: string) => el.includes("0-1"))
      ?.map((el: string) => {
        return Number(el.slice(4)) + 1
      })
    const date_send = values.when
      .filter((el: string) => el.includes("0-2"))
      ?.map((el: string) => {
        return Number(el.slice(4))
      })
    const data:{tasks_sending_id:number} | SetTask = {
      tasks_sending_id:taskId.current!,
      frequency_sending_id,
      date_week_send,
      users_id: !rolesForSelect.includes(user.role) ? [user.id] : values.send_to,
      report_id,
      date_send,
      time_send: values.what_time.format("HH:mm"),
    }

    const onSuccess = () => {
      setVisible({ visible: false, title: "",id:0 })
      setTimeout(()=>{
        setRefetch((prev)=>!prev)
      },1000)
    }
    tgApi.updateTask(data, onSuccess)
  }


  const { SHOW_PARENT } = TreeSelect

  const treeSelectRef = useRef(null);


  const handleSelect = (newSelectedValues: any) => {
    if (treeSelectRef.current) {
      (treeSelectRef.current as HTMLElement).blur()
    }
    const selectedTitles = newSelectedValues.map((value: any) => {
      const node = findNodeByValue(treeData, value)
      return node ? node.title : null
    })
    const everyWeek = "Еженедельно"
    const everyMonth = "Ежемесячно"
    const isLastElDay =
      newSelectedValues[newSelectedValues.length - 1].includes("0-1") ||
      newSelectedValues[newSelectedValues.length - 1].includes("0-2")
    const isDifferentFrequency = !newSelectedValues[
      newSelectedValues.length - 1
    ].includes(newSelectedValues[0].slice(0, 3))
    if (
      selectedTitles.includes(everyWeek) ||
      selectedTitles.includes(everyMonth)
    ) {
      showErrorNotification("Выберите конкретные дни")
      form.setFieldsValue({ when: [ "0-0" ] })
      setWhenValue([ "0-0" ])
      return
    }
    if (isDifferentFrequency) {
      form.setFieldsValue({
        when: [ newSelectedValues[newSelectedValues.length - 1] ],
      })
      return
    }

    if (isLastElDay) {
      form.setFieldsValue({
        when: newSelectedValues.filter(
          (el: string) => el.includes("0-1") || el.includes("0-2")
        ),
      })
      return
    } else if (
      newSelectedValues[newSelectedValues.length - 1].includes("0-0")
    ) {
      form.setFieldsValue({ when: [ "0-0" ] })
      setWhenValue([ "0-0" ])
      return
    }

    setWhenValue(selectedTitles)
  }
  const handleTaskDelete = () => {
    const onSuccess = () => {
      setVisible({ visible: false, title: "",id:0 })
      setTimeout(()=>{
        setRefetch((prev)=>!prev)
      },1000)
    }
    tgApi.deleteTask(taskId.current as number, onSuccess)
  }
  const handleDropdownChange = (visible:boolean) => {
    setDropdownVisible(visible);
  };

  const handleSelectChange = () => {
    setDropdownVisible(false);
  };
  const tProps = {
    treeData,
    value: [ "0-0" ],
    onChange: handleSelect,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Выберите когда отправлять",
    style: {
      width: "100%",
    },
    ref:treeSelectRef
  }
  const rolesForSelect = [ "superadmin", "admin","user:settings" ]
  return (
    <Modal
      title={`Отправка отчёта (${report.title}) в Telegram`}
      open={isVisible}
      onOk={() => form.submit()}
      onCancel={() => {
        form.resetFields()
        setVisible({ visible: false, title: "",id:0 })
      }}
      okText="Обновить задачу"
      cancelText="Отмена"
      footer={[
        <div>
          <Button key="cancel" onClick={() => {
            form.resetFields()
            setVisible({ visible: false, title: "",id:0 })
          }}>
                    Отмена
          </Button>
          <Popconfirm
            title="Подтвердите удаление"
            onConfirm={handleTaskDelete}
            okText="Удалить"
            cancelText="Отмена"
          >
            <Button key="delete" danger>
                    Удалить задачу
            </Button>
          </Popconfirm>
          <Button key="submit" type="primary" onClick={() => form.submit()}>
                    Обновить задачу
          </Button>
        </div>
      ]}
      destroyOnClose>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          name="send_to"
          label="Кому отправлять?"
          rules={[ { required: true } ]}
        >
          {rolesForSelect.includes(user.role) ? (
              <Select
                  mode="multiple"
                  size="middle"
                  placeholder="Выберите кому отправлять"
                  style={{ width: "100%" }}
                  options={options}
                  open={dropdownVisible}
                  onDropdownVisibleChange={handleDropdownChange}
                  onChange={handleSelectChange}
              />
          ) : (
            <Input defaultValue={user.name || "Себе"} disabled />
          )}
        </Form.Item>
        <Form.Item name="when" label="Когда?" rules={[ { required: true } ]}>
          <TreeSelect {...tProps} />
        </Form.Item>
        <Form.Item name="what_time" label="Во сколько?">
          <TimePicker format={"HH:mm"} style={{ width: "150px" }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default EditReportTG
