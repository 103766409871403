import React, { FC } from "react"

import { WarningOutlined } from "@ant-design/icons"
import { Empty } from "antd"

const OverlayTable: FC<any> = ({ headerHeight, empty, error }) => {
    if (error) {
        return (
            <div className="table-overlay">
                <Empty
                    image={<WarningOutlined style={{ fontSize: 90, color: "#ababab" }} />}
                    description="Ошибка при получении данных"
                    style={{position:"absolute",top:"140px"}}
                />
            </div>
        )
    } else if (empty) {
        return (
            <div className="table-overlay" style={{ marginTop: headerHeight }}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет данных, соответствующих условиям отчёта" />
            </div>
        )
    }

    return null
}

export default OverlayTable
