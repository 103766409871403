import React, { useState, useEffect } from "react";
import { Select } from "antd";

const { Option } = Select;

interface Option {
    value: number;
    label: string;
}

interface AutoWidthSelectProps {
    options: Option[];
    value: number;
    onChange: (value: number) => void;
    placeholder?: string;
}

const AutoWidthSelect: React.FC<AutoWidthSelectProps> = ({
                                                             options,
                                                             value,
                                                             onChange,
                                                             placeholder = "",
                                                         }) => {
    const [maxWidth, setMaxWidth] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        const span = document.createElement("span");
        document.body.appendChild(span);
        span.style.visibility = "hidden";
        span.style.whiteSpace = "nowrap";

        let max = 0;
        options.forEach((item) => {
            span.innerText = item.label;
            max = Math.max(max, span.offsetWidth);
        });

        document.body.removeChild(span);
        setMaxWidth(max);
    }, [options]);

    return (
        <Select
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            style={{ ...(dropdownOpen && {width: maxWidth + 40}) }}
            onDropdownVisibleChange={(open) => setDropdownOpen(open)}
        >
            {options.map((option) => (
                <Option key={option.value} value={option.value}>
                    {option.label}
                </Option>
            ))}
        </Select>
    );
};

export default AutoWidthSelect;
