import { adsSpendSliceActions } from "./adsSpendSlice"
import { adsSpendApi } from "../../../../../../shared/common/api/endpoints/adsSpendApi"
import { handleResponseError } from "../../../../../../shared/common/api/middleware"
import { TAppDispatch } from "../../../store"

export const adsSpendActions = {
    ...adsSpendSliceActions,

    adsSpendGetData:
    (schoolId: number | string) => async (dispatch: TAppDispatch) => {
        dispatch(adsSpendActions.setAdsSpendLoading(true))

        adsSpendApi
            .fetchAdsSpendList(schoolId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(adsSpendActions.setAdsSpendData(response.data))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(adsSpendActions.setAdsSpendLoading(false))
            })
    },

    adsSpendGetCompanyList:
    (data:{school_id:number,account_id: number}) => async (dispatch: TAppDispatch) => {
        dispatch(adsSpendActions.setAdsCompanyList([]))
        dispatch(adsSpendActions.setAdsCompanyGrList([]))
        dispatch(adsSpendActions.setAdsCompanyAdList([]))

        adsSpendApi
            .fetchAdsSpendCompList(data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(adsSpendActions.setAdsCompanyList(response.data))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
    },

    adsSpendGetGroupList:
    (schoolId: number, companyId: number) => async (dispatch: TAppDispatch) => {
        dispatch(adsSpendActions.setAdsCompanyGrList([]))
        dispatch(adsSpendActions.setAdsCompanyAdList([]))

        adsSpendApi
            .fetchAdsSpendGrList(schoolId, companyId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(adsSpendActions.setAdsCompanyGrList(response.data))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
    },

    adsSpendGetAdsList:
    (schoolId: number, companyId: number, groupId: number) =>
        async (dispatch: TAppDispatch) => {
            dispatch(adsSpendActions.setAdsCompanyAdList([]))

            adsSpendApi
                .fetchAdsSpendAdList(schoolId, companyId, groupId)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(adsSpendActions.setAdsCompanyAdList(response.data))
                    }
                })
                .catch((err) => {
                    handleResponseError(dispatch, err)
                })
        },

    adsSpendAddSpend:
    (
        schoolId: number,
        data: any,
        // onFinish: () => void,
        // onFormError: (errors: { [key: string]: string }) => void
    ) =>
        async (dispatch: TAppDispatch) => {
            dispatch(adsSpendActions.setAdsSpendLoading(true))

            adsSpendApi
                .createAdsSpend(schoolId, data)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(adsSpendActions.setAdsSpendUpdate(true))
                        // onFinish();
                    }
                })
                .catch((err) => {
                    if (err.response?.status === 400) {
                        // onFormError(err.response.data);
                    }

                    handleResponseError(dispatch, err)

                    dispatch(adsSpendActions.setAdsSpendLoading(false))
                })
        },

    adsSpendGetEditData:
    (schoolId: string, spendId: string, date: string) =>
        async (dispatch: TAppDispatch) => {
            dispatch(adsSpendActions.setAdsSpendLoading(true))

            adsSpendApi
                .fetchUpdateData(schoolId, spendId, date)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(adsSpendActions.setAdsSpendUpdateData({...response.data,ads_id:spendId}))
                    }
                })
                .catch((err) => {
                    handleResponseError(dispatch, err)
                })
                .finally(() => {
                    dispatch(adsSpendActions.setAdsSpendLoading(false))
                })
        },

    // adsSpendEditSpend:
    // (
    //     schoolId: number,
    //     spendId: number,
    //     data: any,
    //     onFinish: () => void,
    //     onFormError: (errors: { [key: string]: string }) => void
    // ) =>
    //     async (dispatch: TAppDispatch) => {
    //         dispatch(adsSpendActions.setAdsSpendLoading(true))
    //
    //         adsSpendApi
    //             .updateAdsSpend(schoolId, spendId, data)
    //             .then((response) => {
    //                 if (response.status === 200) {
    //                     dispatch(adsSpendActions.setAdsSpendUpdate(true))
    //                     onFinish()
    //                 }
    //             })
    //             .catch((err) => {
    //                 if (err.response?.status === 400) {
    //                     onFormError(err.response.data)
    //                 }
    //
    //                 handleResponseError(dispatch, err)
    //
    //                 dispatch(adsSpendActions.setAdsSpendLoading(false))
    //             })
    // },

    adsSpendDeleteSpend:
    (schoolId: number, spendId: number, date: string) =>
        async (dispatch: TAppDispatch) => {
            dispatch(adsSpendActions.setAdsSpendLoading(true))

            adsSpendApi
                .deleteAdsSpend(schoolId, spendId, date)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(adsSpendActions.setAdsSpendUpdate(true))
                    }
                })
                .catch((err) => {
                    handleResponseError(dispatch, err)
                    dispatch(adsSpendActions.setAdsSpendLoading(false))
                })
        },
}
