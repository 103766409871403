import {Input, Popover} from "antd";
import React, {FC, useEffect, useState} from "react";
import { SketchPicker } from 'react-color'
type ColorPreviewProps = {
    color: string;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};
const ColorPreview: FC<ColorPreviewProps> = ({ color, onClick }) => {
    return (
        <div
            onClick={onClick}
            style={{
                width: 20,
                height: 20,
                borderRadius: 4,
                border: "1px solid #d9d9d9",
                background: color,
            }}
        />
    )
}
const InputWithColorPreview = ({value="#000000",onChange=()=>{}}:any) => {
    const [ color,setColor ] = useState(value)
    const handleChange = (e:any) => {
        setColor(typeof e.target.value === "string" ? e.target.value : "#000000")
        onChange(typeof e.target.value === "string" ? e.target.value : "#000000")
    }
    useEffect(()=>{
        setColor(value)
    },[value])
    return (
        <div style={{width:"130px"}}>
            <Popover
                content={
                    <SketchPicker
                        className="colorPicker"
                        color={color}
                        onChangeComplete={ (color) => {setColor(color.hex), onChange(color.hex)}}
                    />
                }
                trigger="click"
                placement="right"
            >
                <div>
                    <Input
                        placeholder={""}
                        value={color}
                        className={"inputWithBorderRadius"}
                        maxLength={8}
                        onChange={handleChange}
                        addonAfter={<ColorPreview color={color} />}
                    />
                </div>
            </Popover>
        </div>
    )
}
export default  InputWithColorPreview
