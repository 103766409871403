import React, {FC, useEffect, useMemo, useState} from "react"

import { DownOutlined, CloseOutlined, EllipsisOutlined, ClearOutlined } from "@ant-design/icons"
import { Tree, Input, Modal, Row, Col, Button } from "antd"
import { ReactSortable } from "react-sortablejs"

import { getParentKey, loopSearch } from "../../../../../../../helpers/utils/reports"
import {getLineHeightForModalSettings} from "../../../../../../../helpers/utils/functions";

const ModalSettings: FC<any> = ({ title, dataItems, listKeys, defSelected, defChecked, actionSet, isDisable }) => {
    const [ isModalVisible, setIsModalVisible ] = useState(false)
    const [ selected, setSelected ] = useState<any>([])
    const [ checkedKeys, setCheckedKeys ] = useState([])
    const [ expandedKeys, setExpandedKeys ] = useState([])
    const [ searchValue, setSearchValue ] = useState("")
    const [ autoExpandParent, setAutoExpandParent ] = useState(true)

    useEffect(() => {
        setSelected(defSelected)
    }, [ defSelected ])

    useEffect(() => {
        setCheckedKeys(defChecked)
    }, [ defChecked ])
    useEffect(()=>{
        if (title === "Метрики") {
            checkedKeys.length !== selected.length && setCheckedKeys(()=>selected.map((el:{key:string})=>el.key))
        }
    },[checkedKeys])

    const removeSelect = (key: string) => {
        setSelected((prev: any) => {
            return [ ...prev ].filter((item) => item.key !== key)
        })

        setCheckedKeys((prev) => {
            return [ ...prev ].filter((item) => item !== key)
        })
    }

    const onExpand = (expandedKeys: any) => {
        setExpandedKeys(expandedKeys)
        setAutoExpandParent(false)
    }

    const onCheck = (checkedKeys: any, e: any) => {
        setCheckedKeys(checkedKeys)
        setSelected((prev: any) => {
            let newData: any = [ ...prev ]
            if (e.checked) {
                const { key, name, dataKey } = e.node
                newData.push({ key, name, dataKey })
            } else {
                newData = newData.filter((item: any) => item.key !== e.node.key)
            }
            return newData
        })
    }

    const onChange = (e: any) => {
        const { value } = e.target
        let expandedKeys = []
        if (value !== "") {
            const reg = new RegExp(value, "i")
            expandedKeys = listKeys
                .map((item: any) => {
                    if (item.label.search(reg) > -1) {
                        return getParentKey(item.key, dataItems)
                    }
                    return null
                })
                .filter((item: any, i: number, self: any) => item && self.indexOf(item) === i)
        }
        setExpandedKeys(expandedKeys)
        setSearchValue(value)
        setAutoExpandParent(true)
    }
    const handleOk = () => {
        actionSet(selected)
        setIsModalVisible(false)
    }
    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const clearCheckboxes = () => {
        setSelected([])
        setCheckedKeys([])
    }

    const listHeight = useMemo(
        ()=>getLineHeightForModalSettings(selected?.length || 0),
        [selected?.length])

    return (
        <>
            <Button
                type="default"
                disabled={isDisable}
                onClick={() => setIsModalVisible(true)}
                style={{ marginLeft: 8 }}
            >
                {title}
            </Button>

            <Modal
                title={title}
                visible={isModalVisible}
                onOk={handleOk}
                okText="Применить"
                onCancel={handleCancel}
                cancelText="Отмена"
                centered
                width={800}
                className="modal-settings"
            >
                <Row gutter={12}>
                    <Col span={10}>
                        <Input
                            style={{ marginBottom: 8 }}
                            placeholder={"Поиск " + title.toLowerCase()}
                            onChange={onChange}
                            allowClear
                        />
                        <div style={{ height: listHeight, overflow: "auto" }}>
                            <Tree.DirectoryTree
                                onExpand={onExpand}
                                expandedKeys={expandedKeys}
                                autoExpandParent={autoExpandParent}
                                onCheck={onCheck}
                                checkedKeys={checkedKeys}
                                blockNode
                                checkable
                                switcherIcon={<DownOutlined />}
                                showIcon={false}
                                selectable={false}
                                treeData={loopSearch(dataItems, searchValue)}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Button
                                type="default"
                                icon={<ClearOutlined />}
                                onClick={() => clearCheckboxes()}
                            >
                                Очистить выбор
                            </Button>
                        </div>
                    </Col>
                    <Col span={14}>
                        <ReactSortable
                            list={selected.map((item: any) => ({ ...item, chosen: true }))}
                            className="modalSortable__container"
                            setList={setSelected}
                            animation={200}
                            delayOnTouchStart={true}
                            delay={2}
                        >
                            {selected.map((item: any) => (
                                <div className="drag-item" key={item.key}>
                                    <span className="drag-handler">
                                        <EllipsisOutlined rotate={90} />
                                        <span style={{ backgroundColor: item?.background ? item.background : "unset",width:"100%"}} className="drag-label">{item.name}</span>
                                    </span>
                                    <div onClick={() => removeSelect(item.key)} className="drag-remove">
                                        <CloseOutlined />
                                    </div>
                                </div>
                            ))}
                        </ReactSortable>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default ModalSettings
