import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IWebinar, IWebinarState } from "./types"

const initialState: IWebinarState = {
    webinarData: [],
    isLoading: false,
    isUpdate: false
}

export const webinarSlice = createSlice({
    name: "webinar",
    initialState: initialState,
    reducers: {
        webinarSetData: (state, action: PayloadAction<IWebinar[]>) => {
            state.webinarData = action.payload
        },
        webinarSetLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        webinarSetUpdate: (state, action: PayloadAction<boolean>) => {
            state.isUpdate = action.payload
        },
    }
})

export const webinarSliceActions = webinarSlice.actions
export const webinarSliceReducer = webinarSlice.reducer
