import { reportsSliceActions } from "./reportsSlice";
import { showSuccessMessage } from "../../../../../../../helpers/utils/ui";
import { unApi } from "../../../../../../../shared/common/api/endpoints/reports/unApi";
import { handleResponseError } from "../../../../../../../shared/common/api/middleware";
import { TAppDispatch } from "../../../../store";
import { tableSliceActions } from "../../table/tableSlice";

export const reportsActions = {
  ...reportsSliceActions,

  getReports: (schoolId: string | number) => async (dispatch: TAppDispatch) => {
    dispatch(reportsActions.setReportsLoading(true));
    let currentLocation = location.pathname;
    unApi
      .getReports(schoolId, currentLocation)
      .then((res) => {
        if (location.pathname !== currentLocation) {
          return;
        }
        dispatch(reportsActions.setReports(res.data));
      })
      .catch((err) => {
        console.log(err);
        handleResponseError(dispatch, err);
      })
      .finally(() => {
        dispatch(reportsActions.setReportsLoading(false));
      });
  },
  getSavedDashboards:
    (schoolId: string | number) => async (dispatch: TAppDispatch) => {
      dispatch(reportsActions.setReportsLoading(true));
      let currentLocation = location.pathname;
      unApi
        .getDashboards(schoolId, currentLocation)
        .then((res) => {
          if (location.pathname !== currentLocation) {
            return;
          }
          dispatch(reportsActions.setSavedDashboards(res.data));
        })
        .catch((err) => {
          console.log(err);
          handleResponseError(dispatch, err);
        })
        .finally(() => {
          dispatch(reportsActions.setReportsLoading(false));
        });
    },

  postReport:
    (schoolId: string | number, { values }: any) =>
    async (dispatch: TAppDispatch) => {
      dispatch(reportsActions.setReportsLoading(true));

      try {
        await unApi.createReport(schoolId, values, location.pathname);
        const isDashboard = location.pathname.includes("dashboards");
        if (isDashboard) {
          const response = await unApi.getDashboards(
            schoolId,
            location.pathname
          );
          const currentDashboardResponse = await unApi.getCurrentDashboard(
            response.data[response.data.length - 1]?.id
          );
          dispatch(reportsActions.setSavedDashboards(response.data));
          dispatch(
            reportsActions.setCurrentDashboard(currentDashboardResponse.data)
          );
          showSuccessMessage("Дашборд сохранен");
        } else {
          const response = await unApi.getReports(schoolId, location.pathname);
          dispatch(reportsActions.setReports(response.data));
          dispatch(
            reportsActions.setCurrentReport(
              response.data[response.data.length - 1]
            )
          );
          showSuccessMessage("Отчёт сохранен");
        }
      } catch (err: any) {
        handleResponseError(dispatch, err);
      } finally {
        dispatch(reportsActions.setReportsLoading(false));
      }
    },
  getCreatedMetrics:
    (schoolId: number | string, pathname = location.pathname) =>
    async (dispatch: TAppDispatch) => {
      unApi
        .getCustomMetrics(schoolId, pathname)
        .then((res) => {
          dispatch(tableSliceActions.setCreatedMetrics(res.data));
        })
        .catch((err) => {
          handleResponseError(dispatch, err);
        });
    },
  putReport:
    (schoolId: string | number, { id, values }: any, currentReport?: any) =>
    async (dispatch: TAppDispatch) => {
      dispatch(reportsActions.setReportsLoading(true));
      try {
        const isDashboard = location.pathname.includes("dashboards");
        if (isDashboard) {
          await unApi.editReport(schoolId, id, values, location.pathname);
          const response = await unApi.getDashboards(
            schoolId,
            location.pathname
          );
          dispatch(reportsActions.setSavedDashboards(response.data));
          const editedReport = response.data.find(
            (report: any) => report.id === id
          );
          if (editedReport.id === currentReport.id) {
            const { data } = await unApi.getCurrentDashboard(id);
            dispatch(reportsActions.setCurrentDashboard(data));
          }
          showSuccessMessage("Отчет пересохранен");
        } else {
          await unApi.editReport(schoolId, id, values, location.pathname);
          const reportsResponse = await unApi.getReports(
            schoolId,
            location.pathname
          );
          dispatch(reportsActions.setReports(reportsResponse.data));
          const editedReport = reportsResponse.data.find(
            (report: any) => report.id === id
          );
          if (editedReport.id === currentReport.id) {
            dispatch(reportsActions.setCurrentReport(editedReport));
          }
          showSuccessMessage("Отчет пересохранен");
        }
      } catch (err: any) {
        handleResponseError(dispatch, err);
      } finally {
        dispatch(reportsActions.setReportsLoading(false));
      }
    },
  getCurrentDashboard: (id: string) => async (dispatch: TAppDispatch) => {
    dispatch(reportsActions.setReportsLoading(true));
    try {
      const { data } = await unApi.getCurrentDashboard(id);
      dispatch(reportsActions.setCurrentDashboard(data));
    } catch (err: any) {
      handleResponseError(dispatch, err);
    } finally {
      dispatch(reportsActions.setReportsLoading(false));
    }
  },
  deleteReport:
    (schoolId: string | number, { id }: any) =>
    async (dispatch: TAppDispatch) => {
      dispatch(reportsActions.setReportsLoading(true));

      try {
        await unApi.deleteReport(schoolId, id, location.pathname);
        const isDashboard = location.pathname.includes("dashboards");

        if (isDashboard) {
          const dashboardsResponse = await unApi.getDashboards(
            schoolId,
            location.pathname
          );
          dispatch(reportsActions.setSavedDashboards(dashboardsResponse.data));
          showSuccessMessage("Дашборд удален");
        } else {
          const reportsResponse = await unApi.getReports(
            schoolId,
            location.pathname
          );
          dispatch(reportsActions.setReports(reportsResponse.data));
          showSuccessMessage("Отчет удален");
        }

        window.location.reload();
      } catch (err: any) {
        handleResponseError(dispatch, err);
      } finally {
        dispatch(reportsActions.setReportsLoading(false));
      }
    },
};
