import {Radio} from "antd";
import React, {useState} from "react";


interface Props {
    mode:number;
    setMode:(param:number)=>void;
}
const ActiveSelect = ({mode,setMode}:Props) => {
    const plainOptions = [
        { label: "Активные", value: 1 },
        { label: "Неактивные", value: 2 },
    ]
return (
    <>
            <Radio.Group
                options={plainOptions}
                onChange={(e) => setMode(e.target.value)}
                value={mode}
                optionType="button"
                buttonStyle="solid"
            />
    </>
)
}
export default ActiveSelect
