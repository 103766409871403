import { domainSliceActions } from "./domainSlice"
import { domainApi } from "../../../../../shared/common/api/endpoints/domainApi"
import { handleResponseError } from "../../../../../shared/common/api/middleware"
import { TAppDispatch } from "../../store"

export const domainActions = {
    ...domainSliceActions,

    domainFetchData: (schoolId: string) => async (dispatch: TAppDispatch) => {
        dispatch(domainActions.domainSetLoading(true))

        domainApi.fetchData(schoolId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(domainActions.domainSetData(response.data))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(domainActions.domainSetLoading(false))
            })
    },
    utmFetchData: (schoolId: string) => async (dispatch: TAppDispatch) => {
        dispatch(domainActions.domainSetLoading(true))

        domainApi.fetchUtmData(schoolId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(domainActions.utmSetData(response.data))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(domainActions.domainSetLoading(false))
            })
    },


    domainChangeActive: (value: boolean, schoolId: number, domain: string) => async (dispatch: TAppDispatch) => {
        dispatch(domainActions.domainSetLoading(true))

        domainApi.changeActive(value, schoolId, domain)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(domainActions.domainSetUpdate(true))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
                dispatch(domainActions.domainSetLoading(false))
            })
    },

    domainAdd: (schoolId: number, domain: string, onFinish: () => void, onFormError: (errors: {[key: string]: string}) => void) => async (dispatch: TAppDispatch) => {
        dispatch(domainActions.domainSetLoading(true))

        domainApi.addDomain(schoolId, domain)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(domainActions.domainSetUpdate(true))
                    onFinish()
                }
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    onFormError(err.response.data)
                }

                handleResponseError(dispatch, err)
                dispatch(domainActions.domainSetLoading(false))
            })
    },

    domainDelete: (schoolId: number, domain: string) => async (dispatch: TAppDispatch) => {
        dispatch(domainActions.domainSetLoading(true))

        domainApi.deleteDomain(schoolId, domain)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(domainActions.domainSetUpdate(true))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
                dispatch(domainActions.domainSetLoading(false))
            })
    },

    domainGetValidateStatus: (schoolId: number | string) => async (dispatch: TAppDispatch) => {
        dispatch(domainActions.domainSetLoading(true))

        domainApi.getValidateStatus(schoolId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(domainActions.domainSetValidateStatus(response.data))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(domainActions.domainSetLoading(false))
            })
    },

    domainUpdateValidateStatus: (schoolId: number, value: boolean) => async (dispatch: TAppDispatch) => {
        dispatch(domainActions.domainSetLoading(true))

        domainApi.setValidateStatus(schoolId, value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(domainActions.domainSetUpdate(true))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
                dispatch(domainActions.domainSetLoading(false))
            })
    },
    utmUpdateStatus: (schoolId: number, value: boolean) => async (dispatch: TAppDispatch) => {
        dispatch(domainActions.domainSetLoading(true))
        domainApi.setUtmStatus(schoolId, value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(domainActions.domainSetUpdate(true))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
                dispatch(domainActions.domainSetLoading(false))
            })
    },
}
