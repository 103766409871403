import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth } from "../../middleware"
import {
    API_UN_REPORT_DELETE_FUNNELS,
    API_UN_REPORT_DELETE_SEGMENTS, API_UN_REPORT_GET_FUNNELS,
    API_UN_REPORT_GET_SEGMENTS, API_UN_REPORT_SET_FUNNELS,
    API_UN_REPORT_SET_SEGMENTS, API_UN_REPORT_UPDATE_FUNNELS,
    API_UN_REPORT_UPDATE_SEGMENTS
} from "../../urls"

export const segmentsApi = {
    getSegments: async (schoolId: string | number,pathName:string): Promise<AxiosResponse> => {
        return axios.get(`${API_UN_REPORT_GET_SEGMENTS}/${schoolId}`, axiosConfigAuth({ Pathname:pathName }))
    },
    getFunnels: async (schoolId: string | number,pathName:string): Promise<AxiosResponse> => {
        return axios.get(`${API_UN_REPORT_GET_FUNNELS}/${schoolId}`, axiosConfigAuth({ Pathname:pathName }))
    },
    createSegment: async (schoolId: string | number, values: any,pathName:string): Promise<AxiosResponse> => {
        const data = {
            school_id: schoolId,
            values: values
        }

        return axios.post(API_UN_REPORT_SET_SEGMENTS, data, axiosConfigAuth({ Pathname:pathName }))
    },
    createFunnel: async (schoolId: string | number, values: any,pathName:string): Promise<AxiosResponse> => {
        const data = {
            school_id: schoolId,
            values: values
        }

        return axios.post(API_UN_REPORT_SET_FUNNELS, data, axiosConfigAuth({ Pathname:pathName }))
    },

    updateSegment: async (schoolId: string | number, id: string, values: any,pathName:string): Promise<AxiosResponse> => {
        const data = {
            school_id: schoolId,
            id: id,
            values: values
        }

        return axios.post(API_UN_REPORT_UPDATE_SEGMENTS, data, axiosConfigAuth({ Pathname:pathName }))
    },
    updateFunnel: async (schoolId: string | number, id: string, values: any,pathName:string): Promise<AxiosResponse> => {
        const data = {
            school_id: schoolId,
            id: id,
            values: values
        }

        return axios.post(API_UN_REPORT_UPDATE_FUNNELS, data, axiosConfigAuth({ Pathname:pathName }))
    },

    deleteSegment: async (id: string,pathName:string): Promise<AxiosResponse> => {
        return axios.get(`${API_UN_REPORT_DELETE_SEGMENTS}/${id}`, axiosConfigAuth({Pathname:pathName}))
    },
    deleteFunnel: async (id: string,pathName:string): Promise<AxiosResponse> => {
        return axios.get(`${API_UN_REPORT_DELETE_FUNNELS}/${id}`, axiosConfigAuth({Pathname:pathName}))
    }
}
