import axios, { AxiosResponse } from "axios";

import { axiosConfigAuth } from "../middleware";
import { API_WEBINAR_GET_DATA, API_WEBINAR_UPDATE_TITLE } from "../urls";

export const webinarApi = {
  fetchWebinarList: async (
    schoolId: string | number
  ): Promise<AxiosResponse> => {
    return axios.get(`${API_WEBINAR_GET_DATA}/${schoolId}`, axiosConfigAuth());
  },

  updateWebinarTitle: async (
    schoolId: number,
    webinarId: number,
    title: string
  ): Promise<AxiosResponse> => {
    const data = {
      school_id: schoolId,
      webinar_id: webinarId,
      webinar_name: title,
    };

    return axios.post(API_WEBINAR_UPDATE_TITLE, data, axiosConfigAuth());
  },
};
