import React, { Dispatch, FC, SetStateAction } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Form, Modal, Select, Spin } from "antd"

import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"
import { showFormErrors } from "../../../../helpers/utils/ui"
import axios from "axios";
import {axiosConfigAuth} from "../../../../shared/common/api/middleware";
import {API_ADS_UPDATE_UTM_LABELS} from "../../../../shared/common/api/urls";
import {useActions} from "../../../../shared/common/hooks/useActions";

interface IAddModalProps {
    setState: Dispatch<SetStateAction<{
        visible: boolean;
        id: number;
        fields: {};
    }>>
    state: {
        visible: boolean;
        id: number;
        fields: {[key: string]: string[] | null};
    }
}

const EditUtm: FC<IAddModalProps> = ({ setState, state }) => {
    const [ form ] = Form.useForm()

    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const { setMetaTableFetching } = useActions()

    const saveStaff = async (values: any) => {
        if (selectedSchool) {
            const parsedData = {
                ...values,
                utm_source: values.utm_source || [],
                utm_medium: values.utm_medium || [],
                utm_campaign: values.utm_campaign || [],
                utm_term: values.utm_term || [],
                utm_content: values.utm_content || [],
                utm_group: values.utm_group || []
            };

            try {
                 await axios.post(API_ADS_UPDATE_UTM_LABELS, {
                    id: state.id,
                    school_id: parseInt(selectedSchool.id),
                    ...parsedData,
                },axiosConfigAuth());

                setMetaTableFetching({value:true})
                setState({visible:false,fields:{},id:0});
            } catch (errors:any) {
                console.error('Error:', errors);
                showFormErrors(form, errors);
            }
        }
    };

    return (
        <Modal
            title="Редактирование UTM меток"
            open={true}
            onOk={() => form.submit()}
            onCancel={() => setState({visible:false,id:0,fields:{}})}
            okText="Сохранить"
            cancelText="Отмена"
            destroyOnClose
        >
            <Spin
                spinning={false}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            >
                <Form
                    form={form}
                    onFinish={saveStaff}
                    layout="vertical"
                    initialValues={{
                        utm_source: state.fields.utm_source || [],
                        utm_medium: state.fields.utm_medium || [],
                        utm_campaign: state.fields.utm_campaign || [],
                        utm_term: state.fields.utm_term || [],
                        utm_content: state.fields.utm_content || [],
                        utm_group: state.fields.utm_group || []
                    }}
                    requiredMark={false}
                >

                            <Form.Item
                                label="Метки utm_source"
                                name="utm_source"
                            >
                                <Select
                                    mode="tags"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Метки utm_medium"
                                name="utm_medium"
                            >
                                <Select
                                    mode="tags"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Метки utm_campaign"
                                name="utm_campaign"
                            >
                                <Select
                                    mode="tags"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Метки utm_term"
                                name="utm_term"
                            >
                                <Select
                                    mode="tags"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Метки utm_content"
                                name="utm_content"
                            >
                                <Select
                                    mode="tags"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label="Метки utm_group"
                                name="utm_group"
                            >
                                <Select
                                    mode="tags"
                                    allowClear
                                />
                            </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}

export default EditUtm
