import React from "react"
import MainLayout from "../../shared/common/components/layouts/MainLayout"
import PageHead from "../../shared/common/components/page-header/PageHeader"
import { routeNames } from "../../shared/common/constants/routes"
import { useTypedSelector } from "../../shared/common/hooks/useTypedSelector"

const AccessEnded = () => {
    const currentSchool = useTypedSelector((state) => state.currentSchool)

    try{
        if(Number(currentSchool?.userAccess?.days) > 0){
            window.location.href = routeNames.home
        }
    }catch(err){
        console.log(err)
    }

    return (
    <MainLayout pageTitle="">
      <div className="page-wrapper">
        <PageHead title="Доступ закончился, обратитесь к администратору." />
      </div>
    </MainLayout>
    )
}

export default AccessEnded
