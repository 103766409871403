import React, { FC, useEffect, useState } from "react"

import { Button, DatePicker, Space, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import moment from "moment"

import { showErrorMessage } from "../../../../../../helpers/utils/ui"
import { billApi } from "../../../../api/endpoints/billApi"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"

const WithdrawsTab: FC<{currency: string}> = ({ currency }) => {

    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)

    const [ data, setData ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [ filterDate, setFilterDate ] = useState<any>(undefined)

    const fetchData = () => {
        setIsLoading(true)

        billApi.getTransactionsData(parseInt(selectedSchool?.id || "0"))
            .then((response) => {
                if (response.status === 200) {
                    setData(response.data)
                }
            })
            .catch(() => {
                showErrorMessage("Ошибка при получении данных.")
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleReset = (clearFilters: () => void) => {
        clearFilters()
        setFilterDate(undefined)
    }

    const columns: ColumnsType<any> = [
        {
            title: "Дата",
            dataIndex: "operation_date",
            key: "operation_date",
            width: 200,
            filterDropdown: ({
                setSelectedKeys,
                confirm,
                clearFilters
            }) => (
                <div style={{ padding: 8 }}>
                    <Space direction="vertical">
                        {/* //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              @ts-ignore*/}
                        <DatePicker.RangePicker
                            value={filterDate}
                            onChange={(e) => {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                setSelectedKeys([ e || [] ])
                                setFilterDate(e)
                            }}
                            allowClear={true}
                            style={{ width: 250 }}
                        />
                        <Space>
                            <Button
                                onClick={() => clearFilters && handleReset(clearFilters)}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Сбросить
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                    confirm({ closeDropdown: true })
                                }}
                            >
                                Применить
                            </Button>
                        </Space>
                    </Space>
                </div>
            ),
            onFilter: (value, record) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const startDate = value[0].format("YYYY-MM-DD") || ""
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const endDate = value[1].format("YYYY-MM-DD") || ""
                const date = moment(record.operation_date)

                return date.isBetween(startDate, endDate, undefined, "[]")
            },
            sorter: (a, b) => moment(a.operation_date).unix() - moment(b.operation_date).unix()
        },
        {
            title: "Название",
            dataIndex: "operation_name",
            key: "operation_name",
            filters: [
                {
                    text: "Доступ оплачен",
                    value: "Доступ оплачен",
                },
                {
                    text: "Льготный доступ",
                    value: "Льготный доступ",
                },
                {
                    text: "Пополнение основного счёта",
                    value: "Пополнение основного счёта",
                },
                {
                    text: "Пополнение бонусного счёта",
                    value: "Пополнение бонусного счёта",
                },
                {
                    text: "Тестовый доступ к сервису",
                    value: "Тестовый доступ к сервису",
                },
                {
                    text: "Доступ ограничен",
                    value: "Доступ ограничен",
                },
            ],
            onFilter: (value, record) => record.operation_name.indexOf(value) === 0,
            // sorter: (a, b) => a.operation_name.length - b.operation_name.length,
        },
        {
            title: "Пополнение",
            dataIndex: "refill",
            key: "refill",
            render: (value) => {
                return value ? `${value} ${currency}` : ""
            }
        },
        {
            title: "Списание",
            dataIndex: "write_off",
            key: "write_off",
            render: (value) => {
                return value ? `${value} ${currency}` : ""
            }
        },
        {
            title: "Основной счет",
            dataIndex: "main_balance",
            key: "main_balance",
            render: (value) => {
                return value ? `${value} ${currency}` : ""
            }
        },
        {
            title: "Бонусный счет",
            dataIndex: "bonus_balance",
            key: "bonus_balance",
            render: (value) => {
                return `${value} ${currency}`
            }
        },
        {
            title: "Общий счет",
            dataIndex: "overall_balance",
            key: "overall_balance",
            render: (value) => {
                return value ? `${value} ${currency}` : ""
            }
        },
        {
            title: "Детали",
            dataIndex: "details",
            key: "details",
        },
    ]

    return (
        <div>
            <Table
                columns={columns}
                dataSource={data}
                loading={isLoading}
            />
        </div>
    )
}

export default WithdrawsTab
