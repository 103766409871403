import React, { useEffect, useState } from "react"

import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { Button, Modal, Table } from "antd"

import {Column, getUniqueEmailsForFilters, parseDate} from "../../../../../helpers/utils/functions"
import { showErrorMessage, showSuccessMessage } from "../../../../../helpers/utils/ui"
import { unApi } from "../../../../../shared/common/api/endpoints/reports/unApi"
import EditAccurals from "../modals/EditAccurals"
import CopyButton from "../ui/CopyButton";
import {FilterDropdownProps} from "antd/lib/table/interface";
import CustomFilter from "../ui/CustomFilter";

const Accruals = () => {
    const [ tableData,setTableData ] = useState([])
    const [ fourceUpdate,setForceUpdate ] = useState(false)
    const [ accuralsData,setAccuralsData ] = useState({
        visible:false,
        initialData: {
            calculation_id:0,
            commission_amount:0,
            comment:""
        }
    })
    const fetchTableData = async () => {
        await   unApi.getTableAccurals().then((res)=>{
            setTableData(res.data.rows)
        })
    }
    const handleDeleteAccurals = (id: number) => {
        Modal.confirm({
            title: "Вы уверены что хотите удалить Начисления?",
            icon: <ExclamationCircleOutlined />,
            okText: "Да",
            okType: "danger",
            cancelText: "Нет",
            onOk() {
                try{
                    unApi.deleteTableAccurals(id).then(()=>{
                        showSuccessMessage("Успешно удалено")
                        setForceUpdate((prev)=>!prev)
                    })
                }catch(err){
                    showErrorMessage("Произошла ошибка , повторите попытку позже.")
                }

            },
        })
    }
    const handleEditAccurals = (data:{calculations_id:number,commission_amount:number,comment:string}) => {
        setAccuralsData({
            visible:true,
            initialData: {
                calculation_id: data.calculations_id,
                commission_amount: data.commission_amount,
                comment: data.comment
            }
        })
    }
    useEffect(()=>{
        fetchTableData()
    },[ fourceUpdate,accuralsData ])


    const columns:Column[] = [
        {
            title: "Дата платежа",
            dataIndex: "payment_date",
            key: "payment_date",
            sorter: (a:{payment_date:string}, b:{payment_date:string}) => new Date(a.payment_date).getTime() - new Date(b.payment_date).getTime(),
            render: (text: string) => {
                const date = new Date(text)
                const formattedDate = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`
                return <span>{formattedDate}</span>
            }
        },
        {
            title: "id платежа",
            dataIndex: "payment_id",
            key: "payment_id",
        },
        {
            title: "Номер счета",
            dataIndex: "calculations_id",
            key: "calculations_id",
        },
        {
            title: "Сумма платежа",
            dataIndex: "payment_sum",
            key: "payment_sum",
        },
        {
            title: "Дата регистрации партнёра",
            dataIndex: "partner_reg_date",
            key: "partner_reg_date",
            sorter: (a:{partner_reg_date:string}, b:{partner_reg_date:string}) => new Date(a.partner_reg_date).getTime() - new Date(b.partner_reg_date).getTime(),
            render: (text: string) => {
                const date = new Date(text)
                const formattedDate = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`
                return <span>{formattedDate}</span>
            }
        },
        {
            title: "id партнёра",
            dataIndex: "partner_id",
            key: "partner_id",
        },
        {
            title: "Имя партнёра",
            dataIndex: "partner_name",
            key: "partner_name",
            render: (text) => {
                if (/<a/i.test(text)) {
                    return <span dangerouslySetInnerHTML={{ __html: text }} />
                }
                return text
            },
        },
        {
            title: "Email партнёра",
            dataIndex: "partner_email",
            key: "partner_email",
            filterDropdown: (props:FilterDropdownProps )=>(
                <CustomFilter {...props} options={getUniqueEmailsForFilters("partner_email", tableData)} title={"Поиск по Email"} />
            ),
            onFilter: (value, record) => {
                const partnerEmail = record.partner_email.toLowerCase();
                return partnerEmail.includes(value.toLowerCase());
            }
        },
        {
            title: "Дата регистрации реферала",
            dataIndex: "referal_reg_date",
            key: "referal_reg_date",
            sorter: (a: { referal_reg_date: string }, b: { referal_reg_date: string }) => {
                const dateA = parseDate(a.referal_reg_date)
                const dateB = parseDate(b.referal_reg_date)
                return dateA.getTime() - dateB.getTime()
            },
            render: (text: string) => {
                const date = parseDate(text)
                const formattedDate = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`
                return <span>{formattedDate}</span>
            }
        },
        {
            title: "id реферала",
            dataIndex: "referal_id",
            key: "referal_id",
        },
        {
            title: "Имя реферала",
            dataIndex: "referal_name",
            key: "referal_name",
        },
        {
            title: "Email реферала",
            dataIndex: "referal_email",
            key: "referal_email",
            filterDropdown: (props:FilterDropdownProps )=>(
                <CustomFilter {...props} options={getUniqueEmailsForFilters("referal_email", tableData)} title={"Поиск по Email"} />
            ),
            onFilter: (value, record) => {
                const referal_email = record.referal_email.toLowerCase();
                return referal_email.includes(value.toLowerCase());
            }
        },
        {
            title: "Уровень реферала",
            dataIndex: "level_id",
            key: "level_id",
        },
        {
            title: "Привлёк покупателей",
            dataIndex: "attracted_buyers",
            key: "attracted_buyers",
        },
        {
            title: "% комиссии",
            dataIndex: "commission_percent",
            key: "commission_percent",
        },
        {
            title: "Сумма начислений",
            dataIndex: "commission_amount",
            key: "commission_amount",
        },
        {
            title: "Комментарий",
            dataIndex: "comment",
            key: "comment",
        },
        {
            title:"Действия",
            dataIndex:"actions",
            key:"actions",
            render: (value, record) => (
                   <div style={{ display:"flex",columnGap:"4px" }}>
                       <Button title={"Редактирование начисления"} icon={<EditOutlined />} onClick={()=>handleEditAccurals({ calculations_id:record.calculations_id,commission_amount:record.commission_amount,comment:record.comment })} />
                       <Button title={"Удаление начисления"}  icon={<DeleteOutlined />} danger onClick={()=>handleDeleteAccurals(record.calculations_id)} />
                   </div>
            ),
        }
    ]

    return (
    <>
        <CopyButton columns={columns} tableData={tableData} styles={{marginBottom:"10px"}} />
        <div style={{ overflow:"auto" }}>
            <Table size={"small"} columns={columns} dataSource={tableData} />
        </div>
        {accuralsData.visible && <EditAccurals visible={accuralsData.visible} setVisible={setAccuralsData} initialData={accuralsData.initialData} />}
    </>
)
}
export default Accruals
