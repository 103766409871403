import React from "react"

import {CheckboxOptionType, Radio} from "antd"

import {useNavigate} from "react-router-dom";
import {useActions} from "../../../../../shared/common/hooks/useActions";


interface Props {
    pageMode:number;
    setPageMode:(params:number)=>void
    options:(string | number | CheckboxOptionType)[],
}
const TabSelect = ({ pageMode,setPageMode,options }:Props) => {
    const { setFilters } = useActions()
    const navigate = useNavigate()
    const handleTabChange = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.set('tab', key);
        if(2 === Number(key) && url.searchParams.has("sub")){
            url.searchParams.delete("sub")
        }
        setFilters([])
        navigate(url.pathname + url.search)
    }


    return (
        <>
            <Radio.Group
                options={options}
                onChange={(e) => {
                    handleTabChange(e.target.value)
                    setPageMode(e.target.value)
                }}
                value={pageMode}
                optionType="button"
                buttonStyle="solid"
            />
        </>
    )
}
export default TabSelect
