import { currentSchoolSliceActions } from "./currentSchoolSlice";
import { ISchoolFormData } from "./types";
import { schoolApi } from "../../../../../shared/common/api/endpoints/schoolApi";
import { handleResponseError } from "../../../../../shared/common/api/middleware";
import { showSuccessNotification } from "../../../../../helpers/utils/ui";
import { TAppDispatch } from "../../store";
import { appActions } from "../app/actions";
import { metaActions } from "../reports/params/meta/actions";

export const currentSchoolActions = {
  ...currentSchoolSliceActions,

  currentSchoolFetchDetails: (id: string) => async (dispatch: TAppDispatch) => {
    dispatch(currentSchoolActions.currentSchoolSetLoading(true));

    schoolApi
      .fetchCurrentSchoolData(id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(currentSchoolActions.currentSchoolSetDetails(response.data));
          location.pathname === '/users/billing'? dispatch(metaActions.setMetaCurrency("₽")) : dispatch(metaActions.setMetaCurrency(response.data.currency.symbol));
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err, {
          "404": "Запрашиваемое действие не найдено.",
        });
      })
      .finally(() => {
        dispatch(currentSchoolActions.currentSchoolSetLoading(false));
      });
  },

  addNewSchool:
    (
      values: ISchoolFormData,
      onFinish: () => void,
      onFormError: (errors: { [key: string]: string }) => void
    ) =>
    async (dispatch: TAppDispatch) => {
      dispatch(currentSchoolActions.currentSchoolSetLoading(true));

      schoolApi
        .addSchool(values)
        .then((response) => {
          if (response.status === 200) {
            showSuccessNotification("Школа успешно добавлена.");
            onFinish();
          }
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            onFormError(err.response.data);
            return;
          }

          handleResponseError(dispatch, err, {
            "404": "Запрашиваемое действие не найдено.",
          });
        })
        .finally(() => {
          dispatch(currentSchoolActions.currentSchoolSetLoading(false));
        });
    },

  currentSchoolUpdate:
    (
      schoolId: string,
      values: ISchoolFormData,
      onFinish: () => void,
      onFormError: (errors: { [key: string]: string }) => void
    ) =>
    async (dispatch: TAppDispatch) => {
      dispatch(currentSchoolActions.currentSchoolSetLoading(true));

      schoolApi
        .updateSchool(schoolId, values)
        .then((response) => {
          if (response.status === 200) {
            showSuccessNotification("Данные успешно обновлены.");
            onFinish();
          }
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            onFormError(err.response.data);
            return;
          }

          handleResponseError(dispatch, err, {
            "404": "Запрашиваемое действие не найдено.",
          });
        })
        .finally(() => {
          dispatch(currentSchoolActions.currentSchoolSetLoading(false));
        });
    },

  currentSchoolDelete:
    (schoolId: string, onFinish: () => void) =>
    async (dispatch: TAppDispatch) => {
      dispatch(currentSchoolActions.currentSchoolSetLoading(true));

      schoolApi
        .deleteSchool(schoolId)
        .then((response) => {
          if (response.status === 200) {
            showSuccessNotification("Школа успешно удалена.");
            onFinish();
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err, {
            "404": "Запрашиваемое действие не найдено.",
          });
        })
        .finally(() => {
          dispatch(currentSchoolActions.currentSchoolSetLoading(false));
        });
    },

  currentSchoolChange:
    (schoolId: string, onFinish: () => void) =>
    async (dispatch: TAppDispatch) => {
      dispatch(appActions.setAppLoading(true));

      schoolApi
        .changeSchool(schoolId)
        .then((response) => {
          if (response.status === 200) {
            onFinish();
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err, {
            "404": "Запрашиваемое действие не найдено.",
          });
        })
        .finally(() => {
          dispatch(appActions.setAppLoading(true));
        });
    },
};
