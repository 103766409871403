import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const Hit: FC = () => {

    const metrics = [
        { name: "Дата и время хита", dataKey: "hit_timestamp" },
        { name: "URL страницы", dataKey: "url_value" },
        { name: "Название страницы", dataKey: "title_value" },
        { name: "Email", dataKey: "email" },
        { name: "account_id", dataKey: "account_id" },
        { name: "id хита", dataKey: "hit_id" },
        { name: "id сессии", dataKey: "session_id" },
        { name: "scan_id", dataKey: "scan_id" },
        { name: "Длительность нахождения на странице", dataKey: "duration" },
        { name: "% просмотра страницы", dataKey: "view_percent" },
        { name: "Клики", dataKey: "clicks_count" },
        { name: "Refer", dataKey: "refer_value" },
        { name: "UTM source хита", dataKey: "utm_source_value" },
        { name: "UTM medium хита", dataKey: "utm_medium_value" },
        { name: "UTM campaign хита", dataKey: "utm_campaign_value" },
        { name: "UTM group хита", dataKey: "utm_group_value" },
        { name: "UTM term хита", dataKey: "utm_term_value" },
        { name: "UTM content хита", dataKey: "utm_content_value" },
        { name: "Страна", dataKey: "country_name" },
        { name: "Регигон", dataKey: "region_name" },
        { name: "Город", dataKey: "city_name" },
        { name: "Часовой пояс", dataKey: "utc_name" },
        { name: "IP адрес", dataKey: "ip_address" },
        { name: "Тип устройства", dataKey: "type_value" },
        { name: "Модель устройства", dataKey: "device_value" },
        { name: "Операционная система", dataKey: "os_name_value" },
        { name: "Версия операционной системы", dataKey: "os_version_value" },
        { name: "Браузер", dataKey: "browser_name_value" },
        { name: "Версия браузера", dataKey: "browser_version_value" },
        { name: "Язык браузера", dataKey: "browser_language_value" },
        { name: "Кол-во экранов", dataKey: "screens_count" },
        { name: "Высота страницы px", dataKey: "page_height" },
        { name: "Ср. скорость просмотра сайта. px/сек.", dataKey: "view_speed" },
        { name: "Балл хита", dataKey: "hit_score" },
        { name: "Глубина просмотра", dataKey: "view_depth" },
        { name: "URL адрес хита (для отладки)", dataKey: "url_text" },
        { name: "Хеш (для отладки)", dataKey: "hash" },
        {name:"Скорость загрузки страницы",dataKey: "load_duration"},
        { name:"ClientID",dataKey: "ym_uid" }
    ]

    return (
        <MainLayout pageTitle="Хиты">
            <div className="page-wrapper">
                <PageHead
                    title="Хиты"
                />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "Сессии", "Хиты" ]}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default Hit
