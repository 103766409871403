import { FC } from "react";

import MainLayout from "../../shared/common/components/layouts/MainLayout";
import PageHead from "../../shared/common/components/page-header/PageHeader";
import BillingPage from "../../shared/common/components/payments/bill/BillingPage";
import {Alert} from "antd";
import {useTypedSelector} from "../../shared/common/hooks/useTypedSelector";

const Bill: FC = () => {
   const userAccess  = useTypedSelector((state)=>state.currentSchool?.userAccess)

  return (
    <MainLayout pageTitle="Биллинг">
      <div className="page-wrapper">
          { (userAccess && "status" in userAccess) && !userAccess && <Alert message="Доступ закрыт. Пополните баланс" type="error" style={{width:"30%",fontSize:"18px",marginLeft:"20px",textAlign:"center"}} /> }
        <PageHead title="Биллинг" />

        <div className="page-content">
          <BillingPage />
        </div>
      </div>
    </MainLayout>
  );
};

export default Bill;
