import React from "react"

import { WarningOutlined } from "@ant-design/icons"
import { Empty } from "antd"

export default function OverlayTable({ headerHeight, empty, error, errorMessage = "Ошибка при получении данных" }) {
    if (error) {
        return (
            <div className="table-overlay">
                <Empty
                    image={<WarningOutlined
                        style={{ fontSize: 90, color: "#ababab" }} />}
                    description={errorMessage}
                />
            </div>
        )
    } else if (empty) {
        return (
            <div className="table-overlay" style={{ marginTop: headerHeight }}>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Нет данных, соответствующих условиям отчёта"
                />
            </div>
        )
    }
    return null
}
