import React, { useEffect, useState } from "react"

import { Modal,Form,Input,Select,InputNumber } from "antd"

import { TariffAdd } from "../../../../../../../app/types/unApi"
import { showErrorMessage } from "../../../../../../../helpers/utils/ui"
import { unApi } from "../../../../../api/endpoints/reports/unApi"
interface Props {
    addTarifModalVisible:boolean,
    setAddTarifModalVisible: (param:boolean)=> void;
    loadRows: () =>void;
}

const AddTariff = ({ addTarifModalVisible , setAddTarifModalVisible,loadRows }:Props) => {
    const [ tariffForm ] = Form.useForm()
    const [ modalLoading, setModalLoading ] = useState(false)
    const tariffAddSubmit = async (values:TariffAdd) => {
        setModalLoading(true)
        try{
            const response = await unApi.addTariff(values)
            if(response.status === 200){
                setAddTarifModalVisible(false)
                loadRows()
                tariffForm.resetFields()
                Modal.info({
                    title:"Успех!",
                    content:"Тариф успешно добавлен."
                })
            }
        }catch(err){
            showErrorMessage("Ошибка при добавлении тарифа.")

        }finally {
            setModalLoading(false)

        }
    }

    return(
    <Modal
        title="Добавить тариф"
        open={addTarifModalVisible}
        onCancel={() => {
            setAddTarifModalVisible(false)
            tariffForm.resetFields()
        }}

        onOk={() => tariffForm.submit()}
        okButtonProps={{ loading: modalLoading }}
        cancelButtonProps={{ loading: modalLoading }}
        okText="Сохранить"
    >
        <Form
            layout="vertical"
            form={tariffForm}
            onFinish={tariffAddSubmit}
        >
            <Form.Item
                label="Название тарифа"
                name="tariff_name"
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="number_of_months"
                label="На сколько месяцев"
            >
                <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
                name="tariff_sum"
                label="Сумма"
            >
                <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
                name="tariff_currency"
                label="Валюта"
            >
                <Select>
                    <Select.Option value={1}>RUB</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="tariff_days"
                label="Количество дней"
            >
                <InputNumber style={{ width: "100%" }} />
            </Form.Item>
        </Form>
    </Modal>
    )
}
export default AddTariff
