import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAuthState } from "./types";
import { getItemFromStore, saveToStore } from "../../../../../helpers/utils/local-store";

const initialState: IAuthState = {
  isAuth: false,
  isLoading: false,
};

if (typeof window !== "undefined") {
  const localAuthState = getItemFromStore("app_auth_state");

  if (localAuthState) {
    const parsedLocalAuthState = JSON.parse(localAuthState);

    initialState.isAuth = parsedLocalAuthState.isAuth
      ? parsedLocalAuthState.isAuth
      : false;
  }
}

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;

      if (typeof window !== "undefined") {
        saveToStore("app_auth_state", JSON.stringify(state));
      }
    },
    setAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;

      if (typeof window !== "undefined") {
        saveToStore("app_auth_state", JSON.stringify(state));
      }
    },
    setAuthClear: (state) => {
      state.isAuth = initialState.isAuth;
      state.isLoading = initialState.isLoading;

      if (typeof window !== "undefined") {
        saveToStore("app_auth_state", JSON.stringify(state));
      }
    },
  },
});

export const authSliceActions = authSlice.actions;
export const authSliceReducer = authSlice.reducer;
