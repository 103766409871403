import Cookies from "js-cookie"

export function getParameterValueFromURL(parameterName: string): string | null {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(parameterName)
}

export const partnerCookies = (): void => {
    const frValue = getParameterValueFromURL("fr")

    if (frValue && !Cookies.get("_scan_fr")) {
        Cookies.set("_scan_fr", frValue, { expires: 365, domain: ".scananalytics.ru" })
    }
}
