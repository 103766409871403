import React, { useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { DatePicker, Form, InputNumber, Modal, Select } from "antd"
import TextArea from "antd/es/input/TextArea"
import axios from "axios"
import moment from "moment"

import { PaymentUpdate } from "../../../../../../../app/types/unApi"
import { showErrorMessage } from "../../../../../../../helpers/utils/ui"
import { unApi } from "../../../../../api/endpoints/reports/unApi"
const { RangePicker }:any = DatePicker
const EditPayment = ({ updatePaymentModalVisible, setUpdatePaymentModalVisible, id, loadRows }:any) => {
    const [ form ] = Form.useForm()
    const dateFormat = "YYYY-MM-DD HH:mm:ss"

    const [ formLoading, setFormLoading ] = useState(false)
    const [ paymentTypes, setPaymentTypes ] = useState([])
    const [ paymentAccounts, setPaymentAccounts ] = useState([])
    const [ formCurrency, setFormCurrency ] = useState(1)
    const [ currentAmount, setCurrentAmount ] = useState(0)
    const [ order_id,setOrderId ] = useState<number | null>(null)
    const fetchData = async () => {
        setFormLoading(true)
        try{
            const [ paymentInfo, types, check ] = await Promise.all([
                unApi.getPaymentInfo(id),
                unApi.getFilterList(0, "crm_payment_type", ""),
                unApi.getFilterList(0, "crm_payment_check", ""),
            ])
            form.resetFields()
            form.setFieldsValue({
                score_account:paymentInfo.data.score_account,
                payment_status:paymentInfo.data.payment_status,
                payment_type:paymentInfo.data.payment_type,
                comment_client:paymentInfo.data.comment_client || "",
                comment_manager:paymentInfo.data.comment_manager || "",
                commission:paymentInfo.data.commission,
                payment_price: parseInt(paymentInfo.data.payment_price),
                created_date: paymentInfo.data.created_date ? moment(paymentInfo.data.created_date, dateFormat) : undefined
            })
            setOrderId(paymentInfo.data.order_id)
            setCurrentAmount(parseInt(paymentInfo.data.payment_price))
            setFormCurrency(paymentInfo.data.payment_currency)
            setPaymentTypes(types.data)
            setPaymentAccounts(check.data)
        }catch(err){
            showErrorMessage("Произошла ошибка , повторите позже.")
        }finally{
            setFormLoading(false)
        }
    }

    useEffect(() => {
        if (updatePaymentModalVisible) {
            fetchData()
        }
    }, [ updatePaymentModalVisible ])

    const editPayment = async (values:PaymentUpdate) => {
        setFormLoading(true)
        try{
            const { created_date,payment_status, comment_client,comment_manager,score_account,commission,payment_type } = values
            const payment_id = Number(id)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const response = await unApi.updatePayment({ created_date: created_date.format(dateFormat),payment_id,payment_status,
                comment_client,comment_manager,score_account,commission,payment_type,payment_price:currentAmount
            })
            if(response.status === 200){
                setUpdatePaymentModalVisible(false)
                loadRows()
            }
        }catch(err){
            showErrorMessage("Произошла ошибка, повторите позже.")
        }finally {
            setFormLoading(false)
        }
    }

    return (
        <Modal
            title="Обновление платежа"
            open={updatePaymentModalVisible}
            onCancel={() => {setUpdatePaymentModalVisible(false)}}
            onOk={() => {form.submit()}}
            okButtonProps={{ loading: formLoading }}
            okText="Сохранить"
        >
            {
                formLoading ?
                    <div className="loading-container">
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                    </div>
                    :
                    <Form
                        form={form}
                        onFinish={editPayment}
                        labelCol={{ span: 11 }}
                        wrapperCol={{ span: 16 }}
                        requiredMark={false}
                    >
                        <Form.Item
                            name="created_date"
                            label="Дата создания платежа"
                            rules={[ { required: true } ]}
                        >
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */ }
                            <DatePicker
                                placeholder="Выберите дату"
                                format={dateFormat}
                                showTime={true}
                            />
                        </Form.Item>

                        <Form.Item
                            name="payment_status"
                            label="Статус платежа"
                        >
                            <Select>
                                <Select.Option value={1}>
                                    Получен
                                </Select.Option>
                                <Select.Option value={2}>
                                    Не учитывать
                                </Select.Option>
                                <Select.Option value={3}>
                                    Возвращен
                                </Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="payment_type"
                            label="Способ оплаты"
                        >
                            <Select loading={formLoading} disabled={formLoading}>
                                {
                                    paymentTypes.length > 0 ?
                                        paymentTypes.map((item:any, index:number) => {
                                            return (
                                                <Select.Option key={index} value={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        })
                                        :
                                        <Select.Option value="">Нет</Select.Option>
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="payment_price"
                            label="Сумма"
                            rules={[ { required: true } ]}
                        >
                            <InputNumber
                                min={0}
                                style={{ marginRight: 5 }}
                                value={currentAmount}
                                onChange={(value:any) => setCurrentAmount(value)}
                            /> {formCurrency}
                        </Form.Item>

                        <Form.Item
                            name="commission"
                            label="Комиссия"
                            rules={[ { required: true } ]}
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                            name="score_account"
                            label="Счет пополнения"
                        >
                            <Select loading={formLoading} disabled={formLoading}>
                                {
                                    paymentAccounts.length > 0 ?
                                        paymentAccounts.map((item:any, index:number) => {
                                            return (
                                                <Select.Option key={index} value={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        })
                                        :
                                        <Select.Option value="">Нет</Select.Option>
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="comment_client"
                            label="Комментарий для клиента"
                        >
                            <TextArea />
                        </Form.Item>
                        <Form.Item
                            name="comment_manager"
                            label="Комментарий для менеджера"
                        >
                            <TextArea />
                        </Form.Item>
                    </Form>
            }
        </Modal>
    )
}

export default EditPayment
