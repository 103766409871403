import { partnerSliceActions } from "./partnerSlice"
import { partnerApi } from "../../../../../shared/common/api/endpoints/partnerApi"
import { handleResponseError } from "../../../../../shared/common/api/middleware"
import { TAppDispatch } from "../../store"

export const partnerActions = {
    ...partnerSliceActions,
    accuralsFetchData: () => async (dispatch: TAppDispatch) => {
        partnerApi.getAccurals()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(partnerActions.setAccurals(response.data))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })

    },
    referalsFetchData:() => async(dispatch:TAppDispatch) => {
        partnerApi.getReferals()
            .then((response)=>{
                if(response.status === 200){
                    dispatch(partnerActions.setReferals(response.data))

                }
            })
            .catch((err)=>{
                handleResponseError(dispatch,err)
            })
    },
    payoutsFetchData: () => async(dispatch:TAppDispatch) => {
        partnerApi.getPayouts()
            .then((response)=>{
                if(response.status === 200){
                    dispatch(partnerActions.setPayouts(response.data))
                }
            })
            .catch((err)=>{
                handleResponseError(dispatch,err)
            })
    },
    statisticsFetchData: () => async(dispatch:TAppDispatch) => {
        partnerApi.getStatistics()
            .then((response)=>{
                if(response.status === 200) {
                    dispatch(partnerActions.setStatistics(response.data))
                }
            })
            .catch((err)=>{
                handleResponseError(dispatch,err)
            })
    }
}
