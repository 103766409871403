import { FC } from "react"

import {
    UserOutlined,

} from "@ant-design/icons"
import { Avatar, Button, Dropdown, Layout } from "antd"
import { Link, useNavigate } from "react-router-dom"

import styles from "./Header.module.scss"
import SchoolDropdowns from "./SchoolDropdowns"
import { routeNames } from "../../constants/routes"
import PermissionWrapper from "../../hoc/PermissionWrapper"
import { useActions } from "../../hooks/useActions"
import { useTypedSelector } from "../../hooks/useTypedSelector"
export const endImpersonation = () => {
    localStorage.setItem("access",localStorage.getItem("access_admin")!)
    localStorage.removeItem("access_admin")
    window.location.href = '/crm/users'
}
const Header: FC = () => {
    const navigate = useNavigate()

    const { authLogout } = useActions()
    const { name } = useTypedSelector((state) => state.currentUser)
    const { userAccess,selectedSchool } = useTypedSelector((state) => state.currentSchool)


    const userMenuItems = [
        {
            key: "1",
            label: (
                <Link to={routeNames.profile}>Профиль</Link>
            ),
        },
        {
            key: "2",
            label: (
                <span>Выход</span>),
            onClick: () =>
                authLogout(() => {
                    navigate(routeNames.login)
                }),
        },
        localStorage.getItem("access_admin") ? {
        key:"3",
            label:(
                <span>Выйти из пользователя</span>
            ),onClick:()=>endImpersonation()
        } : null
    ]

    return (
    <Layout.Header className={styles.headerContainer}>
      <div>
        <Link to="/" className={styles.logoWrapper}>
          <img alt="SCAN" src="/logo.jpg" />
        </Link>
      <SchoolDropdowns/>
      </div>

      <div>
        <div
            style={{
                marginRight: 25,
            }}
            className={styles.headerBill}
        >
          <Button
              type="primary"
              style={{
                  padding: "2px 10px",
                  marginRight: 12,
              }}
          >
            <a href="https://t.me/analytics_scan" target="_blank">
              Написать в поддержку
            </a>
          </Button>
            { selectedSchool && <PermissionWrapper
                allowedRoles={[ "admin", "user:settings" ]}
                redirectToErrPage={false}
                returnedElement={<div />}
            >
            <Button
                type="primary"
                style={{
                    padding: "2px 10px",
                    backgroundColor: "white",
                    color: "black",
                    borderColor: "black",
                }}
            >
              <Link to={routeNames.bill}>Биллинг</Link>
            </Button>
          </PermissionWrapper>}
        </div>

          {selectedSchool && <PermissionWrapper
              allowedRoles={[ "admin", "user:settings" ]}
              redirectToErrPage={false}
              returnedElement={<div />}
          >
          <Link className={styles.licenceInfo} to={routeNames.bill}>
            <p>Доступ до {userAccess?.date || "-"}</p>
            <p>Осталось {userAccess?.days || "0"} дней</p>
          </Link>
        </PermissionWrapper>}

        <Dropdown
            menu = {{ items:userMenuItems }}
            placement="bottom"
            trigger={[ "click" ]}
        >
          <div className={styles.userInfo}>
            <Avatar size="default" icon={<UserOutlined />} />
            <span>{name || "Пользователь"}</span>
          </div>
        </Dropdown>
      </div>
    </Layout.Header>
    )
}

export default Header
