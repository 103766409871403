import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAppState } from "./types";

const initialState: IAppState = {
  isGlobalLoading: true,
  isGlobalError: false,
  isAboutReportOpen:false,
  drawerLink:""
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.isGlobalLoading = action.payload;
    },
    setAppGlobalError: (state, action: PayloadAction<boolean>) => {
      state.isGlobalError = action.payload;
    },
    setAppReportOpen: (state, action: PayloadAction<boolean>) => {
      state.isAboutReportOpen = action.payload;
    },
    setDrawerLink:(state,action:PayloadAction<string>)=>{
      state.drawerLink = action.payload
    }
  },
});

export const appSliceActions = appSlice.actions;
export const appSliceReducer = appSlice.reducer;
