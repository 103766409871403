import React, { FC, useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Button, Table } from "antd"
import { ColumnsType } from "antd/es/table"

import { getAccessToken } from "../../../../helpers/utils/auth"
import { showErrorMessage } from "../../../../helpers/utils/ui"
import { unApi } from "../../api/endpoints/reports/unApi"
import { API_GET_EXPORT } from "../../api/urls"
import { useTypedSelector } from "../../hooks/useTypedSelector"

const ExportPage: FC = () => {
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)

    const [ tableData, setTableData ] = useState([])
    const [ tableLoading, setTableLoading ] = useState(false)

    const downloadFile = (id: string) => {
        if (selectedSchool?.id) {
            const url = `${API_GET_EXPORT}/${
                selectedSchool.id
            }/${id}/${getAccessToken()}`
            const link = document.createElement("a")

            link.href = url

            document.body.appendChild(link)
            link.click()
            link.remove()
        }
    }

    const columns: ColumnsType<any> = [
        {
            title: "Дата создания",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "Название файла",
            dataIndex: "export_name",
            key: "export_name",
        },
        {
            title: "Файл",
            dataIndex: "status",
            key: "status",
            render: (url) => {
                if (!url?.includes("Файл готовится")) {
                    const id = url.split("id=")[1]

                    return (
            <Button type="link" onClick={() => downloadFile(id)}>
              Скачать файл
            </Button>
                    )
                } else {
                    return <span>Файл готовится...</span>
                }
            },
        },
        {
            title: "Кто создал",
            dataIndex: "user_name",
            key: "user_name",
        },
        {
            title: "Дата готовности файла",
            dataIndex: "updated_at",
            key: "updated_at",
        },
    ]

    const fetchData = () => {
        setTableLoading(true)

        unApi
            .getExports(selectedSchool?.id || "")
            .then((response) => {
                if (response.status === 200) {
                    setTableData(response.data)
                } else {
                    setTableData([])
                }
            })
            .catch((err) => {
                console.error(err)
                showErrorMessage("Ошибка при получении данных.")
            })
            .finally(() => {
                setTableLoading(false)
                setTimeout(() => fetchData(), 10000)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
    <div className="app">
      <div style={{ width: "100%" }}>
        <Table
            dataSource={tableData}
            columns={columns}
            loading={{
                indicator: <LoadingOutlined />,
                spinning: tableLoading,
            }}
            pagination={{
                position: [ "bottomLeft" ],
            }}
        />
      </div>
    </div>
    )
}

export default ExportPage
