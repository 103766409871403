import React, { useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Form, Input, Modal } from "antd"

import { showErrorMessage } from "../../../../../helpers/utils/ui"
import { unApi } from "../../../../../shared/common/api/endpoints/reports/unApi"
import {UpdatePartner} from "../../../../../app/types/unApi";


type Props = {
    visible:boolean
    setVisible:  React.Dispatch<React.SetStateAction<{visible: boolean, id:number}>>
    id:number
}
const EditPartner = ({ visible,setVisible,id }:Props) => {
    const [ form ] = Form.useForm()
    const [ formLoading, setFormLoading ] = useState(false)
    const fetchData = () => {
        try{
            unApi.getTablePartnerInfo(id).then((res)=>{
                if(res.status === 200){
                    form.setFieldsValue({
                        comment: res.data.comment,
                    })
                }
            })
        }catch(err){
            showErrorMessage("Произошла ошибка при получении информации об выплате , повторите попытку позже.")
        }

    }
    useEffect(()=> {
        fetchData()
    },[])
    const handleEdit = async (values:UpdatePartner) => {
        const data = {
            ...values,
            partner_id:id,
        }
        setFormLoading(true)
        setVisible({ visible:false,id:0 })
        try{
            const response = await unApi.updateTablePartner(data)
            if(response.status === 200){
                form.resetFields()
                setVisible({ visible:false,id:0 })
                Modal.info({
                    title: "Успех!",
                    content: "Партнёр обновлен.",
                })
            }
        }catch(err:any){
            if(err.response.data){
                showErrorMessage(err.response.data)
            }else{
                showErrorMessage(`Произошла ошибка, повторите попытку позже.`)
            }

        }finally{
            setFormLoading(false)
        }
    }

    return (
        <Modal
            title="Редактирование партнёра"
            open={visible}
            onCancel={() => {setVisible({ visible:false,id:0 })}}
            onOk={() => {form.submit()}}
            okButtonProps={{ loading: formLoading }}
            okText="Сохранить"
        >
            {
                formLoading ?
                    <div className="loading-container">
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                    </div>
                    :
                    <Form
                        form={form}
                        onFinish={handleEdit}
                        requiredMark={false}
                    >
                        <Form.Item
                            name="comment"
                            label="Комментарий"
                            rules={[
                                { required: true },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
            }
        </Modal>
    )
}

export default EditPartner
