// Группировки в графике

export const groups = [
  {
    groupName: "reg",
    metrics: [
      { name: "regs_count_4", label: "Регистрации" },
      { name: "users_count_4", label: "Пользователи" },
      { name: "old_users_count_4", label: "Старые пользователи" },
      { name: "new_users_count_4", label: "Новые пользователи" },
      { name: "paid_regs_count_4", label: "Платные регистрации" },
      { name: "free_regs_count_4", label: "Бесплатные регистрации" },
    ],
  },
  {
    groupName: "pr",
    metrics: [
      { name: "reg_price_4", label: "Цена регистрации" },
      { name: "user_price_4", label: "Цена пользователя" },
      { name: "web_user_price_4", label: "Цена участника вебинара" },
      { name: "payments_price_4", label: "Цена платежа" },
      { name: "buyers_price_4", label: "Цена покупателя" },
      { name: "orders_price_4", label: "Цена заказа" },
      { name: "orders_creators_price_4", label: "Цена создателя заказа" },
      { name: "forders_price_4", label: "Цена заказа [Завершён]" },
    ],
  },
  {
    groupName: "ads_con",
    metrics: [
      { name: "cpm_4", label: "CPM" },
      { name: "cpc_4", label: "CPC" },
    ],
  },
  {
    groupName: "conv",
    metrics: [
      { name: "paid_regs_part_4", label: "Доля платных регистраций" },
      { name: "free_regs_part_4", label: "Доля бесплатных регистраций" },
      { name: "new_users_part_4", label: "Доля новых пользователей" },
      { name: "user_to_creator_converse_4", label: "CR в создателя заказа" },
      {
        name: "orders_to_forders_converse_4",
        label: "CR заказа в завершенный заказ",
      },
      {
        name: "creator_to_buyer_converse_4",
        label: "CR из созд. заказа в покупателя",
      },
      { name: "user_to_buyer_converse_4", label: "CR из польз. в покупателя" },
      { name: "web_1min_users_part_4", label: "Доля кто был на веб. < 1 мин." },
      { name: "web_5min_users_part_4", label: "Доля кто был на веб. < 5 мин." },
      { name: "web_15min_users_part_4", label: "Доля кто был на веб. < 15 мин." },
      { name: "web_60min_users_part_4", label: "Доля кто был на веб. < 1 час." },
      {
        name: "web_60min_g_users_part_4",
        label: "Доля кто был на веб. > 1 час",
      },
      {
        name: "web_120min_g_users_part_4",
        label: "Доля кто был на веб. > 2 час.",
      },
      {
        name: "web_user_to_clicker_converse_4",
        label: "CR из участника в нажав. кнопку",
      },
      {
        name: "clicker_to_creator_converse_4",
        label: "CR из нажав. кнопку в создат. заказа",
      },
      {
        name: "user_to_web_user_converse_4",
        label: "CR из пользователя в участника веб.",
      },
      {
        name: "web_user_to_creator_converse_4",
        label: "CR из участника веб. в создат. заказа",
      },
      { name: "ctr_4", label: "CTR" },
      { name: "ads_click_to_reg_converse_4", label: "CR страницы" },
    ],
  },
  {
    groupName: "orders",
    metrics: [
      { name: "orders_count_4", label: "Заказы" },
      { name: "forders_count_4", label: "Заказы [Завершён]" },
      { name: "payments_count_4", label: "Платежи" },
      { name: "orders_creators_count_4", label: "Создатели заказов" },
      { name: "buyers_count_4", label: "Покупатели" },
    ],
  },
  {
    groupName: "sum",
    metrics: [
      { name: "payments_sum_4", label: "Сумма платежей" },
      { name: "orders_sum_4", label: "Сумма заказов" },
      { name: "forders_sum_4", label: "Сумма заказов [Завершён]" },
      { name: "ads_spend_4", label: "Рекламные расходы" },
      { name: "ads_spend_cabinet_4", label: "Рекламные расходы РК" },
      { name: "profit_4", label: "Прибыль" },
    ],
  },
  {
    groupName: "avg",
    metrics: [
      { name: "orders_sum_avg_4", label: "Ср. сумма заказа" },
      {
        name: "orders_sum_avg_user_4",
        label: "Ср. сумма заказов на 1 пользователя",
      },
      { name: "payments_sum_avg_4", label: "Средний чек платежа" },
      { name: "payments_sum_per_reg_4", label: "Сумма платежей на 1 рег." },
      { name: "profit_per_reg_4", label: "Прибыль на 1 рег." },
      { name: "profit_per_user_4", label: "Прибыль на 1 пользователя" },
    ],
  },
  {
    groupName: "web",
    metrics: [
      { name: "web_users_count_4", label: "Участники вебинара" },
      { name: "web_1min_users_count_4", label: "Был на веб. < 1 мин." },
      { name: "web_5min_users_count_4", label: "Был на веб. < 5 мин." },
      { name: "web_15min_users_count_4", label: "Был на веб. < 15 мин." },
      { name: "web_60min_users_count_4", label: "Был на веб. < 1 час." },
      { name: "web_60min_g_users_count_4", label: "Был на веб. > 1 час." },
      { name: "web_120min_g_users_count_4", label: "Был на веб. > 2 час." },
      { name: "web_clickers_count_4", label: "Нажал кнопку на веб." },
    ],
  },
  {
    groupName: "clicks",
    metrics: [{ name: "ads_clicks_count_4", label: "Клики" }],
  },
  {
    groupName: "abr",
    metrics: [
      { name: "romi_4", label: "ROMI" },
      { name: "roas_4", label: "ROAS" },
      { name: "drr_4", label: "ДРР" },
    ],
  },
];

/**
 Для добавления в группировку информационной иконки требуется добавить
 в объект группировки парметр description
 @param {string} `description`
 ВАЖНО! Использовать инф. иконку можно только в родительских элементах

 Пример:
 { name: "Дата", description: "...", children: [...] }

 Параметр принимает в себя название иконки, список доступных
 иконок для этого модуля редактируется в файле web/js/react-app/src/common/filter/IconFilter.js
 */
export const groupingsData = [
  {
    name: "Регистрации",
    description:
        "Группировка регистраций участников вебинара. Сможете увидеть откуда и когда зарегистрировались на вебинар участники вебинара. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
    children: [
       {
         name: "Дата регистрации",
         description:"Группировка регистраций по дате регистрации. Сможете увидеть в какой день была создана регистрация участником вебинара. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
         children: [
           {
             name: "Дни регистрации",
             description:
               "Регистрации сгруппированы по каждому дню регистрации. Берутся только регистрации созданные участниками вебинара до посещения вебинара",
             dataKey: "reg_date_4",
           },
           {
             name: "Недели регистрации",
             description: "Регистрации сгруппированы по неделям, по 7 дней. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
             dataKey: "reg_date_w_4",
           },
           {
             name: "Дни недели регистрации",
             description:
               "Регистрации сгруппированы по дням недели (понедельник, вторник, среда, четверг, пятница, суббота, воскресенье). Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
             dataKey: "reg_date_dw_4",
           },
           {
             name: "Месяцы регистрации",
             description: "Регистрации сгруппированы по месяцам. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
             dataKey: "reg_date_m_4",
           },
           {
             name: "Кварталы регистрации",
             description: "Регистрации сгруппированы по кварталам, по 3 месяца. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
             dataKey: "reg_date_q_4",
           },
           {
             name: "Года регистрации",
             description: "Регистрации сгруппированы по годам. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
             dataKey: "reg_date_y_4",
           },
         ],
       },
      {
        name: "UTM-метка регистрации",
        description: "Группировка данных по UTM-метке регистрации. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
        children: [
          { name: "utm_source регистрации", dataKey: "reg_utm_source_4" },
          { name: "utm_medium регистрации", dataKey: "reg_utm_medium_4" },
          { name: "utm_campaign регистрации", dataKey: "reg_utm_campaign_4" },
          { name: "utm_content регистрации", dataKey: "reg_utm_content_4" },
          { name: "utm_term регистрации", dataKey: "reg_utm_term_4" },
          { name: "utm_group регистрации", dataKey: "reg_utm_group_4" },
        ],
      },
      {
        name: "Название регистрации",
        description:"Группировка регистраций по названию регистраций. Нужно использовать: Нулевые заказы GC - это название предложения в нулевом заказе GetCourse.Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
        dataKey: "reg_title_4",
      },
      {
        name: "Номер регистрации",
        description:
            "Для типа регистрации: нулевые заказы GC это вывод номера нулевого заказа. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
        dataKey: "reg_number_4",
      },
    ],
  },
  {
    name: "Пользователи",
    children: [
      {
        name: "E-mail",
        description:
            "Вывод данных по Email пользователю. Ссылка Email адреса пользователя будет активная, при переходе откроется карточка пользователя в GetCorse",
        dataKey: "user_email_4",
      },
      {
        name: "id пользователя",
        description:
            "Вывод данных по ID пользователя. Ссылка ID пользователя будет активная, при переходе откроется карточка пользователя в GetCorse",
        dataKey: "user_id_4",
      },
      {
        name: "id - Email - Имя - Телефон",
        description: "Вывод контактных данных пользователя, разделенные точкой с запятой (;)",
        dataKey: "user_id_ext_4",
      },
    ],
  },
  {
    name: "Заказы",
    description:
        "Группировки заказов участников вебинара. Узнаете сколько, когда и какие заказы были созданы участниками вебинара. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
    children: [
      {
        name: "Дата заказа",
        description:
            "Группировка заказов участников вебинара, созданных после посещения вебинара по дате создания заказов.",
        children: [
          {
            name: "Дни заказов",
            description: "Группировка заказов по дням создания заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
            dataKey: "order_date_4",
          },
          {
            name: "Недели заказов",
            description: "Группировка заказов по неделям создания заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него",
            dataKey: "order_date_w_4",
          },
          {
            name: "Дни недели заказов",
            description: "Группировка заказов по дням недели создания заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
            dataKey: "order_date_dw_4",
          },
          {
            name: "Месяцы заказов",
            description: "Группировка заказов по месяцам создания заказов.Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
            dataKey: "order_date_m_4",
          },
          {
            name: "Кварталы заказов",
            description: "Группировка заказов по кварталам создания заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
            dataKey: "order_date_q_4",
          },
          {
            name: "Года заказов",
            description: "Группировка заказов по годам создания заказов.Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
            dataKey: "order_date_y_4",
          },
        ],
      },
      {
      name: "Период от посещения вебинаре до создания заказа",
      description:
        "Группировка заказов участников вебинаров по периоду от посещения вебинара до создания заказа. Сможете увидеть сколько заказов было создано участниками вебинара в день вебинара и после него.",
      children: [
        {
          name: "Дней от посещ. вебинара до заказа",
          description:
            "Группировка заказов участников вебинаров по количеству дней прошедших от посещения вебинара до создания заказа. Сможете увидеть сколько заказов было создано в день участия на вебинаре и сколько в другие дни после вебинара.<br>" +
            "<br>" +
            "Заказы сгруппируются по периодам:<br>" +
            "<br>" +
            "1 день<br>" +
            "2 день<br>" +
            "3 день<br>" +
            "4 день<br>" +
            "5 день<br>" +
            "6 день<br>" +
            "7 день<br>" +
            "8 день<br>" +
            "9 день<br>" +
            "10 день<br>" +
            "11 день<br>" +
            "12 день<br>" +
            "13 день<br>" +
            "14 день<br>" +
            "> 15 дней<br>" +
            "<br>" +
            "В период 1 день попадут все заказы, у которых дата заказа = дате участия на вебинаре <br>" +
            "<br>" +
            "В период 2 день попадут заказы, созданные на следующий день после участия на вебинаре.<br>",
          dataKey: "order_after_web_d_4",
        },
        {
          name: "Недель от посещ. вебинара до заказа",
          description:
            "Группировка заказов участников вебинаров  по количеству недель прошедших от посещения вебинара до создания заказа.<br>" +
            "<br>" +
            "Заказы сгруппируются по периодам:<br>" +
            "<br>" +
            "1 неделя<br>" +
            "2 неделя<br>" +
            "3 неделя<br>" +
            "4 неделя<br>" +
            "5 неделя<br>" +
            "6 неделя<br>" +
            "7 неделя<br>" +
            "8 неделя<br>" +
            "9 неделя<br>" +
            "10 неделя<br>" +
            "11 неделя<br>" +
            "12 неделя<br>" +
            "> 12 недель<br>" +
            "<br>" +
            "В период 1 неделя попадут все заказы, созданные в течение 7 дней после после посещения вебинара. Если посещение вебинара было1 марта, то попадут заказы созданные с 1 по 7 марта.<br>" +
            "<br>" +
            "В период 2 неделя попадут все заказы, созданные в период с 8 по 14 день после посещения вебинара. Если посещение вебинара было 1 марта, то попадут заказы созданные с 8 по 14 марта<br>",
          dataKey: "order_after_web_w_4",
        },
        {
          name: "Месяцев от посещ. вебинара до заказа",
          description:
            "Группировка заказов участников вебинаров  по количеству месяцев прошедших от посещения вебинара до создания заказа.<br>" +
            "<br>" +
            "Заказы сгруппируются по периодам:<br>" +
            "<br>" +
            "1 месяц<br>" +
            "2 месяц<br>" +
            "3 месяц<br>" +
            "4 месяц<br>" +
            "5 месяц<br>" +
            "6 месяц<br>" +
            "7 месяц<br>" +
            "8 месяц<br>" +
            "9 месяц<br>" +
            "10 месяц<br>" +
            "11 месяц<br>" +
            "12 месяц<br>" +
            "> 12 месяцев<br>" +
            "<br>" +
            "В период 1 месяц попадут все заказы, созданные в течение 30 дней после посещения вебинара. Если посещение вебинара было 1 марта, то попадут заказы созданные с 1 по 30 марта.<br>" +
            "<br>" +
            "В период 2 месяца попадут все заказы, созданные в период с 31 по 60 день после посещения вебинара. Если посещение вебинара было 1 марта, то попадут заказы созданные с 31 марта по 29 апреля.<br>" +
            "<br>" +
            "Условно 1 месяц = 30 дней.<br>" +
            "<br>" +
            "<b>Формула расчёта:</b> (Дата заказа - дата посещения вебинара +1) / 30 = число округляем до большего значения.",
          dataKey: "order_after_web_m_4",
        },
      ],
    },
       {
         name: "Предложения",
         description:
             "Группировка заказов по предложениям заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
         dataKey: "order_offers_4",
       },
       {
         name: "Номер заказа",
         description:
           "Данной группировкой можно вывести список номеров заказов GetCourse пользователями, которые сделали заказ.",
         dataKey: "order_number_4",
       },
       {
         name: "Тег предложения",
         description:
           "Группировка данных по тегам предложений заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.<br>" +
           "<br>" +
           "Теги предложение получаем автоматически из выгрузки заказов.<br>" +
           "<br>" +
           "Эта группировка позволяет увидеть заказы по каждому тегу предложения.<br>" +
           "<br>" +
           "ЗЗаказы, у предложений которых не будет тегов предложений, будут сгруппированы в группу (Без тега).<br>",
         dataKey: "order_tag_1_4",
       },
       {
         name: "Тег заказа",
         description:
           "Группировка заказов по тегам заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.<br>" +
           "<br>" +
           "Теги заказов получаем автоматически из выгрузки заказов.<br>" +
           "<br>" +
           "Эта группировка позволяет увидеть заказы каждого тега заказа.<br>" +
           "<br>" +
           "Заказы, у которых нет тега заказа, будут сгруппированы в группу (Без тега).<br>",
         dataKey: "order_tag_3_4",
       },
       {
         name: "Есть заказ",
         description:
           "Группировка участников вебинара на 2 группы." +
             "<br>" +
             "В первую группу (Есть заказ) попадут участники, у которых есть созданный заказ в день посещения вебинара или после него." +
             "<br>" +
             "Во вторую группу (Нет заказа) попадут участники, у которых нет созданных заказов в день посещения вебинара или после него." +
              + "<br>" +
             "В фильтре можно выбрать необходимые заказы",
         dataKey: "has_orders_4",
       },
       {
         name: "Менеджер заказа",
         description:
           "Группировка заказов по менеджерам заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.<br/>",
         dataKey: "order_manager_4",
       },
       {
         name: "Статус заказа",
         description:
             "Группировка данных по статусам заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.<br/>" +
             "<br/>" +
             "Эта группировка позволяет увидеть, в каких статусах находятся заказы.",
         dataKey: "order_status_4",
       },
       {
         name: "UTM-метка заказа",
         description:
             "Группировка заказов по UTM - меткам заказов. Данные берутся из выгрузки GetCourse. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него",
         children: [
           { name: "utm_source заказа", dataKey: "order_utm_source_4" },
           { name: "utm_medium заказа", dataKey: "order_utm_medium_4" },
           { name: "utm_campaign заказа", dataKey: "order_utm_campaign_4" },
           { name: "utm_content заказа", dataKey: "order_utm_content_4" },
           { name: "utm_term заказа", dataKey: "order_utm_term_4" },
           { name: "utm_group заказа", dataKey: "order_utm_group_4" },
         ],
       },
       {
         name: "Партнер заказа",
         description: "Группировка заказов по партнёрам заказов. Данные берутся из выгрузки GetCourse." +
             "<br>" +
             "Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
         children: [
           { name:"Есть партнёр",dataKey: "order_has_partner_4",description: "Группировка заказов на 2 группы." +
                 "<br>" +
                 "В первую группу (Без партнёра) попадут заказы, у которых нет привязанного партнёра." +
                 "<br>" +
                 "Во вторую группу (Есть партнёр) попадут заказы у которых есть привязанный партнёр." +
                 "<br>" +
                 "Берутся только заказы участников вебинара созданные в день посещения вебинара и после него." },
           { name: "id партнера заказа",description:"Вывод id партнёра заказа", dataKey: "order_partner_id_4" },
           { name: "Email партнера заказа",description:"Вывод Email партнёра заказа", dataKey: "order_partner_email_4" },
           { name: "Имя партнера заказа",description:"Вывод имени партнёра заказа", dataKey: "order_partner_name_4" },
         ],
       },
    ],
  },
   {
     name: "Платежи",
     description: "Группировка платежей по разным параметрам. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него.",
     children: [
       {
         name: "Дата платежа",
         description: "Группировка платежей по дате получения платежа. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него.",
         children: [
           {
             name: "День платежа",
             description: "Группировка платежей по дням получения платежей. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него.",
             dataKey: "payment_date_4",
           },
           {
             name: "Неделя платежа",
             description: "Группировка платежей по неделям получения платежей. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него.",
             dataKey: "payment_date_w_4",
           },
           {
             name: "День недели платежа",
             description:
               "Группировка платежей по дням недели получения платежей. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него",
             dataKey: "payment_date_dw_4",
           },
           {
             name: "Месяц платежа",
             description: "Группировка платежей по месяцам получения платежей. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него",
             dataKey: "payment_date_m_4",
           },
           {
             name: "Квартал платежа",
             description:
               "Группировка платежей по кварталам получения платежей. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него",
             dataKey: "payment_date_q_4",
           },
           {
             name: "Год платежа",
             description: "Группировка платежей по годам получения платежей. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него",
             dataKey: "payment_date_y_4",
           },
         ],
       },
       {
         name: "id платежа",
         description: "Группировка платежей по ID платежа.",
         dataKey: "payment_id_4",
       },
       {
         name: "Тип платежа",
         description: "Группировка данных по типу платежной системы.",
         dataKey: "payment_type_4",
       },
       {
         name: "Статус платежа",
         description: "Группировка данных по статусу платежа.",
         dataKey: "payment_status_4",
       },
       {
         name: "Есть платеж",
         description:
           "Группировка регистраций на 2 группы: Есть платёж и Нет платежа. В группу Есть платёж попадут пользователи, которые сделали платёж после регистрации. В группу Нет платежа, соответственно, попадут пользователи без платежей после регистрации.<br/>" +
           "<br/>" +
           "Данной группировкой можно посмотреть, какой процент базы делает платёж после входа в ту или иную воронку.",
         dataKey: "has_payments_4",
       },
       {
         name: "Период от посещения вебинара до платежа",
         description:
             "Группировка платежей участников вебинаров по периоду от посещения вебинара платежа. Сможете увидеть сколько платежей было создано участниками вебинара в день вебинара и после него.",
         children: [
           {
             name: "Дней от регистрации до платежа",
             description:
                 "Группировка платежей участников вебинаров по количеству дней прошедших от посещения вебинара до платежа.<br>" +
                 "<br>" +
                 "<b>Платежи сгруппируются по периодам:</b><br>" +
                 "<br>" +
                 "1 день<br>" +
                 "2 день<br>" +
                 "3 день<br>" +
                 "4 день<br>" +
                 "5 день<br>" +
                 "6 день<br>" +
                 "7 день<br>" +
                 "8 день<br>" +
                 "9 день<br>" +
                 "10 день<br>" +
                 "11 день<br>" +
                 "12 день<br>" +
                 "13 день<br>" +
                 "14 день<br>" +
                 "> 15 дней<br>" +
                 "<br>" +
                 "В период 1 день попадут все платежи, у которых дата платежа = дате участия на вебинаре.<br>" +
                 "<br>" +
                 "В период 2 день попадут платежи, созданные на следующий день после участия на вебинаре.<br>",
             dataKey: "pay_after_web_d_4",
           },
         ],
       },

     ],
   },
  {
    name: "Вебинары",
    description: "",
    children: [
      {
        name: "Дата вебинара",
        description:
            "Группировка участников вебинаров по дате посещения вебинаров.",
        children: [
          {
            name: "День вебинара",
            description: "Группировка участников вебинаров по дням.",
            dataKey: "web_date_4",
          },
          {
            name: "Неделя вебинара",
            description: "Группировка участников вебинаров по неделям.",
            dataKey: "web_date_w_4",
          },
          {
            name: "Дни недели вебинара",
            description: "Группировка участников вебинаров по дням недели.",
            dataKey: "web_date_dw_4",
          },
          {
            name: "Месяц вебинара",
            description: "Группировка участников вебинаров по месяцам.",
            dataKey: "web_date_m_4",
          },
          {
            name: "Квартал вебинара",
            description: "Группировка участников вебинаров по кварталам.",
            dataKey: "web_date_q_4",
          },
          {
            name: "Год вебинара",
            description: "Группировка участников вебинаров по годам.",
            dataKey: "web_date_y_4",
          },
        ],
      },
      {
        name: "Период от регистрации до посещения вебинара",
        description:
            "Группировка участников вебинара по периодам от регистрации до посещения вебинара.",
        children: [
          {
            name: "Дней от регистрации до вебинара",
            description:
                "Группировка участников вебинаров по количеству дней прошедших от регистрации до участия в вебинаре.<br>" +
                "<br>" +
                "Узнаете сколько людей на какой день после регистрации приходят на вебинар.<br>" +
                "Участники вебинаров группируются по периодам:<br>" +
                "<br>" +
                "1 день<br>" +
                "2 день<br>" +
                "3 день<br>" +
                "4 день<br>" +
                "5 день<br>" +
                "6 день<br>" +
                "7 день<br>" +
                "8 день<br>" +
                "9 день<br>" +
                "10 день<br>" +
                "11 день<br>" +
                "12 день<br>" +
                "13 день<br>" +
                "14 день<br>" +
                "> 15 дней",
            dataKey: "web_after_reg_d_4",
          },
          {
            name: "Недель от регистрации до вебинара",
            description:
                "Группировка участников вебинаров по прошедшим неделям от регистрации до посещения вебинара. Узнаете сколько людей на какую неделю после регистрации приходят на вебинар.<br>" +
                "<br>" +
                "Участники вебинаров группируются по периодам:<br>" +
                "<br>" +
                "1 неделя<br>" +
                "2 неделя<br>" +
                "3 неделя<br>" +
                "4 неделя<br>" +
                "5 неделя<br>" +
                "6 неделя<br>" +
                "7 неделя<br>" +
                "8 неделя<br>" +
                "9 неделя<br>" +
                "10 неделя<br>" +
                "11 неделя<br>" +
                "12 неделя<br>" +
                "> 12 недель",
            dataKey: "web_after_reg_w_4",
          },
          {
            name: "Месяцев от регистрации до вебинара",
            description:
                "Группировка участников вебинаров по прошедшим месяцам от регистрации до посещения вебинара. Узнаете сколько людей на какой месяц после регистрации приходят на вебинар.<br>" +
                "<br>" +
                "Участники вебинаров группируются по периодам:<br>" +
                "<br>" +
                "1 месяц<br>" +
                "2 месяц<br>" +
                "3 месяц<br>" +
                "4 месяц<br>" +
                "5 месяц<br>" +
                "6 месяц<br>" +
                "7 месяц<br>" +
                "8 месяц<br>" +
                "9 месяц<br>" +
                "10 месяц<br>" +
                "11 месяц<br>" +
                "12 месяц<br>" +
                "> 12 месяцев",
            dataKey: "web_after_reg_m_4",
          },
          {
            name: "Кварталов от регистрации до вебинара",
            description:
                "Группировка участников вебинаров по прошедшим кварталам от регистрации до посещения вебинара. Узнаете сколько людей на какой квартал после регистрации приходят на вебинар.<br>" +
                "<br>" +
                "Участники вебинаров группируются по периодам:<br>" +
                "<br>" +
                "1 квартал<br>" +
                "2 квартал<br>" +
                "3 квартал<br>" +
                "4 квартал<br>" +
                "> 4 кварталов",
            dataKey: "web_after_reg_q_4",
          },
          {
            name: "Лет от регистрации до вебинара",
            description:
                "Группировка участников вебинаров по прошедшим годам от регистрации до посещения вебинарае. Узнаете сколько людей на какой год после регистрации приходят на вебинар.<br>" +
                "<br>" +
                "Участники вебинаров группируются по периодам:<br>" +
                "<br>" +
                "1 год<br>" +
                "2 год<br>" +
                "3 год<br>" +
                "4 год<br>" +
                "5 год<br>" +
                "> 5 лет",
            dataKey: "web_after_reg_y_4",
          },
        ],
      },
      {
        name: "Название вебинара",
        description:
            "Группировка участников вебинара по названию посещённых вебинаров.",
        dataKey: "web_name_4",
      },
      {
        name: "Коментировал на вебинаре",
        description:
            "Группировка пользователей на 2 группы: Комментировал на вебинаре и Не комментировал на вебинаре.",
        dataKey: "web_commentator_4",
      },
      {
        name: "Длительность участия на вебинаре",
        description:
            "Группировка пользователей на 9 групп по длительности участия на вебинаре.<br/>" +
            "<br/>" +
            "Не участвовал<br/>" +
            "До 1 минуты<br/>" +
            "От 1 до 10 минут<br/>" +
            "От 10 до 30 минут<br/>" +
            "От 30 до 60 минут<br/>" +
            "От 1 часа до 2 часов<br/>" +
            "От 2 часов до 3 часов<br/>" +
            "От 3 часов до 4 часов<br/>" +
            "Больше 4 часов",
        dataKey: "web_duration_4",
      },
    ],
  },
];
export const groupingsAdminData = [
  {
    name: "Регистрации",
    description:
      "Группировка регистраций участников вебинара. Сможете увидеть откуда и когда зарегистрировались на вебинар участники вебинара. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
    children: [
       {
         name: "Дата регистрации",
         description:"Группировка регистраций по дате регистрации. Сможете увидеть в какой день была создана регистрация участником вебинара. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
         children: [
           {
             name: "Дни регистрации",
             description:
               "Регистрации сгруппированы по каждому дню регистрации. Берутся только регистрации созданные участниками вебинара до посещения вебинара",
             dataKey: "reg_date_4",
           },
           {
            name: "Недели регистрации",
             description: "Регистрации сгруппированы по неделям, по 7 дней. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
             dataKey: "reg_date_w_4",
           },
           {
             name: "Дни недели регистрации",
             description:
               "Регистрации сгруппированы по дням недели (понедельник, вторник, среда, четверг, пятница, суббота, воскресенье). Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
             dataKey: "reg_date_dw_4",
           },
           {
             name: "Месяцы регистрации",
             description: "Регистрации сгруппированы по месяцам. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
             dataKey: "reg_date_m_4",
           },
           {
             name: "Кварталы регистрации",
             description: "Регистрации сгруппированы по кварталам, по 3 месяца. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
             dataKey: "reg_date_q_4",
           },
           {
             name: "Года регистрации",
             description: "Регистрации сгруппированы по годам. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
             dataKey: "reg_date_y_4",
           },
         ],
       },
      {
        name: "UTM-метка регистрации",
        description: "Группировка данных по UTM-метке регистрации. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
        children: [
          { name: "utm_source регистрации", dataKey: "reg_utm_source_4" },
          { name: "utm_medium регистрации", dataKey: "reg_utm_medium_4" },
          { name: "utm_campaign регистрации", dataKey: "reg_utm_campaign_4" },
          { name: "utm_content регистрации", dataKey: "reg_utm_content_4" },
          { name: "utm_term регистрации", dataKey: "reg_utm_term_4" },
          { name: "utm_group регистрации", dataKey: "reg_utm_group_4" },
        ],
      },
      {
        name: "Название регистрации",
        description:"Группировка регистраций по названию регистраций. Нужно использовать: Нулевые заказы GC - это название предложения в нулевом заказе GetCourse.Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
        dataKey: "reg_title_4",
      },
      {
        name: "Номер регистрации",
        description:
          "Для типа регистрации: нулевые заказы GC это вывод номера нулевого заказа. Берутся только регистрации созданные участниками вебинара до посещения вебинара.",
        dataKey: "reg_number_4",
      },
    ],
  },
  {
    name: "Пользователи",
    children: [
      {
        name: "E-mail",
        description:
          "Вывод данных по Email пользователю. Ссылка Email адреса пользователя будет активная, при переходе откроется карточка пользователя в GetCorse",
        dataKey: "user_email_4",
      },
      {
        name: "id пользователя",
        description:
          "Вывод данных по ID пользователя. Ссылка ID пользователя будет активная, при переходе откроется карточка пользователя в GetCorse",
        dataKey: "user_id_4",
      },
      {
        name: "id - Email - Имя - Телефон",
        description: "Вывод контактных данных пользователя, разделенные точкой с запятой (;)",
        dataKey: "user_id_ext_4",
      },
    ],
  },
  {
    name: "Заказы",
    description:
      "Группировки заказов участников вебинара. Узнаете сколько, когда и какие заказы были созданы участниками вебинара. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
    children: [
      {
        name: "Дата заказа",
        description:
          "Группировка заказов участников вебинара, созданных после посещения вебинара по дате создания заказов.",
        children: [
          {
            name: "Дни заказов",
            description: "Группировка заказов по дням создания заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
            dataKey: "order_date_4",
          },
          {
            name: "Недели заказов",
            description: "Группировка заказов по неделям создания заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него",
            dataKey: "order_date_w_4",
          },
          {
            name: "Дни недели заказов",
            description: "Группировка заказов по дням недели создания заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
            dataKey: "order_date_dw_4",
          },
          {
            name: "Месяцы заказов",
            description: "Группировка заказов по месяцам создания заказов.Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
            dataKey: "order_date_m_4",
          },
          {
            name: "Кварталы заказов",
            description: "Группировка заказов по кварталам создания заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
            dataKey: "order_date_q_4",
          },
          {
            name: "Года заказов",
            description: "Группировка заказов по годам создания заказов.Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
            dataKey: "order_date_y_4",
          },
        ],
      },
      {
      name: "Период от посещения вебинаре до создания заказа",
      description:
        "Группировка заказов участников вебинаров по периоду от посещения вебинара до создания заказа. Сможете увидеть сколько заказов было создано участниками вебинара в день вебинара и после него.",
      children: [
        {
          name: "Дней от посещ. вебинара до заказа",
          description:
            "Группировка заказов участников вебинаров по количеству дней прошедших от посещения вебинара до создания заказа. Сможете увидеть сколько заказов было создано в день участия на вебинаре и сколько в другие дни после вебинара.<br>" +
            "<br>" +
            "Заказы сгруппируются по периодам:<br>" +
            "<br>" +
            "1 день<br>" +
            "2 день<br>" +
            "3 день<br>" +
            "4 день<br>" +
            "5 день<br>" +
            "6 день<br>" +
            "7 день<br>" +
            "8 день<br>" +
            "9 день<br>" +
            "10 день<br>" +
            "11 день<br>" +
            "12 день<br>" +
            "13 день<br>" +
            "14 день<br>" +
            "> 15 дней<br>" +
            "<br>" +
            "В период 1 день попадут все заказы, у которых дата заказа = дате участия на вебинаре <br>" +
            "<br>" +
            "В период 2 день попадут заказы, созданные на следующий день после участия на вебинаре.<br>",
          dataKey: "order_after_web_d_4",
        },
        {
          name: "Недель от посещ. вебинара до заказа",
          description:
            "Группировка заказов участников вебинаров  по количеству недель прошедших от посещения вебинара до создания заказа.<br>" +
            "<br>" +
            "Заказы сгруппируются по периодам:<br>" +
            "<br>" +
            "1 неделя<br>" +
            "2 неделя<br>" +
            "3 неделя<br>" +
            "4 неделя<br>" +
            "5 неделя<br>" +
            "6 неделя<br>" +
            "7 неделя<br>" +
            "8 неделя<br>" +
            "9 неделя<br>" +
            "10 неделя<br>" +
            "11 неделя<br>" +
            "12 неделя<br>" +
            "> 12 недель<br>" +
            "<br>" +
            "В период 1 неделя попадут все заказы, созданные в течение 7 дней после после посещения вебинара. Если посещение вебинара было1 марта, то попадут заказы созданные с 1 по 7 марта.<br>" +
            "<br>" +
            "В период 2 неделя попадут все заказы, созданные в период с 8 по 14 день после посещения вебинара. Если посещение вебинара было 1 марта, то попадут заказы созданные с 8 по 14 марта<br>",
          dataKey: "order_after_web_w_4",
        },
        {
          name: "Месяцев от посещ. вебинара до заказа",
          description:
            "Группировка заказов участников вебинаров  по количеству месяцев прошедших от посещения вебинара до создания заказа.<br>" +
            "<br>" +
            "Заказы сгруппируются по периодам:<br>" +
            "<br>" +
            "1 месяц<br>" +
            "2 месяц<br>" +
            "3 месяц<br>" +
            "4 месяц<br>" +
            "5 месяц<br>" +
            "6 месяц<br>" +
            "7 месяц<br>" +
            "8 месяц<br>" +
            "9 месяц<br>" +
            "10 месяц<br>" +
            "11 месяц<br>" +
            "12 месяц<br>" +
            "> 12 месяцев<br>" +
            "<br>" +
            "В период 1 месяц попадут все заказы, созданные в течение 30 дней после посещения вебинара. Если посещение вебинара было 1 марта, то попадут заказы созданные с 1 по 30 марта.<br>" +
            "<br>" +
            "В период 2 месяца попадут все заказы, созданные в период с 31 по 60 день после посещения вебинара. Если посещение вебинара было 1 марта, то попадут заказы созданные с 31 марта по 29 апреля.<br>" +
            "<br>" +
            "Условно 1 месяц = 30 дней.<br>" +
            "<br>" +
            "<b>Формула расчёта:</b> (Дата заказа - дата посещения вебинара +1) / 30 = число округляем до большего значения.",
          dataKey: "order_after_web_m_4",
        },
      ],
    },
       {
         name: "Предложения",
         description:
             "Группировка заказов по предложениям заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
         dataKey: "order_offers_4",
       },
       {
         name: "Номер заказа",
         description:
           "Данной группировкой можно вывести список номеров заказов GetCourse пользователями, которые сделали заказ.",
         dataKey: "order_number_4",
       },
       {
         name: "Тег предложения",
         description:
           "Группировка данных по тегам предложений заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.<br>" +
           "<br>" +
           "Теги предложение получаем автоматически из выгрузки заказов.<br>" +
           "<br>" +
           "Эта группировка позволяет увидеть заказы по каждому тегу предложения.<br>" +
           "<br>" +
           "ЗЗаказы, у предложений которых не будет тегов предложений, будут сгруппированы в группу (Без тега).<br>",
         dataKey: "order_tag_1_4",
       },
       {
         name: "Тег заказа",
         description:
           "Группировка заказов по тегам заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.<br>" +
           "<br>" +
           "Теги заказов получаем автоматически из выгрузки заказов.<br>" +
           "<br>" +
           "Эта группировка позволяет увидеть заказы каждого тега заказа.<br>" +
           "<br>" +
           "Заказы, у которых нет тега заказа, будут сгруппированы в группу (Без тега).<br>",
         dataKey: "order_tag_3_4",
       },
       {
         name: "Есть заказ",
         description:
           "Группировка участников вебинара на 2 группы." +
             "<br>" +
             "В первую группу (Есть заказ) попадут участники, у которых есть созданный заказ в день посещения вебинара или после него." +
             "<br>" +
             "Во вторую группу (Нет заказа) попадут участники, у которых нет созданных заказов в день посещения вебинара или после него." +
              + "<br>" +
             "В фильтре можно выбрать необходимые заказы",
         dataKey: "has_orders_4",
       },
       {
         name: "Менеджер заказа",
         description:
           "Группировка заказов по менеджерам заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.<br/>",
         dataKey: "order_manager_4",
       },
       {
         name: "Статус заказа",
         description:
             "Группировка данных по статусам заказов. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.<br/>" +
             "<br/>" +
             "Эта группировка позволяет увидеть, в каких статусах находятся заказы.",
         dataKey: "order_status_4",
       },
       {
         name: "UTM-метка заказа",
         description:
             "Группировка заказов по UTM - меткам заказов. Данные берутся из выгрузки GetCourse. Берутся только заказы участников вебинара созданные в день посещения вебинара и после него",
         children: [
           { name: "utm_source заказа", dataKey: "order_utm_source_4" },
           { name: "utm_medium заказа", dataKey: "order_utm_medium_4" },
           { name: "utm_campaign заказа", dataKey: "order_utm_campaign_4" },
           { name: "utm_content заказа", dataKey: "order_utm_content_4" },
           { name: "utm_term заказа", dataKey: "order_utm_term_4" },
           { name: "utm_group заказа", dataKey: "order_utm_group_4" },
         ],
       },
       {
         name: "Партнер заказа",
         description: "Группировка заказов по партнёрам заказов. Данные берутся из выгрузки GetCourse." +
             "<br>" +
             "Берутся только заказы участников вебинара созданные в день посещения вебинара и после него.",
         children: [
           { name:"Есть партнёр",dataKey: "order_has_partner_4",description: "Группировка заказов на 2 группы." +
                 "<br>" +
                 "В первую группу (Без партнёра) попадут заказы, у которых нет привязанного партнёра." +
                 "<br>" +
                 "Во вторую группу (Есть партнёр) попадут заказы у которых есть привязанный партнёр." +
                 "<br>" +
                 "Берутся только заказы участников вебинара созданные в день посещения вебинара и после него." },
           { name: "id партнера заказа",description:"Вывод id партнёра заказа", dataKey: "order_partner_id_4" },
           { name: "Email партнера заказа",description:"Вывод Email партнёра заказа", dataKey: "order_partner_email_4" },
           { name: "Имя партнера заказа",description:"Вывод имени партнёра заказа", dataKey: "order_partner_name_4" },
         ],
       },
    ],
  },
  {
    name: "Платежи",
    description: "Группировка платежей по разным параметрам. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него.",
    children: [
      {
        name: "Дата платежа",
        description: "Группировка платежей по дате получения платежа. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него.",
        children: [
          {
            name: "День платежа",
            description: "Группировка платежей по дням получения платежей. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него.",
            dataKey: "payment_date_4",
          },
          {
            name: "Неделя платежа",
            description: "Группировка платежей по неделям получения платежей. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него.",
            dataKey: "payment_date_w_4",
          },
          {
            name: "День недели платежа",
            description:
              "Группировка платежей по дням недели получения платежей. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него",
            dataKey: "payment_date_dw_4",
          },
          {
            name: "Месяц платежа",
            description: "Группировка платежей по месяцам получения платежей. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него",
            dataKey: "payment_date_m_4",
          },
          {
            name: "Квартал платежа",
            description:
              "Группировка платежей по кварталам получения платежей. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него",
            dataKey: "payment_date_q_4",
          },
          {
            name: "Год платежа",
            description: "Группировка платежей по годам получения платежей. Берутся только платежи заказов участников вебинара созданные в день посещения вебинара и после него",
            dataKey: "payment_date_y_4",
          },
        ],
      },
      {
        name: "id платежа",
        description: "Группировка платежей по ID платежа.",
        dataKey: "payment_id_4",
      },
      {
        name: "Тип платежа",
        description: "Группировка данных по типу платежной системы.",
        dataKey: "payment_type_4",
      },
      {
        name: "Статус платежа",
        description: "Группировка данных по статусу платежа.",
        dataKey: "payment_status_4",
      },
      {
        name: "Есть платеж",
        description:
          "Группировка регистраций на 2 группы: Есть платёж и Нет платежа. В группу Есть платёж попадут пользователи, которые сделали платёж после регистрации. В группу Нет платежа, соответственно, попадут пользователи без платежей после регистрации.<br/>" +
          "<br/>" +
          "Данной группировкой можно посмотреть, какой процент базы делает платёж после входа в ту или иную воронку.",
        dataKey: "has_payments_4",
      },
      {
        name: "Период от посещения вебинара до платежа",
        description:
            "Группировка платежей участников вебинаров по периоду от посещения вебинара платежа. Сможете увидеть сколько платежей было создано участниками вебинара в день вебинара и после него.",
        children: [
          {
            name: "Дней от регистрации до платежа",
            description:
                "Группировка платежей участников вебинаров по количеству дней прошедших от посещения вебинара до платежа.<br>" +
                "<br>" +
                "<b>Платежи сгруппируются по периодам:</b><br>" +
                "<br>" +
                "1 день<br>" +
                "2 день<br>" +
                "3 день<br>" +
                "4 день<br>" +
                "5 день<br>" +
                "6 день<br>" +
                "7 день<br>" +
                "8 день<br>" +
                "9 день<br>" +
                "10 день<br>" +
                "11 день<br>" +
                "12 день<br>" +
                "13 день<br>" +
                "14 день<br>" +
                "> 15 дней<br>" +
                "<br>" +
                "В период 1 день попадут все платежи, у которых дата платежа = дате участия на вебинаре.<br>" +
                "<br>" +
                "В период 2 день попадут платежи, созданные на следующий день после участия на вебинаре.<br>",
            dataKey: "pay_after_web_d_4",
          },
        ],
      },

    ],
  },
  {
    name: "Вебинары",
    description: "",
    children: [
      {
        name: "Дата вебинара",
        description:
          "Группировка участников вебинаров по дате посещения вебинаров.",
        children: [
          {
            name: "День вебинара",
            description: "Группировка участников вебинаров по дням.",
            dataKey: "web_date_4",
          },
          {
            name: "Неделя вебинара",
            description: "Группировка участников вебинаров по неделям.",
            dataKey: "web_date_w_4",
          },
          {
            name: "Дни недели вебинара",
            description: "Группировка участников вебинаров по дням недели.",
            dataKey: "web_date_dw_4",
          },
          {
            name: "Месяц вебинара",
            description: "Группировка участников вебинаров по месяцам.",
            dataKey: "web_date_m_4",
          },
          {
            name: "Квартал вебинара",
            description: "Группировка участников вебинаров по кварталам.",
            dataKey: "web_date_q_4",
          },
          {
            name: "Год вебинара",
            description: "Группировка участников вебинаров по годам.",
            dataKey: "web_date_y_4",
          },
        ],
      },
      {
        name: "Период от регистрации до посещения вебинара",
        description:
          "Группировка участников вебинара по периодам от регистрации до посещения вебинара.",
        children: [
          {
            name: "Дней от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по количеству дней прошедших от регистрации до участия в вебинаре.<br>" +
              "<br>" +
              "Узнаете сколько людей на какой день после регистрации приходят на вебинар.<br>" +
                "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 день<br>" +
              "2 день<br>" +
              "3 день<br>" +
              "4 день<br>" +
              "5 день<br>" +
              "6 день<br>" +
              "7 день<br>" +
              "8 день<br>" +
              "9 день<br>" +
              "10 день<br>" +
              "11 день<br>" +
              "12 день<br>" +
              "13 день<br>" +
              "14 день<br>" +
              "> 15 дней",
            dataKey: "web_after_reg_d_4",
          },
          {
            name: "Недель от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по прошедшим неделям от регистрации до посещения вебинара. Узнаете сколько людей на какую неделю после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 неделя<br>" +
              "2 неделя<br>" +
              "3 неделя<br>" +
              "4 неделя<br>" +
              "5 неделя<br>" +
              "6 неделя<br>" +
              "7 неделя<br>" +
              "8 неделя<br>" +
              "9 неделя<br>" +
              "10 неделя<br>" +
              "11 неделя<br>" +
              "12 неделя<br>" +
              "> 12 недель",
            dataKey: "web_after_reg_w_4",
          },
          {
            name: "Месяцев от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по прошедшим месяцам от регистрации до посещения вебинара. Узнаете сколько людей на какой месяц после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 месяц<br>" +
              "2 месяц<br>" +
              "3 месяц<br>" +
              "4 месяц<br>" +
              "5 месяц<br>" +
              "6 месяц<br>" +
              "7 месяц<br>" +
              "8 месяц<br>" +
              "9 месяц<br>" +
              "10 месяц<br>" +
              "11 месяц<br>" +
              "12 месяц<br>" +
              "> 12 месяцев",
            dataKey: "web_after_reg_m_4",
          },
          {
            name: "Кварталов от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по прошедшим кварталам от регистрации до посещения вебинара. Узнаете сколько людей на какой квартал после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 квартал<br>" +
              "2 квартал<br>" +
              "3 квартал<br>" +
              "4 квартал<br>" +
              "> 4 кварталов",
            dataKey: "web_after_reg_q_4",
          },
          {
            name: "Лет от регистрации до вебинара",
            description:
              "Группировка участников вебинаров по прошедшим годам от регистрации до посещения вебинарае. Узнаете сколько людей на какой год после регистрации приходят на вебинар.<br>" +
              "<br>" +
              "Участники вебинаров группируются по периодам:<br>" +
              "<br>" +
              "1 год<br>" +
              "2 год<br>" +
              "3 год<br>" +
              "4 год<br>" +
              "5 год<br>" +
              "> 5 лет",
            dataKey: "web_after_reg_y_4",
          },
        ],
      },
      {
        name: "Название вебинара",
        description:
          "Группировка участников вебинара по названию посещённых вебинаров.",
        dataKey: "web_name_4",
      },
      {
        name: "Коментировал на вебинаре",
        description:
          "Группировка пользователей на 2 группы: Комментировал на вебинаре и Не комментировал на вебинаре.",
        dataKey: "web_commentator_4",
      },
      {
        name: "Длительность участия на вебинаре",
        description:
          "Группировка пользователей на 9 групп по длительности участия на вебинаре.<br/>" +
          "<br/>" +
          "Не участвовал<br/>" +
          "До 1 минуты<br/>" +
          "От 1 до 10 минут<br/>" +
          "От 10 до 30 минут<br/>" +
          "От 30 до 60 минут<br/>" +
          "От 1 часа до 2 часов<br/>" +
          "От 2 часов до 3 часов<br/>" +
          "От 3 часов до 4 часов<br/>" +
          "Больше 4 часов",
        dataKey: "web_duration_4",
      },
    ],
  },
];

/**
 Для добавления в метрику информационной иконки требуется добавить
 в объект метрики парметр description
 @param {string} `description`

 ВАЖНО! Использовать инф. иконку можно только в родительских элементах

 Пример:
 { name: "Пользователи", description: "...", children: [...] }

 Параметр принимает в себя название иконки, список доступных
 иконок для этого модуля редактируется в файле web/js/react-app/src/common/filter/IconFilter.js
 */
export const metrics = [
  {
    name: "Вебинары",
    children: [
      {
        name: "Участники вебинара",
        description:
            "Количество людей, кто был на вебинаре. В фильтре можете выбрать нужный вебинар. Если в фильтре не указывать вебинар, то будут учитываться все вебинары.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_users_count_4",
      },
      {
        name: "Был на веб. < 1 мин.",
        description:
            "Количество людей, кто был на вебинаре меньше 1 минуты. То есть, зашёл, быстро вышел и больше не заходил. <br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_1min_users_count_4",
      },
      {
        name: "Был на веб. < 5 мин.",
        description:
            "Количество людей, кто был на вебинаре меньше 5 минут.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_5min_users_count_4",
      },
      {
        name: "Был на веб. < 15 мин.",
        description:
            "Количество людей, кто был на вебинаре меньше 15 минут.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_15min_users_count_4",
      },
      {
        name: "Был на веб. < 30 мин.",
        description:
            "Количество людей, кто был на вебинаре меньше 30 минут.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_30min_users_count_4",
      },
      {
        name: "Был на веб. < 1 час.",
        description:
            "Количество людей, кто был на вебинаре меньше 60 минут.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_60min_users_count_4",
      },
      {
        name: "Был на веб. > 30 мин.",
        description:
            "Количество людей, кто был на вебинаре больше 30 минут.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_30min_g_users_count_4",
      },
      {
        name: "Был на веб. > 1 час.",
        description:
            "Количество людей, кто был на вебинаре больше 60 минут.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_60min_g_users_count_4",
      },
      {
        name: "Был на веб. > 2 час.",
        description:
            "Количество людей, кто был на вебинаре больше 120 минут.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_120min_g_users_count_4",
      },
      {
        name: "Был на веб. > 3 час.",
        description:
            "Количество людей, кто был на вебинаре больше 180 минут.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_180min_g_users_count_4",
      },
      {
        name: "Был на веб. > 4 час.",
        description:
            "Количество людей, кто был на вебинаре больше 240 минут.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_240min_g_users_count_4",
      },
      {
        name: "Доля кто был на веб. < 1 мин.",
        description:
            "Доля людей, кто был на вебинаре меньше 1 минуты от общего количества участников вебинара.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещенные пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар еще на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_1min_users_part_4",
      },
      {
        name: "Доля кто был на веб. < 5 мин.",
        description:
            "Доля людей, кто был на вебинаре меньше 5 минут от общего количества участников вебинара.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_5min_users_part_4",
      },
      {
        name: "Доля кто был на веб. < 15 мин.",
        description:
            "Доля людей, кто был на вебинаре меньше 15 минут от общего количества участников вебинара.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_15min_users_part_4",
      },
      {
        name: "Доля кто был на веб. < 30 мин.",
        description:
            "Доля людей, кто был на вебинаре меньше 30 минут от общего количества участников вебинара.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_30min_users_part_4",
      },
      {
        name: "Доля кто был на веб. < 1 час.",
        description:
            "Доля людей, кто был на вебинаре меньше 60 минут от общего количества участников вебинара.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_60min_users_part_4",
      },
      {
        name: "Доля кто был на веб. > 30 мин.",
        description:
            "Доля людей, кто был на вебинаре больше 30 минут от общего количества участников вебинара.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_30min_g_users_part_4",
      },
      {
        name: "Доля кто был на веб. > 1 час.",
        description:
            "Доля людей, кто был на вебинаре больше 60 минут от общего количества участников вебинара.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_60min_g_users_part_4",
      },
      {
        name: "Доля кто был на веб. > 2 час.",
        description:
            "Доля людей, кто был на вебинаре больше 120 минут от общего количества участников вебинара.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_120min_g_users_part_4",
      },
      {
        name: "Доля кто был на веб. > 3 час.",
        description:
            "Доля людей, кто был на вебинаре больше 180 минут от общего количества участников вебинара.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_180min_g_users_part_4",
      },
      {
        name: "Доля кто был на веб. > 4 час.",
        description:
            "Доля людей, кто был на вебинаре больше 240 минут от общего количества участников вебинара.<br>" +
            "<br>" +
            "Учитывается общее время нахождения пользователя нахождения на  вебинаре. Даже если вкладка у пользователя неактивная, а вебинар идёт, то время учитывается.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_240min_g_users_part_4",
      },
      {
        name: "Нажал кнопку на веб.",
        description:
            "Количество людей, кто нажал на кнопку или на картинку под вебинаром. В фильтре: Действия на вебинаре можно выбрать нужный тип. Если не выбирать, то будут учитываться все нажатия и на кнопку и на картинку, зависит от того, что Вы используете.<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_clickers_count_4",
      },
      {
        name: "CR из участника в нажав. кнопку",
        description:
            "Конверсия показывает сколько людей нажали на кнопку на вебинаре из общего количества участников вебинара.<br>" +
            "<br>" +
            "Формула расчета: Нажал кнопку на веб. / Участники вебинара * 100<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещенные пользователем вебинары после регистрации.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар еще на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_user_to_clicker_converse_4",
      },
      {
        name: "CR из нажав. кнопку в создат. заказа",
        description:
            "Конверсия показывает сколько людей создала заказ после нажатия на кнопку на вебинаре.<br>" +
            "<br>" +
            "Учитываются только люди кто посетил вебинар и у которых дата посещения вебинара = дате созданного заказа. В фильтре можно выбрать нужный вебинар и необходимые заказы по предложению или по тегу предложения.<br>" +
            "<br>" +
            "Формула расчёта: Создатели заказов / Нажал кнопку на веб. * 10<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации. Если не выбрано предложение заказа или тег предложения, то будут учитываться все заказы пользователя, который посетил вебинар и у которого дата создания заказа = дате участия на вебинаре. Если не выбран тип действия на вебинаре, то будут учитываться и нажатия на кнопку и нажатия на картинку.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "clicker_to_creator_converse_4",
      },
      {
        name: "CR из участника веб. в создат. заказа",
        description:
            "Конверсия показывает сколько людей создали заказ из общего количества участников вебинара.<br>" +
            "<br>" +
            "Учитываются только люди кто посетил вебинар и у которых дата посещения вебинара = дате созданного заказа. В фильтре можно выбрать нужный вебинар и необходимые заказы по предложению или по тегу предложения.<br>" +
            "<br>" +
            "Формула расчёта: Создатели заказов / Участники вебинара * 100<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации. И если не выбрано предложение заказа или тег предложения, то будут учитываться все заказы пользователя, который посетил вебинар и у которого дата создания заказа = дате участия на вебинаре.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_user_to_creator_converse_4",
      },
      {
        name: "CR из участника веб. в заявку",
        description:
            "Конверсия показывает сколько людей оставили заявку из общего количества участников вебинара.<br>" +
            "<br>" +
            "Учитываются только люди кто посетил вебинар и у которых дата посещения вебинара = дате заявки. В фильтре можно выбрать нужный вебинар и необходимые заявки.<br>" +
            "<br>" +
            "Формула расчёта: Создатели заказов / Участники вебинара * 100<br>" +
            "<br>" +
            "Если в фильтре название вебинара не выбрано, то учитываются все посещённые пользователем вебинары после регистрации. И если не выбрано предложение заказа или тег предложения, то будут учитываться все заказы пользователя, который посетил вебинар и у которого дата создания заказа = дате участия на вебинаре.<br>" +
            "<br>" +
            "Если пользователь посетил страницу вебинара, а вебинар ещё на начался, то пользователь считается посетившим вебинар. Поэтому, для точного учёта, в фильтре выберите время вебинара. Например, если у Вас автовебинар проходит с 19:00 до 22:00, в фильтре Время проведения вебинара выберите с 19:00 до 22:00 и если будет выбрано несколько дней, то будут учитываться пользователи, находящиеся на странице в этот период времени.<br>" +
            "<br>" +
            "Если пользователь был на вебинаре ДО выбранного типа регистрации и атрибуции, то он не попадёт в эту  метрику. Учитываются только люди после регистрации.",
        dataKey: "web_user_to_no_app_converse_4",
      },
      {
        name: "Посетил 1 вебинар",
        description:
            "Количество участников кто посетил 1 вебинар из выбранных в фильтре вебинаров.",
        dataKey: "web_1_users_count_4",
      },
      {
        name: "Посетил 2 веб.",
        description:
            "Количество участников кто посетил 2 вебинара из выбранных в фильтре вебинаров.",
        dataKey: "web_2_users_count_4",
      },
      {
        name: "Посетил 3 веб.",
        description:
            "Количество участников кто посетил 3 вебинара из выбранных в фильтре вебинаров",
        dataKey: "web_3_users_count_4",
      },
      {
        name: "Посетил 4 веб.",
        description:
            "Количество участников кто посетил 4 вебинара из выбранных в фильтре вебинаров.",
        dataKey: "web_4_users_count_4",
      },
      {
        name: "Посетил 5 веб.",
        description:
            "Количество участников кто посетил 5 вебинаров из выбранных в фильтре вебинаров.",
        dataKey: "web_5_users_count_4",
      },
      {
        name: "Посетил 6 веб.",
        description:
            "Количество участников кто посетил 6 вебинаров из выбранных в фильтре вебинаров.",
        dataKey: "web_6_users_count_4",
      },
      {
        name: "Посетил 7 веб.",
        description:
            "Количество участников кто посетил 7 вебинаров из выбранных в фильтре вебинаров.",
        dataKey: "web_7_users_count_4",
      },
    ],
  },
  {
    name: "Заказы",
    children: [
      {
        name: "Заказы",
        description:
          "Общее количество всех заказов пользователей кроме заказов со статусом “ЛОЖНЫЙ”, созданные в день участия вебинара или после него.\n" +
            "<br>" +
            "В фильтре можете выбрать показать заказы созданные только в день участия вебинара\n",
        dataKey: "orders_count_4",
      },
      {
        name: "Сумма заказов",
        description:
          "Сумма всех заказов пользователей кроме заказов со статусом “ЛОЖНЫЙ”, созданные в день участия вебинара или после него.",
        dataKey: "orders_sum_4",
      },
      {
        name: "Создатели заказов",
        description:
          "Количество людей у кого есть созданные заказы кроме заказов со статусом “ЛОЖНЫЙ” созданные в день участия вебинара или после него.",
        dataKey: "orders_creators_count_4",
      },
      {
        name: "Ср. сумма заказа",
        description:
          "Средняя сумма созданных заказов. <br/> <br/> Формула расчёта: Сумма заказов / Заказы",
        dataKey: "orders_sum_avg_4",
      },
      {
        name: "Ср. сумма заказов на 1 участника вебинара",
        description:
          "Средняя сумма заказов кроме статуса ложный, созданные в день участия вебинара или после него, на одного участника вебинара.  <br/> <br/> Формула расчёта: Сумма заказов / Участники вебинара",
        dataKey: "orders_sum_avg_web_4",
      },
      {
        name: "Заказы [Завершён]",
        description:
            "Общее количество всех заказов со статусом “Завершен”, созданные в день участия вебинара или после него",
        dataKey: "forders_count_4",
      },
      {
        name: "CR заказа в завершенный заказ",
        description:
            "Конверсия в полную оплату заказов,созданных в день участия вебинара или после него. <br/> <br/> Формула расчёта: Заказы [Завершён] / Заказы * 100",
        dataKey: "orders_to_forders_converse_4",
      },
      {
        name: "Сумма заказов [Завершён]",
        description:
          "Сумма заказов со статусом “Завершен”, созданные в день участия вебинара или после него.",
        dataKey: "forders_sum_4",
      },
      {
        name: "Заказы [Частично оплачен]",
        description:
            "Общее количество всех заказов со статусом: Частично оплачен”",
        dataKey: "porders_count_4",
      },
      {
        name: "Сумма заказов [Частично оплачен]",
        description:
            "Сумма заказов со статусом “Частично оплачен”, созданных после регистрации по рекламе.",
        dataKey: "porders_sum_4",
      },
      {
        name: "Заказы [Ожидаем оплаты]",
        description:
            "Общее количество всех заказов со статусом “Ожидаем оплаты”, созданных в день участия на вебинаре или после него.",
        dataKey: "worders_count_4",
      },
      {
        name: "Сумма заказов [Ожидаем оплаты]",
        description:
            "Сумма заказов со статусом “Ожидаем оплаты”, созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.",
        dataKey: "worders_sum_4",
      },
      {
        name: "Актуальные заказы",
        description:"Общее количество всех заказов со статусами: Завершён, Частично оплачен и Ожидаем оплаты. созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций",
        dataKey: "aorders_count_4",
      },
      {
        name: "Сумма актуальных заказов",
        description:"Сумма заказов со статусами:  Завершён, Частично оплачен и Ожидаем оплаты. Созданные после регистрации и подходящие под условия выбранного сегмента и атрибуций.",
        dataKey: "aorders_sum_4",
      },
    ],
  },
  {
    name: "Платежи",
    children: [
      {
        name: "Платежи",
        description:
          "Количество платежей со статусами (ПОЛУЧЕН, НАЧИСЛЕН НА ДЕПОЗИТ) по заказам созданным в день участия вебинара или после него.",
        dataKey: "payments_count_4",
      },
      {
        name: "Сумма платежей",
        description:
          "Сумма платежей пользователей со статусами (ПОЛУЧЕН, НАЧИСЛЕН НА ДЕПОЗИТ) по заказам созданным в день участия вебинара или после него с вычетом комиссии платёжных систем." +
            "<br>" +
            "Формула расчёта:<br> Сумма платежей с комиссией платёжных систем - Комиссия платёжных систем",
        dataKey: "payments_sum_4",
      },
      {
        name: "Сумма платежей с комиссией платёжных систем",
        description:
          "Сумма платежей пользователей со статусами (ПОЛУЧЕН, НАЧИСЛЕН НА ДЕПОЗИТ) по заказам созданным в день участия вебинара или после него БЕЗ вычета комиссии платёжных систем.",
        dataKey: "payments_sum_with_commission_4",
      },
      {
        name: "Комиссия платёжных систем",
        description: "Сумма комиссий платёжных систем платежей заказов созданных в день участия вебинара или после него.",
        dataKey: "payments_commission_sum_4",
      },
      {
        name: "Покупатели",
        description:
          "Количество людей у кого есть хоть один платёж по заказу созданному в день участия вебинара или после него.",
        dataKey: "buyers_count_4",
      },
      {
        name: "Средний чек платежа",
        description:
          "Средняя сумма платежей по заказам созданным в день участия вебинара или после него. <br/> <br/> Формула расчёта: Сумма платежей / Платежи.",
        dataKey: "payments_sum_avg_4",
      },
      {
        name: "CR из созд. заказа в покупателя",
        description:
          "Конверсия из создателя заказа в покупателя. <br/> <br/> Формула расчёта: Создатели заказов / Покупатели * 100.",
        dataKey: "creator_to_buyer_converse_4",
      },
      {
        name: "CR уч. вебинара в покупателя",
        description:
          "Конверсия из участника вебинар в покупателя. <br/> <br/> Формула расчёта: Участники вебинара / Покупатели * 100.",
        dataKey: "web_to_buyer_converse_4",
      },
      {
        name: "CR из суммы заказов в сумму платежей",
        description:
          "Конверсия из суммы заказов в сумму платежей. <br/> <br/> Формула расчёта: Сумма заказов / Сумма платежей * 100",
        dataKey: "orders_to_payments_converse_4",
      },
      {
        name: "Сумма платежей на 1 уч. вебинара.",
        description:
          "Сумма платежей на 1 участника вебинара. <br/> <br/> Формула расчёта: Сумма платежей / Участники вебинара.",
        dataKey: "payments_sum_per_web_4",
      },
      {
        name: "Ожидаемая сумма платежей",
        description:"Сумма оставшихся платежей по заказам со статусами: Частично оплачен и Ожидаем оплаты.\n" +
            "\n" +
            "<b>Формула:</b> Сумма заказов со статусом Ожидаем оплаты + сумма заказов со статусом частично оплачен - Сумма платежей с комиссией платёжных систем по частично оплаченным заказам" ,
        dataKey: "payments_sum_partial_remain_4",
      },
      {
        name: "Возвраты",
        description:
          "Количество платежей пользователей со статусами (ВОВЗРАЩЕН) по заказам созданным в день участия вебинара или после него.",
        dataKey: "refunds_count_4",
      },
      {
        name: "Сумма возвратов",
        description: "Сумма платежей со статусом - ВОЗВРАЩЕН по заказам созданным в день участия вебинара или после него.",
        dataKey: "refunds_sum_4",
      },
      {
        name: "Оформившие возврат",
        description: "Кол-во людей, у кого есть платёж со статусом ВОЗВРАЩЕН по заказам созданным в день участия вебинара или после него.",
        dataKey: "buyers_with_refund_count_4",
      },
      {
        name: "CR из уч. вебинара в оформившего возврат",
        description:
          "Конверсия из участника вебинара в оформившего возврат <br/> <br/> Формула расчёта: Оформившие возврат / Участники вебинара * 100",
        dataKey: "user_to_refunder_converse_4",
      },
      {
        name: "CR из покупателя в оформившего возврат",
        description:
          "Конверсия из людей, кто сделал платёж по заказам созданным в день участия вебинара или после него в оформивших возврат <br/> <br/> Формула расчёта: Оформившие возврат / Покупатели * 100",
        dataKey: "buyer_to_refunder_converse_4",
      },
    ],
  },
];
