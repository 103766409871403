import React, { FC, useEffect } from "react"

import { defaultGroupings, defaultMetrics } from "../../app/providers/redux/slices/reports/table/tableSlice"
import MainLayout from "../../shared/common/components/layouts/MainLayout"
import Loader from "../../shared/common/components/loader/Loader"
import PageHead from "../../shared/common/components/page-header/PageHeader"
import CreateMetricsModal from "../../shared/common/components/reports/CreateMetricsModal"
import { listKeysGroupings } from "../../shared/common/components/reports/Groupings"
import { listKeysMetrics } from "../../shared/common/components/reports/Metrics"
import FirstRegIcon from "../../shared/common/components/reports/params/attribution/icons/FirstRegIcon"
import LastRegIcon from "../../shared/common/components/reports/params/attribution/icons/LastRegIcon"
import TypeIcon from "../../shared/common/components/reports/params/attribution/icons/TypeIcon"
import Params from "../../shared/common/components/reports/params/Params"
import Chart from "../../shared/common/components/reports/un-chart/Chart"
import Table from "../../shared/common/components/reports/un-table/Table"
import UniversalReportTitle from "../../shared/common/components/reports/un-title/UniversalReportTitle"
import { chartDetails } from "../../shared/common/constants/details"
import { useActions } from "../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../shared/common/hooks/useTypedSelector"
import { ToggleChart } from "../../widgets/ToggleChart"

const Home: FC = () => {
    useEffect(() => {
        document.body.style.overflow = "scroll"
        document.body.style.overflowX = "auto"
    },[])
    const { isChartLoading, isTableLoading } = useTypedSelector(
        (state) => state.meta
    )

    const { isChartVisible } = useTypedSelector((state)=>state.chart)
    const { isCreateMetricsVisible } = useTypedSelector((state) => state.reports)

    const {
        setFilters,
        setMetrics,
        setGroupings,
        setFiltersTable,
        setCurrentReport,
        setCurrentSegment,
        setSortTable,
        setMetaCompare,
        setSeries,
        setMetricsChecked,
        setMetaChartFetching,
        setCurrentFunnel,
        setIsCreateMetricsVisible
    } = useActions()

    const attrItems = [
        {
            id: "type_applications",
            name: "Тип регистрации:",
            selectValues: [
                { name: "Нулевые заказы GC", id: "2", icon: null },
                { name: "Регистрации GC", id: "0", icon: null },
                { name: "SCAN", id: "1", icon: null },
                //{ name: "Ручное добавление", id: "3", icon: null },
            ],
            description: `Выбор типа регистрации по которым нужно создать отчёт:
                    <br><br>
                    Типы регистраци:
                    <br><br>
                    - SCAN - Это данные собираемые скриптом SCAN с форм;<br>
                    - Нулевые заказы GC - Заказы в GetCourse со стоимостью 0 руб.;<br>
                    - Регистрации GC -  Первая регистрация (попадания в базу) в GetCourse;<br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=504" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
            icon: <TypeIcon />,
        },
        {
            id: "attribution",
            name: "Атрибуция:",
            selectValues: [
                { name: "Первая регистрация", id: "1", icon: <FirstRegIcon /> },
                { name: "Последняя регистрация", id: "2", icon: <LastRegIcon /> },
            ],
            description: `Сервис SCAN позволяет определить к какой регистрации пользователя привязывать заказ. В сервисе 2 типа атрибуции:
                    <br><br>
                    - Первая регистрация <br>
                    - Последняя регистрация <br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=508" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
            icon: null,
        },
    ]

    useEffect(() => {
        const url = new URL(window.location.href)
        const currentParams = url.searchParams.get("params")
        const paramsObject = currentParams ? JSON.parse(currentParams) : {}
        const metricsFromParams = paramsObject?.metrics?.map((dataKey:string) =>
            listKeysMetrics.find((item: any) => item.dataKey === dataKey))
        const groupingsFromParams = paramsObject?.groupings?.map((dataKey:string) => listKeysGroupings.find((item: any) => item.dataKey === dataKey))
        const metricsData = metricsFromParams || defaultMetrics
        const groupingsData = groupingsFromParams || defaultGroupings
        setMetaCompare({ value:false })
        setMetrics({ data:metricsData })
        setGroupings({ data:groupingsData })
        //setFilters([])
        setFiltersTable([])
        setCurrentReport({})
        setSortTable({})
        setCurrentSegment(null)
        setSeries([])
        setTimeout(()=>{
            setMetaChartFetching({ value:true })
        },700)
        setMetricsChecked({ data:[ { label:defaultMetrics[0].label,name:defaultMetrics[0].dataKey } ] })

        return () => {
            setMetaCompare({ value:false })
            setMetrics({ data:defaultMetrics })
            setGroupings({ data:defaultGroupings })
            setFilters([])
            setFiltersTable([])
            setCurrentReport({})
            setSortTable({})
            setCurrentFunnel([])
            setCurrentSegment(null)
            setMetricsChecked({ data:[] })
        }
    }, [])

    return (
        <>
            <MainLayout pageTitle="Главная">
                <div className="page-wrapper">
                    <PageHead
                        title="Отчёт по регистрациям"
                        description={""}
                        reportInfo={true}
                    />
                <div className="page-content">
                    <Loader loading={isTableLoading || isChartLoading}>
                    <UniversalReportTitle />

                    <Params
                        attributionItems={attrItems}
                        detailsItems={chartDetails}
                        filtersFilter={[
                            "Пользователи",
                            "Регистрации",
                            "Шаги воронки",
                            "Заказы",
                            "Платежи",
                            "Вебинары",
                            "Просмотр страницы после регистрации"
                        ]}
                    />
                    </Loader>
                    <Loader loading={isTableLoading || isChartLoading}>
                        {!isChartVisible && <ToggleChart />}
                    </Loader>
                    {isChartVisible && <Chart />}
                    <Table />
                </div>
                </div>
            </MainLayout>

            {isCreateMetricsVisible && (<CreateMetricsModal title="Создание метрики в отчете по регистрациям" />)}
        </>
    )
}

export default Home
