import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IDashboardState} from "./types"

const initialState: IDashboardState = {
   dashboards:[],
    isFixedHeader:false,
    isOpenFixedHeader:false,
}

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {
        setDashboards: (state, action: PayloadAction<any[]>) => {
            state.dashboards = action.payload
        },
        setIsFixedHeader: (state, action: PayloadAction<boolean>) => {
            state.isFixedHeader = action.payload
        },
        setIsOpenFixedHeader: (state, action: PayloadAction<boolean>) => {
            state.isOpenFixedHeader = action.payload
        },
    },
})

export const dashboardSliceReducer = dashboardSlice.reducer
export const dashboardSliceActions = dashboardSlice.actions
