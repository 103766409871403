import React from "react";
import { Button } from "antd";
type Props = {
    styles?: React.CSSProperties;
    text:string
    onClick?:()=>void
}
const defaultStyles = {
    backgroundColor: "#dd0000",
    borderColor: "#dd0000",
    color: "white",
    borderRadius: "20px",
    padding: "5px 15px",
    fontSize: "14px",
    fontWeight: "bold",
    height: "auto",
}

const HelpButton = ({text,styles={},onClick}:Props) => {
    return (
        <Button
            type="primary"
            style={{...defaultStyles,...styles}}
            onClick={onClick}
        >
            {text}
        </Button>
    );
};

export default HelpButton;
