import React, {FC, useEffect, useMemo, useState} from "react"

import ReportTable from "./table/ReportTable"
import { useActions } from "../../../hooks/useActions"
import Loader from "../../loader/Loader"
import Filter from "../params/filter"
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useLocation} from "react-router-dom";

interface IReportProps {
    metrics: any[]
    apiUrl: string
    filtersFilter?: string[]
    withSegment?: boolean
    withFilter?: boolean
    customColumns?:any[]
}

const TableReport: FC<IReportProps> = ({ metrics, apiUrl,customColumns, filtersFilter, withSegment = true, withFilter = true }) => {

    const [ loadingTable, setLoadingTable ] = useState(false)
    const { setFilters, setFiltersTable, setCurrentReport, setCurrentSegment, setSortTable } = useActions()
    const {  isLoading: isLoadingAds  } = useTypedSelector(
        (state) => state.adsCabinet
    );
    const locationState = useLocation()

    useEffect(() => {
        //setFilters([])
        setFiltersTable([])
        setSortTable({})
        setCurrentReport({})
        setCurrentSegment(null)
    }, [])

    const loading = useMemo(()=>{
        const isFirstTab = !locationState.search.includes("tab=2")
        const isRkPages = (locationState.pathname.includes("/source/ads") || locationState.pathname.includes("/advert/rate"))  && isFirstTab
        if(isRkPages){
            return isLoadingAds
        }
        return loadingTable
    },[isLoadingAds,loadingTable,locationState])

    return (
        <div className="tableContainer">
            <Loader loading={loading}>
                {
                    withFilter ?
                        <Filter
                            filtersFilter={filtersFilter}
                            isCompare={false}
                            withSegment={withSegment}
                        />
                        : null
                }

                <ReportTable
                    apiUrl={apiUrl}
                    loadingTable={loading}
                    setLoadingTable={setLoadingTable}
                    externalMetrics={metrics}
                    customColumns={customColumns}
                />
            </Loader>
        </div>
    )
}

export default TableReport
