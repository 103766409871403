import React, { FC, useEffect, useState } from "react";

import { LoadingOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import axios from "axios";

import {BankCardIcon, DownloadScoreIcon} from "./Icons";
import Loader from "../../loader/Loader";
import {API_BASE_URL} from "../../../api/urls";

const customStyle = {
  color: "#000",
  fontSize:"16px",
  fontWeight:"500"
}
const Pay: FC = () => {
  const [orderHash, setOrderHash] = useState("");
  const [orderInfo, setOrderInfo] = useState({
    order_number: "",
    school_name: "",
    rate: "",
    payments: {
      robokassa: "",
    },
    currency: "",
    amount: "",
  });

  const [pageLoading, setPageLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const redirectToLink = (link: string) => {
    window.location.href = `${link}`;
  };

  const fetchPaymentData = (hash: string) => {
    setPageLoading(true);

    axios
      .get(`${API_BASE_URL}/expense/orders-info/${hash}`)
      .then((response) => {
        if (response.status === 200 && response.data.status !== "error") {
          setOrderInfo({
            order_number: response.data.order_number,
            school_name: response.data.school_name,
            rate: response.data.rate,
            payments: response.data.payments,
            currency: response.data.currency,
            amount: response.data.amount,
          });
        } else {
          console.error(response);
        }
        setPageLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setPageLoading(false);
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has("hash")) {
      const _hash = params.get("hash");

      fetchPaymentData(_hash || "");
      setOrderHash(_hash || "");
    }
  }, []);

  return pageLoading ? (
    <Loader loading />
  ) : (
    <div className="payment_page__paymentPageContainer">
      <div className="payment_page__paymentInfoContainer">
        <div>
          <p>№ заказа:</p>

            <p style={customStyle}>{orderInfo.order_number}</p>
        </div>

        <div>
          <p>Название тарифа:</p>

            <p style={customStyle}>{orderInfo.rate}</p>
        </div>

        <div>
          <p>Название проекта:</p>

            <p style={customStyle}>{orderInfo.school_name}</p>
        </div>
        <div>
          <p>К оплате:</p>

            <p style={{ fontSize: 18, fontWeight: 600,color:"#000" }}>
              {orderInfo.amount} {orderInfo.currency}
            </p>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          textAlign: "center",
          marginBottom: 8,
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        Выберите способ оплаты:
      </div>

      <Spin
        spinning={btnLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <div className="payment_page__payContainer">
          <Button
              className="payment_page__payButton"
              type="default"
              disabled={!orderHash}
              onClick={() => redirectToLink(`https://lk.scananalytics.ru/users/billing?tab=item-2`)}
          >
            <div>
              <DownloadScoreIcon /> <p>Скачать счёт для <br/> безналичной оплаты</p>
            </div>
          </Button>
          <Button
            className="payment_page__payButton"
            type="default"
            disabled={!orderHash}
            onClick={() => redirectToLink(orderInfo.payments.robokassa)}
          >
            <div>
              <BankCardIcon /> <p>Банковская карта</p>
            </div>
          </Button>


          {/*<Button*/}
          {/*  className="payment_page__payButton"*/}
          {/*  type="default"*/}
          {/*  disabled={!orderHash}*/}
          {/*  onClick={() => getPayLink(orderHash, "u_money")}*/}
          {/*>*/}
          {/*  <div>*/}
          {/*    {" "}*/}
          {/*    <UMoneyIcon /> <p>ЮMoney</p>{" "}*/}
          {/*  </div>*/}
          {/*</Button>*/}

          {/*<Button*/}
          {/*  className="payment_page__payButton"*/}
          {/*  type="default"*/}
          {/*  disabled={!orderHash}*/}
          {/*  onClick={() => getPayLink(orderHash, "qiwi")}*/}
          {/*>*/}
          {/*  <div>*/}
          {/*    {" "}*/}
          {/*    <QiwiIcon /> <p>Qiwi</p>{" "}*/}
          {/*  </div>*/}
          {/*</Button>*/}

          {/*<Button
            className="payment_page__payButton"
            type="default"
            disabled={!orderHash}
            onClick={() => getPayLink(orderHash, "payment_account")}
          >
            <div>
              {" "}
              <QiwiIcon /> <p>На расчетный счет</p>{" "}
            </div>
          </Button> */}
        </div>
      </Spin>

      <div className="payment_page__secureInfo">
        <LockOutlined />
        Все Ваши данные передаются в зашифрованном виде и не хранятся на нашем
        сервере.
      </div>
    </div>
  );
};

export default Pay;
