import {Form, Modal, Select} from "antd";
import {billApi} from "../../../../../../api/endpoints/billApi";
import {showErrorMessage} from "../../../../../../../../helpers/utils/ui";
import {useTypedSelector} from "../../../../../../hooks/useTypedSelector";
import React, {useEffect, useState} from "react";

interface Props {
    orderId:number;
    isVisible:boolean
    setIsVisible: React.Dispatch<React.SetStateAction<{ visible: boolean; order_id: number;order_date:string }>>
    orderDate:string
}
interface Req {
    id:number
    name:string
}
interface ReqList{
    loading:boolean,
    state:[] | Req[]
}


const DownloadScore = ({setIsVisible,isVisible,orderId,orderDate}:Props) => {
    const [ form ] = Form.useForm()
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const [reqList,setReqList] = useState<ReqList>({
        loading:true,
        state:[],
    })
    const fetchReqList = async (id:number) =>{
        try{
            const { data } = await billApi.getReqList(id)
            const arr = Object.values(data) as Req[]
            setReqList({loading:false,state:arr})
            form.setFieldsValue({requisites_id:arr[0].name})

        }catch(err){
            showErrorMessage("Произошла ошибка , попробуйте ещё раз позже.")
            setReqList({loading:false,state:[]})
        }
    }
    useEffect(()=>{
         if(selectedSchool && selectedSchool.id){
             setReqList({loading:true,state:[]})
             fetchReqList(Number(selectedSchool.id))
         }
    },[selectedSchool,isVisible])
    const onFinish =  async(values: {requisites_id:number}) => {
        try {
            if (selectedSchool?.id) {
                const requisites_id = Number(values.requisites_id) ? Number(values.requisites_id) : reqList.state.find((el)=>el.name === values.requisites_id.toString())?.id as number
                const body = {
                    school_id:Number(selectedSchool.id),
                    requisites_id,
                    order_id:orderId,
                    order_date:orderDate
                }
                await billApi.getScoreFile(body)
            }
            Modal.info({
                title: "Успех",
                content: "Счет успешно скачен."
            })
            form.resetFields()
           setIsVisible((prev)=>({visible:!prev.visible,order_id:1,order_date:""}))
        } catch (err) {
            showErrorMessage("Произошла ошибка , повторите попытку позже.")
        }
    }
    return (
        <Modal
            title="Выберите реквизиты"
            open={isVisible}
            onOk={() => form.submit()}
            onCancel={() => {
                form.resetFields()
                setIsVisible((prev)=>({visible:!prev.visible,order_id:1,order_date:""}))
            }}
            okText="Скачать"
            cancelText="Отмена"
            destroyOnClose
            width={"44vw"}
        >
            <Form
                form={form}
                name="downloadRequisite"
                onFinish={onFinish}
                layout={"vertical"}
            >
                <Form.Item
                    name="requisites_id"
                    label="Реквизиты"
                    rules={[ { required: true } ]}
                >
                    <Select
                        loading={reqList.loading}
                        disabled={reqList.loading}
                        optionFilterProp="children"
                        showSearch={true}
                        filterOption={(input, option) => (option?.label ?? "").includes(input)}
                        filterSort={(optionA:any, optionB:any) =>
                            (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                    >
                        {
                            reqList.state.length > 0 ?
                                <>
                                    {
                                        reqList.state.map((item:Req, index:number) => {
                                            return (
                                                <Select.Option
                                                    key={index}
                                                    value={item.id}
                                                    label={item.name}
                                                >
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </>
                                :
                                <Select.Option value="">Нет</Select.Option>
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default DownloadScore
