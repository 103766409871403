import React, { FC } from "react"

import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import CohortPage from "../../../shared/common/components/reports/cohort/CohortPage"

const Cohort: FC = () => {
    return (
        <MainLayout pageTitle="Когортный анализ">
            <div className="page-wrapper">
                <PageHead
                    title="Когортный анализ"
                />

                <div className="page-content">
                    <CohortPage />
                </div>
            </div>
        </MainLayout>
    )
}

export default Cohort
