import React, { FC } from "react"

const Banners: FC = () => {
    return (
        <div className="panel-body-sum-container universal-filter-chart-sum-panel">
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="webinar_users_count">0</span></h3>
                <div>Количество участников вебинара</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="commentators_count">0</span></h3>
                <div>Количество комментаторов</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="commentators_part">0</span> %</h3>
                <div>Доля комментаторов от участников вебинара</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="comments_count">0</span></h3>
                <div>Количество комментариев</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="clicks_count">0</span></h3>
                <div>Количество нажатий на кнопку</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="clickers_count">0</span></h3>
                <div>Нажавшие на кнопку</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="orders_creators_converse">0</span>  %</h3>
                <div>Конверсия из участника в создателя заказа</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="clickers_count_converse">0</span> %</h3>
                <div>Конверсия из участника вебинара в нажатие кнопки</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="clicks_count_converse">0</span> %</h3>
                <div>Конверсия из нажавших на кнопку в создателей заказа</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="orders_creators_count">0</span></h3>
                <div>Количество создателей заказа</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="orders_count">0</span></h3>
                <div>Количество созданных заказов</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="complete_orders_count">0</span></h3>
                <div>Количество завершённых заказов</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="complete_orders_converse">0</span> %</h3>
                <div>Конверсия заказа в полную оплату</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="payments_sum">0</span> p.</h3>
                <div>Сумма платежей</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="pay_per_user">0</span> p.</h3>
                <div>Сумма платежей на 1 участника вебинара</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="webinar_users_count_mobile">0</span></h3>
                <div>С телефона</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="webinar_users_count_desktop">0</span></h3>
                <div>С компьютера</div>
            </div>
            <div className="panel-body-sum">
                <h3 className="mb-1"><span id="webinar_users_count_tablet">0</span></h3>
                <div>С планшета</div>
            </div>
        </div>
    )
}

export default Banners
