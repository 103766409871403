import { useState } from "react"

import { Button } from "antd"

import AddRequisites from "./components/modals/AddRequisites"
import RequisitesTable from "./components/tables/RequisitesTable";
import EditRequisite from "./components/modals/EditRequisite";

const RequisitesTab = () => {
    const [ isAddVisible,setIsAddVisible ] = useState(false)
    const [ editOptions,setEditOptions ] = useState({
        visible:false,
        reqId:null
    })
    const [refetch,setRefetch] = useState(false)
    const handleAddClick = () => {
        setIsAddVisible(true)
    }
    return (
    <div style={{display:"flex",flexDirection:"column",rowGap:"20px"}}>
    <div><Button type={"primary"} onClick={()=>handleAddClick()}>Добавить реквизиты</Button></div>
        <AddRequisites setRefetch={setRefetch} isVisible={isAddVisible} setIsVisible={setIsAddVisible} />
        <RequisitesTable setRefetch={setRefetch} setEditOptions={setEditOptions} refetch={refetch} />
        {editOptions.visible && <EditRequisite isVisible={editOptions.visible} setIsVisible={setEditOptions} setRefetch={setRefetch} reqId={editOptions.reqId} />}
    </div>
    )

}
export default RequisitesTab