import React, { FC, useEffect, useState } from "react"

import { EditOutlined, LoadingOutlined } from "@ant-design/icons"
import { Button, Input, Space, Table } from "antd"
import { ColumnsType } from "antd/es/table"

import EditModal from "./EditModal"
import MainLayout from "../../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../../shared/common/components/page-header/PageHeader"
import { useActions } from "../../../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"

const WebinarList: FC = () => {

    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const { webinarData, isLoading, isUpdate } = useTypedSelector((state) => state.webinar)
    const { webinarGetData, webinarSetUpdate } = useActions()

    const [ webData, setWebData ] = useState(webinarData)
    const [ editModalVisible, setEditModalVisible ] = useState(false)
    const [ currentWebinarId, setCurrentWebinarId ] = useState(0)
    const [ currentWebinarName, setCurrentWebinarName ] = useState("")

    useEffect(() => {
        setWebData(webinarData)
    }, [ webinarData ])

    useEffect(() => {
        if (selectedSchool) {
            webinarGetData(selectedSchool.id)
        }
    }, [ selectedSchool ])

    useEffect(() => {
        if (selectedSchool) {
            webinarGetData(selectedSchool.id)
            webinarSetUpdate(false)
        }
    }, [ isUpdate ])

    const webinarListColumns: ColumnsType<any> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Название",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "URL вебинара",
            dataIndex: "url",
            key: "url",
            render: (value) => (
                <a href={value} target="_blank">{value}</a>
            )
        },
        {
            title: "Источник вебинара",
            dataIndex: "source",
            key: "source",
        },
        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            width: 60,
            render: (value, record) => (
                <div>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                            setCurrentWebinarId(record.id)
                            setCurrentWebinarName(record.name)
                            setEditModalVisible(true)
                        }}
                    />
                </div>
            )
        },
    ]

    const searchTable = (value: string) => {
        const _data = webinarData.filter((val) => {
            return `${val.id}`.toLowerCase().includes(value.toLowerCase()) ||
                `${val.name}`.toLowerCase().includes(value.toLowerCase()) ||
                `${val.url}`.toLowerCase().includes(value.toLowerCase()) ||
                `${val.source}`.toLowerCase().includes(value.toLowerCase())
        })

        setWebData(_data)
    }

    return (
        <MainLayout pageTitle="Список вебинаров">
            <div className="page-wrapper">
                <PageHead
                    title="Редактирование названий вебинара"
                />

                <div className="page-content">

                    <Space>
                        <Input
                            placeholder="Поиск по таблице..."
                            onChange={(e) => searchTable(e.target.value)}
                            style={{ width: 300, marginBottom: 20 }}
                            allowClear
                        />
                    </Space>

                    <Table
                        columns={webinarListColumns}
                        dataSource={webData}
                        loading={{
                            spinning: isLoading,
                            indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }}
                        scroll={{ x: 1300 }}
                        bordered
                    />
                </div>

                <EditModal
                    isVisible={editModalVisible}
                    setVisible={setEditModalVisible}
                    webinarId={currentWebinarId}
                    webinarName={currentWebinarName}
                />
            </div>
        </MainLayout>
    )
}

export default WebinarList
