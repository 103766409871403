import React from "react"

import CogApp from "./components"


const CohortPage = () => {

    return (
        <div className="cog-app">
            <CogApp />
        </div>
    )
}

export default CohortPage
