import {useEffect, useRef, useState} from 'react';

export const useTitles = () => {
    const [portalContents, setPortalContents] = useState<any>([]);
    const previousPortalContents = useRef(portalContents);
    useEffect(() => {
        const interval = setInterval(() => {
            const yAxisTextGroups = document.querySelectorAll('.apexcharts-yaxis-texts-g');

            const newPortalContents:any[] = [];
            yAxisTextGroups.forEach(function (group) {
                const tspans = group.querySelectorAll('tspan');

                let minValue = Infinity;

                tspans.forEach(function (tspan) {
                    const value = parseFloat(tspan?.innerHTML);

                    if (!isNaN(value) && value < minValue) {
                        minValue = value;
                    }
                });

                if (minValue !== Infinity) {
                    const svgElement = group?.nextElementSibling;
                    if (svgElement) {
                        const textElement = svgElement.querySelector('.apexcharts-text.apexcharts-yaxis-title-text');
                        const rect = group.getBoundingClientRect();
                        const parser = new DOMParser();
                        const content = parser.parseFromString(`${textElement?.innerHTML}`, 'text/html').body.textContent;

                        newPortalContents.push({
                            left: rect.left,
                            top: rect.bottom + window.scrollY,
                            content: content,
                        });
                    }
                }
            });
            if (JSON.stringify(previousPortalContents.current) !== JSON.stringify(newPortalContents)) {
                setPortalContents(newPortalContents);
                previousPortalContents.current = newPortalContents;
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return portalContents;
};

