import React from "react"

import IconFilter from "../IconFilter"

const NumberRangeView = ({ icon, name, start, end }: any) => {
    return (
        <>
            <IconFilter icon={icon} />
            &nbsp;{name}:
            &nbsp;{start}&nbsp;—&nbsp;{end}
        </>
    )
}

export default NumberRangeView
