import { useState } from "react";

interface PaginationState {
    page: number;
    pageSize: number;
    totalCount: number;
}

type SetPaginationState = React.Dispatch<React.SetStateAction<PaginationState>>;

const usePagination = (pageSize = 10, totalCount = 0): [PaginationState, SetPaginationState, (rows: any[], page: number, pageSize: number) => any[]] => {
    const [paginationState, setPaginationState] = useState<PaginationState>({
        page: 1,
        pageSize,
        totalCount
    });

    const getPaginatedRows = (rows: any[], page: number, pageSize: number): any[] => {
        const startIndex = (page - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, rows.length)
        return rows.slice(startIndex, endIndex);
    }

    return [paginationState, setPaginationState, getPaginatedRows];
}

export default usePagination;
