import React, { FC } from "react"

import {
    UserOutlined,
    UserAddOutlined,
    ShoppingOutlined,
    DollarOutlined,
    VideoCameraOutlined,
    DingdingOutlined, UserSwitchOutlined, FilterOutlined,
    ScanOutlined
} from "@ant-design/icons"

interface IconFilterProps {
    icon: string
}

const IconFilter: FC<IconFilterProps> = ({ icon }) => {
    const switchIcon: {[key: string]: React.ReactElement} = {
        UserOutlined: <UserOutlined />,
        UserAddOutlined: <UserAddOutlined />,
        ShoppingOutlined: <ShoppingOutlined />,
        DollarOutlined: <DollarOutlined />,
        VideoCameraOutlined: <VideoCameraOutlined />,
        DingdingOutlined: <DingdingOutlined />,
        UserSwitchOutlined: <UserSwitchOutlined />,
        FilterOutlined: <FilterOutlined />,
        ScanOutlined:<ScanOutlined />
    }

    return switchIcon[icon] || null
}

export default IconFilter
