import React, {CSSProperties} from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styles from './AdButton.module.scss';
import listTextSelector from "../../../../../shared/common/components/reports/params/filter/ListTextSelector";
type Props = {
    text:string | React.ReactNode;
    onClick?:any
    imgSrc:string
    imgStyles?:CSSProperties
    buttonStyles?:CSSProperties
    textStyles?:CSSProperties
}

const AdButton = ({text,onClick,imgSrc,imgStyles={},buttonStyles={},textStyles={}}:Props) => {
    return (
        <Button
            className={styles['ad-button']}
            style={buttonStyles}
            icon={<img style={imgStyles} alt={"Иконка рекламы"} src={imgSrc} className={styles['ad-button-icon']} />}
            onClick={onClick}
        >
            <span className={styles['ad-button-text']} style={textStyles}>{text}</span>
        </Button>
    );
};

export default AdButton;
