import { FC, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { routeNames } from "../../../constants/routes";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

const GlobalErrorWrapper: FC = ({ children }) => {
  const navigate = useNavigate();

  const { setAppGlobalError } = useActions();
  const { isGlobalError } = useTypedSelector((state) => state.app);

  useEffect(() => {
    if (isGlobalError) {
      navigate(routeNames.error);
    }
  }, [isGlobalError]);

  return <>{children}</>;
};

export default GlobalErrorWrapper;
