import { FC, useState } from "react"

import {
    CloseOutlined,
    PushpinOutlined,
    VerticalLeftOutlined,
    VerticalRightOutlined,
} from "@ant-design/icons"
import { Button, Popover, Space, Tag } from "antd"
import classNames from "classnames"

import { isAdvert } from "../../../../../../helpers/utils/functions"
import { showErrorMessage, showSuccessMessage } from "../../../../../../helpers/utils/ui"
import { unApi } from "../../../../api/endpoints/reports/unApi"
import { useActions } from "../../../../hooks/useActions"

const PinPopover: FC<any> = ({ dataKey, setVisible, setFrozens }) => {
    const handlePinLeft = () => {
        setFrozens((prev: any) => {
            let newData = { ...prev }
            newData[dataKey] = "left"
            return newData
        })
        setVisible(false)
    }
    const handlePinRight = () => {
        setFrozens((prev: any) => {
            let newData = { ...prev }
            newData[dataKey] = "right"
            return newData
        })
        setVisible(false)
    }
    const handlePinReset = () => {
        setFrozens((prev: any) => {
            let newData = { ...prev }
            delete newData[dataKey]
            return newData
        })
        setVisible(false)
    }

    return (
    <div>
      <Space size="small">
        <Button
            onClick={handlePinLeft}
            icon={<VerticalRightOutlined />}
            size="small"
            title="Закрепить слева"
        />
        <Button
            onClick={handlePinReset}
            icon={<CloseOutlined />}
            size="small"
            title="Сбросить закрепление"
        />
        <Button
            onClick={handlePinRight}
            icon={<VerticalLeftOutlined />}
            size="small"
            title="Закрепить справа"
        />
      </Space>
    </div>
    )
}

const FilterTag: FC<any> = ({ filter, onClose }) => {
    if (!filter) return null

    const type: any = {
        less: "<",
        equal: "=",
        over: ">",
    }

    return (
    <div style={{ marginTop: 3 }}>
      <Tag closable onClose={onClose}>
        {type[filter.action]} {filter.value}
      </Tag>
    </div>
    )
}

const HeaderCell: FC<any> = ({ column, filters, sort, setFrozens }) => {
    const { dataKey, title } = column
    const [ visiblePin, setVisiblePin ] = useState(false)
    const { setSortTable, setFiltersTable, setMetaTableFetching } = useActions()

    const handleSort = () => {
        let newSort: any = {}
        if (sort[dataKey] === "ASC") newSort[dataKey] = "DESC"
        else newSort[dataKey] = "ASC"
        if (sort[dataKey] === "DESC") setSortTable({})
        else setSortTable(newSort)

        setTimeout(() => setMetaTableFetching({ value:true }), 50)
    }

    const onRemoveFilter = () => {
        let newFilter = { ...filters }
        delete newFilter[dataKey]

        setFiltersTable(newFilter)
        setTimeout(() => setMetaTableFetching({ value:true }), 50)
    }


    return (
    <div className="table-header-cell" style={isAdvert()}>
      <div style={{ cursor: "pointer" }} onClick={handleSort}>
        <div
            className={classNames(
                "table-header-sort",
                "table-header-withIcon",
                "table-header-sort-right",
                {
                    "sort-asc": sort[dataKey] === "ASC",
                    "sort-desc": sort[dataKey] === "DESC",
                }
            )}
        >
          {title}
        </div>
      </div>
        {location.pathname !== "/advert/rate" && <Space size={5} className="table-header-cell-buttons">
        <Popover
            content={
            <PinPopover
                dataKey={dataKey}
                setVisible={setVisiblePin}
                setFrozens={setFrozens}
            />
            }
            trigger="click"
            visible={visiblePin}
            onVisibleChange={setVisiblePin}
            placement="bottom"
        >
          <Button
              icon={<PushpinOutlined />}
              size="small"
              title="Закрепить колонку"
          />
        </Popover>
      </Space>}
      <FilterTag filter={filters[dataKey]} onClose={onRemoveFilter} />
    </div>
    )
}

export default HeaderCell
