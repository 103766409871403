import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IReportsState } from "./types";
import {
  switchInterval,
  updateQueryParams,
} from "../../../../../../../helpers/utils/functions";

const initialState: IReportsState = {
  isLoading: false,
  reports: [],
  savedDashboards: [],
  currentReport: {},
  currentDashboard: {},
  isCreateMetricsVisible: false,
};
export const reportsSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    setReportsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setReports: (state, action: PayloadAction<any[]>) => {
      let reports = action.payload;

      if (Array.isArray(reports)) {
        reports.forEach((rep) => {
          Object.keys(rep.values).forEach((key) => {
            if (
              [
                "filters",
                "filtersReq",
                "filtersTable",
                "selFilters",
                "sortTable",
              ].includes(key)
            ) {
              if (rep.values[key].length === 0) {
                rep.values[key] = {};
              }
            }
          });
        });

        state.reports = reports;
      } else {
        console.error("Error: 'reports' is not an array:", reports);
      }
    },
    setSavedDashboards: (state, action: PayloadAction<any[]>) => {
      state.savedDashboards = action.payload;
    },
    setCurrentReport: (state, action: PayloadAction<any>) => {
      const report = JSON.parse(JSON.stringify(action.payload));

      if (report?.values?.period?.interval) {
        const { start, end } = switchInterval(report.values.period.interval);
        if (start && end) {
          report.start = start.format("YYYY-MM-DD");
          report.end = end.format("YYYY-MM-DD");
        }
      }
      updateQueryParams(report, "currentReport");
      state.currentReport = report;
    },
    setCurrentDashboard: (state, action: PayloadAction<any>) => {
      const payload = JSON.parse(JSON.stringify(action.payload));
      state.currentDashboard = {
        ...payload,
        values: JSON.parse(payload.values),
      };
    },
    setIsCreateMetricsVisible: (state, action: PayloadAction<boolean>) => {
      state.isCreateMetricsVisible = action.payload;
    },
  },
});

export const reportsSliceActions = reportsSlice.actions;
export const reportsSliceReducer = reportsSlice.reducer;
