import React, { FC } from "react"

import firstRegIcon from "../../../../../assets/first-reg.png"

const FirstRegIcon: FC = () => {
    return (
        <span style={{ marginRight: 5, height: 20 }}>
            <img width={20} height={20} src={firstRegIcon} style={{ verticalAlign: "unset" }}  alt="" />
        </span>
    )
}

export default FirstRegIcon
