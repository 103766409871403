import React, { FC, useState } from "react"

import { Button } from "antd"
import * as _ from "lodash"

import { useActions } from "../../../../../hooks/useActions"
import { useTypedSelector } from "../../../../../hooks/useTypedSelector"


const MetricsSelectModal: FC<any> = ({ dataKey, setVisible }) => {

    const { metrics, metricsChecked } = useTypedSelector((state) => state.table)
    const { setMetricsChecked, setMetaChartFetching } = useActions()

    const [ values, setVales ] = useState([ null, null, null ])

    const selectMetric = (index: number) => {

        const metric = metrics.find((item: any) => item.dataKey === dataKey)

        if (metric) {
            const _metrics = _.cloneDeep(metricsChecked)
            _metrics[index] = { name: metric.dataKey, label: metric.name }

            let _values: any = _.cloneDeep(values)
            _values[index] = metric

            setVales(_values)
            setMetricsChecked({data:_metrics})
            setMetaChartFetching({value:true})
            setVisible(false)
        }
    }

    return (
        <div className="metric-select-modal-wrapper">
            <Button type="text" onClick={() => selectMetric(0)}>
                Ось X
            </Button>

            <Button type="text" onClick={() => selectMetric(1)}>
                Ось Y
            </Button>

            <Button type="text" onClick={() => selectMetric(2)}>
                Диаметр
            </Button>
        </div>
    )
}

export default MetricsSelectModal
