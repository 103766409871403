import { staffApi } from "../../../../../shared/common/api/endpoints/staffApi"
import { handleResponseError } from "../../../../../shared/common/api/middleware"
import { TAppDispatch } from "../../store"
import { staffSliceActions } from "./staffSlice"

export const staffActions = {
    ...staffSliceActions,

    staffGetData: (schoolId: number | string) => async (dispatch: TAppDispatch) => {
        dispatch(staffActions.staffSetLoading(true))

        staffApi.fetchData(schoolId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(staffActions.staffSetData(response.data))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(staffActions.staffSetLoading(false))
            })
    },

    staffGetLists: (schoolId: number | string, onFinish?: () => void) => async (dispatch: TAppDispatch) => {
        dispatch(staffActions.staffSetLoading(true))

        staffApi.getLists(schoolId)
            .then((response) => {
                if (response.status === 200) {
                    console.log('here',response.data)
                    const stringifyIds = (row:any) => {
                        if (row.id) {
                            return { ...row, id: row.id.toString() };
                        }
                        return row
                    }
                    const staffListWithIdAsString = {
                        list_ads_cabinet:response.data?.list_ads_cabinet?.map(stringifyIds),
                        list_offers:response.data?.list_offers?.map(stringifyIds)
                    }
                    dispatch(staffActions.staffSetListsData(staffListWithIdAsString))
                    onFinish && onFinish()
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                if (!onFinish) {
                    dispatch(staffActions.staffSetLoading(false))
                }
            })
    },
    // TODO: прописать типы
    staffAddUser: (schoolId: number, data: any, onFinish: () => void, onFormError: (errors: {[key: string]: string}) => void) => async (dispatch: TAppDispatch) => {
        dispatch(staffActions.staffSetLoading(true))

        staffApi.addStaff(schoolId, data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(staffActions.staffSetUpdate(true))
                    onFinish()
                }
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    onFormError(err.response.data)
                }

                handleResponseError(dispatch, err)
                dispatch(staffActions.staffSetLoading(false))
            })
    },

    staffGetUserData: (schoolId: number, userId: number) => async (dispatch: TAppDispatch) => {
        dispatch(staffActions.staffSetLoading(true))

        staffApi.getCurrentStaff(schoolId, userId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(staffActions.staffSetUpdateData(response.data))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(staffActions.staffSetLoading(false))
            })
    },

    staffUpdateUser: (schoolId: number, userId: number, data: any, onFinish: () => void, onFormError: (errors: {[key: string]: string}) => void) => async (dispatch: TAppDispatch) => {
        dispatch(staffActions.staffSetLoading(true))

        staffApi.updateCurrentStaff(schoolId, userId, data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(staffActions.staffSetUpdate(true))
                    onFinish()
                }
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    onFormError(err.response.data)
                }

                handleResponseError(dispatch, err)
                dispatch(staffActions.staffSetLoading(false))
            })
    },

    staffDeleteUser: (schoolId: number, userId: number) => async (dispatch: TAppDispatch) => {
        dispatch(staffActions.staffSetLoading(true))

        staffApi.deleteCurrentStaff(schoolId, userId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(staffActions.staffSetUpdate(true))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
                dispatch(staffActions.staffSetLoading(false))
            })
    },

}
