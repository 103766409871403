import React, { useEffect, useState } from "react"

import {Button, Table} from "antd"

import {Column, getUniqueEmailsForFilters} from "../../../../../helpers/utils/functions"
import { unApi } from "../../../../../shared/common/api/endpoints/reports/unApi"
import CopyButton from "../ui/CopyButton";
import { EditOutlined} from "@ant-design/icons";
import EditPartner from "../modals/EditPartners";
import CustomFilter from "../ui/CustomFilter";
import {FilterDropdownProps} from "antd/lib/table/interface";

const Partners = () => {
    const [ editPartner,setEditPartner ] = useState({
        visible:false,
        id:0
    })
    const [ tableData,setTableData ] = useState([])
    const fetchTableData = async () => {
        await   unApi.getTablePartners().then((res)=>{
            setTableData(res.data.rows)
        })
    }
    const handleEditPartner = (id:number) => {
        setEditPartner({ visible: true,id })
    }
    useEffect(()=>{
        fetchTableData()
    },[editPartner])
    const columns:Column[] = [
        {
            title: "Дата регистрации партнёра",
            dataIndex: "partner_reg_date",
            key: "partner_reg_date",
            sorter: (a:{partner_reg_date:string}, b:{partner_reg_date:string}) => new Date(a.partner_reg_date).getTime() - new Date(b.partner_reg_date).getTime(),
            render: (text: string) => {
                const date = new Date(text)
                const formattedDate = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`
                return <span>{formattedDate}</span>
            }
        },
        { title: "id партнёра", dataIndex: "partner_id", key: "partner_id" },
        { title: "Имя партнёра", dataIndex: "partner_name", key: "partner_name",
            render: (text) => {
                if (/<a/i.test(text)) {
                    return <span dangerouslySetInnerHTML={{ __html: text }} />
                }
                return text
            },
        },
        {
            title: "Email партнёра",
            dataIndex: "partner_email",
            key: "partner_email",
            filterDropdown: (props:FilterDropdownProps )=>(
                <CustomFilter {...props} options={getUniqueEmailsForFilters("partner_email", tableData)} title={"Поиск по Email"} />
            ),
            onFilter: (value, record) => {
                const partnerEmail = record.partner_email.toLowerCase();
                return partnerEmail.includes(value.toLowerCase());
            }
        },
        { title: "Рефералы 1 ур.", dataIndex: "first_level_count_referral", key: "first_level_count_referral" },
        { title: "Покупатели рефералы 1 ур.", dataIndex: "first_level_buyers_referral", key: "first_level_buyers_referral" },
        { title: "Платежи рефералов 1 ур.", dataIndex: "first_level_payments", key: "first_level_payments" },
        { title: "Начисления за рефералов 1 ур.", dataIndex: "first_level_calculation", key: "first_level_calculation" },
        {title:"Рефералы 2ур.",dataIndex:"second_level_count_referral",key:"second_level_count_referral"},
        { title: "Покупатели рефералы 2 ур.", dataIndex: "second_level_buyers_referral", key: "second_level_buyers_referral" },
        { title: "Платежи рефералов 2 ур.", dataIndex: "second_level_payments", key: "second_level_payments" },
        { title: "Тип процента", dataIndex: "partner_conditions", key: "partner_conditions" },
        { title: "% комиссии", dataIndex: "commission_percent", key: "commission_percent" },
        { title: "Начисления за рефералов 2 ур.", dataIndex: "second_level_calculation", key: "second_level_calculation" },
        { title: "Начислено всего (начисления реф. 1 и 2 уровня)", dataIndex: "total_commission_amount", key: "total_commission_amount" },
        { title: "Выплачено (общая сумма выплат по этому партнёру)", dataIndex: "total_payments_amount", key: "total_payments_amount" },
        { title: "Осталось выплатить (начислено всего - выплачено)", dataIndex: "payment_balance", key: "payment_balance" },
        { title: "Комментарий", dataIndex: "comment", key: "comment" },
        {
            title:"Действия",
            dataIndex:"actions",
            key:"actions",
            render: (value, record) => (
                <div style={{ display:"flex",columnGap:"4px" }}>
                    <Button title={"Редактирование партнёра"} icon={<EditOutlined />} onClick={()=>handleEditPartner(record.partner_id)} />
                </div>
            ),
        }
    ]

    return (
        <>
            <CopyButton tableData={tableData} columns={columns} styles={{marginBottom:"10px"}} />
            <div style={{ overflow:"auto" }}>
                <Table size={"small"} columns={columns} dataSource={tableData} />;
            </div>
            {editPartner.visible && <EditPartner visible={editPartner.visible} setVisible={setEditPartner} id={editPartner.id} />}
        </>
    )
}
export default Partners
