import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const CrmTariffs: FC = () => {

    const metrics = [
        { name: "id тарифа", dataKey: "id" },
        { name: "Название тарифа", dataKey: "tariff_name" },
        { name: "На сколько месяцев", dataKey: "number_of_months" },
        { name: "Сумма", dataKey: "tariff_sum" },
        { name: "Валюта", dataKey: "tariff_currency" },
        { name: "Количество дней", dataKey: "tariff_days" },
        { name: "Сумма в день", dataKey: "amount_per_day" },
        { name: "Статус", dataKey: "tariff_status" },
    ]

    return (
        <MainLayout pageTitle="Тарифы">
            <div className="page-wrapper">
                <PageHead title="Тарифы" />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "" ]}
                        withSegment={false}
                        withFilter={false}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default CrmTariffs
