import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IAccurals, IInitialState, IPayout, IReferals, IStatistics } from "./types"

const initialState:IInitialState = {
    accuralsList:[],
    referalsList:[],
    payoutList:[],
    statistics: null

}

export const partnerSlice = createSlice({
    name: "partner",
    initialState: initialState,
    reducers: {
        setAccurals(state,action:PayloadAction<IAccurals[]>){
            state.accuralsList = action.payload
        },
        setReferals(state,action:PayloadAction<IReferals[]>){
            state.referalsList = action.payload
        },
        setPayouts(state,action:PayloadAction<IPayout[]>){
            state.payoutList = action.payload
        },
        setStatistics(state,action:PayloadAction<IStatistics>){
            state.statistics = action.payload
        }
    }
})

export const partnerSliceActions = partnerSlice.actions
export const partnerSliceReducer = partnerSlice.reducer
