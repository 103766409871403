import { webinarApi } from "../../../../../shared/common/api/endpoints/webinarApi";
import { handleResponseError } from "../../../../../shared/common/api/middleware";
import { TAppDispatch } from "../../store";
import { webinarSliceActions } from "./webinarSlice";

export const webinarActions = {
  ...webinarSliceActions,

  webinarGetData:
    (schoolId: number | string) => async (dispatch: TAppDispatch) => {
      dispatch(webinarActions.webinarSetLoading(true));

      webinarApi
        .fetchWebinarList(schoolId)
        .then((response) => {
          if (response.status === 200) {
            dispatch(webinarActions.webinarSetData(response.data));
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err);
        })
        .finally(() => {
          dispatch(webinarActions.webinarSetLoading(false));
        });
    },

  webinarUpdateTitle:
    (
      schoolId: number,
      webinarId: number,
      title: string,
      onFinish: () => void,
      onFormError: (errors: { [key: string]: string }) => void
    ) =>
    async (dispatch: TAppDispatch) => {
      dispatch(webinarActions.webinarSetLoading(true));

      webinarApi
        .updateWebinarTitle(schoolId, webinarId, title)
        .then((response) => {
          if (response.status === 200) {
            dispatch(webinarActions.webinarSetUpdate(true));
            onFinish();
          }
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            onFormError(err.response.data);
          }

          handleResponseError(dispatch, err);
          dispatch(webinarActions.webinarSetLoading(false));
        });
    },
};
