import React, {FC, useEffect, useState} from "react"

import {
    CloseOutlined,
    DownOutlined,
    FilterFilled,
    LinkOutlined,
    LoadingOutlined,
} from "@ant-design/icons"
import { Button, Input, Popover, Spin } from "antd"
import { useLocation } from "react-router-dom"

import { useActions } from "../../../../../hooks/useActions"
import { useTypedSelector } from "../../../../../hooks/useTypedSelector"

interface ICompareBlockProps {
  prefix: 1 | 2;
}

const CompareSegment: FC<ICompareBlockProps> = ({ prefix }) => {
    const location = useLocation()

    const { segments, segmentsLoading } = useTypedSelector(
        (state) => state.filter
    )
    const { compareSegments,segmentId } = useTypedSelector((state) => state.compare)
    const { setCompareSegment, setCompareFilter } = useActions()

    const [ isPopoverVisible, setIsPopoverVisible ] = useState(false)
    const [ searchVal, setSearchVal ] = useState("")

    const activateSegment = (id: number | null ) => {
        const segment = segments.find((seg: any) => seg.id === id)
        if (segment) {
            setCompareSegment({ index: prefix, segment: segment.values.filters })
            setCompareFilter({ index: prefix, filters: segment.values.filters })
            setIsPopoverVisible(false)
        }else{
            setCompareSegment({ index: prefix, segment: {} })
            setCompareFilter({ index: prefix, filters: []})
            setIsPopoverVisible(false)
        }
    }
useEffect(()=>{
   if(segmentId){
       activateSegment(segmentId)
   }else{
       activateSegment(null)
   }
},[segmentId])
    const clearAllFilters = () => {
        setCompareSegment({ index: prefix, segment: {} })
        setCompareFilter({ index: prefix, filters: [] })
    }

    const handleVisiblePopover = (vis: boolean) => {
        if (segments.length > 0) setIsPopoverVisible(vis)
        else setIsPopoverVisible(false)
    }

    const copySegment = (hash: string) => {
        const segmentLink = `https://lk.scananalytics.ru${location.pathname}?filter_id=${hash}`
        navigator.clipboard.writeText(segmentLink)
    }

    const listSegment = () => (
    <div style={{ width: 300 }}>
      <Input
          value={searchVal}
          onChange={(e) => setSearchVal(e.target.value)}
          placeholder="Найти сегмент"
          allowClear
      />
      <div
          style={{
              maxHeight: 300,
              overflowY: "auto",
              marginTop: 7,
              marginLeft: "-16px",
              marginRight: "-16px",
          }}
      >
        {segments
            .filter((seg: any) =>
                seg.values.name.toLowerCase().includes(searchVal.toLowerCase())
            )
            .map((seg: any) => (
            <div className="segment-list-item" key={seg.id}>
              <div
                  onClick={() => activateSegment(seg.id)}
                  className="segment-list-name"
              >
                {seg.values.name}
              </div>
              <div
                  className="segment-list-item-controls"
                  style={{ marginLeft: "auto", whiteSpace: "nowrap" }}
              >
                <Button
                    onClick={() => copySegment(seg.hash)}
                    icon={<LinkOutlined />}
                    size="small"
                    title="Копировать"
                    type="link"
                    style={{ color: "#9a9a9a" }}
                />
              </div>
            </div>
            ))}
      </div>
    </div>
    )

    return (
        <>
      <Spin
          spinning={segmentsLoading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Popover
              content={listSegment}
              open={isPopoverVisible}
              onOpenChange={handleVisiblePopover}
              trigger="click"
              placement="bottomLeft"
              showArrow={false}
              overlayStyle={{ paddingTop: 0 }}
          >
            <Button
                icon={<FilterFilled />}
                style={{ display: "inline-flex", alignItems: "center" }}
            >
              Сегмент:&nbsp;
              {Object.keys(compareSegments[`compareSegment${prefix}`])
                  .length === 0 ? (
                      "0 условий"
                  ) : (
                <span
                    style={{
                        width: 170,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                    }}
                >
                  {
                      segments.find(
                          (seg: any) =>
                              seg.values.filters ===
                        compareSegments[`compareSegment${prefix}`]
                      )?.values.name
                  }
                </span>
                  )}
              {segments.length > 0 && <DownOutlined />}
            </Button>
          </Popover>

          {Object.keys(compareSegments[`compareSegment${prefix}`]).length ===
          0 ? null : (
            <Button
                onClick={clearAllFilters}
                icon={<CloseOutlined />}
                title="Очистить"
            />
              )}
        </div>
      </Spin>
        </>
    )
}

export default CompareSegment
