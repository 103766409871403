import { filterSliceActions } from "./filterSlice"
import { showSuccessMessage } from "../../../../../../../helpers/utils/ui"
import { segmentsApi } from "../../../../../../../shared/common/api/endpoints/params/segmentsApi"
import { handleResponseError } from "../../../../../../../shared/common/api/middleware"
import { TAppDispatch } from "../../../../store"
import {ISegment} from "./types";

export const filterActions = {
    ...filterSliceActions,

    getSegments: (schoolId: string | number) => async (dispatch: TAppDispatch) => {
        dispatch(filterActions.setSegmentsLoading(true))

        segmentsApi.getSegments(schoolId,location.pathname)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(filterActions.setSegments(response.data))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err, {
                    "404": "Запрашиваемое действие не найдено."
                })
            })
            .finally(() => {
                dispatch(filterActions.setSegmentsLoading(false))
            })
    },
    getFunnels: (schoolId: string | number) => async (dispatch: TAppDispatch) => {
    dispatch(filterActions.setSegmentsLoading(true))

    segmentsApi.getFunnels(schoolId,location.pathname)
        .then((response) => {
            if (response.status === 200) {
                dispatch(filterActions.setFunnels(response.data))
                const url = new URL(window.location.href)
                const currentParams = url.searchParams.get('params')
                const paramsObject = currentParams ? JSON.parse(currentParams) : {}
                const funnelArray = paramsObject?.funnel
                if (funnelArray) {
                    const funnelIds = funnelArray
                   const currentFunnelsFromParams:ISegment[] = []
                    if(response.data.length === 0){
                        return
                    }
                    response.data.forEach((funnel:ISegment)=>{
                        if(funnelIds.includes(funnel.id)){
                            currentFunnelsFromParams.push(funnel)
                        }
                    })
                    currentFunnelsFromParams.length > 0 &&  dispatch(filterActions.setCurrentFunnel(currentFunnelsFromParams))
                }
            }
        })
        .catch((err) => {
            handleResponseError(dispatch, err, {
                "404": "Запрашиваемое действие не найдено."
            })
        })
        .finally(() => {
            dispatch(filterActions.setSegmentsLoading(false))
        })
    },
    createSegment: (schoolId: string | number, values: any) => async (dispatch: TAppDispatch) => {
        dispatch(filterActions.setSegmentsLoading(true))

        segmentsApi.createSegment(schoolId, values,location.pathname)
            .then(() => {
                segmentsApi.getSegments(schoolId,location.pathname).then((response) => {
                    if (response.status === 200) {
                        dispatch(filterActions.setSegments(response.data))
                        setTimeout(() => dispatch(filterActions.setCurrentSegment(response.data[response.data.length - 1])), 100)
                        showSuccessMessage("Сегмент сохранен")
                    }
                })
            }).catch((err) => {
                handleResponseError(dispatch, err, {
                    "404": "Запрашиваемое действие не найдено."
                })
            })
            .finally(() => {
                dispatch(filterActions.setSegmentsLoading(false))
            })
    },
    createFunnel: (schoolId: string | number, values: any) => async (dispatch: TAppDispatch) => {
        dispatch(filterActions.setSegmentsLoading(true))

        segmentsApi.createFunnel(schoolId, values,location.pathname)
            .then(() => {
                segmentsApi.getFunnels(schoolId,location.pathname).then((response) => {
                    if (response.status === 200) {
                        dispatch(filterActions.setFunnels(response.data))
                        setTimeout(() => dispatch(filterActions.setCurrentFunnel([ response.data[response.data.length - 1] ])), 100)
                        showSuccessMessage("Воронка продаж сохранена")
                    }
                })
            }).catch((err) => {
            handleResponseError(dispatch, err, {
                "404": "Запрашиваемое действие не найдено."
            })
        })
            .finally(() => {
                dispatch(filterActions.setSegmentsLoading(false))
            })
    },

    updateSegment: (schoolId: string | number, id: string, hash: string, values: any) => async (dispatch: TAppDispatch) => {
        dispatch(filterActions.setSegmentsLoading(true))

        segmentsApi.updateSegment(schoolId, id, values,location.pathname)
            .then(() => {
                segmentsApi.getSegments(schoolId,location.pathname).then((response) => {
                    if (response.status === 200) {
                        dispatch(filterActions.setSegments(response.data))
                        const updatedSegment = response.data.find((segment: any) => segment.id === id)
                        if (updatedSegment) {
                            dispatch(filterActions.setCurrentSegment(updatedSegment))
                        }
                        showSuccessMessage("Сохранено")
                    }
                })
            }).catch((err) => {
                handleResponseError(dispatch, err, {
                    "404": "Запрашиваемое действие не найдено."
                })
            })
            .finally(() => {
                dispatch(filterActions.setSegmentsLoading(false))
            })
    },
    updateFunnel: (schoolId: string | number, id: string, hash: string, values: any) => async (dispatch: TAppDispatch) => {
        dispatch(filterActions.setSegmentsLoading(true))

        segmentsApi.updateFunnel(schoolId, id, values,location.pathname)
            .then(() => {
                segmentsApi.getFunnels(schoolId,location.pathname).then((response) => {
                    if (response.status === 200) {
                        dispatch(filterActions.setFunnels(response.data))
                        const updatedSegment = response.data.find((segment: any) => segment.id === id)
                        if (updatedSegment) {
                            dispatch(filterActions.setCurrentFunnel([ updatedSegment ]))
                        }
                        showSuccessMessage("Сохранено")
                    }
                })
            }).catch((err) => {
            handleResponseError(dispatch, err, {
                "404": "Запрашиваемое действие не найдено."
            })
        })
            .finally(() => {
                dispatch(filterActions.setSegmentsLoading(false))
            })
    },

    deleteSegment: (id: string) => async (dispatch: TAppDispatch) => {
        dispatch(filterActions.setSegmentsLoading(true))

        segmentsApi.deleteSegment(id,location.pathname)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(filterActions.setSegments(response.data))
                    showSuccessMessage("Сегмент успешно удален")
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err, {
                    "404": "Запрашиваемое действие не найдено."
                })
            })
            .finally(() => {
                dispatch(filterActions.setSegmentsLoading(false))
            })
    },
    deleteFunnel: (id: string) => async (dispatch: TAppDispatch) => {
        dispatch(filterActions.setSegmentsLoading(true))

        segmentsApi.deleteFunnel(id,location.pathname)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(filterActions.setFunnels(response.data))
                    showSuccessMessage("Воронка продаж успешно удалена")
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err, {
                    "404": "Запрашиваемое действие не найдено."
                })
            })
            .finally(() => {
                dispatch(filterActions.setSegmentsLoading(false))
            })
    },
}
