import { FC, CSSProperties } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import ReactDOM from "react-dom"

type Props = {
    isOpen: boolean
}

const Mask: FC<Props> = ({ isOpen }) => {
    if (!isOpen) {
        return null
    }

    const overlayStyles: CSSProperties = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
    }

    const loadingStyles: CSSProperties = {
        fontSize: 76,
        color: "#40a9ff",
        position: "fixed",
        top: "50%",
        left: "50%"
    }

    return (
        <>
            {ReactDOM.createPortal(
                <div style={overlayStyles}>
                    <LoadingOutlined style={loadingStyles} spin />
                </div>,
                document.body
            )}
        </>
    )
}

export default Mask
