import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IListData, IStaffData, IStaffState, IStaffUpdateData } from "./types"

const initialState: IStaffState = {
    staffData: [],
    updateData: null,
    ads_list: [],
    order_list: [],
    isLoading: false,
    isUpdate: false
}

export const staffSlice = createSlice({
    name: "staff",
    initialState: initialState,
    reducers: {
        staffSetData: (state, action: PayloadAction<IStaffData[]>) => {
            state.staffData = action.payload
        },
        staffSetListsData: (state, action: PayloadAction<{ list_ads_cabinet: IListData[], list_offers: IListData[] }>) => {
            state.ads_list = action.payload.list_ads_cabinet
            state.order_list = action.payload.list_offers
        },
        staffSetUpdateData: (state, action: PayloadAction<IStaffUpdateData>) => {
            state.updateData = action.payload
        },
        staffSetLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        staffSetUpdate: (state, action: PayloadAction<boolean>) => {
            state.isUpdate = action.payload
        },
    }
})

export const staffSliceActions = staffSlice.actions
export const staffSliceReducer = staffSlice.reducer
