import React, {FC, useEffect, useState} from "react"

import { Button, Card, Form, Input,Typography } from "antd"
import { useNavigate } from "react-router-dom"

import styles from "./Profile.module.scss"
import MainLayout from "../../shared/common/components/layouts/MainLayout"
import Loader from "../../shared/common/components/loader/Loader"
import PageHead from "../../shared/common/components/page-header/PageHeader"
import { useActions } from "../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../shared/common/hooks/useTypedSelector"
import {profileApi} from "../../shared/common/api/endpoints/profileApi";
import {showErrorMessage} from "../../helpers/utils/ui";

const Profile: FC = () => {
    const navigate = useNavigate()

    const { name, email, phone, isLoading } = useTypedSelector((state) => state.currentUser)
    const { updateUserInfo, updateUserPassword, fetchUserAndSchoolInfo } = useActions()

    const [ contactForm ] = Form.useForm()
    const [ passwordForm ] = Form.useForm()
    const [tgInfo,setTgInfo] = useState({
        url:"",
        name:""
    })

    const userDefaultValues = {
        name,
        email,
        phone
    }

    const saveContactsData = (values: any) => {
        updateUserInfo(values, () => {
            fetchUserAndSchoolInfo(navigate)
        })
    }

    const savePasswordData = (values: any) => {
        updateUserPassword(values)
    }
useEffect(()=>{
    const handleClick = () => {

        profileApi
            .getConnectTelegramLink()
            .then((response) => {
                if (response.status === 200) {
                    setTgInfo({url: response.data.telegram_url,name: response.data.nikname})
                }
            })
            .catch(() => {
                showErrorMessage(
                    "Ошибка при получении ссылки. Повторите попытку позже."
                )
            })
    }
    handleClick()
},[])
    return (
        <MainLayout pageTitle="Профиль">
            <div className="page-wrapper">
                <PageHead title="Профиль" />

                <div className="page-content">
                    <Loader loading={isLoading}>
                        <div className={styles.profileWrapper}>
                            <Card
                                title="Контактные данные"
                                style={{ width: 310 }}
                            >
                                <Form
                                    form={contactForm}
                                    layout="vertical"
                                    onFinish={saveContactsData}
                                    initialValues={userDefaultValues}
                                    requiredMark={false}
                                >
                                    <Form.Item
                                        label="Имя"
                                        name="name"
                                        rules={[ { required: true, type: "string", message: "Введите имя" } ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="Телефон"
                                        name="phone"
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[ { required: true, type: "email", message: "Введите email" } ]}
                                    >
                                        <Input disabled />
                                    </Form.Item>

                                    <Button
                                        type="primary"
                                        onClick={() => contactForm.submit()}
                                    >
                                        Сохранить
                                    </Button>
                                </Form>
                            </Card>

                            <Card
                                title="Смена пароля"
                                style={{ width: 310 }}
                            >
                                <Form
                                    form={passwordForm}
                                    layout="vertical"
                                    onFinish={savePasswordData}
                                    requiredMark={false}
                                >
                                    <Form.Item
                                        label="Старый пароль"
                                        name="password"
                                        rules={[ { required: true, message: "Введите старый пароль." } ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>

                                    <Form.Item
                                        label="Новый пароль"
                                        name="new_password"
                                        rules={[ { required: true, message: "Введите пароль." } ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    <Form.Item
                                        label="Новый пароль еще раз"
                                        name="new_password2"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Введите пароль еще раз.",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue("new_password") === value) {
                                                        return Promise.resolve()
                                                    }
                                                    return Promise.reject(new Error("Пароли должны совпадать."))
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>

                                    <Button
                                        type="primary"
                                        onClick={() => passwordForm.submit()}
                                    >
                                        Сохранить
                                    </Button>
                                </Form>
                            </Card>
                            <Card title="Telegram" bodyStyle={{display:"flex",flexDirection:"column",rowGap:"10px",alignItems:"flex-start"}} style={{ width: 310 }}>
                                <Typography.Text style={{fontSize:"18px"}} strong>{tgInfo.name}</Typography.Text>
                                <Button onClick={()=>window.location.href = tgInfo.url} type={"primary"}>Перепривязать</Button>
                            </Card>
                        </div>
                    </Loader>
                </div>
            </div>
        </MainLayout>
    )
}

export default Profile
