import React, { FC } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"

interface ILoaderProps {
    loading: boolean
    hasTip?: boolean
    fullHeight?: boolean
}

const Loader: FC<ILoaderProps> = ({ loading, hasTip = false, fullHeight, children }) => {
    return (
        <Spin
            spinning={loading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            tip={hasTip ? "Загрузка..." : ""}
            wrapperClassName={fullHeight ? 'global-loader-wrapper-style' : ''}
        >
            {children}
        </Spin>
    )
}

export default Loader
