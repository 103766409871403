import React, { ChangeEvent, useEffect, useMemo, useState } from "react";

import { Button, Checkbox, Input, Radio, Space } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import _ from "lodash";

import { updateFilterItemInList } from "../../../../../../../helpers/utils/reports";
import { unApi } from "../../../../../api/endpoints/reports/unApi";
import { useActions } from "../../../../../hooks/useActions";
import { useForceUpdate } from "../../../../../hooks/useForceUpdate";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import ListTextSelector from "../ListTextSelector";
import usePermission from "../../../../../hooks/usePermission";

const LazyListPicker = ({
  disabled = false,
  id,
  index,
  showListPlus,
  modalVisibleHandler,
  ...props
}: any) => {
  const forceUpdate = useForceUpdate();

  const { selectedSchool } = useTypedSelector((state) => state.currentSchool);
  const { filters } = useTypedSelector((state) => state.filter);
  const { cabinetData } = useTypedSelector(
      (state) => state.adsCabinet
  )
  const checkPermission = usePermission()
  const { setFilters, setFilter, setMetaFilterUpdate } = useActions();

  const [isEditing, setIsEditing] = useState(false);
  const [filterMode, setFilterMode] = useState(1);
  const [_id, _setId] = useState(id);

  useEffect(() => {
    let _tempId = id;

    if (filterMode === 2) {
      setFilterMode(2);
      if (!id.includes("not_")) {
        _tempId = `not_${id}`;
      }
    } else {
      setFilterMode(1);
      if (id.includes("not_")) {
        _tempId = id.split("not_")[1];
      } else {
        _tempId = id;
      }
    }

    _setId(_tempId);
  }, [id, filterMode]);

  useEffect(() => {
    if (id.includes("not_")) {
      setFilterMode(2);
    } else {
      setFilterMode(1);
    }
  }, []);

  const filter = useMemo(() => {
    let _filter = props.itemData ? props.itemData : null;
    if (!props.list) {
      _filter = null;
    } else if (!isEditing) {
      setIsEditing(true);
    }

    return _filter ? _filter : { items: [], checkeds: [], ...props };
  }, [filters, _id, index, props.list, props.itemData, props]);

  const [items, setItems] = useState(() => {
    const safeItems = Array.isArray(filter.items) ? filter.items : [];
    const safeCheckeds = Array.isArray(filter.checkeds) ? filter.checkeds : [];

    return  [
      ...safeCheckeds,
      ...safeItems.filter(
          (x:{id:string}) => !safeCheckeds.some((y:{id:string}) => x.id === y.id)
      ),
    ]
  });


  const [status, setStatus] = useState("start");

  const [currentData, setCurrentData] = useState(filter);

  useEffect(() => {
    forceUpdate();
  }, [props.itemData, index, id, currentData]);

  const onApply = () => {
    let _currentData = {
      ...currentData,
      id: _id,
    };

    if (isEditing) {
      let _filters = _.cloneDeep(filters);

      const payload = { ..._currentData };
      _filters = updateFilterItemInList(_filters, index, payload);

      setFilters(_filters);
      setMetaFilterUpdate({value:true});
      modalVisibleHandler(false);

      return;
    }

    const payload = {
      ..._currentData,
      id: _id,
    };

    setFilter(payload);
    setMetaFilterUpdate({value:true});
    modalVisibleHandler(false);
  };

  const loadItems = (value: string) => {
    if (value.length === 0 || !selectedSchool) return;

    setStatus("load");

    unApi
      .getFilterList(parseInt(selectedSchool?.id || "0"), id, value)
      .then(function (response) {
        const stringifyIds = (row:any) => {
          if (row.id) {
            return {...row, id: row.id.toString()};
          }
          return row
        }
        let filtersData = (structuredClone(response.data.map(stringifyIds)) as {id:string,name:string}[]).map((item)=>({...item,name:item.name.replaceAll("&nbsp;", " ")}))
        const cabinetIds = ["advert_list_ads_cabinet","list_ads_cabinet"]
        if(location.pathname.includes("/source/ads") ||
            location.pathname.includes("/advert/rate") &&
        !checkPermission([ "admin", "user:settings" ]) &&
            cabinetIds.includes(id)){
          filtersData = filtersData.filter(item => cabinetData.some((el)=>el.ad_cabinet.includes(item.name)))
        }
        setItems([
          ...filter.checkeds,
          ...filtersData.filter(
            (x: any) => !filter.checkeds.some((y: any) => x.id === y.id)
          ),
        ]);

        if (!filtersData.length) setStatus("loadfalse");
        else setStatus("loadtrue");
      })
      .catch(function (error) {
        console.log("error = ", error);
        setStatus("loadfalse");
      });
  };

  const onChangeCheck = (checkedValue: CheckboxValueType[]) => {
    setCurrentData((prevState: any) => {
      return {
        ...prevState,
        items,
        checkeds: items.filter(({ id }) => checkedValue.includes(id)),
      };
    });
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    loadItems(value);
  };

  const checkALl = () => {
    setCurrentData((prevState: any) => {
      return {
        ...prevState,
        items,
        checkeds: items,
      };
    });
  };

  const onClear = () => {
    setCurrentData((prevState: any) => {
      return {
        ...prevState,
        checkeds: [],
      };
    });
  };

  const setCheckeds = (checkeds: any[]) => {
    setCurrentData((prevState: any) => {
      return {
        ...prevState,
        checkeds: [...prevState.checkeds, ...checkeds],
      };
    });
  };

  const setList = (items: any[]) => {
    setCurrentData((prevState: any) => {
      return {
        ...prevState,
        items: [...(prevState?.items || []), ...items],
      };
    });
    setItems((prevState: any) => {
      return [...prevState, ...items];
    });
  };
  const checkboxGroupValue = currentData.checkeds.map((item: any) => item.id)
  return (
    <div style={{ height: 380 }}>
      <Input
        onChange={(e) => onChangeSearch(e)}
        placeholder="Найти"
        allowClear
        disabled={disabled}
      />

      <div style={{ marginTop: 15 }}>
        <Radio.Group
          disabled={disabled}
          onChange={(e) => setFilterMode(e.target.value)}
          value={filterMode}
        >
          <Radio value={1}>Включить</Radio>
          <Radio value={2}>Исключить</Radio>
        </Radio.Group>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0px",
        }}
      >
        <Button disabled={disabled} onClick={checkALl} size="small">
          Выбрать все
        </Button>

        <ListTextSelector
          showListPlus={showListPlus}
          id={id}
          setList={setCheckeds}
          setMainList={setList}
        />

        <Button disabled={disabled} onClick={onClear} size="small">
          Очистить
        </Button>
      </div>

      <div
        style={{
          width: 300,
          height: 200,
          overflowY: "auto",
          wordBreak: "break-all",
        }}
      >
        {items.length === 0 && status === "start" && (
          <div style={{ textAlign: "center" }}>
            Пожалуйста, воспользуйтесь поиском для получения нужных вам
            параметров
          </div>
        )}
        {status === "loadfalse" && (
          <div style={{ textAlign: "center" }}>
            К сожалению, по вашему запросу ничего не найдено
          </div>
        )}

        <Checkbox.Group
          disabled={disabled}
          value={checkboxGroupValue}
          onChange={onChangeCheck}
        >
          <Space direction="vertical">
            {items.map((item) => (
                <Checkbox key={`${item.id}_${item.name}`} value={item.id} style={{ marginRight: 7 }}>
                  {item.name.replaceAll(/&nbsp;/g, "\u00A0")}
                </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      </div>

      <Button
        disabled={disabled}
        type="primary"
        style={{ marginTop: 10 }}
        onClick={onApply}
      >
        Применить
      </Button>
    </div>
  );
};

export default LazyListPicker;
