import { Form, Input, Modal } from "antd"
import {useTypedSelector} from "../../../../../../hooks/useTypedSelector";
import {showErrorMessage} from "../../../../../../../../helpers/utils/ui";
import {billApi} from "../../../../../../api/endpoints/billApi";
import {useState} from "react";
interface Props {
    isVisible: boolean;
    setIsVisible: (params: boolean) => void;
    setRefetch:(val: (prev:boolean) => boolean) => void;
}

const AddRequisites = ({ isVisible, setIsVisible,setRefetch }: Props) => {
    const [ form ] = Form.useForm()
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)

    const onFinish =  async(values: any) => {
        try{
            const data = {
                ...values,
                kpp:Number(values.kpp),
                inn:Number(values.inn),
                ogrn:Number(values.ogrn),
                okpo:Number(values.okpo),
                postal_code:Number(values.postal_code),
                bik:Number(values.bik),
                check_account:values.check_account,
                company_phone:Number(values.company_phone),
                corr_account:values.corr_account,
            school_id:Number(selectedSchool?.id)
            }
            console.log(data)
            await billApi.saveReq(data)
                Modal.info({
                title:"Успех",
                content:"Реквизиты успешно добавлены"
            })
           setRefetch((prev)=>!prev)
            form.resetFields()
            setIsVisible(false)
        }catch(err){
            showErrorMessage("Произошла ошибка , повторите попытку позже.")
        }
    }
    const handleKeyPress = (event:React.KeyboardEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.key)) || event.key === ' ') {
            event.preventDefault();
        }
    };
    const handlePaste = (event:React.ClipboardEvent<HTMLInputElement>,name:string) => {
        const pastedText = event.clipboardData.getData("text/plain");
        const sanitizedText = pastedText.replace(/[^0-9]/g, "");
        event.preventDefault()
        form.setFieldValue(name,sanitizedText)
    }

    return (
        <Modal
            title="Добавить реквизиты"
            open={isVisible}
            onOk={() => form.submit()}
            onCancel={() => {
                form.resetFields()
                setIsVisible(false)
            }}
            okText="Добавить"
            cancelText="Отмена"
            destroyOnClose
            width={"52vw"}
        >
            <Form
                form={form}
                name="addRequisitesForm"
                onFinish={onFinish}
                layout={"vertical"}
            >
                <div>
                    <h3>Компания</h3>
                    <div style={{ display:"flex",flexDirection:"row",columnGap:"40px" }}>
                        <div style={{ width:"28vw" }}>
                            <Form.Item name={"company_name"} rules={[{
                                required:true,
                                message:"Пожалуйста, введите название кампании"
                            }]}  label="Название кампании">
                                <Input required={true} />
                            </Form.Item>
                            <Form.Item name={"company_phone"} label="Телефон">
                                <Input />
                            </Form.Item>
                            <Form.Item name={"company_email"} label="Email">
                                <Input />
                            </Form.Item>
                        </div>
                        <div style={{ display:"flex",columnGap:"20px",width:"20vw" }}>
                            <Form.Item name={"company_address"} label="Юридический адрес">
                                <Input />
                            </Form.Item>
                            <Form.Item name={"postal_code"} label="Индекс" rules={[{
                                len:6,
                                message:"Длина должна быть 6 символов"
                            }]}>
                                <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"postal_code")} />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div style={{ display:"flex",flexDirection:"row",columnGap:"40px" }}>
                <div  style={{ width:"28vw" }}>
                    <h3>Реквизиты для документов и платежных поручений</h3>
                    <Form.Item rules={[
                        {
                        required:true,
                        message:"Пожалуйста, введите ИНН"
                        },
                        () => ({
                            validator(_,value){
                                if(value.length === 10 || value.length === 12){
                                    return Promise.resolve()
                                }
                                return Promise.reject(
                                    new Error("Длина должна быть 10 символов, для физического лица - 12")
                                );
                            }
                        })
                    ]} name={"inn"} label="ИНН">
                        <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"inn")} />
                    </Form.Item>
                    <Form.Item name={"kpp"} label="КПП" rules={[
                        {
                            len:9,
                            message:"Длина должна быть 9 символов"
                        }
                    ]}>
                        <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"kpp")} />
                    </Form.Item>
                    <Form.Item name={"ogrn"} label="ОГРН" rules={[
                        {
                            min:13,
                            max:15,
                            message:"Длина должна быть от 13 до 15 символов"
                        }
                    ]}>
                        <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"ogrn")} />
                    </Form.Item>
                    <Form.Item name={"okpo"} label="ОКПО" rules={[
                        {
                            min:8,
                            max:10,
                            message:"Длина должна быть от 8 до 10 символов"
                        }
                    ]}>
                        <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"okpo")} />
                    </Form.Item>
                    <Form.Item name={"user_name"} label="ФИО для подписи">
                        <Input />
                    </Form.Item>
                </div>
                <div style={{ width:"20vw" }}>
                    <h3>Расчетный счет</h3>
                    <Form.Item name={"bank_name"} label="Название банка">
                        <Input />
                    </Form.Item>
                    <Form.Item name={"bik"} label="БИК" rules={[
                        {
                            len:9,
                            message:"Длина должна быть 9 символов"
                        }
                    ]}>
                        <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"bik")} />
                    </Form.Item>
                    <Form.Item name={"check_account"} label="Р/C" rules={[
                        {
                            len:20,
                            message:"Длина должна быть 20 символов"
                        }
                    ]}>
                        <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"check_account")} />
                    </Form.Item>
                    <Form.Item name={"corr_account"} label="К/С" rules={[
                        {
                            len:20,
                            message:"Длина должна быть 20 символов"
                        }
                    ]}>
                        <Input onKeyPress={handleKeyPress} onPaste={(event)=>handlePaste(event,"corr_account")} />
                    </Form.Item>
                </div>
                </div>
            </Form>
        </Modal>
    )
}

export default AddRequisites
