import { useTypedSelector } from "./useTypedSelector";

const usePermission = () => {
  const userRole = useTypedSelector((state) => state.currentUser.role);

  return (allowedRoles: string[]) => {
    const acceptedRoles = ["superadmin", ...allowedRoles];
    return acceptedRoles.includes(userRole);
  };
};

export default usePermission;
