import React from 'react';
import {Button, InputNumber} from 'antd';
import InputWithColorPreview from "./InputWithColorPreview";
import {CloseOutlined} from "@ant-design/icons";

interface FillingConditionsProps {
    from: string
    to: string
    value: string;
    onChange: (field: string, newValue:  string ) => void;
    onDelete:()=>void
    id:number
    onClear:()=>void
}

const FillingConditions: React.FC<FillingConditionsProps> = ({ from, to, value, onChange,onDelete,id,onClear }) => {
    return (
        <div style={{ display: "flex", columnGap: "10px", alignItems: "center" }}>
            от
            <InputNumber
                style={{ width: "130px" }}
                value={from}
                onChange={(newValue) => onChange('from', newValue as string)}
            />
            до
            <InputNumber
                style={{ width: "130px" }}
                value={to}
                onChange={(newValue) => onChange('to', newValue as string)}
            />
            <InputWithColorPreview
                value={value}
                onChange={(newValue:string) => onChange('value', newValue)}
            />
            { id === 1 && <CloseOutlined onClick={onClear} style={{ cursor:"pointer" }} />}
            {id !== 1 && <CloseOutlined onClick={onDelete} style={{ cursor:"pointer" }} /> }
        </div>
    )
}

export default FillingConditions
