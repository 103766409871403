import React, { FC, useState } from "react";

import { Button, Input, Popover } from "antd";
import axios from "axios";
import {
  axiosConfigAuth,
  handleResponseError,
} from "../../../../api/middleware";
import { useDispatch } from "react-redux";
import { API_GET_FILTER_LIST } from "../../../../api/urls";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

interface IProps {
  list?: Array<{
    id: number;
    name: string;
  }>;
  showListPlus?: boolean | undefined;
  id: number;
  setList: (checkeds: any[]) => void;
  setMainList?: (list: any[]) => void;
}

const ListTextSelector: FC<IProps> = ({
  id,
  list,
  setList,
  setMainList,
  showListPlus,
}) => {
  const dispatch = useDispatch();
  const { selectedSchool } = useTypedSelector((state) => state.currentSchool);

  const [open, setOpen] = useState(false);
  const [listText, setListText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const confirmSelect = () => {
    setList([]);
    if (list && list.length > 0) {
      setList([]);
      const parsedInputData = listText.split("\n");
      const filteredList = list.filter((item) =>
        parsedInputData.includes(item.name.replace("&nbsp;", " "))
      );

      setList(filteredList);

      setOpen(false);
      setListText("");
    } else {
      if (selectedSchool) {
        setIsLoading(true);
        setList([]);

        const parsedInputData = listText.split("\n");
        const data = {
          school_id: selectedSchool.id,
          name: id,
          str: "",
          values: parsedInputData,
        };

        axios
          .post(API_GET_FILTER_LIST, data, axiosConfigAuth())
          .then((response) => {
            if (response.status === 200) {
              setList([]);
              setMainList && setMainList([]);

              const list: any[] = response.data;

              const filteredList = list.filter((item) =>
                parsedInputData.includes(item.name.replace("&nbsp;", " "))
              );
              console.log(list, filteredList);
              setMainList && setMainList(list);
              setList(filteredList);

              setOpen(false);
              setListText("");
            }
          })
          .catch((err) => {
            handleResponseError(dispatch, err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  return  (
    <Popover
      title="Введите список значений"
      content={
        <div>
          <p>
            <Input.TextArea
              style={{ width: 300, height: 250 }}
              value={listText}
              onChange={(e) => setListText(e.target.value)}
            />
          </p>

          <div>
            <Button
              type="primary"
              onClick={() => confirmSelect()}
              loading={isLoading}
            >
              Применить
            </Button>
          </div>
        </div>
      }
      trigger="click"
      open={open}
      onOpenChange={(val) => setOpen(val)}
    >
      <Button size="small">+ Список</Button>
    </Popover>
  )
};

export default ListTextSelector;
