import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAdsCabinetData, IAdsCabinetState } from "./types";

const initialState: IAdsCabinetState = {
  cabinetData: [],
  isLoading: false,
  isUpdate: false,
};

export const adsCabinetSlice = createSlice({
  name: "adsCabinet",
  initialState: initialState,
  reducers: {
    setAdsCabinetData: (state, action: PayloadAction<IAdsCabinetData[]>) => {
      state.cabinetData = action.payload;
    },
    setAdsCabinetLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAdsCabinetUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload;
    },
  },
});

export const adsCabinetSliceActions = adsCabinetSlice.actions;
export const adsCabinetSliceReducer = adsCabinetSlice.reducer;
