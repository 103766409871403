import React, { useMemo, useState } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { Popover, Typography } from "antd";

import DateTimeRangePicker from "./pickers/DateTimeRangePicker";
import LazyListPicker from "./pickers/LazyListPicker";
import ListPicker from "./pickers/ListPicker";
import NumberPicker from "./pickers/NumberPicker";
import NumberRangePicker from "./pickers/NumberRangePicker";
import TextPicker from "./pickers/TextPicker";
import TimeRangePicker from "./pickers/TimeRangePicker";
import DateTimeRangeView from "./views/DateTimeRangeView";
import ListView from "./views/ListView";
import NumberRangeView from "./views/NumberRangeView";
import NumberView from "./views/NumberView";
import TextView from "./views/TextView";
import TimeRangeView from "./views/TimeRangeView";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

const FilterItemSelected = ({
  id,
  arrayIndex,
  type,
  name,
  full_name,
  color,
  icon,
  path,
  namePath,
  extra,
  itemData,
  showListPlus,
}: any) => {
  const { filters,filterParent } = useTypedSelector((state) => state.filter);
  const { setFilters, setMetaFilterUpdate, setFilterParent } = useActions();

  const [visible, setVisible] = useState(false);

  const list = true;

  const itemFullName = useMemo(() => {
    let _name;

    if (full_name) {
      _name = full_name;
    } else {
      _name = name;
    }

    return _name;
  }, [full_name, name]);

  const removeFilter = () => {
    let _filtersParent = Array.from(filterParent)
    let _filters = Array.from(filters);
    let filterParentIndex = _filtersParent.findIndex((el)=>el.name === name)

    _filtersParent.splice(filterParentIndex,1)
    _filters.splice(arrayIndex, 1);

    setFilters(_filters);
    setFilterParent(_filtersParent)
    setMetaFilterUpdate({value:true});
  };

  const ContentPopover = ({ typeCont }: { typeCont: string }) => {
    const propsPicker = {
      id,
      color,
      index: arrayIndex,
      icon,
      name,
      full_name,
      type,
      path,
      namePath,
      list,
      disabled: false,
      itemData,
      showListPlus,
    };

    switch (typeCont) {
      case "datetimerange":
        return (
          <DateTimeRangePicker
            {...propsPicker}
            modalVisibleHandler={setVisible}
          />
        );
      case "timerange":
        return (
          <TimeRangePicker {...propsPicker} modalVisibleHandler={setVisible} />
        );
      case "list":
        return <ListPicker {...propsPicker} modalVisibleHandler={setVisible} />;
      case "lazylist":
        return (
          <LazyListPicker {...propsPicker} modalVisibleHandler={setVisible} />
        );
      case "text":
        return <TextPicker {...propsPicker} modalVisibleHandler={setVisible} />;
      case "number":
        return (
          <NumberPicker {...propsPicker} modalVisibleHandler={setVisible} />
        );
      case "numberrange":
        return (
          <NumberRangePicker
            {...propsPicker}
            modalVisibleHandler={setVisible}
          />
        );

      default:
        return null;
    }
  };

  const viewItem: { [key: string]: React.ReactElement } = useMemo(() => {
    return {
      datetimerange: (
        <DateTimeRangeView {...itemData} name={itemFullName} id={id} />
      ),
      timerange: <TimeRangeView {...itemData} name={itemFullName} id={id} />,
      list: <ListView {...itemData} name={itemFullName} id={id} />,
      lazylist: <ListView {...itemData} name={itemFullName} id={id} />,
      text: <TextView {...itemData} name={itemFullName} id={id} />,
      number: <NumberView {...itemData} name={itemFullName} id={id} />,
      numberrange: (
        <NumberRangeView {...itemData} name={itemFullName} id={id} />
      ),
    };
  }, [itemData, id, arrayIndex]);

  return (
    <Popover
      content={
        <div>
          <div style={{ marginBottom: 8 }}>
            <Typography.Text strong>{itemFullName}</Typography.Text>
          </div>
          {<ContentPopover typeCont={type} />}
        </div>
      }
      open={visible}
      onOpenChange={setVisible}
      trigger="click"
      placement={"bottom"}
      showArrow={false}
      overlayStyle={{ paddingTop: 0 }}
    >
      <div
        className="filter-selected-item"
        style={{ backgroundColor: color }}
        key={id}
      >
        <span>
          {viewItem[type]} {extra ? extra : null}
        </span>
        <CloseOutlined
          onClick={removeFilter}
          style={{
            color: "#fefefe",
            padding: 5,
            position: "relative",
            zIndex: 900,
          }}
          tabIndex={1}
        />
      </div>
    </Popover>
  );
};

export default FilterItemSelected;
