import React, { useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Form, Input, InputNumber, Modal } from "antd"

import { showErrorMessage } from "../../../../../helpers/utils/ui"
import { unApi } from "../../../../../shared/common/api/endpoints/reports/unApi"
import {UpdatePayment} from "../../../../../app/types/unApi";
import {DatePicker} from "antd/es";
import moment from "moment";


type Props = {
    visible:boolean
    setVisible:  React.Dispatch<React.SetStateAction<{visible: boolean, id:number}>>
    id:number
}
const EditPayment = ({ visible,setVisible,id }:Props) => {
    const [ form ] = Form.useForm()
    const [ formLoading, setFormLoading ] = useState(false)
    const fetchData = () => {
        try{
            unApi.getTablePaymentInfo(id).then((res)=>{
                if(res.status === 200){
                    form.setFieldsValue({
                        email:res.data.email,
                        payment_amount: res.data.payment_amount,
                        comment: res.data.comment,
                        payment_date: moment(res.data.payment_date, "YYYY-MM-DD")
                    })
                }
            })
        }catch(err){
            showErrorMessage("Произошла ошибка при получении информации об выплате , повторите попытку позже.")
        }

    }
    useEffect(()=> {
        fetchData()
    },[])
    const handleEdit = async (values:UpdatePayment) => {
        const data = {
            ...values,
            payment_id:id,
            payment_date:moment(values.payment_date).format("YYYY-MM-DD")
        }
        setFormLoading(true)
        setVisible({ visible:false,id:0 })
        try{
            const response = await unApi.updateTablePayment(data)
            if(response.status === 200){
                form.resetFields()
                setVisible({ visible:false,id:0 })
                Modal.info({
                    title: "Успех!",
                    content: "Начисления обновлены.",
                })
            }
        }catch(err:any){
            if(err.response.data){
                showErrorMessage(err.response.data)
            }else{
                showErrorMessage(`Произошла ошибка, повторите попытку позже.`)
            }

        }finally{
            setFormLoading(false)
        }
    }

    return (
        <Modal
            title="Редактирование Выплаты"
            open={visible}
            onCancel={() => {setVisible({ visible:false,id:0 })}}
            onOk={() => {form.submit()}}
            okButtonProps={{ loading: formLoading }}
            okText="Сохранить"
        >
            {
                formLoading ?
                    <div className="loading-container">
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                    </div>
                    :
                    <Form
                        form={form}
                        onFinish={handleEdit}
                        requiredMark={false}
                    >
                        <Form.Item
                            name="email"
                            label="email"
                            rules={[
                                { required: true },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Дата рекламы"
                            name="payment_date"
                            rules={[ { required: true } ]}>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <DatePicker format={"DD-MM-YYYY"} />
                        </Form.Item>
                        <Form.Item
                            name="payment_amount"
                            label="Сумма"
                            rules={[ { required: true } ]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            name="comment"
                            label="Комментарий"
                            rules={[
                                { required: true },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
            }
        </Modal>
    )
}

export default EditPayment
