import React from "react"

import {  Result } from "antd"

import MainLayout from "../../shared/common/components/layouts/MainLayout"


const PaymentError: React.FC = () =>{

    return (
        <>
            <MainLayout>
                <div className="page-wrapper">
                    <div className="page-content">
    <Result
        style={{ paddingTop:"26vh" }}
        status="error"
        title={
            <span style={{ fontSize: "28px" }}>
            Ошибка платежа
        </span>
        }
        subTitle={
        <span style={{ fontSize:"18px" }}>
            Ошибка в оплате, платеж не проведен.
        </span>
        }
    >
    </Result>
                    </div>
                </div>
            </MainLayout>
        </>
    )
}

export default PaymentError
