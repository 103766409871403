import React, { useEffect, useState } from "react"

import {Button, Modal, Table} from "antd"

import { unApi } from "../../../../../shared/common/api/endpoints/reports/unApi"
import AddPayment from "../modals/AddPayment"
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {showErrorMessage, showSuccessMessage} from "../../../../../helpers/utils/ui";
import EditPayment from "../modals/EditPayment";
import {Column} from "../../../../../helpers/utils/functions";
import CopyButton from "../ui/CopyButton";

const Payments = () =>
{
    const [ tableData,setTableData ] = useState([])
    const [ forceUpdate,setForceUpdate ] = useState(false)
    const [ addPayment,setAddPayment ] = useState(false)
    const [ editPayment,setEditPayment ] = useState({
        visible:false,
        id:0
    })
    const fetchTableData = async () => {
        await unApi.getTablePayments().then((res)=>{
            setTableData(res.data.rows)
        })
    }
    useEffect(()=>{
        fetchTableData()
    },[ addPayment,forceUpdate,editPayment ])
    const handleEditPayment = (payment_id:number) => {
        setEditPayment({visible:true,id:payment_id})
    }
    const handleDeletePayment = (payment_id:number) => {
        Modal.confirm({
            title: "Вы уверены что хотите удалить Выплату?",
            icon: <ExclamationCircleOutlined />,
            okText: "Да",
            okType: "danger",
            cancelText: "Нет",
            onOk() {
                try{
                    unApi.deleteTablePayment(payment_id).then(()=>{
                        showSuccessMessage("Успешно удалено")
                        setForceUpdate((prev)=>!prev)
                    })
                }catch(err){
                    showErrorMessage("Произошла ошибка , повторите попытку позже.")
                }

            },
        })
    }


    const columns:Column[] = [
        { title: "Дата", dataIndex: "payment_date", key: "payment_date" },
        { title: "id партнёра", dataIndex: "partner_id", key: "partner_id" },
        { title: "Имя партнёра", dataIndex: "name", key: "name",
            render: (text) => {
                if (/<a/i.test(text)) {
                    return <span dangerouslySetInnerHTML={{ __html: text }} />
                }
                return text
            },
        },
        { title: "Email партнёра", dataIndex: "email", key: "email" },
        { title: "Сумма", dataIndex: "payment_amount", key: "payment_amount" },
        { title: "Комментарий", dataIndex: "comment", key: "comment" },
        {
            title:"Действия",
            dataIndex:"actions",
            key:"actions",
            render: (value, record) => (
                <div style={{ display:"flex",columnGap:"4px" }}>
                    <Button title={"Редактирование выплаты"} icon={<EditOutlined />} onClick={()=>handleEditPayment(record.payments_id)} />
                    <Button title={"Удаление выплаты"} icon={<DeleteOutlined />} danger onClick={()=>handleDeletePayment(record.payments_id)} />
                </div>
            ),
        }
    ]

    return (
        <>
            <Button type="primary" onClick={()=>setAddPayment(true)}>Добавить выплату</Button>
            <CopyButton columns={columns} tableData={tableData} styles={{marginLeft:"10px"}} />
            <div style={{ overflow:"auto",marginTop:10 }}>
                <Table size={"small"} columns={columns} dataSource={tableData} />;
            </div>
            {addPayment && <AddPayment visible={addPayment} setVisible={setAddPayment} />}
            {editPayment.visible && <EditPayment id={editPayment.id} setVisible={setEditPayment} visible={editPayment.visible} />}
        </>
    )
}
export default Payments
