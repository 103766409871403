import React, { useEffect } from "react"

import {message, Table} from "antd"

import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"

const CommonInfoTab = () => {
    const { statistics } = useTypedSelector((state)=>state.partner)
    const { statisticsFetchData } = useActions()
    useEffect(()=>{
        statisticsFetchData()
    },[])
    const conditionsColumns = [
        {
            title: "Количество рефералов, кто оплатил доступ к сервису",
            dataIndex: "attracted",
            key: "attracted",
        },
        {
            title: "% вознаграждения за рефералов 1 уровня",
            dataIndex: "award1",
            key: "award1",

        },
        {
            title: "% вознаграждения за рефералов 2 уровня",
            dataIndex: "award2",
            key: "award2",
        },
    ]

    const conditionsData = [
        {
            key: "1",
            attracted: "от 1 до 10",
            award1: "15%",
            award2:"5%"
        },
        {
            key: "2",
            attracted: "от 11 до 25",
            award1: "20%",
            award2:"5%"
        },
        {
            key: "3",
            attracted: "от 26 до 50",
            award1: "25%",
            award2:"5%"
        },
        {
            key: "4",
            attracted: "от 51 и более",
            award1: "30%",
            award2:"5%"
        },
    ]

    const infoColumns = [
        {
            title: "",
            dataIndex: "space",
            key: "space",
        },
        {
            title: "За сегодня",
            dataIndex: "today",
            key: "today",
        },
        {
            title: "За вчера",
            dataIndex: "yesterday",
            key: "yesterday",
        },
        {
            title: "За 7 дней",
            dataIndex: "week",
            key: "week",
        },
        {
            title: "За 30 дней",
            dataIndex: "month",
            key: "month",
        },
        {
            title:"За 90 дней",
            dataIndex: "quarter",
            key:"quarter",
        },
        {
            title: "За 365 дней",
            dataIndex: "year",
            key: "year",
        },
        {
            title: "Всего",
            dataIndex: "allthetime",
            key: "allthetime",
        },
    ]

    const infoData = [
        {
            space: "Регистраций реф. 1 ур.",
            today: statistics?.rows[0].ref_1_count,
            yesterday:statistics?.rows[1].ref_1_count,
            week:statistics?.rows[2].ref_1_count,
            month:statistics?.rows[3].ref_1_count,
            quarter:statistics?.rows[4].ref_1_count,
            year:statistics?.rows[5].ref_1_count,
            allthetime:statistics?.rows[6].ref_1_count,
        },
        {
            space: "Регистраций реф. 2 ур.",
            today: statistics?.rows[0].ref_2_count,
            yesterday:statistics?.rows[1].ref_2_count,
            week:statistics?.rows[2].ref_2_count,
            month:statistics?.rows[3].ref_2_count,
            quarter:statistics?.rows[4].ref_2_count,
            year:statistics?.rows[5].ref_2_count,
            allthetime:statistics?.rows[6].ref_2_count,
        },
        {
            space: "Покупателей 1 ур.",
            today: statistics?.rows[0].buyers_1_count,
            yesterday:statistics?.rows[1].buyers_1_count,
            week:statistics?.rows[2].buyers_1_count,
            month:statistics?.rows[3].buyers_1_count,
            quarter:statistics?.rows[4].buyers_1_count,
            year:statistics?.rows[5].buyers_1_count,
            allthetime:statistics?.rows[6].buyers_1_count,
        },
        {
            space: "Покупателей 2 ур.",
            today: statistics?.rows[0].buyers_2_count,
            yesterday:statistics?.rows[1].buyers_2_count,
            week:statistics?.rows[2].buyers_2_count,
            month:statistics?.rows[3].buyers_2_count,
            quarter:statistics?.rows[4].buyers_2_count,
            year:statistics?.rows[5].buyers_2_count,
            allthetime:statistics?.rows[6].buyers_2_count,
        },
        {
            space: "Начислено с реф. 1 ур.",
            today: statistics?.rows[0].calc_1_sum,
            yesterday:statistics?.rows[1].calc_1_sum,
            week:statistics?.rows[2].calc_1_sum,
            month:statistics?.rows[3].calc_1_sum,
            quarter:statistics?.rows[4].calc_1_sum,
            year:statistics?.rows[5].calc_1_sum,
            allthetime:statistics?.rows[6].calc_1_sum,
        },
        {
            space: "Начислено с реф. 2 ур.",
            today: statistics?.rows[0].calc_2_sum,
            yesterday:statistics?.rows[1].calc_2_sum,
            week:statistics?.rows[2].calc_2_sum,
            month:statistics?.rows[3].calc_2_sum,
            quarter:statistics?.rows[4].calc_2_sum,
            year:statistics?.rows[5].calc_2_sum,
            allthetime:statistics?.rows[6].calc_2_sum,
        },
        {
            space: "Выплачено",
            today: statistics?.rows[0].pay_sum,
            yesterday:statistics?.rows[1].pay_sum,
            week:statistics?.rows[2].pay_sum,
            month:statistics?.rows[3].pay_sum,
            quarter:statistics?.rows[4].pay_sum,
            year:statistics?.rows[5].pay_sum,
            allthetime:statistics?.rows[6].pay_sum,
        },
        {
            space: "Осталось выплатить",
            today: statistics?.rows[0].remain_sum,
            yesterday:statistics?.rows[1].remain_sum,
            week:statistics?.rows[2].remain_sum,
            month:statistics?.rows[3].remain_sum,
            quarter:statistics?.rows[4].remain_sum,
            year:statistics?.rows[5].remain_sum,
            allthetime:statistics?.rows[6].remain_sum,

        },
    ]
const copyPartnerUrl = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        const span = event.target as HTMLSpanElement
        const url = span.innerHTML
    const {top,left} = span.getBoundingClientRect();
console.log(left)
    navigator.clipboard.writeText(url)
        .then(() => {
            message.success({
                content: "Скопировано",
                style:{
                    position:"absolute",
                    top:`${top - 26}px`,
                    left:`${left + 180}px`
                }
            })
        })
        .catch(() => {
            message.error({
                content: "Ошибка при копировании данных",
                style:{
                    position:"absolute",
                    top:`${top - 26}px`,
                    left:`${left + 180}px`
                }
            })
        })
}
    return (
        <div style={{ marginTop:"40px" }}>
            <div style={{ display:"flex", columnGap:"60px" }}>
                <p style={{ width:"305px",marginBottom:"unset" }}>Получайте комиссию в течение года от <strong style={{ fontWeight:"500" }}>15 до 30%</strong> со всех платежей реферала 1 уровня и <strong>5%</strong> с платежей рефералов 2 уровня.</p>
                <div style={{ display:"flex", flexDirection:"column",rowGap:"22px" }}>
                    <p style={{ fontWeight:"600",marginBottom:"unset" }}>Ваш процент вознаграждения: <span style={{ fontWeight:"400" }}>{statistics?.commission_percent}</span></p>
                    <p style={{ fontWeight:"600",marginBottom:"unset" }}>Партнёрская ссылка: <span onClick={(event)=>copyPartnerUrl(event)} style={{ fontWeight:"400",cursor:"pointer" }}>{statistics?.partner_url}</span></p>
                </div>
            </div>

		<h3 style={{ marginTop:"60px" }}>Условия вознаграждения:</h3>
			<div style={{ width:"640px" }}>
				<Table columns={conditionsColumns} dataSource={conditionsData} pagination={false} />

			</div>
			<h3 style={{ marginTop:"60px" }}>Общая статистика:</h3>
			<div style={{ width:"880px" }}>
				<Table columns={infoColumns} dataSource={infoData} pagination={false}/>
			</div>
        </div>
    )
}

export default CommonInfoTab
