import {Tag} from "antd";
import React from "react";

export default function FilterTag({ filter, onClose }: any) {
    if (!filter || filter?.default) return null
    const type: any = {
        less: "<",
        equal: "=",
        over: ">",
    }

    return (
        <div style={{ marginTop: 3 }}>
            <Tag closable onClose={onClose}>
                {type[filter.action]} {filter.value}
            </Tag>
        </div>
    )
}
