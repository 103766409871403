import React, { useMemo } from "react"

import IconFilter from "../IconFilter"

const TimeRangeView = ({ icon, name, start, end }: any) => {
    const _start = useMemo(() => {
        if (!start) return ""
        return start
    }, [ start ])
    const _end = useMemo(() => {
        if (!end) return ""
        return end
    }, [ end ])

    return (
        <>
            <IconFilter icon={icon} />
            &nbsp;{name}:
            &nbsp;{_start}&nbsp;—&nbsp;{_end}
        </>
    )
}

export default TimeRangeView
