import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ICurrentUser } from "./types";

const initialState: ICurrentUser = {
  id: "",
  name: "",
  email: "",
  phone: "",
  role: "",
  isLoading: false,
  isUtm:false,
  isContact:false
};

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: initialState,
  reducers: {
    setUtm: (state,action:PayloadAction<boolean>) => {
      state.isUtm = action.payload
    },
    setContact:(state,action:PayloadAction<boolean>) => {
      state.isContact = action.payload
    },
    currentUserSetData: (state, action: PayloadAction<any>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.role = action.payload.role;
    },
    currentUserClearData: (state) => {
      state.id = initialState.id;
      state.name = initialState.name;
      state.email = initialState.email;
      state.phone = initialState.phone;
      state.role = initialState.role;
    },
    userSetLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const currentUserSliceActions = currentUserSlice.actions;
export const currentUserSliceReducer = currentUserSlice.reducer;
