import React, { useState } from "react";
import { Input, Checkbox, Button, Space } from "antd";
import {FilterConfirmProps} from "antd/lib/table/interface";

interface CustomFilterProps {
    confirm: (param?: FilterConfirmProps) => void;
    selectedKeys: React.Key[];
    setSelectedKeys: (keys: string[]) => void
    title: string
    options:{text:string,value:string}[]
}

const CustomFilter: React.FC<CustomFilterProps> = ({
                                                       confirm,
                                                       selectedKeys,
                                                       setSelectedKeys,
                                                       title,
    options
                                                   }) => {
    const [searchText, setSearchText] = useState<string>("");
    const [checkedFilters, setCheckedFilters] = useState<string[]>(selectedKeys as string[]);
    const [filterOptions, setFilterOptions] = useState<{ text: string; value: string }[]>(options);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value.toLowerCase();
        setSearchText(inputValue);

        const filteredOptions = options.filter((el) =>
            el.value.toLowerCase().includes(inputValue)
        );
        setFilterOptions(filteredOptions);
    };

    const handleSearch = () => {
        confirm();
    };

    const handleReset = () => {
        setSearchText("");
        setSelectedKeys([]);
        setCheckedFilters([]);
        setFilterOptions(options);
    };

    const handleCheckboxChange = (value: string) => {
        const updatedFilters = checkedFilters.includes(value)
            ? checkedFilters.filter((item) => item !== value)
            : [...checkedFilters, value];

        setSelectedKeys(updatedFilters);
        setCheckedFilters(updatedFilters);
    };

    return (
        <div style={{ padding: 8,display:"flex" }}>

                <div style={{
                    display:"flex",
                    flexDirection:"column",
                    rowGap:"5px",
                    alignItems:"baseline",
                    margin:10,
                    maxHeight:200,
                    overflow:"auto"
                }}>
                    {filterOptions.map((filter) => (
                        <Checkbox
                            key={filter.value}
                            value={filter.value}
                            checked={checkedFilters.includes(filter.value)}
                            onChange={() => handleCheckboxChange(filter.value)}
                        >
                            {filter.text}
                        </Checkbox>
                    ))}
            </div>
            <div>
                <Input
                    placeholder={title}
                    value={searchText}
                    onChange={handleChange}
                    onPressEnter={handleSearch}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button type={"primary"} onClick={handleSearch}>Поиск</Button>
                    <Button onClick={handleReset}>Сбросить</Button>
                </Space>
            </div>

        </div>
    );
};

export default CustomFilter;
