import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth } from "../middleware"
import {
    API_PARTNER_GET_REFERALS,
    API_PARTNER_GET_ACCRUALS,
    API_PARTNER_GET_PAYOUTS,
    API_PARTNER_GET_STATISTICS
} from "../urls"

export const partnerApi = {
    getReferals: (): Promise<AxiosResponse> => {
        return axios.get(API_PARTNER_GET_REFERALS, axiosConfigAuth())
    },
    getAccurals: (): Promise<AxiosResponse> => {
        return axios.get(API_PARTNER_GET_ACCRUALS, axiosConfigAuth())
    },
    getPayouts: (): Promise<AxiosResponse> => {
        return axios.get(API_PARTNER_GET_PAYOUTS , axiosConfigAuth())
    },
    getStatistics: (): Promise<AxiosResponse> => {
        return axios.get(API_PARTNER_GET_STATISTICS,axiosConfigAuth())
    }
}
