import React, { FC } from "react";

import ExportPage from "../../shared/common/components/export/ExportPage";
import MainLayout from "../../shared/common/components/layouts/MainLayout";
import PageHead from "../../shared/common/components/page-header/PageHeader";

const Export: FC = () => {
  return (
    <MainLayout pageTitle="Экспорт">
      <div className="page-wrapper">
        <PageHead title="Экспорт" />

        <div className="page-content">
          <ExportPage />
        </div>
      </div>
    </MainLayout>
  );
};

export default Export;
