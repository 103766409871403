import React, { FC } from "react"

import MainLayout from "../../shared/common/components/layouts/MainLayout"
import PageHead from "../../shared/common/components/page-header/PageHeader"
import PartnerPage from "../../shared/common/components/partner/PartnerPage"

const Partner: FC = () => {
    return (
        <MainLayout pageTitle="Партнерский кабинет">
            <div className="page-wrapper">
                <PageHead
                    title="2-х уровневая партнёрская программа"
                />

                <div className="page-content">
                    <PartnerPage />
                </div>
            </div>
        </MainLayout>
    )
}

export default Partner
