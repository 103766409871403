import React, {useEffect} from "react";

import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

import { routeNames } from "../../shared/common/constants/routes";
import { useActions } from "../../shared/common/hooks/useActions";
import {useTypedSelector} from "../../shared/common/hooks/useTypedSelector";

const ErrorPage = () => {
  const navigate = useNavigate();
  const { authLogout,setAppGlobalError } = useActions();
    const { userAccess }  = useTypedSelector((state)=>state.currentSchool)
  useEffect(()=>{
      if(userAccess !== null){
          setAppGlobalError(false);
      }
  },[userAccess])
  return (
    <div className="global-loading-container">
      <Result
        status="error"
        title="Ведётся работа с сервером. Скоро всё заработает"
        subTitle="Пожалуйста повторите позднее или обратитесь в поддержку."
        extra={
          <div>
            <Button
              type="primary"
              onClick={() => (window.location.href = "/")}
              style={{ marginRight: 10 }}
            >
              На главную
            </Button>

            <Button
              type="default"
              onClick={() =>
                authLogout(() => {
                  navigate(routeNames.login);
                })
              }
            >
              Выйти
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default ErrorPage;
