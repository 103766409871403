import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAttributesState } from "./types";
import { updateQueryParams } from "../../../../../../../helpers/utils/functions";
export const defaultAttributes = {
  attribution: ["1"],
  type_applications: ["1"],
} as {
  [key: string]: [string] | string;
};

const stateFromLocalStore =
  location.pathname === "/dashboards"
    ? undefined
    : window.localStorage.getItem("attr_state");
const defaultState: IAttributesState = {
  attributes:
    stateFromLocalStore && stateFromLocalStore !== "undefined"
      ? JSON.parse(stateFromLocalStore)
      : defaultAttributes,
  attributesDashboard: defaultAttributes,
  attributesDashboardBlocks: {},
};
const getInitialState = () => {
  const url = new URL(window.location.href);
  const currentParams = url.searchParams.get("params");
  const paramsObject = currentParams ? JSON.parse(currentParams) : {};
  const attr = paramsObject?.attr;
  if (attr) {
    return { ...defaultState, ...attr };
  }
  return defaultState;
};
const initialState = getInitialState();

export const attrSlice = createSlice({
  name: "attr",
  initialState: initialState,
  reducers: {
    setDashboardAttributes: (
      state,
      action: PayloadAction<{ [key: string]: [string] | string }>
    ) => {
      if (action.payload) {
        state.attributesDashboard = action.payload;
      }
    },
    setAttributes: (
      state,
      action: PayloadAction<{ [key: string]: [string] | string }>
    ) => {
      if (action.payload) {
        state.attributes = action.payload;
        updateQueryParams(action.payload, "attr");
        window.localStorage.setItem(
          "attr_state",
          JSON.stringify(action.payload)
        );
      }
    },
    setAttributesDashboardBlocks: (
      state,
      action: PayloadAction<{
        blockId: string;
        [key: string]: [string] | string;
      }>
    ) => {
      if (action.payload) {
        const { blockId, ...restPayload } = action.payload;
        state.attributesDashboardBlocks![blockId]! = { ...restPayload };
      }
    },
  },
});

export const attrSliceReducer = attrSlice.reducer;
export const attrSliceActions = attrSlice.actions;
