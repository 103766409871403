import {Button} from "antd";
import {copyToClipboard, TableRow} from "../../../../../helpers/utils/functions";
import {CSSProperties} from "react";
interface Column {
    title: string;
    dataIndex: string;
    key: string;
    filters?: any
    onFilter?: (value: any, record: any) => boolean;
    render?: (value: any, record: any) => JSX.Element;
}

type Props = {
    columns:Column[],
    tableData:TableRow[],
    styles:CSSProperties
}
export default function CopyButton({columns,tableData,styles}:Props){
    return (
        <Button style={styles} type="primary" onClick={()=>copyToClipboard(columns,tableData)}>Копировать данные из таблицы</Button>
    )
}
