import axios from "axios"

import {SetTask, UserTG} from "./types"
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../helpers/utils/ui"
import { axiosConfigAuth } from "../middleware"
import {
  API_TG_ADD_TASK,
  API_TG_AVAILABLE_USERS,
  API_TG_CHECK_REPORT,
  API_TG_GET_TASK,
  API_TG_UPDATE_TASK,
  API_TG_DELETE_TASK
} from "../urls"

export const tgApi = {
  deleteTask: async(id:number,onSuccess:() => void) => {
    try{
      const response = await axios.get(`${API_TG_DELETE_TASK}/${id}`, axiosConfigAuth())
      if (response.status === 200) {
        showSuccessMessage("Задача успешно удалена.")
        onSuccess()
      }

    }catch (err: any) {
      if (err.response.status === 400) {
        showErrorMessage(err.response.data)
      } else {
        console.dir(err)
        showErrorMessage("Произошла ошибка во время удаления задачи.")
      }
    }
  },
  updateTask: async (data: {tasks_sending_id:number} | SetTask, onSuccess: () => void) => {
    try {
      const response = await axios.post(
        API_TG_UPDATE_TASK,
        data,
        axiosConfigAuth()
      )
      if (response.status === 200) {
        showSuccessMessage("Задача успешно Обновлена.")
        onSuccess()
      }
    } catch (err: any) {
      if (err.response.status === 400) {
        showErrorMessage(err.response.data)
      } else {
        console.dir(err)
        showErrorMessage("Произошла ошибка во время обновления задачи.")
      }
    }
  },
  isExistTask: async (id: number) => {
    try {
      const response = await axios.get(
        `${API_TG_CHECK_REPORT}/${id}`,
        axiosConfigAuth()
      )
      if (response.status === 200) {
        return response.data.status
      }
    } catch (err) {
      console.dir(err)
    }
    return false
  },

  getTaskById: async (id:number) => {
    const response = await axios.get(
      `${API_TG_GET_TASK}/${id}`,
      axiosConfigAuth()
    )
    if (response.status === 200) {
      return response.data
    } else {
      showErrorMessage("Произошла ошибка во время получения задач")
    }
  },
  getAvailableUsers: async (): Promise<UserTG[] | undefined> => {
    const response = await axios.get(API_TG_AVAILABLE_USERS, axiosConfigAuth())
    if (response.status === 200) {
      return response.data
    } else {
      showErrorMessage("Произошла ошибка при получении пользователей.")
    }
  },
  addTask: async (data: SetTask, onSuccess: () => void) => {
    try {
      const response = await axios.post(
        API_TG_ADD_TASK,
        data,
        axiosConfigAuth()
      )
      if (response.status === 200) {
        showSuccessMessage("Задача успешно добавлена.")
        onSuccess()
      }
    } catch (err: any) {
      if (err.response.status === 400) {
        showErrorMessage(err.response.data)
      } else {
        console.dir(err)
        showErrorMessage("Произошла ошибка во время создания задачи.")
      }
    }
  },
}
