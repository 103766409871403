import React, { useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { DatePicker, Form, Input, Modal } from "antd"
import moment from "moment"

import { UserUpdate } from "../../../../../../../../app/types/unApi"
import { showErrorMessage } from "../../../../../../../../helpers/utils/ui"
import { unApi } from "../../../../../../api/endpoints/reports/unApi"

type Props = {
    visible:boolean
    id:number
    setVisible: React.Dispatch<React.SetStateAction<{visible: boolean, id: number}>>
    loadRows:()=>void
}
const EditUser = ({ visible,id,setVisible,loadRows }:Props) => {
    const [ form ] = Form.useForm()
    const [ formLoading, setFormLoading ] = useState(false)

    const fetchData =  async () => {
        setFormLoading(true)
        try{
             const response = await unApi.getUserInfo(Number(id))
            form.resetFields()
            const { created_at, email, name } = response.data
            const formattedDate = moment(created_at)
            form.setFieldsValue({
                name: name,
                created_at: formattedDate,
                email: email
            })
        }catch(err){
            showErrorMessage("Произошла ошибка, повторите попытку позже.")
        }finally{
            setFormLoading(false)

        }
    }

    useEffect(() => {
        if (visible) {
            fetchData()
        }
    }, [ visible ])

    const editUser = async (values: UserUpdate) => {
        const data = {
            user_id:id,
            email:values.email,
            created_at:moment(values.created_at).format("YYYY-MM-DD")
        }
        setFormLoading(true)
        try{
            const response = await unApi.updateUserInfo(data)
            if(response.status === 200){
                form.resetFields()
                setVisible({ visible:false,id:0 })
                loadRows()
                Modal.info({
                    title: "Успех!",
                    content: "Информация об пользователе обновлена.",
                })
            }
        }catch(err){
            showErrorMessage("Произошла ошибка, повторите попытку позже.")

        }finally{
            setFormLoading(false)
        }
    }

    return (
        <Modal
            title="Обновление Пользователя"
            open={visible}
            onCancel={() => {setVisible({ visible:false,id:0 })}}
            onOk={() => {form.submit()}}
            okButtonProps={{ loading: formLoading }}
            okText="Сохранить"
        >
            {
                formLoading ?
                    <div className="loading-container">
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                    </div>
                    :
                    <Form
                        form={form}
                        onFinish={editUser}
                        requiredMark={false}
                    >
                        <Form.Item
                            name="name"
                            label="Имя пользователя"
                            rules={[ { required: true } ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="created_at"
                            label="Дата создания"
                            rules={[ { required: true } ]}
                        >
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <DatePicker style={{ marginLeft:26 }} format="DD/MM/YYYY" />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                { required: true },
                                { type: "email", message: "Введите корректный email" }
                            ]}
                        >
                            <Input style={{ marginLeft:84,width:"200px" }} />
                        </Form.Item>
                    </Form>
            }
        </Modal>
    )
}

export default EditUser
