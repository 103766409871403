import React, {useState, FC, Dispatch, SetStateAction} from "react"

import { Modal, Checkbox, Row, Col } from "antd"
import type { CheckboxChangeEvent } from "antd/es/checkbox"

import { IItem } from "./ConnectAds"
import Search from "antd/es/input/Search";

interface IAddModalProps {
    isVisible: boolean;
    setVisible: (val: boolean) => void;
    items: { id?: string; name?: string; data?: unknown }[];
    setItems: (val: { id?: string; name?: string }[]) => void;
    setImportFile:Dispatch<SetStateAction<{ visible: boolean; account_id: number; }>>
}
const ManualAddVK: FC<IAddModalProps> = ({
                                          isVisible,
                                          setVisible,
                                          items,
                                          setItems,
                                          setImportFile
                                      }) => {
    const [filteredItems, setFilteredItems] = useState(items);
    const [ selectedIds, setSelectedIds ] = useState<string[]>([])


    const handleCheckboxChange = (value: string, event: CheckboxChangeEvent) => {
        const checked = event.target.checked
        const index = selectedIds.indexOf(value)
        if (checked && index === -1) {
            setSelectedIds([ value ])
        } else if (!checked && index !== -1) {
            setSelectedIds(selectedIds.filter((id) => id !== value))
        }
    }

    const onClose = () => {
        setVisible(false)
        setItems([])
    }
    const handleOk = () => {
        setVisible(false)
        const selectedItems = items.filter((item: any) =>
            selectedIds.includes(item?.id)
        )
        const users = selectedItems.map((item: IItem) => item?.data)
        setImportFile({visible:true,account_id:+selectedItems[0].id!})
        setItems([])
    }
    const handleSearch = (value:string) => {
        const filtered = items.filter((item) =>{
                if(item.name){
                    return item.name.toLowerCase().includes(value.toLowerCase())
                }
            }
        );
        setFilteredItems(filtered);
    };
    return (
        <Modal
            maskClosable={false}
            open={isVisible}
            title={
                <>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                        }}
                    >
            <span style={{ marginRight: "16px" }}>
              Выберите рекламные кабинеты
            </span>
                        <div style={{
                            marginTop:"16px"
                        }}>
                            <Search placeholder="Поиск" onSearch={handleSearch} style={{ width: 200 }} />
                        </div>
                    </div>
                </>
            }
            okText="Добавить"
            cancelText="Отмена"
            onOk={handleOk}
            onCancel={onClose}
        >
            {filteredItems.length === 0 ? (
                <p style={{textAlign:"center"}}>Ничего не найдено</p>
            ) : (
                <Checkbox.Group value={selectedIds}>
                    <Row gutter={[8, 16]}>
                        {filteredItems.map((item) => (
                            <Col key={item.id} span={8}>
                                <Checkbox
                                    value={item.id}
                                    onChange={(event) => {
                                        if (item.id !== undefined) {
                                            handleCheckboxChange(item.id, event);
                                        }
                                    }}
                                >
                                    {item.name}
                                </Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            )}
        </Modal>
    )
}

export default ManualAddVK
