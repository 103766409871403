import React, { FC } from "react"

import CompareSegment from "./CompareSegment"
import CompareFilter from "./filter/CompareFilter"

interface ICompareBlockProps {
    prefix: 1 | 2
    filtersFilter?: string[]
}

const CompareFilterBlock: FC<ICompareBlockProps> = ({ prefix, filtersFilter }) => {
    return (
        <>
            <div>
                <CompareSegment prefix={prefix} />
            </div>
            <div>
                <CompareFilter prefix={prefix} filtersFilter={filtersFilter} />
            </div>
        </>
    )
}

export default CompareFilterBlock
