import React, { FC, useEffect, useState } from "react"

import ModalSettings from "./ModalSettings"
import { generateListKeys, prepareData } from "../../../../../../../helpers/utils/reports"
import { useActions } from "../../../../../hooks/useActions"
import { useTypedSelector } from "../../../../../hooks/useTypedSelector"

const Metrics: FC<any> = ({ isDisable, metricsData }) => {

    const { metricsReport } = useTypedSelector((state) => state.table)
    const { setReportMetrics, setMetaTableFetching } = useActions()

    const [ listKeysMetrics, setListKeysMetrics ] = useState([])
    const [ data, setData ] = useState([])

    useEffect(() => {
        let data = prepareData(metricsData, "", [], false)
        setData(data)
        setListKeysMetrics(generateListKeys(data))
        if(!location.pathname.includes("users")){
            setReportMetrics(data)
            return
        }
        const selectedMetrics = window.localStorage.getItem(`tableMetricsItemSelected_${location.pathname}`)
        if (selectedMetrics) {
            setReportMetrics(JSON.parse(selectedMetrics))
        }else {
            window.localStorage.setItem(`tableMetricsItemSelected_${location.pathname}`, JSON.stringify(data))
            setReportMetrics(data)
        }
    }, [])

    const actionSet = (selected: any) => {
        window.localStorage.setItem(`tableMetricsItemSelected_${location.pathname}`, JSON.stringify(selected))
        setReportMetrics(selected)
        setMetaTableFetching({value:true})
    }

    return (
        <ModalSettings
            title="Метрики"
            dataItems={data}
            listKeys={listKeysMetrics}
            defSelected={metricsReport}
            defChecked={metricsReport.map((item) => item.key)}
            isDisable={isDisable}
            actionSet={actionSet}
        />
    )
}

export default Metrics
