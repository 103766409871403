import React, { FC } from "react";

import { API_REPORT_TABLE } from "../../../shared/common/api/urls";
import MainLayout from "../../../shared/common/components/layouts/MainLayout";
import PageHead from "../../../shared/common/components/page-header/PageHeader";
import TableReport from "../../../shared/common/components/reports/table-report/TableReport";

const CrmActivity: FC = () => {
  const metrics = [
    { name: "Дата посещения", dataKey: "date_of_visit" },
    { name: "id школы", dataKey: "school_id" },
    { name: "Название школы", dataKey: "school_name" },
    { name: "id пользователя", dataKey: "user_id" },
    { name: "Email", dataKey: "email" },
    { name: "Чат", dataKey: "telegram" },
    { name: "Имя", dataKey: "name" },
    { name: "Тип пользователя", dataKey: "role" },
    { name: "URL адрес", dataKey: "visit_url" },
    { name: "ip адрес", dataKey: "ip_address" },
    { name: "Кол-во кликов", dataKey: "clicks_count" },
    { name: "Длительность", dataKey: "hit_duration" },
  ];

  return (
    <MainLayout pageTitle="Активность">
      <div className="page-wrapper">
        <PageHead title="Активность" />

        <div className="page-content">
          <TableReport
            apiUrl={API_REPORT_TABLE}
            metrics={metrics}
            filtersFilter={["Активность (CRM)"]}
            withSegment={false}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default CrmActivity;
