import React, {FC, useState} from "react"

import { Form, Input, Modal, Select } from "antd"

import { routeNames } from "../../../../shared/common/constants/routes"
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"
import { ISchoolFormData } from "../../../../app/providers/redux/slices/currentSchool/types"
import {showErrorMessage, showFormErrors, showSuccessNotification} from "../../../../helpers/utils/ui"
import { validateDomain} from "../../../../helpers/utils/validators";
import {schoolApi} from "../../../../shared/common/api/endpoints/schoolApi";
import {handleResponseError} from "../../../../shared/common/api/middleware";
import {useDispatch} from "react-redux";
import {TAppDispatch} from "../../../../app/providers/redux/store";

interface IAddModalProps {
    isVisible: boolean
    setVisible: (val: boolean) => void
}

const AddModal: FC<IAddModalProps> = ({ isVisible, setVisible }) => {
    const [ form ] = Form.useForm()
    const dispatch = useDispatch<TAppDispatch>()
    const { isLoading } = useTypedSelector((state) => state.currentSchool)

    const addSchool = (values: ISchoolFormData) => {
        try{
            const domainValue = values.domain.includes(".getcourse") ? values.domain : `${values.domain}.getcourse.ru`
            const modifiedValues = { ...values, domain: domainValue };
            Object.entries(modifiedValues).forEach(([ key, value ]) => {
                if (typeof value === "string") {
                    if (value.startsWith("http")) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        values[key] = value.replace(/(^\w+:|^)\/\//, "")
                    }
                }
            })
            schoolApi
                .addSchool(modifiedValues)
                .then((response) => {
                    if (response.status === 200) {
                        showSuccessNotification("Школа успешно добавлена.");
                        window.location.href = routeNames.schoolAdded
                    }
                })
                .catch((err) => {
                    console.log(err.response)
                    handleResponseError(dispatch,err)
                    if (err.response?.status === 400) {
                        showFormErrors(form, err.response.data)
                        return;
                    }
                })
        }catch(err){
            console.log(err)
            showErrorMessage("Произошла ошибка, повторите попытку позже.")
        }
    }

    const [domainSuffix,setDomainSuffix] = useState('.getcourse.ru')

    const handleDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputDomain = e.target.value;
        let cleanedDomain = inputDomain;

        cleanedDomain = cleanedDomain.replace(/(https?:\/\/)?(www\.)?/i, '');

        // cleanedDomain = cleanedDomain.replace(/\.getcourse\.ru/i, '').replace(/\/$/, '');
        //
        // const getcourseMatch = cleanedDomain.match(/\.getcourse\.(\w+)/i)
        //
        // if (getcourseMatch) {
        //     setDomainSuffix(`.getcourse.${getcourseMatch[1]}`)
        // } else {
        //     setDomainSuffix('.getcourse.ru')
        // }

        form.setFieldsValue({ domain: cleanedDomain });
    };

    return (
        <Modal
            title="Добавление школы, созданной на платформе GetCourse"
            open={isVisible}
            onOk={() => form.submit()}
            onCancel={() => {
                form.resetFields()
                setVisible(false)
            }
            }
            okText="Добавить"
            cancelText="Отмена"
            okButtonProps={{ loading: isLoading }}
            destroyOnClose
        >
            <Form
                form={form}
                onFinish={addSchool}
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    currency: 1,
                    timezone: "74",
                    language: 1
                }}
            >

                <Form.Item
                    label="Название школы"
                    name="name"
                    rules={[ { required: true, message: "Поле обязательное" } ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Валюта"
                    name="currency"
                >
                    <Select>
                        <Select.Option value={1}>Рубль РФ</Select.Option>
                        <Select.Option value={2}>Доллар США</Select.Option>
                        <Select.Option value={3}>Евро</Select.Option>
                        <Select.Option value={4}>Тенге</Select.Option>
                        <Select.Option value={5}>Гривна </Select.Option>
                        <Select.Option value={6}>Беларусский рубль</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Часовой пояс"
                    name="timezone"
                >
                    <Select>
                        <Select.Option value="1" data-select2-id="17">(UTC-12:00) Линия перемены дат</Select.Option>
                        <Select.Option value="2" data-select2-id="18">(UTC-11:00) Время в формате UTC -11</Select.Option>
                        <Select.Option value="3" data-select2-id="19">(UTC-10:00) Алеутские острова</Select.Option>
                        <Select.Option value="4" data-select2-id="20">(UTC-10:00) Гавайи</Select.Option>
                        <Select.Option value="5" data-select2-id="21">(UTC-09:30) Маркизские острова</Select.Option>
                        <Select.Option value="6" data-select2-id="22">(UTC-09:00) Аляска</Select.Option>
                        <Select.Option value="7" data-select2-id="23">(UTC-09:00) Время в формате UTC -09</Select.Option>
                        <Select.Option value="8" data-select2-id="24">(UTC-08:00) Время в формате UTC -08</Select.Option>
                        <Select.Option value="9" data-select2-id="25">(UTC-08:00) Нижняя Калифорния</Select.Option>
                        <Select.Option value="10" data-select2-id="26">(UTC-08:00) Тихоокеанское время США и Канада</Select.Option>
                        <Select.Option value="11" data-select2-id="27">(UTC-07:00) Аризона</Select.Option>
                        <Select.Option value="12" data-select2-id="28">(UTC-07:00) Горное время США и Канада</Select.Option>
                        <Select.Option value="13" data-select2-id="29">(UTC-07:00) Ла-Пас, Мазатлан, Чихуахуа</Select.Option>
                        <Select.Option value="14" data-select2-id="30">(UTC-07:00) Юкон</Select.Option>
                        <Select.Option value="15" data-select2-id="31">(UTC-06:00) Гвадалахара, Мехико, Монтеррей</Select.Option>
                        <Select.Option value="16" data-select2-id="32">(UTC-06:00) о. Пасхи</Select.Option>
                        <Select.Option value="17" data-select2-id="33">(UTC-06:00) Саскачеван</Select.Option>
                        <Select.Option value="18" data-select2-id="34">(UTC-06:00) Центральная Америка</Select.Option>
                        <Select.Option value="19" data-select2-id="35">(UTC-06:00) Центральное время США и Канада</Select.Option>
                        <Select.Option value="20" data-select2-id="36">(UTC-05:00) Богота, Кито, Лима, Рио-Бранко</Select.Option>
                        <Select.Option value="21" data-select2-id="37">(UTC-05:00) Восточное время США и Канада</Select.Option>
                        <Select.Option value="22" data-select2-id="38">(UTC-05:00) Гавана</Select.Option>
                        <Select.Option value="23" data-select2-id="39">(UTC-05:00) Гаити</Select.Option>
                        <Select.Option value="24" data-select2-id="40">(UTC-05:00) Индиана восток</Select.Option>
                        <Select.Option value="25" data-select2-id="41">(UTC-05:00) Острова Теркс и Кайкос</Select.Option>
                        <Select.Option value="26" data-select2-id="42">(UTC-05:00) Четумаль</Select.Option>
                        <Select.Option value="27" data-select2-id="43">(UTC-04:00) Асунсьон</Select.Option>
                        <Select.Option value="28" data-select2-id="44">(UTC-04:00) Атлантическое время Канада</Select.Option>
                        <Select.Option value="29" data-select2-id="45">(UTC-04:00) Джорджтаун, Ла-Пас, Манаус, Сан-Хуан</Select.Option>
                        <Select.Option value="30" data-select2-id="46">(UTC-04:00) Каракас</Select.Option>
                        <Select.Option value="31" data-select2-id="47">(UTC-04:00) Куяба</Select.Option>
                        <Select.Option value="32" data-select2-id="48">(UTC-04:00) Сантьяго</Select.Option>
                        <Select.Option value="33" data-select2-id="49">(UTC-03:30) Ньюфаундленд</Select.Option>
                        <Select.Option value="34" data-select2-id="50">(UTC-03:00) Арагуаяна</Select.Option>
                        <Select.Option value="35" data-select2-id="51">(UTC-03:00) Бразилия</Select.Option>
                        <Select.Option value="36" data-select2-id="52">(UTC-03:00) Буэнос-Айрес</Select.Option>
                        <Select.Option value="37" data-select2-id="53">(UTC-03:00) Гренландия</Select.Option>
                        <Select.Option value="38" data-select2-id="54">(UTC-03:00) Кайенна, Форталеза</Select.Option>
                        <Select.Option value="39" data-select2-id="55">(UTC-03:00) Монтевидео</Select.Option>
                        <Select.Option value="40" data-select2-id="56">(UTC-03:00) Пунта-Аренас</Select.Option>
                        <Select.Option value="41" data-select2-id="57">(UTC-03:00) Сальвадор</Select.Option>
                        <Select.Option value="42" data-select2-id="58">(UTC-03:00) Сен-Пьер и Микелон</Select.Option>
                        <Select.Option value="43" data-select2-id="59">(UTC-02:00) Время в формате UTC -02</Select.Option>
                        <Select.Option value="44" data-select2-id="60">(UTC-01:00) Азорские о-ва</Select.Option>
                        <Select.Option value="45" data-select2-id="61">(UTC-01:00) Кабо-Верде</Select.Option>
                        <Select.Option value="46" data-select2-id="62">(UTC+00:00) Дублин, Эдинбург, Лиссабон, Лондон</Select.Option>
                        <Select.Option value="47" data-select2-id="63">(UTC+00:00) Монровия, Рейкьявик</Select.Option>
                        <Select.Option value="48" data-select2-id="64">(UTC+00:00) Сан-Томе</Select.Option>
                        <Select.Option value="49" data-select2-id="65">(UTC+01:00) Касабланка</Select.Option>
                        <Select.Option value="50" data-select2-id="66">(UTC+01:00) Амстердам, Берлин, Берн, Вена, Рим, Стокгольм</Select.Option>
                        <Select.Option value="51" data-select2-id="67">(UTC+01:00) Белград, Братислава, Будапешт, Любляна, Прага</Select.Option>
                        <Select.Option value="52" data-select2-id="68">(UTC+01:00) Брюссель, Копенгаген, Мадрид, Париж</Select.Option>
                        <Select.Option value="53" data-select2-id="69">(UTC+01:00) Варшава, Загреб, Сараево, Скопье</Select.Option>
                        <Select.Option value="54" data-select2-id="70">(UTC+01:00) Западная Центральная Африка</Select.Option>
                        <Select.Option value="55" data-select2-id="71">(UTC+02:00) Амман</Select.Option>
                        <Select.Option value="56" data-select2-id="72">(UTC+02:00) Афины, Бухарест</Select.Option>
                        <Select.Option value="57" data-select2-id="73">(UTC+02:00) Бейрут</Select.Option>
                        <Select.Option value="58" data-select2-id="74">(UTC+02:00) Вильнюс, Киев, Рига, София, Таллин, Хельсинки</Select.Option>
                        <Select.Option value="59" data-select2-id="75">(UTC+02:00) Виндхук</Select.Option>
                        <Select.Option value="60" data-select2-id="76">(UTC+02:00) Дамаск</Select.Option>
                        <Select.Option value="61" data-select2-id="77">(UTC+02:00) Джуба</Select.Option>
                        <Select.Option value="62" data-select2-id="78">(UTC+02:00) Иерусалим</Select.Option>
                        <Select.Option value="63" data-select2-id="79">(UTC+02:00) Каир</Select.Option>
                        <Select.Option value="64" data-select2-id="80">(UTC+02:00) Калининград</Select.Option>
                        <Select.Option value="65" data-select2-id="81">(UTC+02:00) Кишинев</Select.Option>
                        <Select.Option value="66" data-select2-id="82">(UTC+02:00) Сектор Газа, Хеврон</Select.Option>
                        <Select.Option value="67" data-select2-id="83">(UTC+02:00) Триполи</Select.Option>
                        <Select.Option value="68" data-select2-id="84">(UTC+02:00) Хараре, Претория</Select.Option>
                        <Select.Option value="69" data-select2-id="85">(UTC+02:00) Хартум</Select.Option>
                        <Select.Option value="70" data-select2-id="86">(UTC+03:00) Багдад</Select.Option>
                        <Select.Option value="71" data-select2-id="87">(UTC+03:00) Волгоград</Select.Option>
                        <Select.Option value="72" data-select2-id="88">(UTC+03:00) Кувейт, Эр-Рияд</Select.Option>
                        <Select.Option value="73" data-select2-id="89">(UTC+03:00) Минск</Select.Option>
                        <Select.Option value="74" data-select2-id="4">(UTC+03:00) Москва, Санкт-Петербург</Select.Option>
                        <Select.Option value="75" data-select2-id="90">(UTC+03:00) Найроби</Select.Option>
                        <Select.Option value="76" data-select2-id="91">(UTC+03:00) Стамбул</Select.Option>
                        <Select.Option value="77" data-select2-id="92">(UTC+03:30) Тегеран</Select.Option>
                        <Select.Option value="78" data-select2-id="93">(UTC+04:00) Абу-Даби, Мускат</Select.Option>
                        <Select.Option value="79" data-select2-id="94">(UTC+04:00) Астрахань, Ульяновск</Select.Option>
                        <Select.Option value="80" data-select2-id="95">(UTC+04:00) Баку</Select.Option>
                        <Select.Option value="81" data-select2-id="96">(UTC+04:00) Ереван</Select.Option>
                        <Select.Option value="82" data-select2-id="97">(UTC+04:00) Ижевск, Самара</Select.Option>
                        <Select.Option value="83" data-select2-id="98">(UTC+04:00) Порт-Луи</Select.Option>
                        <Select.Option value="84" data-select2-id="99">(UTC+04:00) Саратов</Select.Option>
                        <Select.Option value="85" data-select2-id="100">(UTC+04:00) Тбилиси</Select.Option>
                        <Select.Option value="86" data-select2-id="101">(UTC+04:30) Кабул</Select.Option>
                        <Select.Option value="87" data-select2-id="102">(UTC+05:00) Ашхабад, Ташкент</Select.Option>
                        <Select.Option value="88" data-select2-id="103">(UTC+05:00) Екатеринбург</Select.Option>
                        <Select.Option value="89" data-select2-id="104">(UTC+05:00) Исламабад, Карачи</Select.Option>
                        <Select.Option value="90" data-select2-id="105">(UTC+05:00) Кызылорда</Select.Option>
                        <Select.Option value="91" data-select2-id="106">(UTC+05:30) Колката, Мумбаи, Нью-Дели, Ченнай</Select.Option>
                        <Select.Option value="92" data-select2-id="107">(UTC+05:30) Шри-Джаявардене-пура-Котте</Select.Option>
                        <Select.Option value="93" data-select2-id="108">(UTC+05:45) Катманду</Select.Option>
                        <Select.Option value="94" data-select2-id="109">(UTC+06:00) Астана</Select.Option>
                        <Select.Option value="95" data-select2-id="110">(UTC+06:00) Дакка</Select.Option>
                        <Select.Option value="96" data-select2-id="111">(UTC+06:00) Омск</Select.Option>
                        <Select.Option value="97" data-select2-id="112">(UTC+06:30) Янгон</Select.Option>
                        <Select.Option value="98" data-select2-id="113">(UTC+07:00) Бангкок, Джакарта, Ханой</Select.Option>
                        <Select.Option value="99" data-select2-id="114">(UTC+07:00) Барнаул, Горно-Алтайск</Select.Option>
                        <Select.Option value="100" data-select2-id="115">(UTC+07:00) Красноярск</Select.Option>
                        <Select.Option value="101" data-select2-id="116">(UTC+07:00) Новосибирск</Select.Option>
                        <Select.Option value="102" data-select2-id="117">(UTC+07:00) Томск</Select.Option>
                        <Select.Option value="103" data-select2-id="118">(UTC+07:00) Ховд</Select.Option>
                        <Select.Option value="104" data-select2-id="119">(UTC+08:00) Гонконг, Пекин, Урумчи, Чунцин</Select.Option>
                        <Select.Option value="105" data-select2-id="120">(UTC+08:00) Иркутск</Select.Option>
                        <Select.Option value="106" data-select2-id="121">(UTC+08:00) Куала-Лумпур, Сингапур</Select.Option>
                        <Select.Option value="107" data-select2-id="122">(UTC+08:00) Перт</Select.Option>
                        <Select.Option value="108" data-select2-id="123">(UTC+08:00) Тайбэй</Select.Option>
                        <Select.Option value="109" data-select2-id="124">(UTC+08:00) Улан-Батор</Select.Option>
                        <Select.Option value="110" data-select2-id="125">(UTC+08:45) Юкла</Select.Option>
                        <Select.Option value="111" data-select2-id="126">(UTC+09:00) Осака, Саппоро, Токио</Select.Option>
                        <Select.Option value="112" data-select2-id="127">(UTC+09:00) Пхеньян</Select.Option>
                        <Select.Option value="113" data-select2-id="128">(UTC+09:00) Сеул</Select.Option>
                        <Select.Option value="114" data-select2-id="129">(UTC+09:00) Чита</Select.Option>
                        <Select.Option value="115" data-select2-id="130">(UTC+09:00) Якутск</Select.Option>
                        <Select.Option value="116" data-select2-id="131">(UTC+09:30) Аделаида</Select.Option>
                        <Select.Option value="117" data-select2-id="132">(UTC+09:30) Дарвин</Select.Option>
                        <Select.Option value="118" data-select2-id="133">(UTC+10:00) Брисбен</Select.Option>
                        <Select.Option value="119" data-select2-id="134">(UTC+10:00) Владивосток</Select.Option>
                        <Select.Option value="120" data-select2-id="135">(UTC+10:00) Гуам, Порт-Морсби</Select.Option>
                        <Select.Option value="121" data-select2-id="136">(UTC+10:00) Канберра, Мельбурн, Сидней</Select.Option>
                        <Select.Option value="122" data-select2-id="137">(UTC+10:00) Хобарт</Select.Option>
                        <Select.Option value="123" data-select2-id="138">(UTC+10:30) Лорд-Хау</Select.Option>
                        <Select.Option value="124" data-select2-id="139">(UTC+11:00) Магадан</Select.Option>
                        <Select.Option value="125" data-select2-id="140">(UTC+11:00) Остров Бугенвиль</Select.Option>
                        <Select.Option value="126" data-select2-id="141">(UTC+11:00) Остров Норфолк</Select.Option>
                        <Select.Option value="127" data-select2-id="142">(UTC+11:00) Сахалин</Select.Option>
                        <Select.Option value="128" data-select2-id="143">(UTC+11:00) Соломоновы о-ва, Нов. Каледония</Select.Option>
                        <Select.Option value="129" data-select2-id="144">(UTC+11:00) Чокурдах</Select.Option>
                        <Select.Option value="130" data-select2-id="145">(UTC+12:00) Анадырь, Петропавловск-Камчатский</Select.Option>
                        <Select.Option value="131" data-select2-id="146">(UTC+12:00) Веллингтон, Окленд</Select.Option>
                        <Select.Option value="132" data-select2-id="147">(UTC+12:00) Время в формате UTC +12</Select.Option>
                        <Select.Option value="133" data-select2-id="148">(UTC+12:00) Фиджи</Select.Option>
                        <Select.Option value="134" data-select2-id="149">(UTC+12:45) Чатем</Select.Option>
                        <Select.Option value="135" data-select2-id="150">(UTC+13:00) Время в формате UTC +13</Select.Option>
                        <Select.Option value="136" data-select2-id="151">(UTC+13:00) Нукуалофа</Select.Option>
                        <Select.Option value="137" data-select2-id="152">(UTC+13:00) Самоа</Select.Option>
                        <Select.Option value="138" data-select2-id="153">(UTC+14:00) О-в Киритимати</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Язык интерфейса"
                    name="language"
                >
                    <Select>
                        <Select.Option value={1}>Русский</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Адрес аккаунта GetCourse (sait.getcourse.ru - в настройках)"
                    name="domain"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательное",
                        },
                        {
                            validator:validateDomain
                        }
                    ]}
                    help={form.getFieldError("domain") ? form.getFieldError("domain")[0] : ""}
                >
                    <Input onChange={handleDomainChange}
                           // suffix={domainSuffix}
                           placeholder="В формате name.getcourse.ru" />
                </Form.Item>

                <Form.Item
                    label="Привязанный домен к GetCourse, если есть"
                    name="main_domain"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательное",
                        },
                        {
                            validator:validateDomain
                        }
                    ]}
                    help={form.getFieldError("domain") ? form.getFieldError("domain")[0] : ""}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Getcourse API Key"
                    name="api_key"
                    rules={[ { required: true, message: "Поле обязательное" } ]}
                >
                    <Input />
                </Form.Item>

            </Form>
        </Modal>
    )
}

export default AddModal
