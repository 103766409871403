import { useEffect } from "react"

import { Table } from "antd"

import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"

const columns = [
    {
        title: "Дата регистрации",
        dataIndex: "reg_date",
        key: "reg_date",
    },
    {
        title: "ID реферала",
        dataIndex: "referal_id",
        key: "referal_id",
    },
    {
        title: "Уровень реферала",
        dataIndex: "level_id",
        key: "level_id",
    },
    {
        title: "Имя реферала",
        dataIndex: "referal_name",
        key: "referal_name",
    },
    {
        title: "Email реферала",
        dataIndex: "referal_email",
        key: "referal_email",
    },
    {
        title: "Привязан до",
        dataIndex: "tied_up",
        key: "tied_up",
    },
    {
        title: "Осталось дней",
        dataIndex: "days_left",
        key: "days_left",
    },
    {
        title: "Сумма платежей",
        dataIndex: "payment_sum",
        key: "payment_sum",
    },
    {
        title: "Сумма начисленний",
        dataIndex: "payment_amount",
        key: "payment_amount",
    },
]



const ReferralsTab = () => {
    const { referalsList } = useTypedSelector((state)=> state.partner)
    const { referalsFetchData } = useActions()
    useEffect(()=>{
        referalsFetchData()
    },[])
    return (
		<Table columns={columns} dataSource={referalsList} pagination={false} />
    )
}

export default ReferralsTab