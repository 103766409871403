import {API_CRM_GET_REQUEST} from "../urls";
import {getAccessToken} from "../../../../helpers/utils/auth";

export const crmRequestsApi = {
    downloadSqlRequest: async (id:number | string) => {
            const url = `${API_CRM_GET_REQUEST}/${id}/${getAccessToken()}`
            const link = document.createElement("a")

            link.href = url

            document.body.appendChild(link)
            link.click()
            link.remove()
    }
}
