import React, { FC } from "react"

import { CopyOutlined } from "@ant-design/icons"
import { Button } from "antd"

import MainLayout from "../../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../../shared/common/components/page-header/PageHeader"
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"
import { showErrorMessage, showSuccessMessage } from "../../../../helpers/utils/ui"
import styles from "./ConnectBizon.module.scss"

const ConnectBizon: FC = () => {

    const { id } = useTypedSelector((state) => state.currentUser)
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)

    const script = `
    <!— =====analitika.school=====START========== —>
    <script>
    var school_webvisor = {
        user_id: ${id},
        school_id: ${selectedSchool?.id || 0},
        params: {
            launch_delay: 0,
            interval: 10000
        }
    };
    
    (function(conf) {
        let GETParams, script;
    
        GETParams = new URLSearchParams({
            user_id: conf.user_id,
            school_id: conf.school_id,
        });
    
        script = document.createElement('script');
        script.setAttribute('src','https://api.analitika.school/script_bizon?' + GETParams.toString());
        script.setAttribute("async","true");
        document.documentElement.children[0].appendChild(script);
    })(window.school_webvisor || {});
    </script>
    <!— ===== analitika.school=====END========== —>

    `

    const copyScript = () => {
        navigator.clipboard.writeText(script)
            .then(() => {
                showSuccessMessage("Скрипт скопирован.")
            })
            .catch(() => {
                showErrorMessage("Произошла ошибка при копировании.")
            })
    }

    return (
        <MainLayout pageTitle="Подключение Bizon365">
            <div className="page-wrapper">
                <PageHead title="Скрипт для Bizon365" />

                <div className="page-content">
                    <div>
                        <p>
                            Скопируйте и вставьте данный скрипт в настройках комнаты Bizon365, раздел Разное-{">"}HTML-{">"}Постоянный HTML/JS-код.
                        </p>

                        <Button
                            type="primary"
                            icon={<CopyOutlined />}
                            onClick={() => copyScript()}
                            className={styles.copyButton}
                        >
                            Скопировать в буфер
                        </Button>

                        <pre className={styles.scriptPane}>
                            {script}
                        </pre>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

export default ConnectBizon
