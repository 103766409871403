import React, { FC, useEffect } from "react"

import {
    PieChartOutlined,
    LineChartOutlined,
    DotChartOutlined,
    AreaChartOutlined,
    BarChartOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons"
import { Button, Space, Tooltip } from "antd"

import { chartDescription } from "../../../../constants/tooltips"
import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import usePermission from "../../../../hooks/usePermission";
import {ToggleChart} from "../../../../../../widgets/ToggleChart";

const ButtonsType: FC = () => {
    const { isCompare } = useTypedSelector((state) => state.meta)
    const { chartType,isChartVisible } = useTypedSelector((state) => state.chart)
    const { setChartType, setMetaChartFetching, setMetaCompareChartFetching } = useActions()

    const changeType = (type: string) => {

        setChartType(type)
        if (isCompare) {
            setTimeout(() => setMetaCompareChartFetching({value:true}), 50)
        } else {
            setMetaChartFetching({value:true})
        }
    }

    useEffect(() => {
        if (isCompare) {
            if (chartType === "bar") {
                changeType("bar")
            } else if (chartType !== "line") {
                changeType("line")
            }
        }
    }, [ isCompare ])

    if(window.location.pathname === '/dashboards'){
        return null
    }

    return (
        <Space size={7}>
            {isChartVisible && <ToggleChart />}
            <Button
                onClick={() => changeType("pie")}
                icon={<PieChartOutlined />}
                type={chartType === "pie" ? "primary" : "text"}
                title="Круговая"
                disabled={isCompare}
            />
            <Button
                onClick={() => changeType("line")}
                icon={<LineChartOutlined />}
                type={chartType === "line" ? "primary" : "text"}
                title="Линии"
            />
            <Button
                onClick={() => changeType("area")}
                icon={<AreaChartOutlined />}
                type={chartType === "area" ? "primary" : "text"}
                title="Области"
                disabled={isCompare}
            />
            <Button
                onClick={() => changeType("bar")}
                icon={<BarChartOutlined />}
                type={chartType === "bar" ? "primary" : "text"}
                title="Колонки"
            />
            <Button
                onClick={() => changeType("bubble")}
                icon={<DotChartOutlined />}
                type={chartType === "bubble" ? "primary" : "text"}
                title="Пузырьковая"
                disabled={isCompare}
            />

            <Tooltip
                placement="bottom"
                title={
                    <div className="page-title-tooltip-text" dangerouslySetInnerHTML={{ __html: chartDescription }}></div>
                }
                color="#fff"
                zIndex={9999}
                trigger="click"
                overlayClassName="page-title-tooltip"
            >
                <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14, marginLeft: 10 }}/>
            </Tooltip>
        </Space>
    )
}

export default ButtonsType
