import { FC } from "react";

import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

import { routeNames } from "../../shared/common/constants/routes";
import { useActions } from "../../shared/common/hooks/useActions";

const NoAccess: FC = () => {
  const navigate = useNavigate();

  const { authLogout } = useActions();

  return (
    <div className="global-loading-container">
      <Result
        status="warning"
        title="Ознакомительный период и доступ к сервису закончился."
        subTitle={
          <div>
            <span>&nbsp; &nbsp; &nbsp; &nbsp;Пожалуйста, напишите нам </span>
            <a href="https://t.me/analytics_scan" target="_blank">
              https://t.me/analytics_scan
            </a>
            <span>, чтобы продлить доступ.</span>
          </div>
        }
        extra={
          <div>
            <Button
              type="primary"
              onClick={() => {
                window.location.href = "/";
              }}
              style={{ marginRight: 10 }}
            >
              На главную
            </Button>
            <Button
              type="default"
              onClick={() =>
                authLogout(() => {
                  navigate(routeNames.login);
                })
              }
            >
              Выйти
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default NoAccess;
