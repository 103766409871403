import React, { useEffect, useState } from "react";

import { Card, Statistic } from "antd";
import moment from "moment/moment";

import { MetricsPaths } from "../../../../helpers/utils/enums";
import { showErrorMessage } from "../../../../helpers/utils/ui";
import { unApi } from "../../../../shared/common/api/endpoints/reports/unApi";
import Loader from "../../../../shared/common/components/loader/Loader";
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector";

const CartNumber = (props: any) => {
  const metricName = props.metricsChecked[0]?.label || "Выберите метрику";
  const period = useTypedSelector((state) => state.period);
  const [value, setValue] = useState("");
  const filtersStorageValue =
    JSON.parse(localStorage.getItem("dashboardFilters") || "") || [];
  const { isAllBlocksFetching } = useTypedSelector((state) => state.meta);
  const { filters: commonFilter } = useTypedSelector((state) =>
    props?.blockId
      ? props?.isShow
        ? state.filter
        : filtersStorageValue
      : filtersStorageValue
  );
  const commonFilters =
    commonFilter?.length === 0 && props?.isShow
      ? filtersStorageValue
      : commonFilter;
  const { attributesDashboard } = useTypedSelector((state) => state.attributes);
  const [isLoading, setLoading] = useState(false);
  const loadRows = (loadCurrent?: any) => {
    setLoading(true);
    try {
      type DateKeys = "reg" | "ad" | "order" | "payment" | "web" | "visit";

      const dateToPathname: Record<DateKeys, MetricsPaths> = {
        reg: MetricsPaths.Universal,
        ad: MetricsPaths.AdReports,
        order: MetricsPaths.OrderReports,
        payment: MetricsPaths.PaymentReports,
        web: MetricsPaths.WebinarReports,
        visit: MetricsPaths.Universal,
      };

      let __attribution = (
        attributesDashboard?.attribution === "blocks"
          ? props?.attribution
          : attributesDashboard
      )?.attribution;
      let __type_app = (
        attributesDashboard?.attribution === "blocks"
          ? props?.attribution
          : attributesDashboard
      )?.type_applications;
      let __period = period;

      const format = "YYYY-MM-DD";
      const _period = {
        start: moment(__period.start).format(format),
        end: moment(__period.end).format(format),
      };
      const groupings_names = props.selectedGroupings.map(
        (col: any) => col.dataKey
      );
      const metrics_names = props.selectedMetrics.map(
        (col: any) => col.dataKey
      );
      const expanded_ids = loadCurrent ? loadCurrent.clientData.path : [];

      if (expanded_ids.length === groupings_names.length) {
        return;
      }
      let data = {
        period: _period,
        filters: [...props.filters, ...(props?.isShow ? commonFilters : [])],
        attribution: {
          attribution:
            typeof __attribution === "string" ? [__attribution] : __attribution,
          type_applications:
            typeof __type_app === "string" ? [__type_app] : __type_app,
        },
        groupings_names,
        metrics_names,
        expanded_ids,
      };

      window.localStorage.setItem("prevRequestState", JSON.stringify(data));
      console.log("=>> REQUEST =>>\n", data, "\n=>> REQUEST =>>");
      unApi
        .getUniversalReportTableData(
          data,
          dateToPathname[props.date as DateKeys]
        )
        .then((response) => {
          const rowsWithIdAsString = response.data?.rows?.map((row: any) => {
            if (row.id) {
              return { ...row, id: row.id.toString() };
            }
            return row;
          });

          const data = { ...response.data, rows: rowsWithIdAsString };

          console.log("<<= RESPONSE <<=\n", data, "\n<<= RESPONSE <<=");
          if (data.success) {
            setValue(data.total[props.metricsChecked[0]?.name]);
          } else {
            //showErrorMessage("Ошибка при получении данных")
            if (response.status === 504) {
              showErrorMessage(
                "Запрос выполняется слишком долго, из-за большого количества данных. \n Можно уменьшить период отчёта, удалить ненужные метрики или фильтры."
              );
            }

            console.log("Ошибка");
          }
          // setCompareLoading((prevState) => ([ ...prevState.filter((val) => val.time !== time), {time: time, loading: false}]))
        })
        .catch((error) => {
          console.log("error = ", error);
          showErrorMessage("Ошибка при получении данных");
          if (error.response) {
            if (error.response.status === 504) {
              showErrorMessage(
                "Запрос выполняется слишком долго, из-за большого количества данных. \n Можно уменьшить период отчёта, удалить ненужные метрики или фильтры."
              );
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadRows();
  }, [period, ...(props.isShow ? commonFilters : []), metricName]);
  useEffect(() => {
    if (isAllBlocksFetching) {
      loadRows();
    }
  }, [isAllBlocksFetching]);
  const getStylesForBody = () => {
    return props?.isShow
      ? {
          padding: 0,
          jusstifyContent: "flex-start",
          alignItems: "flex-start",
        }
      : {
          padding: "10px 20px",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };
  };
  const getStyles = () => {
    return props?.isShow
      ? {
          padding: 0,
          marginTop: 40,
          jusstifyContent: "flex-start",
          alignItems: "flex-start",
        }
      : {
          borderRadius: "10px",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };
  };

  return (
    <Card
      bordered={!props?.isShow}
      style={getStyles()}
      bodyStyle={getStylesForBody()}
      className={"bordered-card"}
    >
      <Statistic
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: props?.isShow ? "flex-start" : "center",
            }}
          >
            {!props?.isShow && (
              <span style={{ color: "black" }}>{metricName}</span>
            )}
            <Loader loading={isLoading}>
              <span
                style={{
                  color: "black",
                  fontSize: props?.isShow ? "30px" : "16px",
                  fontWeight: "500",
                }}
              >
                {!value ? "Нет данных" : value}
              </span>
            </Loader>
          </div>
        }
        prefix={"+"}
        suffix={"%"}
        value={"72,43"}
        valueStyle={{ color: "#47d347", fontSize: "12px" }}
        precision={0}
      />
    </Card>
  );
};
export default CartNumber;
