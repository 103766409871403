import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import moment from "moment"
import { IPeriodState } from "./types"
import { switchInterval, updateQueryParams} from "../../../../../../../helpers/utils/functions";
const defaultState = {
    start: moment().set({ hour: 0, minute: 0, second: 0 }).format("YYYY-MM-DD"),
    end: moment().set({ hour: 23, minute: 59, second: 59 }).format("YYYY-MM-DD"),
    interval: "today",
    select: "",
    blocks: {},
}
const getStateFromQuery = () => {
    const url = new URL(window.location.href)
    const currentParams = url.searchParams.get('params')
    const paramsObject = currentParams ? JSON.parse(currentParams) : {}
    const period = paramsObject?.period
    if (period) {
        return paramsObject.period
    }
    return defaultState
}


export const initialPeriodState: IPeriodState = {
    ...getStateFromQuery()
}

export const periodSlice = createSlice({
    name: "period",
    initialState: initialPeriodState,
    reducers: {
        setPeriod: (state, action: PayloadAction<IPeriodState & { blockId?: string }>) => {
            const { blockId, ...periodData } = action.payload

            const setPeriodData = (data:any,isBlocks=false) => {
                const report = { ...data }
                if(report?.interval){
                    const { start, end } = switchInterval(report.interval)
                    if(start && end){
                        report.start = start.format("YYYY-MM-DD")
                        report.end = end.format("YYYY-MM-DD")
                    }
                }
                const newPeriodState = {
                    start: moment(report.start, "YYYY-MM-DD"),
                    end: moment(report.end, "YYYY-MM-DD"),
                    interval: report.interval,
                    select: report.select || report.interval,
                    days: report.days,
                    ...(isBlocks && {blocks:state.blocks})
                }
               updateQueryParams(newPeriodState,"period")
                return newPeriodState
            }

            if (blockId) {
                state.blocks![blockId] = setPeriodData(periodData)
            } else {
                window.localStorage.setItem(`current_un_period`, JSON.stringify(setPeriodData(periodData)))
                return setPeriodData(periodData,true)
            }
        },
    },
})

export const periodSliceActions = periodSlice.actions
export const periodSliceReducer = periodSlice.reducer
