import React, { useEffect, useMemo, useState } from "react"

import { Button, InputNumber, Radio, Space } from "antd"
import _ from "lodash"

import { updateFilterItemInList } from "../../../../../../../helpers/utils/reports"
import { useActions } from "../../../../../hooks/useActions"
import { useForceUpdate } from "../../../../../hooks/useForceUpdate"
import { useTypedSelector } from "../../../../../hooks/useTypedSelector"

const NumberRangePicker = ({ disabled = false, id, index, modalVisibleHandler, ...props }: any) => {
    const forceUpdate = useForceUpdate()

    const { filters } = useTypedSelector((state) => state.filter)
    const { setFilters, setFilter, setMetaFilterUpdate } = useActions()

    const [ isEditing, setIsEditing ] = useState(false)
    const [ filterMode, setFilterMode ] = useState(1)
    const [ _id, _setId ] = useState(id)
    const isPeriodFilter = props?.name?.includes("Период от")
    useEffect(() => {
        forceUpdate()
    }, [ props.itemData, index, id ])

    useEffect(() => {
        let _tempId = id

        if (filterMode === 2) {
            setFilterMode(2)
            if (!id.includes("not_")) {
                _tempId = `not_${id}`
            }
        } else {
            setFilterMode(1)
            if (id.includes("not_")) {
                _tempId = id.split("not_")[1]
            } else {
                _tempId = id
            }
        }

        _setId(_tempId)
    }, [ id, filterMode ])

    useEffect(() => {
        if (id.includes("not_")) {
            setFilterMode(2)
        } else {
            setFilterMode(1)
        }
    }, [])

    const filter = useMemo(() => {
        let _filter = props.itemData ? props.itemData : null
        if (!props.list) {
            _filter = null
        } else if (!isEditing) {
            setIsEditing(true)
        }

        return _filter ? _filter : { start: null, end: null, ...props }
    }, [ filters, _id, index, props.list, props.itemData, props ])

    const [ currentData, setCurrentData ] = useState(filter)

    const onApply = () => {
        let _currentData = {
            ...currentData,
            id: _id
        }

        if (isEditing) {
            let _filters = _.cloneDeep(filters)

            const payload = { ..._currentData }
            _filters = updateFilterItemInList(_filters, index, payload)

            setFilters(_filters)
            setMetaFilterUpdate({value:true})
            modalVisibleHandler(false)

            return
        }

        const payload = {
            ..._currentData,
            id: _id
        }

        setFilter(payload)
        setMetaFilterUpdate({value:true})
        modalVisibleHandler(false)
    }

    const onChange = (type: string, val: number) => {
        setCurrentData((prevState: any) => {
            return {
                ...prevState,
                [type]: val
            }
        })
    }
    const nameMap:any = {
        count_orders:"заказов",
        count_payments:"платежей",
        uwe_duration_the_webinar:"Минут",
        sum_order:" ",
        uoe_sum_order:" ",
        first_order_sum:" "
    }
    const idWithNegative = ['number_days_access']
    return (
        <div>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Radio.Group disabled={disabled} onChange={(e) => setFilterMode(e.target.value)} value={filterMode}>
                    <Radio value={1}>Включить</Radio>
                    <Radio value={2}>Исключить</Radio>
                </Radio.Group>
            </div>

            <Space>
                <span>от</span>
                <InputNumber disabled={disabled} value={currentData.start} onChange={(val) => onChange("start", val)} min={isPeriodFilter ? 1 : idWithNegative.includes(id) ? -Infinity : 0} style={{ width: 64 }} />
                {/*<InputNumber onChange={(val) => onChange('start', val)} min={1} style={{ width: 100 }} />*/}
                <span>до</span>
                <InputNumber disabled={disabled} value={currentData.end} onChange={(val) => onChange("end", val)} min={idWithNegative.includes(id) ? -Infinity : 0} style={{ width: 64 }} />
                <span>{nameMap[id] ? nameMap[id] : "дней"}</span>
                {/*<InputNumber onChange={(val) => onChange('end', val)} min={1} style={{ width: 100 }} />*/}
            </Space>
            <br />
            <Button disabled={disabled} type="primary" style={{ marginTop: 10 }} onClick={onApply}>
                Применить
            </Button>
        </div>
    )
}

export default NumberRangePicker
