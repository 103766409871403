import React, {useState, FC, ChangeEventHandler, ChangeEvent} from "react"

import { Modal, Checkbox, Row, Col } from "antd"
import type { CheckboxChangeEvent } from "antd/es/checkbox"
import { useNavigate } from "react-router-dom"

import { routeNames } from "../../../../shared/common/constants/routes"
import { useActions } from "../../../../shared/common/hooks/useActions"
import Search from "antd/es/input/Search";
import {IItem} from "../../ads/connect-ads/ConnectAds";

interface IAddModalProps {
    isVisible: boolean;
    setVisible: (val: boolean) => void;
    items: { id?: string; name?: string; data?: unknown }[];
    setItems: (val: { id?: string; name?: string }[]) => void;
    setRefetch:(prev: (prev: boolean) => boolean)=>void;
}
const AddModal: FC<IAddModalProps> = ({
                                          isVisible,
                                          setVisible,
                                          items,
                                          setItems,
                                          setRefetch
                                      }) => {
    const [filteredItems, setFilteredItems] = useState(items);
    const { adsCabinetConnectRK } = useActions()
    const navigate = useNavigate()
    const [ selectAllChecked, setSelectAllChecked ] = useState(false)
    const [ selectedIds, setSelectedIds ] = useState<string[]>([])
    const handleSelectAllChange = (event: CheckboxChangeEvent) => {
        const checked = event.target.checked
        setSelectAllChecked(checked)

        if (checked) {
            setSelectedIds(items.map((item) => item.id) as string[])
        } else {
            setSelectedIds([])
        }
    }

    const handleCheckboxChange = (value: string, event: CheckboxChangeEvent) => {
        const checked = event.target.checked
        const index = selectedIds.indexOf(value)

        if (checked && index === -1) {
            setSelectedIds([ ...selectedIds, value ])
        } else if (!checked && index !== -1) {
            setSelectedIds(selectedIds.filter((id) => id !== value))
            setSelectAllChecked(false)
        } else {
            setSelectAllChecked(selectedIds.length === items.length)
        }
    }

    const onClose = () => {
        setVisible(false)
        setItems([])
        navigate(routeNames.yandexMetrics)
    }
    const handleOk = () => {
        const selectedItems = items.filter((item: any) =>
            selectedIds.includes(item?.id)
        )
        const users = selectedItems.map((item: IItem) => item?.data)
        users.length > 0 && adsCabinetConnectRK(users)
        setItems([])
        setTimeout(()=>{
            setRefetch((prev)=>!prev)
        },350)
        setVisible(false)
        navigate(routeNames.yandexMetrics)
    }
    const handleSearch = (e:ChangeEvent<HTMLInputElement>) => {
        const filtered = items.filter((item) =>{
                if(item.name){
                    return item.name.toLowerCase().includes(e.target.value.toLowerCase())
                }
            }
        );
        setFilteredItems(filtered);
    };
    return (
        <Modal
            maskClosable={false}
            open={isVisible}
            title={
                <>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                        }}
                    >
            <span style={{ marginRight: "16px" }}>
              Выберите счётчики
            </span>
                        <div style={{
                            marginTop:"16px"
                        }}>
                            <Checkbox
                                checked={selectAllChecked}
                                onChange={handleSelectAllChange}
                                style={{
                                    marginRight: "16px",
                                    fontWeight: "400",
                                }}
                            >
                                Выбрать все
                            </Checkbox>
                            <Search placeholder="Поиск" onChange={handleSearch} style={{ width: 200 }} />
                        </div>
                    </div>
                </>
            }
            okText="Добавить"
            cancelText="Отмена"
            onOk={handleOk}
            onCancel={onClose}
        >
            {filteredItems.length === 0 ? (
                <p style={{textAlign:"center"}}>Ничего не найдено</p>
            ) : (
                <Checkbox.Group value={selectedIds}>
                    <Row gutter={[8, 16]}>
                        {filteredItems.map((item) => (
                            <Col key={item.id} span={8}>
                                <Checkbox
                                    value={item.id}
                                    onChange={(event) => {
                                        if (item.id !== undefined) {
                                            handleCheckboxChange(item.id, event);
                                        }
                                    }}
                                >
                                    {item.name}
                                </Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            )}
        </Modal>
    )
}

export default AddModal
