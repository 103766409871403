export const covertFormatGroup = [
    "creator_to_buyer_converse", // Конверсия из создателя заказа в покупателя
    "new_users_part",
    "paid_regs_part",
    "visitor_to_user_converse",
    "visit_to_reg_converse",
    "free_regs_part",
    "user_to_creator_converse",
    "orders_to_forders_converse",
    "creator_to_buyer_converse",
    "user_to_buyer_converse",
    "web_1min_users_part",
    "web_5min_users_part",
    "web_15min_users_part",
    "web_60min_users_part",
    "web_60min_g_users_part",
    "web_120min_g_users_part",
    "web_user_to_clicker_converse",
    "clicker_to_creator_converse",
    "user_to_web_user_converse",
    "web_user_to_creator_converse",
    "web_user_to_no_app_converse",
    "romi",
    "drr",
    "ctr",
    "ads_click_to_reg_converse",
    "orders_to_payments_converse",
    "user_to_refunder_converse",
    "buyer_to_refunder_converse",
    "orders_sum",
]
export const moneyFormatGroup = [
    "orders_sum",
    "payments_sum",
    "reg_price",
    "user_price",
    "orders_sum_avg",
    "orders_sum_avg_user",
    "orders_price",
    "orders_creators_price",
    "forders_sum",
    "porders_sum",
    "worders_sum",
    "aorders_sum",
    "payments_sum_avg",
    "payments_sum_per_reg",
    "payments_sum_per_user",
    "forders_price",
    "buyers_price",
    "payments_price",
    "web_user_price",
    "ads_spend",
    "ads_spend_cabinet",
    "profit",
    "profit_per_reg",
    "profit_per_user",
    "payments_sum_with_commission",
    'payments_sum_partial',
    "payments_commission_sum",
    "payments_sum",
    "refunds_sum",
    "cpm",
    'cpc',
]
export const percentFormatGroup = [
    "new_users_part",
    "visitor_to_user_converse",
    "visit_to_reg_converse",
    "paid_regs_part",
    "free_regs_part",
    "user_to_no_step1_converse",
    "user_to_no_app_converse",
    "no_step1_to_no_step2_converse",
    "no_step2_to_no_step3_converse",
    "no_step3_to_no_step4_converse",
    "no_step4_to_no_step5_converse",
    "no_step5_to_no_app_converse",
    "web_30min_users_part",
    "web_30min_g_users_part",
    "web_180min_g_users_part",
    "web_240min_g_users_part",
    "user_to_creator_converse",
    "orders_to_forders_converse",
    "creator_to_buyer_converse",
    "user_to_buyer_converse",
    "orders_to_payments_converse",
    "user_to_refunder_converse",
    "buyer_to_refunder_converse",
    "web_1min_users_part",
    "web_5min_users_part",
    "web_15min_users_part",
    "web_60min_users_part",
    "web_60min_g_users_part",
    "web_120min_g_users_part",
    "web_user_to_clicker_converse",
    "clicker_to_creator_converse",
    "user_to_web_user_converse",
    "web_user_to_creator_converse",
    "web_user_to_no_app_converse",
    "ctr",
    "ads_click_to_reg_converse",
    "romi",
    "drr",
]
export const summaryFormatGroup = [
    "buyers_with_refund_count",
    "refunds_count",
    "refunds_sum",
    'regs_count',
    'users_count',
    'orders_count',
    'orders_sum',
    'payments_count',
    'payments_sum',
    'ads_spend',
    "payments_sum_per_reg",
    'profit',
    'profit_per_reg',
    'reg_price',
    'web_30min_users_count',
    'web_30min_g_users_count',
    'user_price',
    'orders_price',
    'forders_price',
    'orders_creators_price',
    'web_user_price',
    'profit_per_user',
    'buyers_price',
    'payments_price',
    'ads_clicks_count',
    'ads_impressions',
    'web_users_count',
    'web_1min_users_count',
    'web_5min_users_count',
    'web_15min_users_count',
    'web_60min_users_count',
    "visitors_count",
    "visits_count",
    'web_60min_g_users_count',
    'web_120min_g_users_count',
    'web_clickers_count',
    'payments_sum_with_commission',
    'payments_sum_partial',
    'payments_commission_sum',
    'buyers_count',
    'orders_creators_count',
    'forders_count',
    'forders_sum',
    "porders_count",
    "porders_sum",
    "worders_count",
    "worders_sum",
    "rorders_sum",
    "aorders_count",
    "aorders_sum",
    'no_step1_count',
    'no_step2_count',
    'no_step3_count',
    'no_step4_count',
    'no_step5_count',
    'no_app_count',
    'old_users_count',
    'new_users_count',
    'paid_regs_count',
];
export const averageFormatGroup = [
    'roas',
    'free_regs_count',
    'orders_sum_avg',
    'orders_sum_avg_user',
    'payments_sum_avg',
    'payments_sum_per_user',
    'profit_per_reg',
    'reg_price',
    'user_price',
    'orders_price',
    'orders_creators_price',
    'profit_per_user',
];
export const nativeFormatGroup = [ "roas" ]
