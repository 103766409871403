import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const CrmPayments: FC = () => {

    const metrics = [
        { name: "Дата платежа", dataKey: "created_date" },
        { name: "id платежа", dataKey: "payment_id" },
        { name: "id школы", dataKey: "school_id" },
        { name: "Название школы", dataKey: "school_name" },
        { name: "id заказа", dataKey: "order_id" },
        { name: "Статус платежа", dataKey: "payment_status" },
        { name: "Тип платежа", dataKey: "payment_type" },
        { name: "Сумма", dataKey: "payment_price" },
        { name: "Комиссия", dataKey: "commission" },
        { name: "Чат", dataKey: "school_chat" },
        { name: "Чат с админом", dataKey: "admin_telegram" },
        { name: "id админа", dataKey: "admin_user_id" },
        { name: "Имя админа", dataKey: "admin_name" },
        { name: "Телефон админа", dataKey: "admin_phone" },
        { name: "Счёт пополнения", dataKey: "score_account" },
        { name: "Комментарий для клиента", dataKey: "comment_client" },
        { name: "Комментарий менеджера", dataKey: "comment_manager" },
        { name: "Статус обработки платежа скриптом", dataKey: "processing_status" },
        { name: "Последняя дата изменения", dataKey: "updated_date" },
    ]

    return (
        <MainLayout pageTitle="Платежи">
            <div className="page-wrapper">
                <PageHead title="Платежи" />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "Платежи (CRM)" ]}
                        withSegment={false}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default CrmPayments
