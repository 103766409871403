import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const CrmSchools: FC = () => {

    const metrics = [
        { name: "id школы", dataKey: "id" },
        { name: "Название школы", dataKey: "school_name" },
        { name: "Домен GC", dataKey: "school_domain" },
        { name: "Основной домен", dataKey: "school_main_domain" },
        { name: "Дата подключение школы", dataKey: "created_at" },
        { name: "Админ школы", dataKey: "user_id" },
        { name: "Телефон админа", dataKey: "phone" },
        { name: "Email админа", dataKey: "email" },
        { name: "Telegram админа", dataKey: "telegram" },
        { name: "Статус подключения", dataKey: "status" },
        { name: "Статус доступа", dataKey: "tariff" },
        { name: "Часовой пояс", dataKey: "timezone_name" },
        { name: "Язык кабинета", dataKey: "school_language_name" },
        { name: "Валюта", dataKey: "currency_description" },
        { name: "Денег принесли", dataKey: "payment_price" },
        { name: "Дата окончания доступа", dataKey: "date_end" },
        { name: "Осталось дней", dataKey: "days_left" },
        { name: "Сколько сотрудников", dataKey: "employees_count" },
        { name: "Ссылка на чат", dataKey: "chat_url" },
        { name: "Последняя активность", dataKey: "last_activity" },
        { name: "Последние данные из GC", dataKey: "date_api" },
        { name: "Последние данные из РК ВК", dataKey: "date_ads_vk" },
        { name: "Последние данные из РК ЯД", dataKey: "date_ads_yandex" },
        { name: "Последние данные из РК GA", dataKey: "date_ads_adwords" },
        { name: "Последние данные из РК MT", dataKey: "date_ads_mytarget" },
        { name: "Последние данные из РК FB", dataKey: "date_ads_facebook" },
        { name: "Последние данные по хитам", dataKey: "date_hits" },
        { name: "Последние данные по участникам вебинаров", dataKey: "date_webinars" },
        { name: "Последние данные по регистрациям SCAN", dataKey: "date_applications" },
        { name: "Выручка за последние 30 дней", dataKey: "payments_sum" },
        { name: "Комментарий", dataKey: "comment" },
    ]

    return (
        <MainLayout pageTitle="Школы">
            <div className="page-wrapper">
                <PageHead title="Школы" />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "Школы (CRM)" ]}
                        withSegment={false}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default CrmSchools
