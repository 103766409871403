import React, { FC, useEffect } from "react"

import { Button } from "antd"

import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"

interface IRequestButtonProps {
    btnDisabled: boolean
    setBtnDisabled: (val: boolean) => any
}

const RequestButton: FC<IRequestButtonProps> = ({ btnDisabled, setBtnDisabled }) => {

    const { isFilterUpdate } = useTypedSelector((state) => state.meta)
    const { setMetaFilterUpdate, setAllDataFetching,setAllBlocksFetching } = useActions()

    useEffect(() => {
        if (isFilterUpdate) {
            setBtnDisabled(false)
            setMetaFilterUpdate({value:false})
        }
    }, [ isFilterUpdate ])

    const sendRequest = () => {
        setBtnDisabled(true)
        setAllBlocksFetching({value:true})
        setAllDataFetching({value:true})
        setTimeout(()=>{
            setAllBlocksFetching({value:false})
        },3000)
    }

    return btnDisabled ? null : (
        <Button
            type="primary"
            className="request_button"
            onClick={() => sendRequest()}
        >
            Показать
        </Button>
    )
}

export default RequestButton
