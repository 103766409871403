import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth } from "../middleware"
import { API_SCHOOL_FILE_UPLOAD, API_SCHOOL_INFO } from "../urls"

export const uploadsSchoolApi = {
    uploadFile: async (schoolId: number, type: string, file: any,account_id=0): Promise<AxiosResponse> => {
        const data = new FormData()
        data.append("school_id", `${schoolId}`)
        data.append("name", `${type}`)
        data.append("file_v", file)
        if(account_id){
            data.append("account_id",`${account_id}`)
        }

        return axios.post(API_SCHOOL_FILE_UPLOAD, data, axiosConfigAuth())
    },

    getInfo: async (schoolId: number | string): Promise<AxiosResponse> => {
        return axios.get(`${API_SCHOOL_INFO}/${schoolId}`, axiosConfigAuth())
    }
}
