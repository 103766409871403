import React from "react"

import { Result } from "antd"

import MainLayout from "../../shared/common/components/layouts/MainLayout"


const PaymentSuccess: React.FC = () =>{

    return (
        <>
            <MainLayout>
                <div className="page-wrapper">
                    <div className="page-content">
                        <Result
                            style={{ paddingTop:"26vh" }}
                            status="success"
                            title={
                            <span style={{ fontSize:"28px" }}>
                                Оплата прошла успешно
                            </span>
                            }
                            subTitle={
                            <span style={{ fontSize:"18px" }}>
                                Доступ откроется в течение 1 минуты.
                            </span>
                            }
                        >
                        </Result>
                    </div>
                </div>
            </MainLayout>
        </>
    )
}

export default PaymentSuccess
