import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const Visitors: FC = () => {

    const metrics = [
        { name: "scan_id", dataKey: "scan_id" },
        { name: "account_id", dataKey: "account_id" },
        { name: "Email", dataKey: "email" },
    ]

    return (
        <MainLayout pageTitle="Посетители">
            <div className="page-wrapper">
                <PageHead
                    title="Посетители"
                />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "Сессии" ]}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default Visitors
