import React, {FC, useEffect, useState} from "react"

import {Button, Tooltip} from "antd"

import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import {ReloadOutlined} from "@ant-design/icons";


const RefreshButton = () => {
    const [ btnDisabled, setBtnDisabled] = useState(false)
    const { isFilterUpdate } = useTypedSelector((state) => state.meta)
    const { setMetaFilterUpdate, setAllDataFetching } = useActions()

    useEffect(() => {
        if (isFilterUpdate) {
            setBtnDisabled(false)
            setMetaFilterUpdate({value:false})
        }else{
            setBtnDisabled(false)
        }
    }, [ isFilterUpdate ])

    const sendRequest = () => {
        setAllDataFetching({value:true})
    }

    return btnDisabled ? null : (
        <Tooltip
        title={"Перезагрузить данные"}
        >
            <Button
                onClick={() => sendRequest()}
                icon={<ReloadOutlined />}
                style={{
                    marginRight:"5px"
                }}
            >
            </Button>
        </Tooltip>
    )
}

export default RefreshButton
