import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const CrmDetails: FC = () => {

    const metrics = [
        { name: "id записи", dataKey: "id" },
        { name: "id школы", dataKey: "school_id" },
        { name: "Дата", dataKey: "operation_date" },
        { name: "Название школы", dataKey: "school_name" },
        { name: "Название операции", dataKey: "operation_name" },
        { name: "Пополнение", dataKey: "refill" },
        { name: "Списание", dataKey: "write_off" },
        { name: "Счёт основной", dataKey: "main_balance" },
        { name: "Счёт бонусный", dataKey: "bonus_balance" },
        { name: "Баланс общий", dataKey: "overall_balance" },
        { name: "Детали", dataKey: "details" },
        { name: "id платежа", dataKey: "payment_id" },
    ]

    return (
        <MainLayout pageTitle="Детализация">
            <div className="page-wrapper">
                <PageHead title="Детализация" />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "Детализация (CRM)" ]}
                        withSegment={false}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default CrmDetails
