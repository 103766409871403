import {Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import React from "react";

const FieldTooltip = ({ text }:{text:string}) => (
    <Tooltip
        placement="bottomLeft"
        title={<div className="page-title-tooltip-text">{text}</div>}
        color="#fff"
        zIndex={9999}
        overlayClassName="page-title-tooltip"
    >
        <QuestionCircleOutlined
            style={{color: "#ccc", fontSize: 16}}/>
    </Tooltip>
)

export default FieldTooltip
