import React, { useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Form, Input, InputNumber, Modal } from "antd"

import { EditAccural } from "../../../../../app/types/unApi"
import { showErrorMessage } from "../../../../../helpers/utils/ui"
import { unApi } from "../../../../../shared/common/api/endpoints/reports/unApi"


type Props = {
    visible:boolean
    setVisible:  React.Dispatch<React.SetStateAction<{visible: boolean, initialData: {calculation_id: number, commission_amount: number, comment: string}}>>
    initialData: {
        calculation_id:number
        commission_amount:number
        comment:string
    }
}
const EditAccurals = ({ visible,setVisible,initialData }:Props) => {
    const [ form ] = Form.useForm()
    const [ formLoading, setFormLoading ] = useState(false)
        useEffect(()=> {
            if (initialData) {
                form.setFieldsValue({
                    calculation_id: initialData.calculation_id,
                    commission_amount: initialData.commission_amount,
                    comment: initialData.comment
                })
            }
        },[])
    const handleEdit = async (values:EditAccural) => {
        const data = {
            ...values,
            calculation_id:initialData.calculation_id
        }
        setFormLoading(true)
        setVisible({ visible:false,initialData })
        try{
            const response = await unApi.editTableAccurals(data)
            if(response.status === 200){
                form.resetFields()
                setVisible({ visible:false,initialData })
                Modal.info({
                    title: "Успех!",
                    content: "Начисления обновлены.",
                })
            }
        }catch(err){
            showErrorMessage("Произошла ошибка, повторите попытку позже.")

        }finally{
            setFormLoading(false)
        }
    }

    return (
        <Modal
            title="Редактирование начисления"
            open={visible}
            onCancel={() => {setVisible({ visible:false,initialData })}}
            onOk={() => {form.submit()}}
            okButtonProps={{ loading: formLoading }}
            okText="Сохранить"
        >
            {
                formLoading ?
                    <div className="loading-container">
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                    </div>
                    :
                    <Form
                        form={form}
                        onFinish={handleEdit}
                        requiredMark={false}
                    >
                        <Form.Item
                            name="commission_amount"
                            label="Сумма начислений"
                            rules={[ { required: true } ]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            name="comment"
                            label="Комментарий"
                        >
                            <Input />
                        </Form.Item>
                    </Form>
            }
        </Modal>
    )
}

export default EditAccurals
