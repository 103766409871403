import React from "react"

import IconFilter from "../IconFilter"

const NumberView = ({ icon, name, number }: any) => {
    return (
        <>
            <IconFilter icon={icon} />
            &nbsp;{name}:
            &nbsp;{number}
        </>
    )
}

export default NumberView
