import React, { FC } from "react";

import Pay from "../../shared/common/components/payments/pay/Pay";

const PayPage: FC = () => {
  return (
    <div className="payment_page__pageWrapper">
      <div className="payment_page__pageLogo">
        <img src="/logo.jpg" alt="" />
      </div>

      <Pay />
    </div>
  );
};

export default PayPage;
