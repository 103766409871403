import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth } from "../middleware"
import {
    API_STAFF_ADD_USER, API_STAFF_DELETE_USER,
    API_STAFF_GET_DATA,
    API_STAFF_GET_LISTS,
    API_STAFF_GET_USER_INFO,
    API_STAFF_SET_USER_INFO
} from "../urls"

export const staffApi = {
    fetchData: async (schoolId: string | number): Promise<AxiosResponse> => {
        return axios.get(`${API_STAFF_GET_DATA}/${schoolId}`, axiosConfigAuth())
    },

    getLists: async (schoolId: string | number): Promise<AxiosResponse> => {
        return axios.get(`${API_STAFF_GET_LISTS}/${schoolId}`, axiosConfigAuth())
    },
    // TODO: прописать типы
    addStaff: async (schoolId: number, values: any): Promise<AxiosResponse> => {
        const data = {
            ...values,
            school_id: schoolId
        }

        return axios.post(API_STAFF_ADD_USER, data, axiosConfigAuth())
    },

    getCurrentStaff: async (schoolId: number, userId: number): Promise<AxiosResponse> => {
        const data = {
            user_id: userId,
            school_id: schoolId
        }

        return axios.post(API_STAFF_GET_USER_INFO, data, axiosConfigAuth())
    },

    updateCurrentStaff: async (schoolId: number, userId: number, values: any): Promise<AxiosResponse> => {
        const data = {
            ...values,
            user_id: userId,
            school_id: schoolId
        }

        return axios.post(API_STAFF_SET_USER_INFO, data, axiosConfigAuth())
    },

    deleteCurrentStaff: async (schoolId: number, userId: number): Promise<AxiosResponse> => {
        const data = {
            user_id: userId,
            school_id: schoolId
        }

        return axios.post(API_STAFF_DELETE_USER, data, axiosConfigAuth())
    },
}
