export const dateObjects = [
  {
    id: "order",
    name: "Дата заказа",
  },
  {
    id: "reg",
    name: "Дата регистрации",
  },
  {
    id: "ad",
    name: "Дата рекламы",
  },
  {
    id: "web",
    name: "Дата вебинара",
  },
  {
    id: "payment",
    name: "Дата платежа",
  },
  {
    id: "visit",
    name: "Дата посещения страницы",
  },
];
export const displayObjects = [
  {
    id: "table",
    name: "Таблица",
  },
  {
    id: "chart",
    name: "Диаграмма",
  },
  {
    id: "number",
    name: "Показатель",
  },
];
