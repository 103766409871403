/* eslint-disable import/order */
import React, {FC, lazy, useEffect, useRef} from "react"

import {
    Routes,
    Route,
    Navigate,
    useNavigate,
    useLocation,
} from "react-router-dom"

import { routeNames } from "../../../../shared/common/constants/routes"
import PermissionWrapper from "../../../../shared/common/hoc/PermissionWrapper"
import { useActions } from "../../../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"
import AccessEnded from "../../../../pages/access-ended/AccessEnded"
const ADReports = lazy(() => import("../../../../pages/ad-reports"))
import Login from "../../../../pages/auth/login"
import Reset from "../../../../pages/auth/reset"
import Restore from "../../../../pages/auth/restore"
import Signup from "../../../../pages/auth/signup"
import Bill from "../../../../pages/bill"
import CrmActivity from "../../../../pages/crm/activity"
// import CrmChat from "./pages/crm/chat"
import CrmDetails from "../../../../pages/crm/details"
import CrmOrders from "../../../../pages/crm/orders"
import CrmPayments from "../../../../pages/crm/payments"
import CrmSchools from "../../../../pages/crm/schools"
import CrmTariffs from "../../../../pages/crm/tariffs"
// import CrmTasks from "./pages/crm/tasks"
import CrmUsers from "../../../../pages/crm/users"
import ErrorPage from "../../../../pages/error"
import Export from "../../../../pages/export"
import Home from "../../../../pages/home/Home"
import NoAccess from "../../../../pages/no-access"
import NotFound from "../../../../pages/not-found"
import OrderReports from "../../../../pages/order-reports"
import Partner from "../../../../pages/partner/Partner"
import PayPage from "../../../../pages/pay"
import PaymentReports from "../../../../pages/payment-reports"
import PaymentError from "../../../../pages/paymentResults/PaymentError"
import PaymentSuccess from "../../../../pages/paymentResults/PaymentSuccess"
import Profile from "../../../../pages/profile"
import Cohort from "../../../../pages/reports/cohort"
import Hit from "../../../../pages/reports/hit"
import HitStat from "../../../../pages/reports/hit-stat"
import Orders from "../../../../pages/reports/orders"
import Payments from "../../../../pages/reports/payments"
import Regs from "../../../../pages/reports/regs"
import Session from "../../../../pages/reports/session"
import Users from "../../../../pages/reports/users"
import Visitors from "../../../../pages/reports/visitors"
import WebinarComments from "../../../../pages/reports/webinar-comments"
import WebinarQuality from "../../../../pages/reports/webinar-quality"
import AddSpend from "../../../../pages/settings/ads/add-spend/components"
import ConnectAds from "../../../../pages/settings/ads/connect-ads"
import LoadData from "../../../../pages/settings/school/load-data"
import SchoolAdd from "../../../../pages/settings/school/school-add"
import SchoolEdit from "../../../../pages/settings/school/school-edit"
import Staff from "../../../../pages/settings/school/staff"
import TrackScript from "../../../../pages/settings/school/track-script"
import ConnectBizon from "../../../../pages/settings/webinar/connect-bizon"
import WebinarList from "../../../../pages/settings/webinar/list"
import TelegramConnect from "../../../../pages/telegram-connect"
import { sendPageHit } from "../../../../helpers/utils/hits"
import { partnerCookies } from "../../../../helpers/utils/partnerCookies"
import WebinarReports from "../../../../pages/webinar-reports/WebinarReports"
import PartnerCrm from "../../../../pages/crm/partner/PartnerCrm"
import { Tasks } from "../../../../pages/tasks"
import { Suspense } from "react"
import SuccessAddSchool from "../../../../pages/school/SuccessAddSchool"
import Visits from "../../../../pages/reports/visits/Visits"
import LastData from "../../../../pages/settings/LastData/LastData"
import YandexMetricsPage from "../../../../pages/settings/yandexMetrics"
import Dashboards from "../../../../pages/dashboards"
import CrmRequests from "../../../../pages/crm/requests"
import Loading from "../../../../shared/common/components/reports/cohort/components/Loading"
import Ad from "../../../../pages/settings/ads/ad/Ad"
import PageHead from "../../../../shared/common/components/page-header/PageHeader";
import Loader from "../../../../shared/common/components/loader/Loader";
import FixedUniversalTitle from "../../../../shared/common/components/reports/dashboards/title/FixedUniversalTitle";
import UniversalReportTitle from "../../../../shared/common/components/reports/dashboards/title/UniversalReportTitle";
import AddBlockModal from "../../../../pages/dashboards/components/modal/AddBlockModal";
import EditBlockModal from "../../../../pages/dashboards/components/modal/EditBlockModal";
import MainLayout from "../../../../shared/common/components/layouts/MainLayout";
import {isAccessEnded} from "../../../../helpers/utils/functions";
export const AppRouter: FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    useEffect(()=>{
        partnerCookies()
    },[])
    const { isGlobalLoading } = useTypedSelector((state) => state.app)
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const { isAuth, isLoading } = useTypedSelector((state) => state.auth)
    const { fetchUserAndSchoolInfo, currentSchoolFetchDetails } = useActions()

    useEffect(() => {
        if (isAuth) {
            setTimeout(() => {
                fetchUserAndSchoolInfo(navigate)
            }, 10)
        }
    }, [ isAuth ])

    useEffect(() => {
        if (selectedSchool) {
            currentSchoolFetchDetails(selectedSchool.id)
        }
    }, [ selectedSchool ])

    useEffect(() => {
        const isPartner = window.location.pathname.includes("/users/partner")
        const isProfile = window.location.pathname.includes("users/profile")
        setTimeout(() => {
            if (
                (selectedSchool === null || !selectedSchool) &&
        !isGlobalLoading &&
        !isLoading &&
        isAuth && !isPartner
                && !isProfile
            ) {
                navigate(routeNames.schoolAdd)
            }
        }, 1)
    }, [ location.pathname, selectedSchool ])

    useEffect(() => {
        sendPageHit()
    }, [ location.pathname ])

    // test iframe
    const iframeRef = useRef(null);
    return (
    <Routes>
        <Route path={routeNames.schoolAdded} element={<SuccessAddSchool />} />
        <Route path={routeNames.payment} element={<PayPage />} />
        <Route path={routeNames.paymentSuccess} element={<PaymentSuccess />} />
        <Route path={routeNames.paymentError} element={<PaymentError />} />
        {/* iframe test */}
        <Route path={routeNames.testRoute} element={
            <MainLayout pageTitle="Дашборды">
                <div className="page-wrapper" style={{}}>
                    <PageHead
                        title="Дашборды"
                        description={""}
                        reportInfo={true}
                    />
                    {isAccessEnded() ? (
                        <iframe
                            style={{border: "none", width: "990px", float: "left", minHeight: "100vh"}}
                            ref={iframeRef}
                            src={`https://help.scananalytics.ru?timestamp=${new Date().getTime()}`}
                            width="100%"
                            height="100%"

                        />
                        ) : (
                            <p>Access is still on :)</p>
                    )}
                </div>
            </MainLayout>
        }/>
        {isAuth ? (
            <>
                <Route path={routeNames.home} element={<Home/>}/>
                <Route path={routeNames.dashboards} element={<Dashboards/>}/>
                <Route
                    path={routeNames.accessEnded}
              element={
                <AccessEnded />
              }
          />
          <Route
              path={routeNames.orderReports}
              element={
                <OrderReports />
              }
          />
              <Route path={routeNames.paymentReports} element={
                      <PaymentReports />
              } />
              <Route path={routeNames.lastData} element={
                  <LastData />
              } />
              <Route path={routeNames.adReports} element={
                  <Suspense fallback={<Loading loading={true}>{}</Loading>}>
                      <ADReports />
                  </Suspense>
              } />
              <Route path={routeNames.webinarReports} element={
                      <WebinarReports />
              } />
              <Route path={routeNames.tasks} element={
                  <PermissionWrapper allowedRoles={[ "superadmin" ]}>
                      <Suspense fallback={<h1>Loading...</h1>}>
                          <Tasks />
                      </Suspense>
                  </PermissionWrapper>
              } />
              <Route path={routeNames.reportVisits} element={
                  <PermissionWrapper allowedRoles={[ "superadmin" ]}>
                      <Suspense fallback={<h1>Loading...</h1>}>
                          <Visits />
                      </Suspense>
                  </PermissionWrapper>
              } />
          <Route path={routeNames.profile} element={<Profile />} />

          {/* school settings pages */}
          <Route
              path={routeNames.schoolEdit}
              element={
              <PermissionWrapper allowedRoles={[ "admin", "user:settings" ]}>
                <SchoolEdit />
              </PermissionWrapper>
              }
          />
          <Route
              path={routeNames.schoolTrackScript}
              element={
              <PermissionWrapper allowedRoles={[ "admin", "user:settings" ]}>
                <TrackScript />
              </PermissionWrapper>
              }
          />
          <Route
              path={routeNames.schoolStaff}
              element={
              <PermissionWrapper allowedRoles={[ "admin", "user:settings" ]}>
                <Staff />
              </PermissionWrapper>
              }
          />

          <Route
              path={routeNames.schoolLoadData}
              element={
                <LoadData />
              }
          />
          <Route path={routeNames.schoolAdd} element={<SchoolAdd />} />

          {/* webinar settings pages */}
          <Route
              path={routeNames.webinarList}
              element={
              <PermissionWrapper allowedRoles={[ "admin", "user:settings" ]}>
                <WebinarList />
              </PermissionWrapper>
              }
          />
          <Route
              path={routeNames.webinarConnectBizon}
              element={
              <PermissionWrapper allowedRoles={[ "admin", "user:settings" ]}>
                <ConnectBizon />
              </PermissionWrapper>
              }
          />
          {/*<Route*/}
          {/*    path={routeNames.webinarCommentsSettings}*/}
          {/*    element={<CommentsSettings />}*/}
          {/*/>*/}

          {/* ads settings */}
          <Route path={routeNames.adsConnect} element={<Ad />} />
          <Route path={routeNames.adsSpend} element={<Ad />} />
          <Route path={routeNames.ad} element={<Ad />} />
              <Route path={routeNames.yandexMetrics} element={
                  // <PermissionWrapper allowedRoles={[ "superadmin" ]}>
                      <YandexMetricsPage />
                  // </PermissionWrapper>
              } />
          {/* reports */}
          <Route
              path={routeNames.reportWebinarQuality}
              element={<WebinarQuality />}
          />
          <Route
              path={routeNames.reportWebinarComments}
              element={
                  <PermissionWrapper allowedRoles={[ "admin", "user:settings","user:contacts" ]}>
                      <WebinarComments />
                  </PermissionWrapper>
              }
          />
          <Route path={routeNames.reportCohort} element={<Cohort />} />
          <Route path={routeNames.reportUsers} element={<Users />} />
          <Route path={routeNames.reportOrders} element={<Orders />} />
          <Route path={routeNames.reportPayments} element={<Payments />} />
          <Route path={routeNames.reportRegs} element={<Regs />} />
          <Route path={routeNames.reportSession} element={<Session />} />
          <Route path={routeNames.reportHit} element={<Hit />} />
          <Route path={routeNames.reportVisitors} element={<Visitors />} />
          <Route path={routeNames.reportHitStat} element={<HitStat />} />

          {/* parter cab */}
          <Route path={routeNames.partner} element={<Partner />} />

          {/* crm */}
          <Route
              path={routeNames.crmSchool}
              element={
              <PermissionWrapper allowedRoles={[]}>
                <CrmSchools />
              </PermissionWrapper>
              }
          />
              <Route
                  path={routeNames.crmRequests}
                  element={
                      <PermissionWrapper allowedRoles={[]}>
                          <CrmRequests />
                      </PermissionWrapper>
                  }
              />
          <Route
              path={routeNames.crmUsers}
              element={
              <PermissionWrapper allowedRoles={[]}>
                <CrmUsers />
              </PermissionWrapper>
              }
          />
          <Route
              path={routeNames.crmOrders}
              element={
              <PermissionWrapper allowedRoles={[]}>
                <CrmOrders />
              </PermissionWrapper>
              }
          />
          <Route
              path={routeNames.crmPayments}
              element={
              <PermissionWrapper allowedRoles={[]}>
                <CrmPayments />
              </PermissionWrapper>
              }
          />
          <Route
              path={routeNames.crmTariffs}
              element={
              <PermissionWrapper allowedRoles={[]}>
                <CrmTariffs />
              </PermissionWrapper>
              }
          />
          {/*<Route*/}
          {/*    path={routeNames.crmTasks}*/}
          {/*element={<PermissionWrapper allowedRoles={[]}><CrmTasks /> </PermissionWrapper>}*/}
          {/*/>*/}
          <Route
              path={routeNames.crmActivity}
              element={
              <PermissionWrapper allowedRoles={[]}>
                <CrmActivity />
              </PermissionWrapper>
              }
          />
          <Route
              path={routeNames.crmDetails}
              element={
              <PermissionWrapper allowedRoles={[]}>
                <CrmDetails />
              </PermissionWrapper>
              }
          />
              <Route path={routeNames.crmPartner} element={<PartnerCrm />} />
          {/*<Route*/}
          {/*    path={routeNames.crmChat}*/}
          {/*element={<PermissionWrapper allowedRoles={[]}><CrmChat /></PermissionWrapper>}*/}
          {/*/>*/}

          {/* extra */}
          <Route
              path={routeNames.telegramConnect}
              element={<TelegramConnect />}
          />
          <Route path={routeNames.noAccess} element={<NoAccess />} />
          <Route
              path={routeNames.bill}
              element={
              <PermissionWrapper allowedRoles={[ "admin", "user:settings" ]}>
                <Bill />
              </PermissionWrapper>
              }
          />
          <Route path={routeNames.export} element={<Export />} />
          {/* Payment Results */}

          {/* app extra */}
          <Route path={routeNames.notFound} element={<NotFound />} />
          <Route path={routeNames.error} element={<ErrorPage />} />
              <Route path="/" element={<Navigate to={routeNames.home} replace />} />
              <Route path="*" element={<Navigate to={routeNames.home} replace />} />
          </>
      ) : (
          <>
          <Route path={routeNames.login} element={<Login />} />
          <Route path={routeNames.signup} element={<Signup />} />
          <Route path={routeNames.resetPassword} element={<Reset />} />
          <Route path={routeNames.restorePassword} element={<Restore />} />

          <Route path={routeNames.notFound} element={<NotFound />} />
          <Route path={routeNames.error} element={<ErrorPage />} />


          <Route
              path="*"
              element={<Navigate to={routeNames.login} replace />}
          />
          </>
      )}
    </Routes>
    )
}
