import React, { FC, useEffect } from "react"

import {attrItems, defaultGroupingsAd, defaultMetricsAd, filtersFilter} from "./constants"
import { defaultGroupings, defaultMetrics } from "../../app/providers/redux/slices/reports/table/tableSlice"
import MainLayout from "../../shared/common/components/layouts/MainLayout"
import Loader from "../../shared/common/components/loader/Loader"
import PageHead from "../../shared/common/components/page-header/PageHeader"
import Chart from "../../shared/common/components/reports/ad-chart/Chart"
import Table from "../../shared/common/components/reports/ad-table/Table"
import CreateMetricsModal from "../../shared/common/components/reports/CreateMetricsModal"
import Params from "../../shared/common/components/reports/params/Params"
import UniversalReportTitle from "../../shared/common/components/reports/un-title/UniversalReportTitle"
import { chartDetails } from "../../shared/common/constants/details"
import { useActions } from "../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../shared/common/hooks/useTypedSelector"
import { ToggleChart } from "../../widgets/ToggleChart"
import {isAccessEnded} from "../../helpers/utils/functions";

const ADReports: FC = () => {
    const {
        setFilters,
        setFiltersTable,
        setCurrentReport,
        setMetrics,
        setCurrentSegment,
        setSortTable,
        setMetaCompare,
        setGroupings,
        setMetaChartFetching,
        setMetricsChecked,
        setCurrentFunnel,
        setIsCreateMetricsVisible,
    } = useActions()

    const { isChartVisible } = useTypedSelector((state) => state.chart)
    const { isCreateMetricsVisible } = useTypedSelector((state) => state.reports)

    const { isChartLoading, isTableLoading } = useTypedSelector(
        (state) => state.meta
    )

    useEffect(() => {
        document.body.style.overflow = "scroll"
        document.body.style.overflowX = "auto"

        setMetaCompare({ value: false })
        setMetrics({ data: defaultMetricsAd })
        setGroupings({ data: defaultGroupingsAd })
        setFiltersTable([])
        setCurrentReport({})
        setSortTable({})
        setCurrentSegment(null)
        setTimeout(() => {
            setMetaChartFetching({ value:true })
        },700)
        setMetricsChecked({ data:[ { label: defaultMetrics[0].label, name: defaultMetrics[0].dataKey } ] })

        return () => {
            setMetaCompare({ value: false })
            setMetrics({ data: defaultMetrics })
            setGroupings({ data: defaultGroupings })
            setFilters([])
            setFiltersTable([])
            setCurrentReport({})
            setSortTable({})
            setCurrentFunnel([])
            setCurrentSegment(null)
        }
    }, [])

    return (
        <>
            <MainLayout pageTitle="Отчёт по рекламе">
                <div className="page-wrapper">
                    <PageHead
                        title="Отчёт по рекламе"
                        description={""}
                        reportInfo={true}
                    />
                    {isAccessEnded() ? (
                        <iframe
                            style={{border: "none", width: "990px", float: "left", minHeight: "100vh", marginTop: "2vh"}}
                            src={`https://help.scananalytics.ru/dostup-k-reklame?timestamp=${new Date().getTime()}`}
                            width="100%"
                            height="100%"
                        />
                    ) : (
                        <div className="page-content">
                            <Loader loading={isTableLoading || isChartLoading}>
                                <UniversalReportTitle/>

                                <Params
                                    attributionItems={attrItems}
                                    detailsItems={chartDetails}
                                    filtersFilter={filtersFilter}
                                />
                            </Loader>

                            <Loader loading={isTableLoading || isChartLoading}>
                                {!isChartVisible && <ToggleChart/>}
                            </Loader>

                            {isChartVisible && <Chart/>}

                            <Table/>
                        </div>
                    )}
                </div>
            </MainLayout>

            {isCreateMetricsVisible && (<CreateMetricsModal title="Создание метрики в отчете по рекламе" />)}
        </>
    )
}

export default ADReports
