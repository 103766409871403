import React, { FC } from "react"

import lastRegIcon from "../../../../../assets/last-reg.png"

const LastRegIcon: FC = () => {
    return (
        <span style={{ marginRight: 5, height: 20 }}>
            <img width={20} height={20} src={lastRegIcon} style={{ verticalAlign: "unset" }}  alt="" />
        </span>
    )
}

export default LastRegIcon
