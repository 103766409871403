import React, { FC, useEffect, useMemo, useState } from "react"

import { CloseOutlined } from "@ant-design/icons"
import { Popover, Typography } from "antd"

import DateTimeRangePicker from "./pickers/DateTimeRangePicker"
import LazyListPicker from "./pickers/LazyListPicker"
import ListPicker from "./pickers/ListPicker"
import NumberPicker from "./pickers/NumberPicker"
import NumberRangePicker from "./pickers/NumberRangePicker"
import TextPicker from "./pickers/TextPicker"
import TimeRangePicker from "./pickers/TimeRangePicker"
import { useForceUpdate } from "../../../../../../hooks/useForceUpdate"
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector"
import DateTimeRangeView from "../../views/DateTimeRangeView"
import ListView from "../../views/ListView"
import NumberRangeView from "../../views/NumberRangeView"
import NumberView from "../../views/NumberView"
import TextView from "../../views/TextView"
import TimeRangeView from "../../views/TimeRangeView"
import { useActions } from "../../../../../../hooks/useActions"
import _ from "lodash"

const SelectedCompareFilterItem: FC<any> = ({ id, arrayIndex, type, name, full_name, color, icon, path, namePath, extra, itemData, prefix, showListPlus }) => {
    const forceUpdate = useForceUpdate()

    const filters = useTypedSelector((state) => state.compare.compareFilters[`compareFilters${prefix}`])
    const { setCompareFilter } = useActions()

    const [ visible, setVisible ] = useState(false)

    useEffect(() => {
        forceUpdate()
    }, [ prefix ])

    const itemFullName = useMemo(() => {
        let _name

        if (full_name) {
            _name = full_name
        } else {
            _name = name
        }

        return _name
    }, [ full_name, name ])

    const removeFilter = () => {
        let _filters = _.cloneDeep(filters)

        _filters.splice(arrayIndex, 1)

        setCompareFilter({ index: prefix, filters: _filters })
    }

    const ContentPopover: FC<any> = ({ typeCont, prefix }) => {
        const propsPicker = { id, color, index: arrayIndex, prefix, icon, name, full_name, type, path, namePath, list: true, disabled: false, itemData, showListPlus }

        switch (typeCont) {
            case "datetimerange":
                return <DateTimeRangePicker {...propsPicker} modalVisibleHandler={setVisible} />
            case "timerange":
                return <TimeRangePicker {...propsPicker} modalVisibleHandler={setVisible} />
            case "list":
                return <ListPicker {...propsPicker} modalVisibleHandler={setVisible} />
            case "lazylist":
                return <LazyListPicker {...propsPicker} modalVisibleHandler={setVisible} />
            case "text":
                return <TextPicker {...propsPicker} modalVisibleHandler={setVisible} />
            case "number":
                return <NumberPicker {...propsPicker} modalVisibleHandler={setVisible} />
            case "numberrange":
                return <NumberRangePicker {...propsPicker} modalVisibleHandler={setVisible} />

            default:
                return null
        }
    }

    const viewItem: { [key: string]: React.ReactElement } = useMemo(() => {
        return {
            datetimerange: <DateTimeRangeView {...itemData} name={itemFullName} id={id} />,
            timerange: <TimeRangeView {...itemData} name={itemFullName} id={id} />,
            list: <ListView {...itemData} name={itemFullName} id={id} />,
            lazylist: <ListView {...itemData} name={itemFullName} id={id} />,
            text: <TextView {...itemData} name={itemFullName} id={id} />,
            number: <NumberView {...itemData} name={itemFullName} id={id} />,
            numberrange: <NumberRangeView {...itemData} name={itemFullName} id={id} />,
        }
    }, [ itemData, id, prefix ])

    return (
        <Popover
            content={
                <div>
                    <div style={{ marginBottom: 8 }}>
                        <Typography.Text strong>{itemFullName}</Typography.Text>
                    </div>
                    {<ContentPopover typeCont={type} prefix={prefix} />}
                </div>
            }
            open={visible}
            onOpenChange={setVisible}
            trigger="click"
            placement={"bottom"}
            showArrow={false}
            overlayStyle={{ paddingTop: 0 }}
        >
            <div className="filter-selected-item" style={{ backgroundColor: color }} key={id}>
                <span>
                    {viewItem[type]} {extra ? extra : null}
                </span>
                <CloseOutlined
                    onClick={removeFilter}
                    style={{ color: "#fefefe", padding: 5, zIndex: 900 }}
                />
            </div>
        </Popover>
    )
}

export default SelectedCompareFilterItem
