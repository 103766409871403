import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { bindActionCreators } from "redux"

import { authApi } from "./endpoints/authApi"
import { rootAction } from "../../../app/providers/redux/root"
import { TAppDispatch } from "../../../app/providers/redux/store"
import {
    getAccessToken,
    getFingerPrint,
    isAccessTokenExpired,
    saveAccessToken,
} from "../../../helpers/utils/auth"
import { showErrorMessage } from "../../../helpers/utils/ui"
import JSONbig from "json-bigint";

export const axiosConfigAuth = (
    headers?: any,
    options?: any
): AxiosRequestConfig => {
    return {
        headers: {
            Token: `${getAccessToken()}`,
            ...headers,
        },
        ...options,
        transformResponse: [(data) => {
        try {
            return JSONbig.parse(data);
        } catch (e) {
            console.error('Ошибка при парсинге данных:', e);
            return data;
        }
    }]
    }
}

export const axiosRefreshConfig = (options?: any): AxiosRequestConfig => {
    return {
        withCredentials: true,
        ...options,
    }
}

export const handleResponseError = (
    dispatch: TAppDispatch,
    error: AxiosError,
    messages?: { [key: number]: string }
) => {
    const { authLogout, setAppGlobalError } = bindActionCreators(
        rootAction,
        dispatch
    )

    if (error.response) {
        if (error.response?.status === 404) {
            setAppGlobalError(true)
            return
        }

        if (error.response?.status === 401) {
            setAppGlobalError(false)
            authLogout(() => {
                showErrorMessage("Сессия истекла. Пожалуйста, войдите снова.")
            })
            return
        } else if (error.response?.status === 500) {
            setAppGlobalError(true)
            return
        }

        Object.entries(messages || []).forEach(([ key, val ]) => {
            if (`${error.response?.status}` === key) {
                showErrorMessage(val)
                return
            }
        })

        showErrorMessage("Произошла ошибка, повторите попытку позже.")
        return
    }
    // else {
    //   showErrorNotification("Произошла ошибка, повторите попытку позже.");
    //   return;
    // }
}

export const initAuthInterceptor = async (/*dispatch: TAppDispatch*/) => {
    const fingerprint = await getFingerPrint()
    //    const { authLogout } = bindActionCreators(rootAction, dispatch)

    axios.interceptors.request.use(
        async (config) => {
            const accessToken = getAccessToken()

            if (config.url?.includes("logout")) {
                return config
            }

            if (config.url?.includes("refresh-token")) {
                return config
            }

            if (isAccessTokenExpired(accessToken)) {
                await authApi
                    .refreshToken(fingerprint)
                    .then((response) => {
                        const newToken = response.data.access_token

                        config.headers.Token = newToken
                        saveAccessToken(newToken)
                    })
                    .catch((error) => {
                        //                    authLogout(() => {
                        //                        showErrorNotification("Сессия истекла. Пожалуйста, войдите снова.")
                        //                    })
                        return Promise.resolve(error)
                    })
            } else {
                config.headers.Token = accessToken
            }

            return config
        },
        (error) => {
            return Promise.resolve(error)
        }
    )
}
