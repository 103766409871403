import React from "react"

import { Space } from "antd"
import moment from "moment"

import IconFilter from "../IconFilter"
import { getStartEndType } from "../pickers/DateTimeRangePicker"

const DateTimeRangeView = ({ icon, name, start: _start, end: _end, calendar }: any) => {
    const { start, end } = getStartEndType({ start: _start, end: _end, calendar })
    const formatView = calendar.time ? "DD MMM YYYY HH:mm" : "DD MMM YYYY"
    return (
        <Space size="small">
            <div>
                <IconFilter icon={icon} />
                &nbsp;{name}:
            </div>
            {!start && !end ? (
                <div>Все время</div>
            ) : (
                <>
                    <div>
                        {start && moment(start).format("dd").toUpperCase()}&nbsp;{start && moment(start).format(formatView)}
                    </div>
                    <div style={{ opacity: 0.5 }}>—</div>
                    <div>
                        {end && moment(end).format("dd").toUpperCase()}&nbsp;{end && moment(end).format(formatView)}
                    </div>
                </>
            )}
        </Space>
    )
}

export default DateTimeRangeView
