import React, { FC, useState } from "react"

import { Button, Checkbox, Form, Input } from "antd"
import { Link, useNavigate } from "react-router-dom"

import styles from "./Signup.module.scss"
import { routeNames } from "../../../shared/common/constants/routes"
import { useActions } from "../../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../../shared/common/hooks/useTypedSelector"
import { ISignupData } from "../../../app/types/auth"
import { showFormErrors } from "../../../helpers/utils/ui"

const Signup: FC = () => {
    document.title = "Регистрация"

    const navigate = useNavigate()
    const [ form ] = Form.useForm()

    const { isLoading } = useTypedSelector((state) => state.auth)
    const { authSignup } = useActions()
    const [ mode,setMode ] = useState(0)
    /**
   * Обработчик регистрации.
   * @param values - объект с данными регистрации
   */
    const onFinish = async (values: ISignupData) => {
        await authSignup(
            values.name,
            values.email.toLowerCase(),
            values.password,
            values.phone,
            values.agreed,
            `${window.location.href}`,
            () => {
                navigate("/", { replace: true })
            },
            (errors) => {
                showFormErrors(form, errors)
            }
        )
    }
    const pageMap: { [ key:number]:any } = {
        0:(
            <>
                <Form.Item>
                    <div className={styles.topInfo}>Вы хотите подключить аналитику или получить доступ к уже подключенной школе?</div>
                </Form.Item>
            <div style={{ display:"flex",justifyContent:"center",columnGap:"16px",marginBottom:"24px" }}>
                <Button onClick={()=>setMode(1)} style={{ color:"white",background:"#942323",height:"40px" }}>Подключить аналитику</Button>
                <Button onClick={()=>setMode(2)} style={{ color:"white",background:"green",height:"40px" }}>Получить доступ</Button>
            </div>
            </>
        ),
        1:(
        <>
            <Form.Item
                name="name"
                rules={[
                    { required: true, message: "Введите свое имя.", type: "string" },
                ]}
            >
                <Input placeholder="Ваше имя" size="large" />
            </Form.Item>

            <Form.Item
                name="email"
                rules={[
                    { required: true, message: "Введите email.", type: "email" },
                ]}
            >
                <Input placeholder="Email" size="large" />
            </Form.Item>

            <Form.Item
                name="phone"
                rules={[
                    {
                        required: true,
                        message: "Введите свой номер телефона.",
                        type: "string",
                    },
                ]}
            >
                <Input placeholder="Телефон, +7 000 000 0000" size="large" />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[ { required: true, message: "Введите пароль." } ]}
            >
                <Input.Password placeholder="Пароль" size="large" />
            </Form.Item>
            <Form.Item
                name="password_repeat"
                rules={[
                    {
                        required: true,
                        message: "Введите пароль еще раз.",
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                                return Promise.resolve()
                            }
                            return Promise.reject(new Error("Пароли должны совпадать."))
                        },
                    }),
                ]}
            >
                <Input.Password placeholder="Повторите пароль" size="large" />
            </Form.Item>

            <Form.Item
                name="agreed"
                rules={[
                    {
                        required: true,
                        message: "Требуется подтвердить согласие с политикой.",
                    },
                ]}
                valuePropName="checked"
            >
                <Checkbox>
                    С текстом{" "}
                    <a href="https://scananalytics.ru/terms" target="_blank">
                        лицензионного договора-оферты «SCANanalytics»
                    </a>{" "}
                    согласен и даю согласие на обработку своих персональных данных.
                    Подтверждаю, что ознакомлен с{" "}
                    <a href="https://scananalytics.ru/politica" target="_blank">
                        Политикой обработки персональных данных
                    </a>
                    .
                </Checkbox>
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    className={styles.submitButton}
                    loading={isLoading}
                >
                    Зарегистрироваться
                </Button>
            </Form.Item>
            <div style={{ display:"flex",justifyContent:'center',marginTop:"-20px" }}>
                <Button onClick={()=>setMode(0)} style={{ height:"40px",width:"168px",marginBottom:"10px" }}>Назад</Button>
            </div>
        </>
    ),
        2:(
            <>
                    <div style={{ fontSize:"18px",marginBottom:"10px" }} className={styles.topInfo}>Для получения доступа регистрироваться не нужно. Напишите администратору школы свой Email, чтобы он Вас добавил в проект.
                    </div>
                <div style={{ display:"flex",justifyContent:'center' }}>
                    <Button onClick={()=>setMode(0)} style={{ height:"40px",width:"168px",marginBottom:"10px" }}>Назад</Button>
                </div>
                </>
        )
    }

    return (
    <div className={styles.loginPage}>
      <div className={styles.loginModal}>
        <Form
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
        >
          <Form.Item>
            <div className={styles.logoWrapper}>
              <img alt="SCAN" src="/logo.jpg" />
            </div>
          </Form.Item>

          <Form.Item>
            <div className={styles.topInfo}>РЕГИСТРАЦИЯ В СЕРВИСЕ</div>
          </Form.Item>
            {pageMap[mode]}
          <div className={styles.loginInfo}>
            Есть аккаунт? <Link to={routeNames.login}>Войти</Link>.
          </div>
        </Form>
      </div>
    </div>
    )
}

export default Signup
