
import React from "react";
import PartnerTabs from "./components/PartnerTabs";
import MainLayout from "../../../shared/common/components/layouts/MainLayout";
import PageHead from "../../../shared/common/components/page-header/PageHeader";



const PartnerCrm = () => {
    return (
        <MainLayout pageTitle="Партнёрская программа">
            <div className="page-wrapper">
                <PageHead title="Партнёрская программа" />

                <div className="page-content">
                   <PartnerTabs />
                </div>
            </div>
        </MainLayout>
    )
}
export default PartnerCrm
