import React, { FC, useEffect, useState } from "react"

import {Checkbox, Popconfirm, Space} from "antd"
import classNames from "classnames"

import { finishRows } from "../../../../../../helpers/utils/reports"
import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import {MinusSquareOutlined, PlusSquareOutlined} from "@ant-design/icons";

const GroupHeader: FC<any> = ({ sort, dataKey,handleExpandAll }) => {

    const [ checked, setChecked ] = useState(true)

    const {
        groupings,
        groupsChecked,
        totalChecked,
        tableData,
        percentages,
        filtersTable,
        sortTable
    } = useTypedSelector((state) => state.table)
    const { clearTotalChecked, setGroupingsChecked, setSortTable, setMetaChartFetching, setMetaCompareChartFetching } = useActions()

    useEffect(() => {
        if (groupsChecked.length === 0 && totalChecked.length === 0) {
            setChecked(false)
        } else {
            setChecked(true)
        }
    }, [ groupsChecked, totalChecked ])


    const clearCheckedGroups = () => {
        clearTotalChecked()
        setGroupingsChecked([])
    }

    const setCheckedForCurrentGroup = () => {
        try {
            const data = finishRows({
                rows: tableData.data,
                filters: filtersTable,
                sort: sortTable,
                percentages,
                totalRow: tableData.total,
            })

            const firstGroupings = data.slice().map((item: any) => ({
                ids: [ item.id ? item.id : item.pathStr ],
                pathStr: item.pathStr,
                label: item.group
            }))

            clearTotalChecked()
            setGroupingsChecked(firstGroupings)
        } catch (e) {
            console.log(e)
        }
    }

    const onChange = (e: any) => {

        e.stopPropagation()
        if (groupings.length <= 0) {
            setChecked(false)
            return
        }

        if (checked) {
            setChecked(false)
            clearCheckedGroups()

            setMetaChartFetching({value:true})
            setMetaCompareChartFetching({value:true})
        } else {
            setChecked(true)
            setCheckedForCurrentGroup()

            setMetaChartFetching({value:true})
            setMetaCompareChartFetching({value:true})
        }
    }

    const sortGroups = () => {
        let newSort: any = {}

        if (sort[dataKey] === "ASC") {
            newSort[dataKey] = "DESC"
        } else {
            newSort[dataKey] = "ASC"
        }

        if (sort[dataKey] === "DESC") {
            setSortTable({})
        } else {
            setSortTable(newSort)
        }
    }
    const [expanded,setExpanded] = useState(false)


    const handleExpandAllClick = () => {
        const pathStrings = tableData.data.map((el)=>el.pathStr2)
        handleExpandAll && handleExpandAll(pathStrings)
        setExpanded(!expanded)
    }
    const handleUnexpandedAllClick = () => {
        handleExpandAll && handleExpandAll([])
        setExpanded(!expanded)
    }

    return (
        <div style={{ marginLeft: -18 }}>
            <div
                className={classNames("table-header-sort", "table-header-sort-right", "table-header-withIcon", {
                    "sort-asc": sort[dataKey] === "ASC",
                    "sort-desc": sort[dataKey] === "DESC",
                })}
            >
                {
                    groupings.length <= 0 ? null :
                        <Space>
                            {expanded ? (
                                <MinusSquareOutlined
                                    style={{ color: "#5b5b5b",fontSize:"16px"   }}
                                    onClick={handleUnexpandedAllClick}
                                />
                            ) : (
                                <Popconfirm
                                    title={
                                        <span>
                Раскрытие всех уровней может занять некоторое время.<br />
                Оставьте только нужные группировки. <br />Точно раскрыть все группировки?
            </span>
                                    }
                                    onConfirm={handleExpandAllClick}
                                    okText="Да"
                                    cancelText="Нет"
                                >
                                    <PlusSquareOutlined style={{ color: "#5b5b5b",fontSize:"16px" }} />
                                </Popconfirm>
                            )}

                            <Checkbox checked={checked} onChange={onChange} style={{ marginRight: 5,width:"16px",height:"16px",marginTop:"-3px" }} />
                        </Space>
                }
                <span style={{ cursor: "pointer" }} onClick={() => sortGroups()}>
                    {
                        groupings.length > 0 ?
                            groupings.map((item: any) => item.label).join(" → ") :
                            "Группировки не выбраны"
                    }
                </span>
            </div>
        </div>
    )
}

export default GroupHeader
