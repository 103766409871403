import React, { FC, useEffect, useState } from "react"

import {
    CopyOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined,
    PlusOutlined, QuestionCircleOutlined
} from "@ant-design/icons"
import { Button, Modal, Switch, Table } from "antd"
import { ColumnsType } from "antd/es/table"

import AddDomainModal from "./AddModal"
import styles from "./TrackScript.module.scss"
import MainLayout from "../../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../../shared/common/components/page-header/PageHeader"
import { useActions } from "../../../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"
import { showErrorMessage, showSuccessMessage } from "../../../../helpers/utils/ui"
import usePermission from "../../../../shared/common/hooks/usePermission";

const TrackScript: FC = () => {

    const { id } = useTypedSelector((state) => state.currentUser)
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const { domainsData, validateStatus, isLoading, isUpdate,utmStatus } = useTypedSelector((state) => state.domains)
    const { domainFetchData, domainSetUpdate, domainChangeActive, domainDelete, domainGetValidateStatus, domainUpdateValidateStatus,utmFetchData,utmUpdateStatus,setAppReportOpen,setDrawerLink } = useActions()
    const checkPermission = usePermission()
    const [ addDomainModalVisible, setAddDomainModalVisible ] = useState(false)

    useEffect(() => {
        if (selectedSchool) {
            domainFetchData(selectedSchool.id)
            utmFetchData(selectedSchool.id)
            domainGetValidateStatus(selectedSchool.id)
        }
    }, [ selectedSchool ])

    useEffect(() => {
        if (isUpdate && selectedSchool) {
            domainFetchData(selectedSchool.id)
            domainGetValidateStatus(selectedSchool.id)
            utmFetchData(selectedSchool.id)
            domainSetUpdate(false)
        }
    }, [ isUpdate ])

    const script = `
    <!— ===SCAN===analitika.school===START===v2=== —>
    <script type="text/javascript">
    var school_webvisor = {
            user_id: ${id},
            school_id: ${selectedSchool?.id || 0},
            account_id: window.accountUserId,
            version: 2,
            accept_incoming: false,
            params: {
                launch_delay: 1000,
                interval: 10000
            }
    };
    
    (function(conf) {
        let GETParams, script;
    
        GETParams = new URLSearchParams({
            user_id: conf.user_id,
            school_id: conf.school_id,
            account_id: conf.account_id,
            version: conf.version
        });
    
        script = document.createElement('script');
        script.setAttribute('src','https://api.analitika.school/script?' + GETParams.toString());
        script.setAttribute("async","true");
        document.documentElement.children[0].appendChild(script);
    })(window.school_webvisor || {});
    </script>
    <!— ===SCAN===analitika.school===END===v2=== —>

    `

    const domainColumns: ColumnsType<any> = [
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            render: (value, record, index) => (
                <span>{index + 1}</span>
            )
        },
        {
            title: "domain",
            dataIndex: "domain",
            key: "domain",
        },
        {
            title: "active",
            dataIndex: "status",
            key: "status",
            width: 160,
            render: (value, record) =>  (
                <div>
                    <Switch
                        checked={value}
                        onChange={(checked) => {
                            domainChangeActive(checked, record.school_id, record.domain)
                        }}
                    />
                </div>
            )
        },
        {
            title: "actions",
            dataIndex: "actions",
            key: "actions",
            width: 60,
            render: (value, record) => (
                <div>
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            Modal.confirm({
                                title: "Вы уверены что хотите удалить домен?",
                                icon: <ExclamationCircleOutlined />,
                                okText: "Да",
                                okType: "danger",
                                cancelText: "Нет",
                                onOk() {
                                    domainDelete(record.school_id, record.domain)
                                },
                            })
                        }}
                        danger
                    />
                </div>
            )
        },
    ]

    const copyScript = () => {
        navigator.clipboard.writeText(script)
            .then(() => {
                showSuccessMessage("Скрипт скопирован.")
            })
            .catch(() => {
                showErrorMessage("Произошла ошибка при копировании.")
            })
    }

    const changePostValidate = (value: boolean) => {
        if (selectedSchool) {
            domainUpdateValidateStatus(parseInt(selectedSchool.id), value)
        }
    }
    const changeUtmStatus = (value: boolean) => {
        if(selectedSchool){
            utmUpdateStatus(parseInt(selectedSchool.id),value)
        }
    }
    const connectStagesBtn = (
        <span
            style={{
                marginLeft: 10,
                marginRight: 10,
                marginTop: 3,
                cursor:"pointer"
            }}
            onClick={() => {
                try{
                    setDrawerLink(`https://help.scananalytics.ru/skript-otclezhivaniya-scan?timestamp=${new Date().getTime()}`)
                    setAppReportOpen(true);
                }
                catch(err){
                    console.log(err)
                }
            }}
        >
                        <QuestionCircleOutlined
                            style={{ color: "#ccc", fontSize: 14,marginTop:"8px",marginLeft:"-10px" }}
                        />
                  </span>
    )
    return (
        <MainLayout pageTitle="Скрипт отслеживания">
            <div className="page-wrapper">
                <PageHead title="Скрипт для GetCourse" extraContent={connectStagesBtn} />

                <div className="page-content">
                    <div>
                        <p>Скопируйте и вставьте данный скрипт на страницу настроек в GetCourse. </p>

                        <div className={styles.switchWrapper}>
                            <Switch
                                loading={isLoading}
                                checked={validateStatus}
                                onChange={changePostValidate}
                            />
                            <span>Валидация электронной почты</span>
                        </div>
                        <div style={{ marginTop:"10px" }} className={styles.switchWrapper}>
                            <Switch
                                loading={isLoading}
                                checked={utmStatus}
                                onChange={changeUtmStatus}
                            />
                            <span>Перекидывать UTM метки на все страницы</span>
                        </div>

                        <pre className={styles.scriptPane}>
                            {script}
                        </pre>

                        <Button
                            type="primary"
                            icon={<CopyOutlined />}
                            onClick={() => copyScript()}
                        >
                            Скопировать в буфер
                        </Button>
                    </div>

                    <div className={styles.domainSection}>
                        <p className={styles.domainTableTitle}>
                            Междоменное отслеживание
                        </p>

                        <Table
                            columns={domainColumns}
                            dataSource={domainsData}
                            loading={{
                                spinning: isLoading,
                                indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
                            }}
                            bordered
                            pagination={false}
                            showHeader={false}
                        />

                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => setAddDomainModalVisible(true)}
                            style={{ marginTop: 20 }}
                        >
                            Добавить домен
                        </Button>
                    </div>
                </div>

                <AddDomainModal
                    isVisible={addDomainModalVisible}
                    setVisible={setAddDomainModalVisible}
                />
            </div>
        </MainLayout>
    )
}

export default TrackScript
