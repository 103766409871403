import React, { useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Form, Input, Modal } from "antd"

import { SchoolUpdate } from "../../../../../../../app/types/unApi"
import { showErrorMessage } from "../../../../../../../helpers/utils/ui"
import { unApi } from "../../../../../api/endpoints/reports/unApi"

const EditSchool = ({ editSchoolModalVisible, setEditSchoolModalVisible, id, loadRows }:any) => {
    const [ form ] = Form.useForm()
    const [ formLoading, setFormLoading ] = useState(false)

    const fetchData =  async () => {
        setFormLoading(true)
        try{
            const response = await unApi.getSchoolInfo(Number(id))
            form.resetFields()
            form.setFieldsValue({
                ...response.data
            })
        }catch(err){
            showErrorMessage("Произошла ошибка, повторите попытку позже.")
        }finally{
            setFormLoading(false)

        }
    }

    useEffect(() => {
        if (editSchoolModalVisible) {
            fetchData()
        }
    }, [ editSchoolModalVisible ])

    const editPayment = async (values: SchoolUpdate) => {
        setFormLoading(true)
        try{
            const response = await unApi.updateSchoolInfo({ ...values,school_id :Number(id) })
            if(response.status === 200){
                form.resetFields()
                setEditSchoolModalVisible(false)
                loadRows()
                Modal.info({
                    title: "Успех!",
                    content: "Информация об школе обновлена.",
                })
            }
        }catch(err){
            showErrorMessage("Произошла ошибка, повторите попытку позже.")

        }finally{
            setFormLoading(false)
        }
    }

    return (
        <Modal
            title="Обновление школы"
            open={editSchoolModalVisible}
            onCancel={() => {setEditSchoolModalVisible(false)}}
            onOk={() => {form.submit()}}
            okButtonProps={{ loading: formLoading }}
            okText="Сохранить"
        >
            {
                formLoading ?
                    <div className="loading-container">
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                    </div>
                    :
                    <Form
                        form={form}
                        onFinish={editPayment}
                        labelCol={{ span: 11 }}
                        wrapperCol={{ span: 16 }}
                        requiredMark={false}
                    >
                        <Form.Item
                            name="school_name"
                            label="Название школы"
                            rules={[ { required: true } ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="domain"
                            label="Основной домен"
                            rules={[ { required: false } ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="chat_url"
                            label="Ссылка на чат"
                            rules={[ { required: false, type: "url" } ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="comment"
                            label="Комментарий"
                            rules={[ { required: false } ]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Form>
            }
        </Modal>
    )
}

export default EditSchool
