import React, { useMemo, useState } from "react"

import { Popover, Typography } from "antd"

import DateTimeRangePicker from "./pickers/DateTimeRangePicker"
import LazyListPicker from "./pickers/LazyListPicker"
import ListPicker from "./pickers/ListPicker"
import NumberPicker from "./pickers/NumberPicker"
import NumberRangePicker from "./pickers/NumberRangePicker"
import TextPicker from "./pickers/TextPicker"
import TimeRangePicker from "./pickers/TimeRangePicker"
import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { data } from "../../../../constants/filters";
import {isObjectEmpty} from "../../../../../../helpers/utils/functions";

const FilterItem = ({ id, index, type, name, full_name, searchName, color, icon, path, namePath, extra, showListPlus,items }: any) => {
    const { filters } = useTypedSelector((state) => state.filter)
    const { setFilterSelectorVisible,setFilterParent } = useActions()

    const [ visible, setVisible ] = useState(false)

    const list = false

    const setModalsVisible = (value: boolean) => {
        setVisible(value)
        setFilterSelectorVisible(value)
    }

    const itemFullName = useMemo(() => {
        let _name

        if (full_name) {
            _name = full_name
        } else {
            _name = name
        }

        return _name
    }, [ full_name, name ])

    const contentPopover: { [key: string]: React.ReactElement } = useMemo(() => {
        const propsPicker = { id, color, index, icon, name, full_name, type, path, namePath, list, showListPlus,items }

        return {
            datetimerange: <DateTimeRangePicker {...propsPicker} modalVisibleHandler={setModalsVisible} />,
            timerange: <TimeRangePicker {...propsPicker} modalVisibleHandler={setModalsVisible} />,
            list: <ListPicker {...propsPicker} modalVisibleHandler={setModalsVisible} />,
            lazylist: <LazyListPicker {...propsPicker} modalVisibleHandler={setModalsVisible} />,
            text: <TextPicker {...propsPicker} modalVisibleHandler={setModalsVisible} />,
            number: <NumberPicker {...propsPicker} modalVisibleHandler={setModalsVisible} />,
            numberrange: <NumberRangePicker {...propsPicker} modalVisibleHandler={setModalsVisible} />,
        }
    }, [ color, icon, id, index, name, full_name, path, type, namePath, list ])
    const CountEl = () => {
        const filter = filters.find((fItem) => fItem.id === id)
        let filterParent

        if(filter?.namePath.length >  3){
            let parent = data.find((el:{name:string})=>el.name === filter?.namePath[1])
            if(parent){
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                filterParent = parent.children.find((el)=>el.name === filter?.namePath[2])
            }
        }
        if (!filter) return null
        if (filter.type === "datetimerange") {
            if (!filter.start && !filter.end) {
                return null
            }
        }
        let count = 1
        if (filter.checkeds) count = filter.checkeds.length
        if(count && (typeof filterParent === "undefined")){
            return count ? <div className="picker-counter-element" /> : null
        }
        if(!isObjectEmpty(filterParent)){
            setFilterParent(filterParent)
            return count ? <div className="picker-counter-element" /> : null
        }
        return null
    }

    return (
        <Popover
            content={
                <div>
                    <div style={{ marginBottom: 7 }}>
                        <Typography.Text strong>{itemFullName}</Typography.Text>
                    </div>
                    {contentPopover[type]}
                </div>
            }
            open={visible}
            onOpenChange={setVisible}
            trigger="click"
            placement={"right"}
        >
            <div style={{ display: "inline-flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                {searchName}
                <span className={extra ? "counter-icon-wrapper" : ""}>
                    <CountEl />
                </span>
                {extra ? extra : null}
            </div>

        </Popover>
    )
}

export default FilterItem
