import { authSliceActions } from "./authSlice"
import { authApi } from "../../../../../shared/common/api/endpoints/authApi"
import {
    clearStorage,
    getAccessToken,
    getFingerPrint,
    saveAccessToken,
} from "../../../../../helpers/utils/auth"
import {
    showErrorMessage,
    showSuccessNotification,
} from "../../../../../helpers/utils/ui"
import { TAppDispatch } from "../../store"
import { appActions } from "../app/actions"
import { currentSchoolActions } from "../currentSchool/actions"
import { currentUserActions } from "../currentUser/actions"

export const authActions = {
    ...authSliceActions,

    authLogin:
    (
        login: string,
        password: string,
        onFinish: () => void,
        onFormError: (errors: { [key: string]: string }) => void
    ) =>
        async (dispatch: TAppDispatch) => {
            dispatch(authActions.setAuthLoading(true))

            const fingerprint = await getFingerPrint()

            authApi
                .login(login, password, fingerprint)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(authActions.setIsAuth(true))

                        saveAccessToken(response.data.access_token)
                        onFinish()
                    }
                })
                .catch((err) => {
                    if (err.response?.status === 400) {
                        onFormError(err.response.data)
                    } else {
                        showErrorMessage("Произошла ошибка, повторите попытку позже.")
                    }
                })
                .finally(() => {
                    dispatch(authActions.setAuthLoading(false))
                })
        },

    authLogout: (onFinish: () => void) => (dispatch: TAppDispatch) => {
        const savedToken = getAccessToken()

        dispatch(appActions.setAppLoading(true))
        dispatch(authActions.setAuthClear())
        dispatch(currentUserActions.currentUserClearData())
        dispatch(currentSchoolActions.currentSchoolClearData())
        dispatch(authActions.setIsAuth(false))

        clearStorage()

        dispatch(appActions.setAppLoading(false))
        onFinish()

        authApi
            .logout(savedToken)
            .catch((err) => {
                console.error(err)
                onFinish()
            })
            .finally(() => {
                onFinish()
            })
    },

    authSignup:
    (
        name: string,
        email: string,
        password: string,
        phone: string,
        agreed: boolean,
        url: string,
        onFinish: () => void,
        onFormError: (errors: { [key: string]: string }) => void
    ) =>
        async (dispatch: TAppDispatch) => {
            dispatch(authActions.setAuthLoading(true))

            const fingerprint = await getFingerPrint()

            authApi
                .signup(name, email, password, phone, agreed, url, fingerprint)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(authActions.setIsAuth(true))

                        saveAccessToken(response.data.access_token)
                        onFinish()
                    }
                })
                .catch((err) => {
                    if (err.response?.status === 400) {
                        onFormError(err.response.data)
                    } else {
                        showErrorMessage("Произошла ошибка, повторите попытку позже.")
                    }
                })
                .finally(() => {
                    dispatch(authActions.setAuthLoading(false))
                })
        },

    authResetPass:
    (
        email: string,
        onFinish: () => void,
        onFormError: (errors: { [key: string]: string }) => void
    ) =>
        async (dispatch: TAppDispatch) => {
            dispatch(authActions.setAuthLoading(true))

            authApi
                .resetPassword(email)
                .then((response) => {
                    if (response.status === 200) {
                        showSuccessNotification(
                            "Письмо с для восстановления пароля отправлено на вашу почту"
                        )
                        onFinish()
                    } else {
                        showErrorMessage("Произошла ошибка, повторите попытку позже.")
                    }
                })
                .catch((err) => {
                    if (err.response?.status === 400) {
                        onFormError(err.response.data)
                    } else {
                        showErrorMessage("Произошла ошибка, повторите попытку позже.")
                    }
                })
                .finally(() => {
                    dispatch(authActions.setAuthLoading(false))
                })
        },

    authRestorePassword:
    (token: string, password: string, onFinish: () => void) =>
        async (dispatch: TAppDispatch) => {
            dispatch(authActions.setAuthLoading(true))

            const fingerprint = await getFingerPrint()

            authApi
                .recoveryPassword(token, password, fingerprint)
                .then((response) => {
                    if (response.status === 200) {
                        showSuccessNotification(
                            "Пароль обновлен. Войдите заного в сой аккаунт."
                        )
                        onFinish()
                    } else {
                        showErrorMessage("Произошла ошибка, повторите попытку позже.")
                    }
                })
                .catch((err) => {
                    if (err.response?.status === 400) {
                        showErrorMessage("Пользователь не найден.")
                    } else {
                        showErrorMessage("Произошла ошибка, повторите попытку позже.")
                    }
                })
                .finally(() => {
                    dispatch(authActions.setAuthLoading(false))
                })
        },
}
