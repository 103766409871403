
export const tooltipText = {
    searchInput:"Можете быстро заполнить поля уже созданного рекламного объявления. Введите его id и нажмите значок поиска, данные подставятся автоматически.",
    ad:"Поле обязательное для заполнения. Выберите рекламный кабинет для ручного ввода рекламного расхода.",
    adDate:"Поле обязательное для заполнения. Укажите дату когда оплатили за рекламу или когда опубликовали её. По одному объявлению за один день может быть только один рекламный расход. Если по одному объявлению нужно внести два расхода за один день, просто отредактируйте сумму первого рекламного расхода.",
    adsSpend: "Поле обязательное для заполнения. Сумма, которую заплатили за рекламу. Валюта указывается в настройках рекламного кабинета. \n",
    url: "Поле обязательное для заполнения. Рекламная ссылку на которую покупается реклама. Чтобы была сквозная аналитика и было понимание какое объявление окупается, а каккое нет, у рекламной ссылки должны быть UTM метки. Вы можете указать сразу ссылку с UTM метками и SCAN автоматически заполнит UTM метки в разделе UTM метки. Можете указать ссылку без UTM меток, но чтобы была сквозная аналитика, нужно заполнить UTM метки рекламной ссылки в соответствующем разделе. Это позволит в отчете по рекламе быстро сгруппировать данные по UTM меткам и сделать анализ.",
    heading:"Поле не обязательное для заполнения. Если в рекламном объявлении есть заголовок, то Вы можете указать его. Это позволит в отчёте по рекламе быстро проанализировать и сгруппировать данные по заголовку.",
    subHeading:"Поле не обязательное для заполнения. Если в рекламном объявлении есть подзаголовок, то Вы можете указать его. Это позволит в отчёте по рекламе быстро проанализировать и сгруппировать данные по подзаголовку.\n",
    text:"Поле не обязательное для заполнения. Можете вставить рекламный текст объявления. Это позволит в отчёте по рекламе быстро проанализировать и сгруппировать данные по тексту объявления.\n",
    mediaUrl:"Поле не обязательное для заполнения. Вы можете указать ссылку на картинку или видео, которое используете в рекламном посте. Ссылка может вести на любое облако (Google Диск, Яндекс Диск, Облако Mail.ru). Это позволит в отчёте по рекламе быстро проанализировать и сгруппировать данные по медиафайлу.\n",
    buttonText:"Поле не обязательное для заполнения. Если в рекламном объявлении есть кнопка, то Вы можете указать её текст. Это позволит быстро проанализировать и сгруппировать данные по тексту кнопки.\n",
    impressions:"Поле не обязательное для заполнения. Если у Вас есть данные по количеству показов/просмотров объявления, Вы можете его заполнить. Это позволит в отчёте по рекламе в метрике: Показы увидеть данные по показам/просмотрам по разным объявлениям и рассчитать конверсии.\n",
    clicks:"Поле не обязательное для заполнения. Если у Вас есть данные по количеству кликов по объявлению, Вы можете его заполнить. Это позволит в отчёте по рекламе в метрике: Показы увидеть данные по кликам по разным объявлениям и рассчитать конверсии. В отчёте по рекламе есть метрика: Переходы с рекламы, она собирается автоматически при переходе с рекламных ссылок. Эта метрика идентичная метрике: Клики.\n",
    ads_company:"Поле не обязательное для заполнения. Вы можете задать структура рекламных объявлений. В одном рекламном кабинете можно создать несколько кампаний. В каждой кампании можно создать несколько групп объявлений. В каждой группе объявлений можно создать несколько объявлений. Это позволит в отчете по рекламе быстро сгруппировать и проанализировать данные по кампаниям, группам объявлений, объявлениям. Вы можете не заполнять этот раздел, SCAN автоматически задаст названия: Новая кампания, Новая группа объявлений, новое объявление.\n",
    ads_group:"Поле не обязательное для заполнения. Вы можете задать структура рекламных объявлений. В одном рекламном кабинете можно создать несколько кампаний. В каждой кампании можно создать несколько групп объявлений. В каждой группе объявлений можно создать несколько объявлений. Это позволит в отчете по рекламе быстро сгруппировать и проанализировать данные по кампаниям, группам объявлений, объявлениям. Вы можете не заполнять этот раздел, SCAN автоматически задаст названия: Новая кампания, Новая группа объявлений, новое объявление.\n",
    ad_name:"Поле не обязательное для заполнения. Вы можете задать структура рекламных объявлений. В одном рекламном кабинете можно создать несколько кампаний. В каждой кампании можно создать несколько групп объявлений. В каждой группе объявлений можно создать несколько объявлений. Это позволит в отчете по рекламе быстро сгруппировать и проанализировать данные по кампаниям, группам объявлений, объявлениям. Вы можете не заполнять этот раздел, SCAN автоматически задаст названия: Новая кампания, Новая группа объявлений, новое объявление.\n",
    comment:"Поле не обязательное для заполнения. Вы можете оставить заметку по объявлению, чтобы сохранить нужную информацию. Это позволит в отчете по рекламе вывести данные по комментариям и в списке объявлений.\n"
}
