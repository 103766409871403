import React, {FC, useEffect, useRef, useState} from "react"

import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Button, Tooltip } from "antd"

import { compareDescription } from "../../../../constants/tooltips"
import { useActions } from "../../../../hooks/useActions"

interface ICompareButtonProps {
  isCompare: boolean;
}

const CompareButton: FC<ICompareButtonProps> = ({ isCompare }) => {
    const { setMetaCompare } = useActions()
    const containerRef = useRef(null);
    const [isAbsolutePosition, setIsAbsolutePosition] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const containerElement = containerRef.current;

            if (containerElement) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const containerRect = containerElement.getBoundingClientRect();
                const distanceFromRight = window.innerWidth - containerRect.right;

                !isAbsolutePosition ? setIsAbsolutePosition(distanceFromRight < 10) : null;
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div ref={containerRef} style={isAbsolutePosition ? { position: 'absolute', right: "1%",top:"-45px",display: 'flex' } : {display: 'flex'}}>
      {isCompare ? (
        <Button
            icon={<CloseOutlined style={{ color: "#000000" }} />}
            onClick={() => {
                setMetaCompare({value:false})
            }}
        >
          Закончить сравнение
        </Button>
      ) : (
          <Button
              icon={
                  <svg
                      className="params__compare-icon"
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet"
                  >
                      <g
                          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                          fill="#000000"
                          stroke="none"
                      >
                          <path d="M2488 5099 c-77 -40 -72 22 -78 -974 l-5 -890 -1150 -320 c-632 -176 -1160 -324 -1173 -329 -32 -11 -73 -69 -79 -113 -11 -86 58 -165 144 -165 29 0 161 32 336 81 159 45 292 81 297 81 5 0 -167 -294 -383 -652 l-392 -653 1 -95 c1 -125 25 -241 78 -372 237 -587 924 -860 1492 -594 243 115 414 280 529 511 67 135 101 251 116 398 10 97 9 117 -4 150 -9 20 -208 357 -442 748 -235 391 -424 713 -421 716 7 7 2319 651 2324 648 1 -1 -170 -290 -381 -642 -212 -353 -390 -657 -397 -677 -16 -49 3 -231 36 -345 95 -325 347 -605 655 -727 223 -87 492 -102 718 -38 123 34 290 121 392 204 182 147 318 352 379 572 25 90 46 271 37 318 -4 20 -197 352 -442 760 -239 398 -431 727 -427 731 4 4 180 55 392 113 212 59 396 115 409 124 116 85 77 255 -62 269 -33 3 -282 -62 -1142 -302 -605 -168 -1108 -308 -1117 -311 -17 -5 -18 39 -18 836 0 823 0 842 -20 880 -37 73 -127 99 -202 59z m1868 -2451 l344 -573 -344 -3 c-190 -1 -502 -1 -694 0 l-350 3 346 577 c191 317 348 575 351 573 2 -2 158 -262 347 -577z m-2887 -811 c188 -313 341 -570 341 -573 0 -2 -313 -4 -695 -4 -382 0 -695 3 -695 6 0 6 667 1121 683 1142 6 7 14 9 18 5 4 -4 161 -264 348 -576z" />
                      </g>
                  </svg>
              }
              onClick={() => {
                  setMetaCompare({value:true})
              }}
          >
              Сравнить
          </Button>
      )}

      <span
          style={{
              marginLeft: 6,
              marginRight: 10,
              marginTop: 3,
          }}
      >
        <Tooltip
            placement="bottom"
            title={
            <div
                className="page-title-tooltip-text"
                dangerouslySetInnerHTML={{ __html: compareDescription }}
            ></div>
            }
            color="#fff"
            zIndex={9999}
            trigger="click"
            overlayClassName="page-title-tooltip"
        >
          <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }} />
        </Tooltip>
      </span>
    </div>
    )
}

export default CompareButton
