import React, { useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Form, Input, InputNumber, Modal } from "antd"
import { DatePicker } from "antd/es"
import moment from "moment"

import { AddPayment as TAddPayment } from "../../../../../app/types/unApi"
import { showErrorMessage } from "../../../../../helpers/utils/ui"
import { unApi } from "../../../../../shared/common/api/endpoints/reports/unApi"


type Props = {
    visible:boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
}
const AddPayment = ({ visible,setVisible }:Props) => {
    const [ form ] = Form.useForm()
    const [ formLoading, setFormLoading ] = useState(false)

    const addPayment = async (values:TAddPayment) => {
        const data = {
            ...values,
            payment_date:moment(values.payment_date).format("YYYY-MM-DD"),
        }
        setFormLoading(true)
        try{
            const response = await unApi.addPaymentTable(data)
            if(response.status === 200){
                form.resetFields()
                setVisible(false)
                Modal.info({
                    title: "Успех!",
                    content: "Выплата добавлена.",
                })
            }
        }catch(err:any){
            if(err.response.status === 400){
                showErrorMessage("Произошла ошибка, повторите попытку позже, проверьте правильность Email.")
            }else{
                showErrorMessage("Произошла ошибка, повторите попытку позже.")
            }

        }finally{
            setFormLoading(false)
        }

    }


    return (
        <Modal
            title="Добавить выплату"
            open={visible}
            onCancel={() => {setVisible(false)}}
            onOk={() => {form.submit()}}
            okButtonProps={{ loading: formLoading }}
            okText="Добавить"
        >
            {
                formLoading ?
                    <div className="loading-container">
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                    </div>
                    :
                    <Form
                        form={form}
                        onFinish={addPayment}
                        requiredMark={false}
                    >
                        <Form.Item
                            label="Дата"
                            name="payment_date"
                            rules={[ { required: true } ]}>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <DatePicker format={"DD-MM-YYYY"} />
                        </Form.Item>
                        <Form.Item
                            label="Email партнёра"
                            name="email"
                            rules={[ { required: true, type: "email", message: "Введите корректный Email" } ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="payment_amount"
                            label="Сумма выплаты"
                            rules={[ { required: true } ]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            name="comment"
                            label="Комментарий"
                            rules={[
                                { required: true },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
            }
        </Modal>
    )
}

export default AddPayment
