import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const WebinarComments: FC = () => {

    const metrics = [
        { name: "Дата", dataKey: "comment_date" },
        { name: "Время", dataKey: "comment_time" },
        { name: "id вебинара", dataKey: "webinar_id" },
        { name: "Название вебинара", dataKey: "webinar_name" },
        { name: "Комментарий", dataKey: "comment_text" },
        { name: "id пользователя", dataKey: "id" },
        { name: "Имя", dataKey: "name" },
        { name: "Email", dataKey: "email" },
    ]

    return (
        <MainLayout pageTitle="Комментарии вебинара">
            <div className="page-wrapper">
                <PageHead
                    title="Комментарии"
                />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "Пользователи", "Вебинары" ]}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default WebinarComments
