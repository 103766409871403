import React, { FC } from "react"

import { Layout } from "antd"

const Footer: FC = () => {
    return (
        <Layout.Footer>

        </Layout.Footer>
    )
}

export default Footer
