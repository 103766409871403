import { RuleObject } from 'antd/lib/form';
export const validateDomain = (_:RuleObject,value:string) => {
    if (value && (value.includes("http") || value.includes("https") || value.includes("::/") || value.includes("/"))) {
        return Promise.reject("Недопустимое значение поля");
    }
    return Promise.resolve();
}
export const validateURL = (_: RuleObject, value: string) => {
    const urlPattern = new RegExp(
        "^(https:\\/\\/)?" +
        "((([a-zA-Z\\d](([a-zA-Z\\d-]*[a-zA-Z\\d])?))\\.)+[a-zA-Z]{2,}|localhost|" +
        "\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|" +
        "\\[?[a-fA-F\\d:]+\\]?)" +
        "(\\:\\d+)?(\\/[-a-zA-Z\\d%@_.~+&:]*)*" +
        "(\\?[;&a-zA-Z\\d%@_.,~+&:=-]*)?" +
        "(\\#[-a-zA-Z\\d_]*)?$",
        "i"
    );

    if (value && !urlPattern.test(value)) {
        return Promise.reject("Недопустимое значение поля");
    }
    return Promise.resolve();
};
