import React, { FC } from "react"

import {
    AreaChartOutlined,
    BarcodeOutlined,
    CalendarOutlined,
    DollarOutlined,
    FileSyncOutlined, ProfileOutlined,
    ReadOutlined,
    ShoppingCartOutlined,
    SolutionOutlined,
    TeamOutlined,
    UploadOutlined,
    UserOutlined,
    UserSwitchOutlined,
    VideoCameraAddOutlined,
    VideoCameraFilled,
} from "@ant-design/icons"
import { Menu } from "antd"
import { Link, NavLink as NativeLink, useNavigate, useLocation } from "react-router-dom"

import styles from "./Nav.module.scss"
import { MenuItem } from "../../../../app/types/nav"
import {clearSearchParams, getLinkPath, isAccessEnded } from "../../../../helpers/utils/functions"
import { routeNames } from "../../constants/routes"
import { useActions } from "../../hooks/useActions"
import usePermission from "../../hooks/usePermission"
import { useTypedSelector } from "../../hooks/useTypedSelector"

type NavLinkProps = {
    to:string
    onClick:()=>void
    children:React.ReactNode
}
const NavLink = ({to,onClick,children}:NavLinkProps) => {
    const onClickHandler = () => {
        clearSearchParams()
        onClick && onClick()
    }
    return <NativeLink to={to} onClick={onClickHandler}>
        {children}
    </NativeLink>
}

const Nav: FC = () => {
    const { isUtm,isContact } = useTypedSelector(
        (state) => state.currentUser
    )
    const { currentSchoolChange, fetchUserAndSchoolInfo,setFilters } = useActions()

    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        onClick?: (info?: any) => void,
    ): MenuItem => {
        const handleClick = () => {
            clearSearchParams()
            setFilters([])
            if (onClick) onClick()
        };
        const notToShow = ["Когортный анализ"]
        // const notToShowContacts = ["Отчёт по заказам","Отчёт по платежам","Отчёт по вебинарам","Качество вебинара","Когортный анализ"]
        if(!isUtm && !isContact || checkPermission([ "superadmin","admin" ])){
            return {
                key,
                icon,
                children,
                label,
                onClick:handleClick,
            } as MenuItem
        }
        /*
           if(isUtm && !isContact && notToShow.includes((label as any)?.props?.children)){
            return null
        }
         */

        if(!isContact && notToShow.includes((label as any)?.props?.children)){
            return null
        }
        return {
            key,
            icon,
            children,
            label,
            onClick,
        } as MenuItem
    }

    const navigate = useNavigate()
    const checkPermission = usePermission()

    const { schoolList, selectedSchool } = useTypedSelector(
        (state) => state.currentSchool
    )

    const { userAccess } = useTypedSelector((state) => state.currentSchool)


    const location = useLocation()

    const handleRefresh = () => {
        if (location.pathname === routeNames.home) {
            window.location.reload()
        }
    }

    const items: MenuItem[] = [
        checkPermission([ "superadmin", ])
            ? getItem(
                <Link to={getLinkPath(routeNames.reportVisits)}>
                    Отчёт по посещениям
                </Link>,
                "19",
                <AreaChartOutlined />
            )
            : null,
        getItem(
      <NavLink to={getLinkPath(routeNames.home)} onClick={handleRefresh}>
          Отчёт по регистрациям
      </NavLink>,
      "0",
      <AreaChartOutlined />
        ),
        getItem(
                <Link to={getLinkPath(routeNames.orderReports)} style={{display: "flex", gap: "10px", alignItems: "baseline"}}>
                  {isAccessEnded() ?
                    (
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" viewBox="0 0 24 24" style={{color: "#2387de"}}>
                            <path fill="currentColor" d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"/>
                        </svg>
                    ) : (
                          <AreaChartOutlined />
                      )
                  }
                  Отчёт по заказам
                </Link>,
                "14",
        ),
        getItem(
            <Link to={getLinkPath(routeNames.paymentReports)} style={{display: "flex", gap: "10px", alignItems: "baseline"}}>
            {isAccessEnded() ?
                (
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" viewBox="0 0 24 24" style={{color: "#2387de"}}>
                        <path fill="currentColor" d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"/>
                    </svg>
                ) : (
                    <AreaChartOutlined />
                )
            }
            Отчёт по платежам
            </Link>,
            "15",
        ),
        getItem(
            <Link to={getLinkPath(routeNames.adReports)} style={{display: "flex", gap: "10px", alignItems: "baseline"}}>
            {isAccessEnded() ?
                (
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" viewBox="0 0 24 24" style={{color: "#2387de"}}>
                        <path fill="currentColor" d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"/>
                    </svg>
                ) : (
                    <AreaChartOutlined />
                )
            }
            Отчёт по рекламе
            </Link>,
            "16",
        ),
        getItem(
            <Link to={getLinkPath(routeNames.webinarReports)} style={{display: "flex", gap: "10px", alignItems: "baseline"}}>
            {isAccessEnded() ?
                (
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" viewBox="0 0 24 24" style={{color: "#2387de"}}>
                        <path fill="currentColor" d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"/>
                    </svg>
                ) : (
                    <AreaChartOutlined />
                )
            }
            Отчёт по вебинарам
            </Link>,
            "17",
        ),
        getItem(
      <Link to={getLinkPath(routeNames.reportWebinarQuality)}>
        Качество вебинара
      </Link>,
      "1",
      <VideoCameraFilled />
        ),
        checkPermission([ "admin", "user:settings", "user:contacts" ])
            ? getItem(
          <Link to={getLinkPath(routeNames.reportWebinarComments)}>
            Комментарии вебинара
          </Link>,
          "2",
          <VideoCameraAddOutlined />
            )
            : null,
        getItem(
      <Link to={getLinkPath(routeNames.reportCohort)}>Когортный анализ</Link>,
      "3",
      <CalendarOutlined />
        ),

        checkPermission([ "admin", "user:settings", "user:contacts" ])
            ? getItem(
          <Link to={getLinkPath(routeNames.reportUsers)}>Пользователи</Link>,
          "4",
          <UserOutlined />
            )
            : null,

        checkPermission([ "admin", "user:settings", "user:contacts" ])
            ? getItem(
          <Link to={getLinkPath(routeNames.reportOrders)}>Заказы</Link>,
          "5",
          <ShoppingCartOutlined />
            )
            : null,
        checkPermission([ "admin", "user:settings", "user:contacts" ])
            ? getItem(
          <Link to={getLinkPath(routeNames.reportPayments)}>Платежи</Link>,
          "6",
          <DollarOutlined />
            )
            : null,
        checkPermission([ "admin", "user:settings", "user:contacts" ])
            ? getItem(
          <Link to={getLinkPath(routeNames.reportRegs)}>Регистрации</Link>,
          "7",
          <TeamOutlined />
            )
            : null,

        checkPermission([ "admin", "user:settings", "user:contacts" ])
            ? getItem(
          <Link to={getLinkPath(routeNames.export)}>Экспорт данных</Link>,
          "8",
          <UploadOutlined />
            )
            : null,

        checkPermission([ "superadmin","admin","user:settings" ])
            ? getItem(
          <Link to={getLinkPath(routeNames.reportSession)}>Сессии</Link>,
          "10",
          <SolutionOutlined />
            )
            : null,
        checkPermission([ "superadmin","admin","user:settings" ])
            ? getItem(
          <Link to={getLinkPath(routeNames.reportHit)}>Хиты</Link>,
          "11",
          <BarcodeOutlined />
            )
            : null,
        checkPermission([ "superadmin","admin","user:settings" ])
            ? getItem(
          <Link to={getLinkPath(routeNames.reportVisitors)}>Посетители</Link>,
          "12",
          <UserSwitchOutlined />
            )
            : null,
        checkPermission([ "superadmin" ])
            ? getItem(
          <Link to={getLinkPath(routeNames.reportHitStat)}>Статистика по экранам хита</Link>,
          "13",
          <FileSyncOutlined />
            )
            : null,
        checkPermission([ "superadmin" ]) ? getItem(<Link to={getLinkPath(routeNames.tasks)}>Задачи</Link>,"18",<ProfileOutlined />) : null,
        checkPermission([ "superadmin" ]) ? getItem(<Link to={getLinkPath(routeNames.dashboards)}>Дашборды</Link>,"19",<ProfileOutlined />) : null,
    ]

    const changeSchool = (id: string) => {
        currentSchoolChange(id, () => {
            fetchUserAndSchoolInfo(navigate)
            window.location.reload()
        })
    }

    const schoolSelectionItems: MenuItem[] = [
        getItem(<div style={{ whiteSpace:"pre-wrap",lineHeight:"24px" }}>{selectedSchool?.name || "-"}</div>, "1", <ReadOutlined />, [
            ...Object.entries(schoolList || {}).map(([ key, val ]) => {
                return getItem(<div style={{ whiteSpace:"pre-wrap",lineHeight:"24px" }} onClick={() => changeSchool(key)}>{val}</div>, key)
            }),
        ]),
    ]

    return (
        <>
      <div className={styles.schoolSelectorWrapper}>
        <Menu className={styles.schoolName}
            theme="dark"
            mode="inline"
            items={schoolSelectionItems}
            selectable={false}
        />
      </div>
      <Menu theme="dark" mode="inline" items={items} selectable={false} />
        </>
    )
}

export default Nav
