import {Button} from "antd";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import React from "react";
import {useTypedSelector} from "../../../shared/common/hooks/useTypedSelector";
import {useActions} from "../../../shared/common/hooks/useActions";

export const ToggleChart = () => {
    const { isChartVisible } = useTypedSelector((state)=>state.chart)
    const { setChartVisible } = useActions()

    const btnText = isChartVisible ? "Скрыть график" : "Показать график"
    const handleToggle = () => {
        localStorage.setItem("isChartVisible",`${!isChartVisible}`)
        setChartVisible()
    }
    if(window.location.pathname === '/dashboards'){
        return null
    }
    return(
        <Button style={{marginTop: isChartVisible ? "unset" : "15px"}} shape={"round"} onClick={handleToggle} icon={isChartVisible ? <EyeInvisibleOutlined />
            :  <EyeOutlined />}>{btnText}</Button>
    )
}
