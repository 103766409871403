import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IDomain, IDomainState } from "./types"

const initialState: IDomainState = {
    domainsData: [],
    validateStatus: false,
    isLoading: false,
    isUpdate: false,
    utmStatus:false
}

export const domainSlice = createSlice({
    name: "domain",
    initialState: initialState,
    reducers: {
        domainSetData: (state, action: PayloadAction<IDomain[]>) => {
            state.domainsData = action.payload
        },
        domainSetValidateStatus: (state, action: PayloadAction<boolean>) => {
            state.validateStatus = action.payload
        },
        domainSetLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        domainSetUpdate: (state, action: PayloadAction<boolean>) => {
            state.isUpdate = action.payload
        },
        utmSetData:(state,action:PayloadAction<boolean>) => {
            state.utmStatus = action.payload
        }
    }
})

export const domainSliceActions = domainSlice.actions
export const domainSliceReducer = domainSlice.reducer
