import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import moment from "moment"

import { ICompareState } from "./types"

const initialState: ICompareState = {
    compareFilters: {
        compareFilters1: [],
        compareFilters2: []
    },
    comparePeriod: {
        comparePeriod1: {
            start: moment().set({ hour: 0, minute: 0, second: 0 }).toISOString(),
            end: moment().set({ hour: 23, minute: 59, second: 59 }).toISOString(),
            interval: "today",
        },
        comparePeriod2: {
            start: moment().set({ hour: 0, minute: 0, second: 0 }).toISOString(),
            end: moment().set({ hour: 23, minute: 59, second: 59 }).toISOString(),
            interval: "today",
        },
    },
    compareSegments: {
        compareSegment1: {},
        compareSegment2: {}
    },
    segmentId:null
}

export const compareSlice = createSlice({
    name: "compare",
    initialState: initialState,
    reducers: {
        setCompareFilter: (state, action: PayloadAction<{ index: 1 | 2, filters: any[] }>) => {
            state.compareFilters[`compareFilters${action.payload.index}`] = action.payload.filters
        },
        setComparePeriod: (state, action: PayloadAction<{ index: 1 | 2, period: any }>) => {
            state.comparePeriod[`comparePeriod${action.payload.index}`] = action.payload.period
        },
        setCompareSegment: (state, action: PayloadAction<{ index: 1 | 2, segment: any }>) => {
            state.compareSegments[`compareSegment${action.payload.index}`] = action.payload.segment
        },
        setSegmentId: ( state,action:PayloadAction<number | null> )=>{
            state.segmentId = action.payload
        }
    }
})

export const compareSliceActions = compareSlice.actions
export const compareSliceReducer = compareSlice.reducer
