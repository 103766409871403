import React, { useEffect, useState } from "react";
import { Form, Modal, Select, DatePicker } from "antd";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { billApi } from "../../../../api/endpoints/billApi";
import { showErrorMessage } from "../../../../../../helpers/utils/ui";
import moment from "moment";

type Props = {
    state: { visible: boolean; item: { id: number; date: string }; loading: boolean };
    setState: React.Dispatch<React.SetStateAction<{ visible: boolean; item: { id: number; date: string }; loading: boolean }>>;
};

interface Req {
    id: number;
    name: string;
}

interface ReqList {
    loading: boolean;
    state: [] | Req[];
}

const DownloadAct = ({ state, setState }: Props) => {
    const [form] = Form.useForm();
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool);
    const [reqList, setReqList] = useState<ReqList>({
        loading: true,
        state: [],
    });

    const changedDate = moment(state.item.date, "DD-MM-YYYY HH:mm:ss").add(31, "days");

    const fetchReqList = async (id: number) => {
        try {
            const { data } = await billApi.getReqList(id);
            const arr = Object.values(data) as Req[];
            setReqList({ loading: false, state: arr });
            form.setFieldsValue({ requisites_id: arr[0].name });
        } catch (err) {
            showErrorMessage("Произошла ошибка, попробуйте ещё раз позже.");
            setReqList({ loading: false, state: [] });
        }
    };

    useEffect(() => {
        if (selectedSchool && selectedSchool.id) {
            setReqList({ loading: true, state: [] });
            fetchReqList(Number(selectedSchool.id));
        }
    }, [selectedSchool, state.visible]);

    const onFinish = async (values: { requisites_id: number; payment_date: any }) => {
        try {
            if (selectedSchool?.id) {
                const requisites_id = Number(values.requisites_id) ? Number(values.requisites_id) : reqList.state.find((el) => el.name === values.requisites_id.toString())?.id as number;

                const body = {
                    school_id: Number(selectedSchool.id),
                    requisites_id,
                    order_id:state.item.id,
                    order_date:moment(values.payment_date).format("YYYY-MM-DD")
                };
                await billApi.getActFile(body);
            }
            Modal.info({
                title: "Успех",
                content: "Акт успешно скачан.",
            });
            form.resetFields();
            setState({ visible: false, item: { id: 0, date: "" }, loading: false });
        } catch (err) {
            showErrorMessage("Произошла ошибка, повторите попытку позже.");
        }
    };
    const modalTitle = (
        <span style={{ paddingRight: "20px", display: "inline-block" }}>
      Формирование акта выполненных работ для счёта {state.item.id} от {changedDate.format("DD-MM-YYYY")}
    </span>
    );

    return (
        <Modal
            title={modalTitle}
            open={state.visible}
            onOk={() => form.submit()}
            onCancel={() => {
                form.resetFields();
                setState({ visible: false, item: { id: 0, date: "" }, loading: false });
            }}
            width={600}
            okText="Скачать"
            cancelText="Отмена"
            destroyOnClose
        >
            <Form form={form} name="downloadRequisite" onFinish={onFinish} layout={"vertical"}>
                <Form.Item name="requisites_id" label="Реквизиты" rules={[{ required: true }]}>
                    <Select
                        loading={reqList.loading}
                        disabled={reqList.loading}
                        optionFilterProp="children"
                        showSearch={true}
                        filterOption={(input, option) => (option?.label ?? "").includes(input)}
                        filterSort={(optionA: any, optionB: any) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
                    >
                        {reqList.state.length > 0 ? (
                            <>
                                {reqList.state.map((item: Req, index: number) => {
                                    return (
                                        <Select.Option key={index} value={item.id} label={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    );
                                })}
                            </>
                        ) : (
                            <Select.Option value="">Нет</Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item label="Дата акта" name="payment_date" rules={[{ required: true }]} initialValue={changedDate}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <DatePicker format={"DD-MM-YYYY"} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default DownloadAct;
