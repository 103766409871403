import { useEffect } from "react"

import { Table,Input,Button } from "antd"

import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"


const columns = [
    {
        title: "Дата",
        dataIndex: "payment_date",
        key: "payment_date",
    },
    {
        title: "Сумма",
        dataIndex: "payment_amount",
        key: "payment_amount",
    },
    {
        title: "Комментарий",
        dataIndex: "comment",
        key: "comment",
    },
]
const PaymentsTab = () => {
    const { payoutList } = useTypedSelector((state)=>state.partner)
    const { payoutsFetchData } = useActions()
    useEffect(()=>{
        payoutsFetchData()
    },[])
    return (
        <>
            {/*<h3>Реквизиты для выплаты:</h3>*/}
            {/*<div style={{ display:"flex",flexDirection:"column",rowGap:"16px" }}>*/}
            {/*	<Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} style={{ width:"22%" }} rows={4} />*/}
            {/*	<Button type="primary" style={{ backgroundColor: "blue", color: "white",width:"10%" }}>*/}
            {/*		Сохранить*/}
            {/*	</Button>*/}
            {/*</div>*/}

			<div style={{ width:"450px",marginTop:"40px" }}>
				<Table columns={columns} dataSource={payoutList} pagination={false} />

			</div>
        </>
    )
}

export default PaymentsTab