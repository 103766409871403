// Группировки в графике
export const groups = [
    {
        groupName: "reg",
        metrics: [
            { name: "regs_count_3", label: "Регистрации" },
            { name: "users_count_3", label: "Пользователи" },
        ],
    },
    {
        groupName: "conv",
        metrics: [
            {
                name: "orders_to_forders_converse_3",
                label: "CR заказа в завершенный заказ",
            },
            {
                name: "creator_to_buyer_converse_3",
                label: "CR из созд. заказа в покупателя",
            },
        ],
    },
    {
        groupName: "orders",
        metrics: [
            { name: "orders_count_3", label: "Заказы" },
            { name: "forders_count_3", label: "Заказы [Завершён]" },
            { name: "payments_count_3", label: "Платежи" },
            { name: "orders_creators_count_3", label: "Создатели заказов" },
            { name: "buyers_count_3", label: "Покупатели" },
        ],
    },
    {
        groupName: "sum",
        metrics: [
            { name: "payments_sum_3", label: "Сумма платежей" },
            { name: "orders_sum_3", label: "Сумма заказов" },
            { name: "forders_sum_3", label: "Сумма заказов [Завершён]" },
        ],
    },
    {
        groupName: "avg",
        metrics: [
            { name: "orders_sum_avg_3", label: "Ср. сумма заказа" },
            { name: "payments_sum_avg_3", label: "Средний чек платежа" },
        ],
    },
]

/**
 Для добавления в группировку информационной иконки требуется добавить
 в объект группировки парметр description
 @param {string} `description`
 ВАЖНО! Использовать инф. иконку можно только в родительских элементах

 Пример:
 { name: "Дата", description: "...", children: [...] }

 Параметр принимает в себя название иконки, список доступных
 иконок для этого модуля редактируется в файле web/js/react-app/src/common/filter/IconFilter.js
 */

export const groupingsData = [
    {
        name: "Регистрации",
        description:
			"Группировка данных в таблице по дате регистрации. Можно выбрать разную детализацию.",
        children: [
            {
                name: "Дата регистрации",
                children: [
                    {
                        name: "Дни регистрации",
                        description: "Регистрации сгруппированы по каждому дню регистрации.",
                        dataKey: "reg_date_3",
                    },
                    {
                        name: "Недели регистрации",
                        description: "Регистрации сгруппированы по неделям, по 7 дней.",
                        dataKey: "reg_date_w_3",
                    },
                    {
                        name: "Дни недели регистрации",
                        description:
							"Регистрации сгруппированы по дням недели (понедельник, вторник, среда, четверг, пятница, суббота, воскресенье).",
                        dataKey: "reg_date_dw_3",
                    },
                    {
                        name: "Месяцы регистрации",
                        description: "Регистрации сгруппированы по месяцам.",
                        dataKey: "reg_date_m_3",
                    },
                    {
                        name: "Кварталы регистрации",
                        description: "Регистрации сгруппированы по кварталам, по 3 месяца.",
                        dataKey: "reg_date_q_3",
                    },
                    {
                        name: "Года регистрации",
                        description: "Регистрации сгруппированы по годам.",
                        dataKey: "reg_date_y_3",
                    },
                ],
            },
            {
                name: "UTM-метка регистрации",
                description: "Группировка данных по UTM-метке регистрации.",
                children: [
                    { name: "utm_source регистрации", dataKey: "reg_utm_source_3" },
                    { name: "utm_medium регистрации", dataKey: "reg_utm_medium_3" },
                    { name: "utm_campaign регистрации", dataKey: "reg_utm_campaign_3" },
                    { name: "utm_content регистрации", dataKey: "reg_utm_content_3" },
                    { name: "utm_term регистрации", dataKey: "reg_utm_term_3" },
                    { name: "utm_group регистрации", dataKey: "reg_utm_group_3" },
                ],
            },
            {
                name: "Название регистрации",
                description:
					"Группировка регистраций по названию регистраций. <br/>" +
					"Если выбран тип регистрации: SCAN, то название регистрации - название страницы, на которой была заполнена форма. <br/>" +
					"Если выбран тип регистрации: Нулевые заказы GС, то названием регистрации будет являться название предложения в нулевом заказе GetCourse.",
                dataKey: "reg_title_3",
            },
            {
                name: "Номер регистрации",
                description: "Для типа регистрации: нулевые заказы GC это вывод номера нулевого заказа.",
                dataKey: "reg_number_3",
            },
        ],
    },
    {
        name: "Пользователи",
        children: [
            {
                name: "E-mail",
                description:
					"Вывод данных по Email пользователю. Ссылка Email адреса пользователя будет активная, при переходе откроется карточка пользователя в GetCourse.",
                dataKey: "user_email_3",
            },
            {
                name: "id пользователя",
                description:
					"Вывод данных по ID пользователя. Ссылка ID пользователя будет активная, при переходе откроется карточка пользователя в GetCourse.",
                dataKey: "user_id_3",
            },
            {
                name: "id - Email - Имя - Телефон",
                description: "Вывод контактных данных пользователя, разделенные точкой с запятой (;)",
                dataKey: "user_id_ext_3",
            },
        ],
    },
    {
        name: "Заказы",
        description:
			"Группировка регистраций пользователей по заказам. Данные группировки покажут сколько и какие заказы были созданы пользователями в разрезе по регистрациям.",
        children: [
            {
                name: "Дата заказа",
                description: "Группировка заказов пользователей по дате создания заказов.",
                children: [
                    {
                        name: "Дни заказов",
                        description: "Группировка заказов по дням создания заказов.",
                        dataKey: "order_date_3",
                    },
                    {
                        name: "Недели заказов",
                        description: "Группировка заказов по неделям создания заказов.",
                        dataKey: "order_date_w_3",
                    },
                    {
                        name: "Дни недели заказов",
                        description: "Группировка заказов по дням недели создания заказов.",
                        dataKey: "order_date_dw_3",
                    },
                    {
                        name: "Месяца заказов",
                        description: "Группировка заказов по месяцам создания заказов.",
                        dataKey: "order_date_m_3",
                    },
                    {
                        name: "Кварталы заказов",
                        description: "Группировка заказов по кварталам создания заказов.",
                        dataKey: "order_date_q_3",
                    },
                    {
                        name: "Года заказов",
                        description: "Группировка заказов по годам создания заказов.",
                        dataKey: "order_date_y_3",
                    },
                ],
            },
            {
                name: "Предложения",
                description:
					"Группировка данных по предложениям заказов, которые пользователи создали после регистрации.<br/>" +
					"<br/>" +
					"Эта группировка позволяет увидеть, на какие предложения, сколько заказов и оплат было создано людьми в разрезе по определённым регистрациям.",
                dataKey: "order_offers_3",
            },
            {
                name: "Номер заказа",
                description:
					"Данной группировкой можно вывести список номеров заказов GetCourse пользователями, которые сделали заказ.",
                dataKey: "order_number_3",
            },
            {
                name: "Тег предложения заказа",
                description:
					"Группировка данных по тегам предложений заказов, которые пользователи создали после регистрации.<br>" +
					"<br>" +
					"Теги предложение получаем автоматически из выгрузки заказов.<br>" +
					"<br>" +
					"Эта группировка позволяет увидеть заказы по каждому тегу предложения.<br>" +
					"<br>" +
					"Заказы, у предложений которых не будет тегов предложений, будут сгруппированы в группу (Без тегов)<br>",
                dataKey: "order_tag_1_3",
            },
            {
                name: "Тег заказа",
                description:
					"Группировка заказов по тегам заказов, которые пользователи создали после регистрации.<br>" +
					"<br>" +
					"Теги заказов получаем автоматически из выгрузки заказов.<br>" +
					"<br>" +
					"Эта группировка позволяет увидеть заказы каждого тега заказа. <br>" +
					"<br>" +
					"Заказы, у которых нет тега заказа, будут сгруппированы в группу (Без тега).<br>",
                dataKey: "order_tag_3_3",
            },
            {
                name: "Менеджер заказа",
                description:
					"Группировка данных по менеджерам заказов, которые пользователи создали после регистрации. Данные берутся из выгрузки GetCourse.<br/>" +
					"<br/>" +
					"Эта группировка позволяет увидеть статистику менеджеров в разрезе по определенным регистрациям.",
                dataKey: "order_manager_3",
            },
            {
                name: "Статус заказа",
                description:
					"Группировка данных по статусам заказов, которые пользователи создали после регистрации.<br/>" +
					"<br/>" +
					"Эта группировка позволяет увидеть, в каких статусах находятся заказы, созданные людьми в разрезе по определенным регистрациям.",
                dataKey: "order_status_3",
            },
            {
                name: "UTM-метка заказа",
                description:
					"Группировка заказов по UTM - меткам заказов. Данные берутся из выгрузки GetCourse.",
                children: [
                    { name: "utm_source заказа", dataKey: "order_utm_source_3" },
                    { name: "utm_medium заказа", dataKey: "order_utm_medium_3" },
                    { name: "utm_campaign заказа", dataKey: "order_utm_campaign_3" },
                    { name: "utm_content заказа", dataKey: "order_utm_content_3" },
                    { name: "utm_term заказа", dataKey: "order_utm_term_3" },
                    { name: "utm_group заказа", dataKey: "order_utm_group_3" },
                ],
            },
        ],
    },
    {
        name: "Платежи",
        description: "Группировка платежей по разным параметрам.",
        children: [
            {
                name: "Дата платежа",
                description: "Группировка платежей по дате получения платежа.",
                children: [
                    {
                        name: "День платежа",
                        description: "Группировка платежей по дням получения платежей.",
                        dataKey: "payment_date_3",
                    },
                    {
                        name: "Неделя платежа",
                        description: "Группировка платежей по неделям получения платежей.",
                        dataKey: "payment_date_w_3",
                    },
                    {
                        name: "День недели платежа",
                        description: "Группировка платежей по дням недели получения платежей.",
                        dataKey: "payment_date_dw_3",
                    },
                    {
                        name: "Месяц платежа",
                        description: "Группировка платежей по месяцам получения платежей.",
                        dataKey: "payment_date_m_3",
                    },
                    {
                        name: "Квартал платежа",
                        description: "Группировка платежей по кварталам получения платежей.",
                        dataKey: "payment_date_q_3",
                    },
                    {
                        name: "Год платежа",
                        description: "Группировка платежей по годам получения платежей.",
                        dataKey: "payment_date_y_3",
                    },
                ],
            },
            {
                name: "id платежа",
                description: "Группировка платежей по ID платежа.",
                dataKey: "payment_id_3",
            },
            {
                name: "Период от регистрации до платежа",
                description:
					"Группировка платежей пользователей по периоду от регистрации до платежа. Сможете увидеть в какой день или неделю после регистрации происходит больше всего оплат",
                children: [
                    {
                        name: "Дней от регистрации до платежа",
                        description:
							"Группировка платежей по количеству дней прошедших от регистрации до совершения платежа. Узнаете на какой день после регистрации больше всего совершается оплат.<br>" +
							"<br>" +
							"Платежи группируются по периодам:<br>" +
							"<br>" +
							"1 день<br>" +
							"2 день<br>" +
							"3 день<br>" +
							"4 день<br>" +
							"5 день<br>" +
							"6 день<br>" +
							"7 день<br>" +
							"8 день<br>" +
							"9 день<br>" +
							"10 день<br>" +
							"11 день<br>" +
							"12 день<br>" +
							"13 день<br>" +
							"14 день<br>" +
							"> 15 дней<br>" +
							"<br>" +
							"В период 1 день попадут все платежи, у которых дата платежа = дате регистрации.<br>" +
							"<br>" +
							"В период 2 день попадут платежи, совершённые на следующий день после регистрации.<br>",
                        dataKey: "pay_after_reg_d_3",
                    },
                    {
                        name: "Недель от регистрации до платежа",
                        description:
							"Группировка платежей по количеству недель прошедших от регистрации до совершения платежа. Узнаете в какую неделю после регистрации больше всего совершается оплат.<br>" +
							"<br>" +
							"Платежи группируются по периодам:<br>" +
							"<br>" +
							"1 неделя<br>" +
							"2 неделя<br>" +
							"3 неделя<br>" +
							"4 неделя<br>" +
							"5 неделя<br>" +
							"6 неделя<br>" +
							"7 неделя<br>" +
							"8 неделя<br>" +
							"9 неделя<br>" +
							"10 неделя<br>" +
							"11 неделя<br>" +
							"12 неделя<br>" +
							"> 12 недель<br>" +
							"<br>" +
							"В период 1 неделя попадут все платежи, совершенные в течение 7 дней после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 1 по 7 марта.<br>" +
							"<br>" +
							"В период 2 неделя попадут все платежи, совершенные в период с 8 по 14 день после регистрации. Если регистрация 1 марта, то попадут платежи созданные с 8 по 14 марта.<br>",
                        dataKey: "pay_after_reg_w_3",
                    },
                    {
                        name: "Месяцев от регистрации до платежа",
                        description:
							"Группировка платежи по количеству месяцев прошедших от регистрации до совершения платежа. Узнаете в какой месяц после регистрации больше всего совершается платежей.<br>" +
							"<br>" +
							"Платежи группируются по периодам:<br>" +
							"<br>" +
							"1 месяц<br>" +
							"2 месяц<br>" +
							"3 месяц<br>" +
							"4 месяц<br>" +
							"5 месяц<br>" +
							"6 месяц<br>" +
							"7 месяц<br>" +
							"8 месяц<br>" +
							"9 месяц<br>" +
							"10 месяц<br>" +
							"11 месяц<br>" +
							"12 месяц<br>" +
							"> 12 месяцев<br>" +
							"<br>" +
							"В период 1 месяц попадут все платежи, совершенные в течение 30 дней после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 1 по 30 марта.<br>" +
							"<br>" +
							"В период 2 месяца попадут все платежи, совершенные в период с 31 по 60 день после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 31 марта по 29 апреля.<br>" +
							"<br>" +
							"Условно 1 месяц = 30 дней.<br>" +
							"<br>" +
							"Формула расчёта: (Дата платежа - дата регистрации + 1) / 30 = число округляем до большего значения.<br>",
                        dataKey: "pay_after_reg_m_3",
                    },
                    {
                        name: "Кварталов от регистрации до платежа",
                        description:
							"Группировка платежей по количеству кварталов прошедших от регистрации до совершения платежа. Узнаете в какой квартал после регистрации больше всего совершается платежей.<br>" +
							"<br>" +
							"Платежи группируются по периодам:<br>" +
							"<br>" +
							"1 квартал<br>" +
							"2 квартал<br>" +
							"3 квартал<br>" +
							"4 квартал<br>" +
							"> 4 кварталов<br>" +
							"<br>" +
							"В период 1 квартал попадут все платежи, совершенные в течение 91 дня после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 1 марта по 30 мая.<br>" +
							"<br>" +
							"В период 2 квартал попадут все платежи, совершенные в период с 92 по 182 день после регистрации. Если регистрация 1 марта, то попадут платежи совершенные с 31 мая по 29 августа.<br>" +
							"<br>" +
							"Условно 1 квартал = 91 день.<br>" +
							"<br>" +
							"Формула расчёта: (Дата платежа - дата регистрации + 1) / 91 = число округляем до большего значения.<br>",
                        dataKey: "pay_after_reg_q_3",
                    },
                    {
                        name: "Лет от регистрации до платежа",
                        description:
							"Группировка платежей по количеству лет прошедших от регистрации до совершения платежа. Узнаете в какой год после регистрации больше всего совершается платежей.<br>" +
							"<br>" +
							"Платежи группируются по периодам:<br>" +
							"<br>" +
							"1 год<br>" +
							"2 год<br>" +
							"3 год<br>" +
							"4 год<br>" +
							"5 год<br>" +
							"> 5 лет<br>" +
							"<br>" +
							"В период 1 год попадут все платежи, совершенные в течение 365 дней после регистрации.<br>" +
							"<br>" +
							"В период 2 год попадут все платежи, совершенные в период с 366 по 730 день после регистрации.<br>" +
							"<br>" +
							"Условно 1 год = 365 дней.<br>" +
							"<br>" +
							"Формула расчёта: (Дата платежа - дата регистрации + 1) / 365 = число округляем до большего значения.<br>",
                        dataKey: "pay_after_reg_y_3",
                    },
                ],
            },
            {
                name: "Тип платежа",
                description: "Группировка данных по типу платежной системы.",
                dataKey: "payment_type_3",
            },
            {
                name: "Статус платежа",
                description: "Группировка данных по статусу платежа.",
                dataKey: "payment_status_3",
            },
        ],
    },
]

/**
 Для добавления в метрику информационной иконки требуется добавить
 в объект метрики парметр description
 @param {string} `description`

 ВАЖНО! Использовать инф. иконку можно только в родительских элементах

 Пример:
 { name: "Пользователи", description: "...", children: [...] }

 Параметр принимает в себя название иконки, список доступных
 иконок для этого модуля редактируется в файле web/js/react-app/src/common/filter/IconFilter.js
 */
export const metrics = [
    {
        name: "Платежи",
        children: [
            {
                name: "Платежи",
                description:
					"Количество платежей пользователей со статусами (ПОЛУЧЕН, НАЧИСЛЕН НА ДЕПОЗИТ)  созданные в отчётный период.",
                dataKey: "payments_count_3",
            },
            {
                name: "Сумма платежей",
                description:
					"Сумма платежей со статусами (ПОЛУЧЕН, НАЧИСЛЕН НА ДЕПОЗИТ)  созданные в отчётный период, с вычетом комиссии платёжных систем." +
                    "<br/> <br/>"
                + "<b>Формула расчёта:</b>"
                + "<br/> <br/> Сумма платежей с комиссией платёжных систем - Комиссия платёжных систем"
                ,
                dataKey: "payments_sum_3",
            },
            {
                name: "Сумма платежей с комиссией платёжных систем",
                description:
					"Сумма платежей со статусами (ПОЛУЧЕН, НАЧИСЛЕН НА ДЕПОЗИТ) созданные в отчётный период БЕЗ вычета комиссии платёжных систем.",
                dataKey: "payments_sum_with_commission_3",
            },
            {
                name: "Комиссия платёжных систем",
                description: "Сумма комиссий платёжных систем",
                dataKey: "payments_commission_sum_3",
            },
            {
                name: "Покупатели",
                description:
					"Количество людей у кого есть хоть один созданный платёж в отчётный период.",
                dataKey: "buyers_count_3",
            },
            {
                name: "Средний чек платежа",
                description:
                    "Средняя сумма платежей созданных в отчётный период." +
                    "<br/> <br/>"
                    + "<b>Формула расчёта:</b>"
                    + "<br/> <br/> Сумма платежей / Платежи",
                dataKey: "payments_sum_avg_3",
            },
            {
                name: "Возвраты",
                description:
					"Количество платежей со статусом (ВОВЗРАЩЕН),  созданные в отчётный период",
                dataKey: "refunds_count_3",
            },
            {
                name: "Сумма возвратов",
                description: "Сумма платежей со статусом (ВОВЗРАЩЕН),  созданные в отчётный период.",
                dataKey: "refunds_sum_3",
            },
            {
                name: "Оформившие возврат",
                description: "Кол-во людей, у кого есть платёж со статусом ВОЗВРАЩЕН, созданный в отчётный период",
                dataKey: "buyers_with_refund_count_3",
            },
            {
                name: "CR из покупателя в оформившего возврат",
                description:
                    "Конверсия из людей, кто сделал платёж в отчётный период в оформивших возврат." +
                    "<br/> <br/>"
                    + "<b>Формула расчёта:</b>"
                    + "<br/> <br/> Оформившие возврат / Покупатели * 100",
                dataKey: "buyer_to_refunder_converse_3",
            },
        ],
    },
]
