import React, { FC, useEffect, useState } from "react"

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Divider, Form, Input, InputNumber, Modal, Select, Space, Spin } from "antd"
import { DatePicker } from "antd/es"
import moment from "moment"

import { useActions } from "../../../../../../shared/common/hooks/useActions"
import { useForceUpdate } from "../../../../../../shared/common/hooks/useForceUpdate"
import { useTypedSelector } from "../../../../../../shared/common/hooks/useTypedSelector"
import {showErrorMessage, showFormErrors} from "../../../../../../helpers/utils/ui"
import {unApi} from "../../../../../../shared/common/api/endpoints/reports/unApi";

interface IEditModalProps {
    isVisible: {
        visible:boolean
        id:number
    }
    setVisible: ({visible,id}:{visible:boolean;id:number}) => void
    setRefetch:(val: (prev:boolean) => boolean) => void;
}

const EditRK: FC<IEditModalProps> = ({ isVisible, setVisible,setRefetch }) => {
    const [ form ] = Form.useForm()

    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const [isLoading, setIsLoading] = useState(false)


    const editRK = async (values:{name:string}) => {
        try{
            setIsLoading(true)
            const data = {
                ...values,
                school_id:Number(selectedSchool?.id),
                id:isVisible.id
            }
            const response = await unApi.updateRK(data)
            form.resetFields()
            setVisible({id:0,visible:false})
            setRefetch((prev)=>!prev)
        }catch(err){
            showErrorMessage("Произошла ошибка , повторите попытку позже. Проверьте название , оно должно быть уникальным.")
        }finally{
            setIsLoading(false)
        }
    }

    return (
        <Modal
            title="Редактирование рекламного кабинета"
            open={isVisible.visible}
            onOk={() => form.submit()}
            onCancel={() => setVisible({visible:false,id:0})}
            okText="Переименовать"
            cancelText="Отмена"
            okButtonProps={{ loading: isLoading }}
            destroyOnClose
        >
            <Spin
                spinning={isLoading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            >
                <Form
                    form={form}
                    onFinish={editRK}
                    layout="vertical"
                    requiredMark={false}
                >
                    <Form.Item
                        label="Название рекламного кабинета"
                        name="name"
                        rules={[ { required: true, message: "Поле обязательное" } ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}

export default EditRK
