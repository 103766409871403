import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import _ from "lodash"

import { IFilter, IFilterState, ISegment } from "./types"
import {updateQueryParams} from "../../../../../../../helpers/utils/functions";
import {calendarTypes} from "../../../../../../../shared/common/constants/filters";

const savedSegment = window.localStorage.getItem("current_segment")
const defaultState = {
    filters: [],
    filterSelectorVisible: false,
    currentSegment: savedSegment ? JSON.parse(savedSegment) : null,
    segments: [],
    funnels:[],
    segmentsLoading: false,
    filterParent:[],
    currentFunnel:[],
    blocks:{}
}
const getInitialState = () => {
    const url = new URL(window.location.href)
    const currentParams = url.searchParams.get('params')
    const paramsObject = currentParams ? JSON.parse(currentParams) : {}

    return defaultState
}
const initialState:IFilterState = getInitialState()


export const filterSlice = createSlice({
    name: "filter",
    initialState: initialState,
    reducers: {
        setFilters: (state, action: PayloadAction<IFilter[] | any>) => {
            let filters:any[] = []
            if(Array.isArray(action.payload)){
                filters = _.cloneDeep(action.payload)
            }else{
                filters = _.cloneDeep(action.payload.data)
            }
            filters.forEach((filter)=>{
                if(filter.type === "datetimerange" && filter?.calendar?.type === "selectdates" && filter?.start?.length === 10){
                    filter.start = filter.start + " 00:00:00"
                    filter.end = filter.end + " 23:59:59"
                }
            })
            const getMinifiedFilters = () => {
                return filters.map((el)=>{
                    switch(el.type){
                        case "number":{
                            return {id:el.id,number:el.number}
                        }
                        case "datetimerange":{
                            return {id:el.id,...(calendarTypes.includes(el?.calendar?.type) ? {} : { start:el.start,end:el.end}),calendar:el.calendar}
                        }
                        case "timerange":{
                            return {id:el.id,start:el.start,end:el.end}
                        }
                    }
                })
            }
            console.log(filters,'here',getMinifiedFilters())
            updateQueryParams(getMinifiedFilters(),"filters")
            if(Array.isArray(action.payload)){
                state.filters = filters
            }else{
                if (!state.blocks[action.payload.blockId]) {
                    state.blocks[action.payload.blockId] = { filters: [] }
                }
                state.blocks[action.payload.blockId].filters = filters
            }
        },
        setFilter: (state, action: PayloadAction<IFilter>) => {
            let _filters = _.cloneDeep(state.filters)
            const filter = JSON.parse(JSON.stringify(action.payload))
            if(filter?.calendar?.type === "selectdates" && filter?.start?.length === 10){
                filter.start = filter.start + " 00:00:00"
                filter.end = filter.end + " 23:59:59"
            }
            _filters.push(filter)
            updateQueryParams(_filters,"filters")
            state.filters = _filters
        },
        setFilterParent: (state,action:PayloadAction<IFilter>)=>{
            if(Array.isArray(action.payload)){
                state.filterParent = []
                return
            }
            const findElement = state.filterParent.find((el)=>el.id === action.payload.id)
            findElement ? null : state.filterParent = [...state.filterParent,{id:action.payload.id,name:action.payload.name}]
        },
        setFilterSelectorVisible: (state, action: PayloadAction<boolean>) => {
            state.filterSelectorVisible = action.payload
        },
        setSegments: (state, action: PayloadAction<ISegment[]>) => {
            state.segments = action.payload
        },
        setCurrentSegment: (state, action: PayloadAction<ISegment | null>) => {
            state.currentSegment = action.payload
            window.localStorage.setItem("current_segment", JSON.stringify(action.payload))
        },
        setCurrentFunnel: (state,action: PayloadAction<ISegment[] | null>) => {
            state.currentFunnel = action.payload
            if(Array.isArray(state.currentFunnel)){
                updateQueryParams(state.currentFunnel.map((el)=>el.id),"funnel")
            }
        },
        setFunnels: (state, action: PayloadAction<ISegment[]>) => {
            state.funnels = action.payload
        },
        setSegmentsLoading: (state, action: PayloadAction<boolean>) => {
            state.segmentsLoading = action.payload
        },
    }
})

export const filterSliceReducer = filterSlice.reducer
export const filterSliceActions = filterSlice.actions
