export const routeNames = {
    // main
    home: "/users/universal",
    profile: "/users/profile",
    notFound: "/404",
    error: "/error",
    orderReports: "/users/order-reports",
    accessEnded: "/access-ended",
    paymentReports:"/users/payment-reports",
    adReports: "/users/ad-reports",
    webinarReports:"/users/webinar-reports",
    tasks:"/tasks",
    yandexMetrics:"/source/yandex",
    dashboards:"/dashboards",

    // auth
    login: "/login",
    signup: "/signup",
    resetPassword: "/reset-password",
    restorePassword: "/restore",

    // school settings
    schoolEdit: "/source/school",
    schoolTrackScript: "/source/counter",
    schoolStaff: "/source/access",
    schoolLoadData: "/source/loading",
    schoolAdd: "/source/addschool",
    lastData: "/source/data",

    // webinar settings
    webinarList: "/webinar/items",
    webinarConnectBizon: "/source/counter2",
    webinarCommentsSettings: "/webinar/comments",

    // ads settings
    adsConnect: "/source/ads",
    adsSpend: "/advert/rate",
    ad: "/ad",

    // reports
    reportWebinarQuality: "/webinar/quality",
    reportVisits:"/users/visits",
    reportWebinarComments: "/webinar/webinarcomments",
    reportCohort: "/users/cohort",
    reportUsers: "/users/list",
    reportOrders: "/users/orders",
    reportPayments: "/users/payments",
    reportRegs: "/users/registration",
    reportSession: "/visits/sessions",
    reportHit: "/visits/hits",
    reportVisitors: "/visits/visitors",
    reportHitStat: "/visits/hitstats",

    // extra
    bill: "/users/billing",
    payment: "/payment",
    export: "/users/reports",
    telegramConnect: "/users/telegram",
    noAccess: "/users/no-access",
    schoolAdded: "/success-added",

    // partner cab
    partner: "/users/partner",

    // crm
    crmSchool: "/crm/schools",
    crmUsers: "/crm/users",
    crmOrders: "/crm/orders",
    crmPayments: "/crm/payments",
    crmTariffs: "/crm/tariff",
    crmTasks: "/crm/tasks",
    crmActivity: "/crm/activity",
    crmDetails: "/crm/detailing",
    crmPartner:"/crm/partner",
    crmRequests:"/crm/requests",
    crmChat: "/crm/chat",
    // Payment Results
    paymentError:"/payment/error",
    paymentSuccess:"/payment/success",

    // test
    testRoute:"/test"
}
