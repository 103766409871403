import { ColumnsType } from "antd/es/table";
import {Button, Popconfirm, Space, Switch, Table} from "antd";
import React, { useEffect, useState } from "react";
import {DeleteOutlined, EditOutlined, LoadingOutlined} from "@ant-design/icons";
import {useTypedSelector} from "../../../../shared/common/hooks/useTypedSelector";
import {showErrorMessage} from "../../../../helpers/utils/ui";
import {unApi} from "../../../../shared/common/api/endpoints/reports/unApi";
import {adsCabinetApi} from "../../../../shared/common/api/endpoints/adsCabinetApi";



interface Props {
    mode:number
    addModalVisible:boolean
    refetch:boolean
    handleEditTask:(taskId:number)=>void
}
const YandexTable = ({ mode,addModalVisible,refetch:parentRefetch,handleEditTask }:Props) => {
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const [tableData,setTableData] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const [ refetch,setRefetch ] = useState(false)
     const getTableData =  async () => {
         try{
             setIsLoading(true)
             const values = {
                 school_id:Number(selectedSchool?.id),
                 status_id:mode
             }
             switch (mode){
                 case 1: {
                     const { data } = await unApi.getYandexTasks(Number(selectedSchool?.id))
                     setTableData(data)
                     break
                 }
                 case 2:{
                     const { data } = await unApi.getYandexMetrics(Number(selectedSchool?.id))
                     setTableData(data)
                     break
                 }
             }

         }catch(err){
             showErrorMessage("Произошла ошибка , повторите попытку позже.")
         }
         finally{
             setIsLoading(false)
         }
     }

     useEffect(() => {
         getTableData()
     },[mode,selectedSchool,refetch,addModalVisible,parentRefetch])

    const modeColumnsMap:{
        [key: number]: ColumnsType<any>;
    } = {
        1:[
            {
                title:"id",
                key:"id",
                dataIndex:"id"
            },
            {
                title:"Название задачи",
                key:"task_name",
                dataIndex:"task_name"
            },
            {
                title: "Что делаем",
                dataIndex: "task_type_name",
                key: "task_type_name",
            },
            {
                title:"Действия",
                dataIndex:"cab_actions",
                key:"cab_actions",
                render: (value, record) => {
                    return (
                        <Space>
                            <Popconfirm
                                title="Вы уверенны, что хотите удалить задачу?"
                                onConfirm={async () => {
                                    await unApi.removeYandexTask(record.id)
                                    setRefetch((prev) => !prev)
                                }}
                                okText="Да"
                                cancelText="Нет"
                            >
                                <Button
                                    danger
                                    type="default"
                                    size="small"
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                            <Button
                                type="default"
                                size="small"
                                onClick={()=>{handleEditTask(record.id)}}
                                icon={<EditOutlined />}
                            />
                        </Space>
                    )
                },
            },
        ],
        2:[
            {
                title:"Логин",
                key:"login",
                dataIndex:"login"
            },
            {
                title: "№ Счётчика",
                dataIndex: "counter_id",
                key: "counter_id",
            },
            {
                title: "Название счётчика",
                dataIndex: "counter_name",
                key: "counter_name",
                width:"12%"
            },
            {
                title: "Статус",
                dataIndex: "status",
                key: "status",
                render: (value, record) => (
                    <Switch
                        checked={value == true}
                        onChange={(value) => {
                            if (selectedSchool) {
                                const update = async () => {
                                    setIsLoading(true)
                                    await adsCabinetApi.updateStatusYandex(
                                        parseInt(selectedSchool.id),
                                        record.id,
                                        value
                                    )
                                    setIsLoading(false)
                                    setRefetch((prev)=>!prev)
                                }
                                update()
                            }
                        }}
                    />
                ),
            },
            {
                title:"Ошибка",
                dataIndex:"msg",
                key:"msg"
            },
            {
                title:"Действия",
                dataIndex:"cab_actions",
                key:"cab_actions",
                render: (value, record) => {
                    return (
                        <div>
                            <Popconfirm
                                title={record?.checking_tasks ? "У данного счётчика есть задачи , сначала удалите их." : "Вы уверенны, что хотите удалить счётчик?"}
                                onConfirm={async () => {
                                    record?.checking_tasks ? showErrorMessage("Перед удалением счётчика удалите его задачи.") :  await adsCabinetApi.removeYandexCounter(record.id)
                                        setRefetch((prev) => !prev)
                                }}
                                okText="Да"
                                cancelText="Нет"
                            >
                                <Button
                                    danger
                                    type="default"
                                    size="small"
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                        </div>
                    );
                },
            },
        ]
    }

    return (
        <Table
            columns={modeColumnsMap[mode]}
            dataSource={tableData}
            loading={{
                spinning: isLoading,
                indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
            }}
            bordered
        />
    )

}
export default YandexTable
