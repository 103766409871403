import React from 'react';
import { Table } from 'antd';

const dataSource = [
    {
        key: '1',
        activeUsers: 'от 0 до 1 000',
        cost30: 'р.3 000,00',
        cost1: 'р.100,00',
    },
    {
        key: '2',
        activeUsers: 'от 1 001 - 5 000',
        cost30: 'р.6 000,00',
        cost1: 'р.200,00',
    },
    {
        key: '3',
        activeUsers: 'от 5 001 до 10 000',
        cost30: 'р.9 000,00',
        cost1: 'р.300,00',
    },
    {
        key: '4',
        activeUsers: 'от 10 001 до 20 000',
        cost30: 'р.12 000,00',
        cost1: 'р.400,00',
    },
    {
        key: '5',
        activeUsers: 'от 20 001 до 30 000',
        cost30: 'р.15 000,00',
        cost1: 'р.500,00',
    },
    {
        key: '6',
        activeUsers: 'от 30 001 до 40 000',
        cost30: 'р.18 000,00',
        cost1: 'р.600,00',
    },
    {
        key: '7',
        activeUsers: 'от 40 001 до 50 000',
        cost30: 'р.21 000,00',
        cost1: 'р.700,00',
    },
    {
        key: '8',
        activeUsers: 'от 50 001 до 60 000',
        cost30: 'р.24 000,00',
        cost1: 'р.800,00',
    },
    {
        key: '9',
        activeUsers: 'от 60 001 до 70 000',
        cost30: 'р.27 000,00',
        cost1: 'р.900,00',
    },
    {
        key: '10',
        activeUsers: 'от 70 001 до 80 000',
        cost30: 'р.30 000,00',
        cost1: 'р.1 000,00',
    },
];

const columns = [
    {
        title: ()=><div className={"billing-header-cell"}>Кол-во активных<br /> пользователей за 30 дней</div>,
        dataIndex: 'activeUsers',
        key: 'activeUsers',
    },
    {
        title:()=><div className={"billing-header-cell"}>Стоимость за 30 дней</div>,
        dataIndex: 'cost30',
        key: 'cost30',
    },
    {
        title: ()=><div className={"billing-header-cell"}>Стоимость за 1 день</div>,
        dataIndex: 'cost1',
        key: 'cost1',
    },
];

export const UsersTable = () => <Table style={{maxWidth:"700px"}} pagination={false} dataSource={dataSource} columns={columns} />

