import React, { FC, useEffect, useState } from "react"

const getLabelName = (filterType: string) => {
    switch (filterType) {
        case "reg":
            return "Дата платежа"
        case "geo":
            return "Дата платежа"
        case "app":
            return "Дата платежа"
        case "orders":
            return "Дата платежа"
        case "payments":
            return "Дата платежа"
        case "webinar":
            return "Дата платежа"
        case "ads":
            return "Дата рекламы"
        default:
            return "Дата платежа"
    }
}

const ChartLabel: FC<any> = ({ chartFilter, isDisabled = false, chartType, width }) => {

    const [ label, setLabel ] = useState("")

    useEffect(() => {
        if (chartFilter) {
            setLabel(getLabelName(chartFilter))
        } else {
            setLabel("")
        }
    }, [ chartFilter ])

    if (chartType !== "line" && chartType !== "area") {
        return null
    }

    return chartFilter && !isDisabled ? (
        <div className="universal-chart-label" style={{ width: "100%" }}>
            <p>
                {label}
            </p>
        </div>
    ) : null
}

export default ChartLabel
