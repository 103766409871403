import React, { useEffect, useState } from "react"

import { Empty } from "antd"
import ru from "apexcharts/dist/locales/ru.json"
// eslint-disable-next-line import/default
import Chart from "react-apexcharts"

import { useTypedSelector } from "../../../../../hooks/useTypedSelector"
import Loading from "../Loading"
import { getSeriesAndLabels } from "../utils"


const ChartUniversal = ({ loadingChart }) => {
    const chartDiv = document.getElementById("universal_chart")

    const { tableData } = useTypedSelector((state) => state.table)
    const chartType = "bar"

    const [ series, setSeries ] = useState([])
    const [ labels, setLabels ] = useState([])
    const [ chartWidth, setChartWidth ] = useState(1000)
    const [ dimensionsChart, setDimensionsChart ] = useState({ height: 370 })
    const [ errorMessage, setErrorMessage ] = useState("Нет данных, соответствующих условиям отчёта")

    useEffect(() => {
        if (tableData.data.length > 0) {
            const { newSeries, newLabels } = getSeriesAndLabels(tableData)

            setLabels(newLabels)
            setSeries(newSeries)
        }
    }, [ tableData ])

    useEffect(() => {
        if (chartDiv) {
            const calcChartWidth = () => {
                const width = chartDiv.offsetWidth
                setChartWidth(width <= 1000 ? 1000 : width)
            }

            new ResizeObserver(calcChartWidth).observe(chartDiv)
            calcChartWidth()
        }
    }, [ chartDiv ])

    useEffect(() => {
        setDimensionsChart({
            width: chartWidth,
            height: 370
        })
    }, [ chartWidth ])

    const ChooseChartType = () => {

        if (tableData.data.length === 0 && Object.keys(tableData.total).length === 0) {
            return (
                <div style={{ width: dimensionsChart.width, height: dimensionsChart.height }}>
                    <div className="table-overlay">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={errorMessage} />
                    </div>
                </div>
            )
        }
        const chartProps = {
            width: dimensionsChart.width,
            height: 370,
        }
        const fontSizeForXaxis = () => {
            let fontsize;
            const width = dimensionsChart.width;

            if (width >= 1300) {
                fontsize = 12;
            } else if (width >= 1200) {
                fontsize = 10;
            } else {
                fontsize = 8;
            }

            return fontsize
        }
        const generalOptions = {
            chart: {
                locales: [ ru ],
                defaultLocale: "ru",
                stacked: true,
                animations: {
                    enabled: false,
                    toolbar: {
                        show: true,
                    },
                },
            },
            colors: [
                "#119CFF",
                "#7700FF",
                "#BD07FF",
                "#FF05D1",
                "#FF006E",
                "#FF1C1C",
                "#FF6A07",
                "#FFB711",
                "#FBFF19",
                "#A8FF11",
                "#00FF43",
                "#0FFFBB",
                "#07EEFF",
                "#0751FF",
                "#959299",
                "#4C00A3",
                "#6D0493",
                "#8E0275",
                "#7C0036",
                "#A01111",
                "#CE4400",
                "#B77D00",
                "#A0A300",
                "#6CA00B",
                "#00BC2F",
                "#0BBF8C",
                "#049BA5",
                "#0A6099",
                "#043199",
                "#4D4B4F",
            ],
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true,
                position: "right",
                offsetY: 40
            },
            noData: {
                text: errorMessage,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        total: {
                            enabled: false,
                        }
                    }
                },
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: function(value) {
                        return value.toLocaleString("ru-RU")
                    },
                    title: {
                        formatter: function(seriesName) {
                            return `${seriesName}:`
                        }
                    }
                }
            },
            xaxis: {
                type: "category",
                categories: labels,
                labels: {
                    rotateAlways: labels[0]?.split('/').length - 1 === 2,
                    hideOverlappingLabels: false,
                    style: {
                        fontSize:fontSizeForXaxis(),
                    }
                },
            },
            fill: {
                opacity: 1
            },
        }

        const variantChart = {
            bar: (
                <Chart
                    type="bar"
                    series={series}
                    options={{
                        ...generalOptions,
                    }}
                    {...chartProps}
                />
            ),
        }

        return <div>{variantChart[chartType]}</div>
    }

    return (
        <div className="universal-chart" id="universal_chart" fullHeight>
            <Loading loading={loadingChart}>
                {/*<ButtonsType />*/}
                {
                    loadingChart ?
                        <div style={{ height: dimensionsChart.height }} /> :
                        <ChooseChartType />
                }
            </Loading>

        </div>
    )
}

export default ChartUniversal
