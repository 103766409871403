import { adsCabinetActions } from "./slices/ads/adsCabinet/actions"
import { adsCabinetSliceReducer } from "./slices/ads/adsCabinet/adsCabinetSlice"
import { adsSpendActions } from "./slices/ads/adsSpend/actions"
import { adsSpendSliceReducer } from "./slices/ads/adsSpend/adsSpendSlice"
import { appActions } from "./slices/app/actions"
import { appSliceReducer } from "./slices/app/appSlice"
import { authActions } from "./slices/auth/actions"
import { authSliceReducer } from "./slices/auth/authSlice"
import { currentSchoolActions } from "./slices/currentSchool/actions"
import { currentSchoolSliceReducer } from "./slices/currentSchool/currentSchoolSlice"
import { currentUserActions } from "./slices/currentUser/actions"
import { currentUserSliceReducer } from "./slices/currentUser/currentUserSlice"
import { domainActions } from "./slices/domains/actions"
import { domainSliceReducer } from "./slices/domains/domainSlice"
import { partnerActions } from "./slices/partner/actions"
import { partnerSliceReducer } from "./slices/partner/partnerSlice"
import { chartActions } from "./slices/reports/chart/actions"
import { chartSliceReducer } from "./slices/reports/chart/chartSlice"
import { attrActions } from "./slices/reports/params/attributes/actions"
import { attrSliceReducer } from "./slices/reports/params/attributes/attrSlice"
import { compareActions } from "./slices/reports/params/compare/actions"
import { compareSliceReducer } from "./slices/reports/params/compare/compareSlice"
import { detailActions } from "./slices/reports/params/details/actions"
import { detailSliceReducer } from "./slices/reports/params/details/detailsSlice"
import { filterActions } from "./slices/reports/params/filter/actions"
import { filterSliceReducer } from "./slices/reports/params/filter/filterSlice"
import { metaActions } from "./slices/reports/params/meta/actions"
import { metaSliceReducer } from "./slices/reports/params/meta/metaSlice"
import { periodActions } from "./slices/reports/params/period/actions"
import { periodSliceReducer } from "./slices/reports/params/period/periodSlice"
import { reportsActions } from "./slices/reports/params/reports/actions"
import { reportsSliceReducer } from "./slices/reports/params/reports/reportsSlice"
import { tableActions } from "./slices/reports/table/actions"
import { tableSliceReducer } from "./slices/reports/table/tableSlice"
import { staffActions } from "./slices/staff/actions"
import { staffSliceReducer } from "./slices/staff/staffSlice"
import { webinarActions } from "./slices/webinar/actions"
import { webinarSliceReducer } from "./slices/webinar/webinarSlice"
import {dashboardActions} from "./slices/dashboard/actions";
import {dashboardSliceReducer} from "./slices/dashboard/dashboardSlice";

export const rootAction = {
    ...appActions,
    ...authActions,
    ...currentUserActions,
    ...currentSchoolActions,
    ...domainActions,
    ...staffActions,
    ...webinarActions,
    ...adsSpendActions,
    ...adsCabinetActions,

    ...periodActions,
    ...attrActions,
    ...dashboardActions,
    ...detailActions,
    ...filterActions,
    ...metaActions,
    ...chartActions,
    ...tableActions,
    ...reportsActions,
    ...compareActions,
    ...partnerActions,
}

export const rootReducer = {
    app: appSliceReducer,
    auth: authSliceReducer,
    currentUser: currentUserSliceReducer,
    currentSchool: currentSchoolSliceReducer,
    domains: domainSliceReducer,
    staff: staffSliceReducer,
    webinar: webinarSliceReducer,
    adsSpend: adsSpendSliceReducer,
    adsCabinet: adsCabinetSliceReducer,
    dashboard:dashboardSliceReducer,
    period: periodSliceReducer,
    attributes: attrSliceReducer,
    details: detailSliceReducer,
    filter: filterSliceReducer,
    meta: metaSliceReducer,
    chart: chartSliceReducer,
    table: tableSliceReducer,
    reports: reportsSliceReducer,
    compare: compareSliceReducer,
    partner:partnerSliceReducer,
}
