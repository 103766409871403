import React, { FC, useMemo, useState } from "react"

import { Popover, Typography } from "antd"

import DateTimeRangePicker from "./pickers/DateTimeRangePicker"
import LazyListPicker from "./pickers/LazyListPicker"
import ListPicker from "./pickers/ListPicker"
import NumberPicker from "./pickers/NumberPicker"
import NumberRangePicker from "./pickers/NumberRangePicker"
import TextPicker from "./pickers/TextPicker"
import TimeRangePicker from "./pickers/TimeRangePicker"
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector"
const CompareFilterItem: FC<any> = ({ id, index, type, name, full_name, searchName, color, icon, path, namePath, extra, prefix, modalVisibleHandler, showListPlus }) => {
    const filters = useTypedSelector((state) => state.compare.compareFilters[`compareFilters${prefix}`])
    const [ visible, setVisible ] = useState(false)

    const visibleHandler = (value: boolean) => {
        setVisible(value)
        modalVisibleHandler(value)
    }

    const list = false

    const itemFullName = useMemo(() => {
        let _name

        if (full_name) {
            _name = full_name
        } else {
            _name = name
        }

        return _name
    }, [ full_name, name ])

    const contentPopover: { [key: string]: React.ReactElement } = useMemo(() => {
        const propsPicker = { id, color, index, icon, name, full_name, type, path, namePath, list, prefix, showListPlus }

        return {
            datetimerange: <DateTimeRangePicker {...propsPicker} modalVisibleHandler={visibleHandler} />,
            timerange: <TimeRangePicker {...propsPicker} modalVisibleHandler={visibleHandler} />,
            list: <ListPicker {...propsPicker} modalVisibleHandler={visibleHandler} />,
            lazylist: <LazyListPicker {...propsPicker} modalVisibleHandler={visibleHandler} />,
            text: <TextPicker {...propsPicker} modalVisibleHandler={visibleHandler} />,
            number: <NumberPicker {...propsPicker} modalVisibleHandler={visibleHandler} />,
            numberrange: <NumberRangePicker {...propsPicker} modalVisibleHandler={visibleHandler} />,
        }
    }, [ color, icon, id, index, name, full_name, path, type, namePath, list, prefix ])

    const CountEl = () => {
        const filter = filters.find((fItem: any) => fItem.id === id)

        if (!filter) return null
        if (filter.type === "datetimerange") {
            if (!filter.start && !filter.end) {
                return null
            }
        }

        let count = 1
        if (filter.checkeds) count = filter.checkeds.length
        return count ? <div className="picker-counter-element" /> : null
    }

    return (
        <Popover
            content={
                <div>
                    <div style={{ marginBottom: 7 }}>
                        <Typography.Text strong>{itemFullName}</Typography.Text>
                    </div>
                    {contentPopover[type]}
                </div>
            }
            open={visible}
            onOpenChange={setVisible}
            trigger="click"
            placement={"right"}
        >
            <div style={{ display: "inline-flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                {searchName}
                <span className={extra ? "counter-icon-wrapper" : ""}>
                    <CountEl />
                </span>
                {extra ? extra : null}
            </div>

        </Popover>
    )
}

export default CompareFilterItem
