import React, { FC } from "react"

import { Tabs } from "antd"

import AccrualsTab from "./tabs/AccrualsTab"
import CommonInfoTab from "./tabs/CommonInfoTab"
import PaymentsTab from "./tabs/PaymentsTab"
import ReferralsTab from "./tabs/ReferralsTab"


const PartnerPage: FC = () => {

    const tabs = () => [
        { label: "Общая информация", key: "item-1", children: <CommonInfoTab />, forceRender: true },
        { label: "Рефералы", key: "item-2", children: <ReferralsTab />, forceRender: true },
        { label: "Начисления", key: "item-3", children: <AccrualsTab />, forceRender: true },
        { label: "Выплаты", key: "item-4", children: <PaymentsTab />, forceRender: true },
        { label: "Рекламные креативы", key: "item-5", children: <h1>Скоро добавим информацию</h1>, forceRender: true },
    ]

    return (
        <div>
            <Tabs
                type="card"
                items={tabs()}
            />
        </div>
    )
}

export default PartnerPage
